<template>
  <v-card elevation="0" class="main-content pa-0 px-0 pb-0 pt-0 fill-height">
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      customMessage=""
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>

    <v-row>
      <v-col cols="5">
        <v-card-title>
          <h4>Exclusão de reservas</h4>
        </v-card-title>
        <v-card-subtitle>
          Efetue a exclusão das reservas dos passageiros que já foram realizadas
          no sistema.
        </v-card-subtitle>
      </v-col>

      <v-col class="mt-3 mr-3" align="right">
        <GenericButton
          title="Download do template"
          icon="mdi-tray-arrow-down"
          href="exclusao-reserva-exemplo.csv"
          target="_blank"
          class="mr-4"
          secondary
        ></GenericButton>
    
        <GenericDialog
          close
          :visible="showDialog"
          v-on:close-dialog="closeDialog"
          width="600px"
        >
          <template v-slot:header>
            <v-card-title
              class="px-0 py-0 ml-1"
              style="color: #393939; font-size: 24px"
            >
              Importar exclusão de reserva
            </v-card-title>
          </template>

          <template v-slot:subtitle>
            <v-divider class="mt-4"></v-divider>

            <v-card-subtitle class="pl-0 ml-1 pt-6">
              Para importar a exclusão de reserva, selecione a planilha
              desejada.
            </v-card-subtitle>
          </template>

          <template v-slot:body>
            <v-card-actions>
              <v-file-input
                v-model="file"
                label="Selecione um arquivo do seu computador"
                filled
                dense
                cleable
                accept=".csv"
                prepend-inner-icon="mdi-cloud-upload-outline"
                :prepend-icon="null"
                @change="fileWasChosen = true"
                @click:clear="resetFileInput"
              ></v-file-input>
            </v-card-actions>

            <v-col class="mt-3 mr-0 pt-2 pr-0" align="right">
              <GenericButton
                title="Importar"
                icon="mdi-tray-arrow-up"
                target="_blank"
                class="md-6"
                primary
                v-on:click="sendFile"
                :disabled="!fileWasChosen"
              ></GenericButton>
            </v-col>
          </template>
        </GenericDialog>

        <GenericButton
          title="Importar exclusão"
          icon="mdi-tray-arrow-up"
          primary
          v-on:click="openDialog"
        ></GenericButton>
      </v-col>
    </v-row>

    <v-card-text>
      <v-progress-linear
        :indeterminate="indeterminateQuery"
        :active="showProgressBar"
      ></v-progress-linear>

      <v-data-table
        show-expand
        class="elevation-1"
        :headers="headers"
        :single-expand="singleExpand"
        item-key="id"
        :items="logTable"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLogs"
      >
        <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
          <td v-if="item.hasErrors" class="text-start">
            <v-btn
              icon
              @click="expand(!isExpanded)"
              class="v-data-table__expand-icon"
              :class="{ 'v-data-table__expand-icon--active': isExpanded }"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </td>
        </template>

        <template v-slot:[`expanded-item`]="{ item }">
          <tr v-for="tableError in item.errorsByTable" :key="tableError.id">
            <td class="pt-3 pb-2 px-8">Error na tabela</td>
            <td class="pt-3 pb-2 px-8">
              {{ tableError.type }}
            </td>
            <td></td>
            <td class="pt-3 pb-2 px-8">
              Erro ao importar a tabela. Tente novamente.
            </td>
          </tr>

          <tr v-for="lineError in item.errorsByLine" :key="lineError.id">
            <td class="pt-3 pb-2 px-8">Error na linha</td>
            <td class="pt-3 pb-2 px-8">{{ lineError.lineNumber }}</td>
            <td class="pt-3 pb-2 px-8">
              {{ translateErrorByLine(lineError.type) }}
            </td>
            <td class="pt-3 pb-2 px-8">
              {{ lineError.additionalInformation }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <EmailSender ref="emailSender" />
  </v-card>
</template>

<script>
import { ReservationLongTimeService } from "../services/reservationLongTime";
import { EmployeeService } from "@/services/employee";
import { BookingService } from "@/services/booking";
import GenericDialog from "@/components/GenericDialog";
import CustomAlert from "@/components/CustomAlert";
import EmailSender from "@/components/EmailSender";
import Papa from "papaparse";
import GenericButton from "../components/GenericButton.vue";
import { PermissionService } from "../services/permission";

export default {
  name: "ReservationManagmentExclusionTab",
  components: {
    CustomAlert,
    GenericDialog,
    EmailSender,
    GenericButton,
  },
  watch: {
    options: {
      handler() {
        this.getLogSendFile();
      },
      deep: true,
    },
  },
  data: () => ({
    // openDialog: false,
    headers: [
      { text: "Nome", value: "logFileName" },
      { text: "Status", value: "translatedStatus" },
      { text: "Data da importação", value: "formattedDate" },
      { text: "", value: "data-table-expand" },
    ],
    options: {},
    fileWasChosen: false,
    file: null,
    logTable: [],
    showAlert: false,
    showDialog: false,
    showProgressBar: true,
    indeterminateQuery: true,
    loading: true,
    expanded: [],
    permission: "",
    search: "",
    alertMessageType: "default",
    alertType: "success",
    singleExpand: true,
    btnKey: 0,
    totalLogs: 0,
    currentUser: {},
  }),
  methods: {
    openDialog() {
      this.showDialog = true;
      this.resetFileInput();
    },
    closeDialog() {
      this.showDialog = false;
    },
    closeAlert() {
      this.showAlert = false;
    },
    sendFile: async function () {
      try {
        if (!this.file) {
          this.setAlertProps(false, "noFile");
          return;
        }
        await ReservationLongTimeService.registerExclusionReservation(
          this.file
        );
        await this.uploadSuccess();
        this.sendEmail();
        setTimeout(() => {
          this.resetFileInput();
          this.getLogSendFile();
        }, 1000);
      } catch (error) {
        this.uploadError();
        setTimeout(() => {
          this.resetFileInput();
          this.getLogSendFile();
        }, 1000);
      } finally {
        setTimeout(() => {
          this.closeAlert();
        }, 3000);
      }
    },
    resetFileInput() {
      this.file = null;
      this.fileWasChosen = false;
      this.btnKey++;
    },
    async uploadSuccess() {
      this.setAlertProps(true, "uploadSuccess");
      this.closeDialog();
    },
    uploadError() {
      this.setAlertProps(false, "uploadError");
    },
    async getLogSendFile() {
      try {
        this.loading = true;
        this.logTable = await ReservationLongTimeService.getLogDeleted();
        this.totalLogs = this.logTable.length;
        this.logTable.forEach((log) => {
          log.translatedStatus = this.translateStatus(log.status);
          const time = new Date(log.date).toLocaleTimeString().slice(0, 5);
          const date = new Date(log.date).toLocaleDateString();
          log.formattedDate = date + " " + time;
        });
        this.loading = false;
      } catch {
        this.logTable = null;
        this.loading = false;
      }
    },
    translateStatus(status) {
      if (status == null) return "";
      if (status == "SUCCESS") return "Importado com sucesso";
      if (status == "EXCLUSION_BOOKINGS")
        return "Aguarde, excluindo as reservas";
      if (status == "ERROR") return "Falha na importação";
    },
    translateErrorByLine(error) {
      if (error == null) return "";
      if (error == "EMPLOYEE") return "Passageiro não encontrado";
      return "Erro na importação.";
    },
    setAlertProps(isSuccess, type) {
      if (isSuccess) {
        this.alertType = "success";
      } else {
        this.alertType = "error";
      }
      this.showAlert = true;
      this.alertMessageType = type;
    },
    async getAllRegistrationNumbersFromFile() {
      return await this.convertCSVToArray(this.file);
    },
    convertCSVToArray(file) {
      return new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          delimiter: ";",
          transformHeader: function (h) {
            return h.replaceAll(" ", "_").toLowerCase();
          },
          skipEmptyLines: true,
          complete: function (results) {
            resolve(results.data);
          },
          error: function (e) {
            reject(e);
          },
        });
      });
    },
    async getEmployeeData(registrationNumbers) {
      let employees = [];

      for (const registrationNumber of registrationNumbers) {
        const employee = await EmployeeService.getEmployeeSearch(
          registrationNumber.usuario_id
        );
        if (employee.length !== 0) {
          if (
            !employees.some(
              (e) => e.registrationNumber === employee[0].registrationNumber
            )
          ) {
            let hasBooking = await this.checkIfEmployeeHasFutureBooking(
              employee[0]
            );
            if (hasBooking) {
              employees.push(employee[0]);
            }
          }
        }
      }

      return employees;
    },
    async checkIfEmployeeHasFutureBooking(employee) {
      const booking = await BookingService.getMyBooking(
        employee.registrationNumber
      );

      if (booking.length === 0) {
        return false;
      }

      const today = new Date();

      for (const book of booking) {
        let startDate = this.setStartDate(book.startDate);

        if (startDate.getTime() >= today.getTime()) {
          return true;
        }
      }
      return false;
    },
    async sendEmail() {
      let registrationNumbers = await this.getAllRegistrationNumbersFromFile();
      let employees = await this.getEmployeeData(registrationNumbers);

      for (const employee of employees) {
        const paramsToEmployee = {
          to: employee.email,
          subject: "Reserva de ônibus removida",
          emailBody: ``,
          administrador: false,
        };

        await this.$refs.emailSender.postEmailDeleteBatchBooking(
          paramsToEmployee
        );
      }

      let paramsToAdmin = {
        to: this.currentUser.email,
        subject: "Remoção de reserva via planilha",
        emailBody: ``,
        administrador: true,
      };
      this.$refs.emailSender.postEmailDeleteBatchBooking(paramsToAdmin);
    },
    setStartDate(startDate) {
      const dateParts = startDate.split("/");
      const newDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      return newDate;
    },
  },
  async beforeMount() {
    this.permission = PermissionService.getPermission();
    this.currentUser = await EmployeeService.getEmployee(
      localStorage.getItem("registrationNumber")
    );
    this.getLogSendFile();
  },
  async mounted() {
    this.showProgressBar = false;
    this.indeterminateQuery = true;
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;

  .v-card-subtitle {
    color: $greyVale40;
  }

  .v-card-text {
    text-align: center;
  }
}

.v-btn {
  text-transform: none;
}

.v-data-table {
  tbody {
    color: $greyVale;

    .v-icon {
      color: $greyVale;
    }
  }

  th {
    text-align: center;
  }

  td {
    font-size: 14px;
  }
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding-top: 0px;
  padding-left: 1px;
}

.v-input__slot {
  margin-bottom: 0px;
}

.v-text-field.v-text-field--enclosed ::v-deep .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 2px;
}

.v-tab {
  text-transform: capitalize;
}
</style>
