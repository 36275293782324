<template v-bind:class="{ 'fill-height': fillHeight }">
  <div class="div-principal">

    <template v-if="loading">
      <v-progress-linear
        indeterminate
        color="primary"
      ></v-progress-linear>
    </template>

    <div
      v-if="!loading && bookings.length < 1"
      v-bind:class="{ 'fill-height': fillHeight }"
      class=""
    >
      <div class="card-booking pa-4">
        <div class="div-bus-icon"><v-icon class="" color="primary">mdi-bus-alert</v-icon></div>
        <strong>Não possui reservas futuras.</strong>
      </div>
    </div>

    <div v-for="bookingItem in bookings"
      v-bind:key="bookingItem.id"
    >
      <div class="card-booking pa-4">
        <div class="div-bus-icon"><v-icon class="" color="primary">mdi-bus</v-icon></div>
        <div>
        <strong>
          {{ bookingItem.busLine ? bookingItem.busLine.code : "" }} - {{ bookingItem.departureBusStop ? bookingItem.departureBusStop.name : "" }}        
        </strong>
        <div class="div-data-mobile"> {{ bookingItem ? bookingItem.endDate : "" }} </div>
        </div>
        <div class="div-separator col-visibility"></div>
        <v-col class="col-visibility"> 
          <v-row class="title-booking">Data inicial</v-row>
          <v-row> {{ bookingItem ? bookingItem.startDate : "" }}</v-row>
        </v-col>
        <v-col class="col-visibility"> 
          <v-row class="title-booking">Data final</v-row>
          <v-row> {{ bookingItem ? bookingItem.endDate : "" }}</v-row>
        </v-col>
        <v-col class="col-visibility"> 
          <v-row class="title-booking">Direção</v-row>
          <v-row> {{ bookingItem ? bookingItem.direction : "" }}</v-row>
        </v-col>
        <v-col class="col-visibility"> 
          <v-row class="title-booking">Dias de recorrência</v-row>
          <v-row> {{ bookingItem.daysRecurrence }} </v-row>
        </v-col>
        <v-col class="" align="end">
            <v-icon class=""  @click="setReservationModel(bookingItem, 'edit')">mdi-pencil</v-icon>
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>
import { BookReservationStore } from "@/store/bookReservationForm";
import { BookingService } from "@/services/booking";
import { BookUtils } from "@/utils/bookUtils";

export default {
  name: "LatestBooking",
  components: {
  },
  store: BookReservationStore,
  data: () => ({
    bookings: [],
    fillHeight: "fill-height",
    padding: "",
    margin: "mt-2",
    loading: false,
  }),
   methods: {
    setReservationModel(reservation, operation) {
      BookReservationStore.commit("setReservationModel", reservation);
      BookReservationStore.commit("setOperation", operation);
      this.$router.push("/booking");
    },
   },   
  async beforeMount() {
    try {
      this.loading = true;
      this.bookings = await BookingService.getLatestBooking(
        localStorage.getItem("registrationNumber")
      );
      BookUtils.updateDaysRecurrenceList(this.bookings);

      if (this.bookings.length > 1) {
        this.fillHeight = "";
        this.padding = "pa-2";
      }
    } catch (err) {
      this.bookings = [{}];
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.div-principal {
   width: 100%;   
   min-height: 80px;
   color: #393939;
}
.card-booking {
   display: grid;
   grid-template-columns: 0.1fr 3fr 0.1fr 1fr 1fr 1fr 1fr 0.1fr;
   min-height: 88px;
   align-items: center;
   border: solid 1px;
   border-radius: 8px;
   border-color: #E0E0E0;
   margin-bottom: 1.5rem;
   @media (max-width: '1365px') {
    grid-template-columns: 0.1fr 3fr 0 0 0 0 0 0.1fr;
    min-height: 40px;
   }
}
.div-separator{
  width: 1px;
  height: 38px;
  background-color: #E0E0E0;
  margin-right: 18px;
}
.div-bus-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 4px;
  background-color: #D6EBEA;
}
.title-booking {
  font-size: 12px;
  color: #6F6F6F;
}
.col-visibility {
  font-size: 14px;
  @media (max-width: '1365px') {
    visibility: hidden !important;
    height: 0px;
    width: 0px;
  }
}
.div-data-mobile {
  visibility: hidden !important;
  height: 0px;
  width: 0px;  
  @media (max-width: '1365px') {
    visibility: initial !important;
    width: 100%;
    height: 100%;
    font-size: 14px;
  }
}

</style>
