<template>
  <v-card class="main-content pa-2 fill-height">
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :customMessage="customMessage"
      :type="alertType"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>
    <v-row align="center">
      <v-col align-self="center" cols="8">
        <v-card-title>
          <h4>Alterar tipo de ônibus</h4>
        </v-card-title>
        <v-card-subtitle>
          Aqui você pode visualizar, editar ou excluir os tipos de ônibus.
        </v-card-subtitle>
      </v-col>
      <v-col align="right" class="mr-4">
        <GenericButton
          title="Cancelar"
          icon="mdi-close"
          class="mr-4"
          secondary
          @click="cancelEdit"
        ></GenericButton>
        <v-progress-circular
          style="width: 103px"
          :size="25"
          :width="2"
          color="primary"
          indeterminate
          v-show="loading"
        >
        </v-progress-circular>
        <GenericButton
          title="Salvar"
          icon="mdi-content-save-outline"
          primary
          :disabled="!isEdited"
          v-show="!loading"
          @click="updateBusType"
        ></GenericButton>
      </v-col>
    </v-row>
    <v-card outlined elevation="0" class="pa-6 ma-4">
      <v-form ref="form">
        <v-row no-gutters class="pt-6">
          <v-col cols="5">
            <v-text-field
              v-model="busType.name"
              :rules="[isNotEmptyRule]"
              label="Nome"
              outlined
              dense
              @input="fieldValueHasChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="5" class="ml-13 pl-13">
            <v-select
              label="Abrangência"
              v-model="busType.coverage"
              :items="coverageTypes"
              item-text="name"
              item-value="value"
              outlined
              dense
              @input="fieldValueHasChanged"
            ></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-6">
          <v-col cols="5">
            <v-select
              label="Selecione o estado"
              v-model="uf"
              :items="listaEstados"
              @change="UFChanged()"
              item-text="nome"
              item-value="sigla"
              :rules="[isNotEmptyRule]"
              outlined
              dense
              @input="fieldValueHasChanged"
            />
          </v-col>
          <v-col cols="5" class="ml-13 pl-13">
            <v-select
              label="Complexo"
              v-model="busType.region"
              :items="regions"
              item-text="name"
              return-object
              :rules="[isNotEmptyRule]"
              :disabled="uf == null"
              outlined
              dense
              @input="fieldValueHasChanged"
            />
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-6">
          <v-col cols="5">
            <v-currency-field
              label="Custo fixo"
              v-model="busType.fixedCost"
              prefix="R$"
              outlined
              dense
              @input="fieldValueHasChanged"
            ></v-currency-field>
          </v-col>
          <v-col cols="5" class="ml-13 pl-13">
            <v-currency-field
              label="Custo variável"
              v-model="busType.variableCost"
              prefix="R$"
              outlined
              dense
              @input="fieldValueHasChanged"
            ></v-currency-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-6">
          <v-col cols="5">
            <v-text-field
              label="Capacidade"
              v-model="busType.capacity"
              :rules="[isNotEmptyRule, isGreaterThanZero]"
              outlined
              dense
              type="number"
              min="0"
              @input="fieldValueHasChanged"
            ></v-text-field>
          </v-col>
          <v-col cols="5" class="ml-13 pl-13">
            <v-text-field
              label="Capacidade preferencial"
              v-model="busType.capacityForPrioritySeats"
              :rules="[isNotEmptyRule, isPositiveNumber]"
              outlined
              dense
              type="number"
              min="0"
              @input="fieldValueHasChanged"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-6">
          <v-col cols="5">
            <v-select
              label="Elevador para acessibilidade"
              v-model="busType.accessibilityElevator"
              :items="accessibilityElevatorOptions"
              item-text="name"
              item-value="value"
              outlined
              dense
              @input="fieldValueHasChanged"
            >
            </v-select>
          </v-col>
          <v-col cols="5" class="ml-13 pl-13">
            <v-text-field
              label="Emissão de CO2"
              v-model="busType.carbonDioxideEmission"
              outlined
              dense
              type="number"
              min="0"
              @input="fieldValueHasChanged"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-dialog v-model="showCancelConfirm" width="500">
      <v-card>
        <v-card-title> Cancelar </v-card-title>
        <v-card-text> Tem certeza de que gostaria de cancelar ? </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <GenericButton
            class="mr-4"
            title="Não"
            secondary
            @click="showCancelConfirm = false"
          ></GenericButton>
          <GenericButton
            title="Sim"
            primary
            @click="cancelConfirm"
          ></GenericButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { isNotEmptyRule } from "@/services/validation";
import { isGreaterThanZero } from "@/services/validation";
import { isPositiveNumber } from "@/services/validation";
import { BusTypeFormStore } from "@/store/busTypeForm";
import { BusTypeService } from "@/services/busType";
import { RegionService } from "@/services/region";
import CustomAlert from "@/components/CustomAlert";
import { estados } from "@/store/estados";
import GenericButton from "../components/GenericButton.vue";
import { PermissionService } from "../services/permission";

export default {
  name: "BusTypeEdid",
  store: BusTypeFormStore,
  components: {
    CustomAlert,
    GenericButton,
  },
  data: () => ({
    loading: false,
    busType: {},
    busTypeDefault: {},
    listaEstados: estados,
    uf: null,
    ufDefault: null,
    regions: [],
    editMode: false,
    originalBusType: {},
    showAlert: false,
    customMessage: "",
    alertMessageType: "default",
    alertType: "success",
    coverageTypes: [
      { name: "Intermunicipal", value: "INTERCITY" },
      { name: "Intramunicipal", value: "INTRACITY" },
    ],
    accessibilityElevatorOptions: [
      { name: "Sim", value: true },
      { name: "Não", value: false },
    ],
    busTypeBackup: {},
    showCancelModal: false,
    isEdited: false,
    showCancelConfirm: false,
  }),
  methods: {
    isNotEmptyRule,
    isPositiveNumber,
    isGreaterThanZero,
    fieldValueHasChanged() {
      if (
        this.busTypeDefault.name.localeCompare(this.busType.name, {
          sensitivity: "accent",
        }) != 0 ||
        this.busTypeDefault.coverage.localeCompare(this.busType.coverage, {
          sensitivity: "accent",
        }) != 0 ||
        this.ufDefault.localeCompare(this.uf, {
          sensitivity: "accent",
        }) != 0 ||
        this.busTypeDefault.region.id.localeCompare(this.busType.region.id, {
          sensitivity: "accent",
        }) != 0 ||
        this.busTypeDefault.fixedCost != this.busType.fixedCost ||
        this.busTypeDefault.variableCost != this.busType.variableCost ||
        this.busTypeDefault.capacity != this.busType.capacity ||
        (this.busTypeDefault.capacityForPrioritySeats !=
          this.busType.capacityForPrioritySeats &&
          this.busType.capacityForPrioritySeats > 0) ||
        this.busTypeDefault.carbonDioxideEmission !=
          this.busType.carbonDioxideEmission ||
        this.busTypeDefault.accessibilityElevator !=
          this.busType.accessibilityElevator
      ) {
        this.isEdited = true;
      } else {
        this.isEdited = false;
      }
    },
    deleteError() {
      this.setAlertProps(false, "deleteError");
    },
    async updateBusType() {
      try {
        if (!this.$refs.form.validate()) {
          return;
        }

        this.loading = true;
        await BusTypeService.updateBusType(this.busType);
        this.loading = false;
        this.alertType = "success";
        this.showAlert = true;
        this.alertMessageType = "defaultSuccess";
        this.$router.push({
          path: "bus-type-management",
          query: { showAlert: true },
        });
      } catch (e) {
        if (e.status === 409) {
          this.alertType = "warning";
          this.customMessage = e.data.message;
        } else {
          this.alertType = "error";
        }
        this.alertMessageType = "defaultError";
        this.showAlert = true;
        this.loading = false;
      }
    },
    closeAlert() {
      this.showAlert = false;
    },
    cancelEdit() {
      this.showCancelConfirm = true;
    },
    cancelConfirm() {
      this.showCancelConfirm = false;
      this.$router.push("/bus-type-management");
    },
    async UFChanged() {
      this.regions = await RegionService.getRegionByUf(this.uf);
      this.busType.region = null;
      this.busType.site = null;
      this.sites = [];
    },
  },
  async beforeMount() {
    this.loading = true;
    this.permission = PermissionService.getPermission();
    if (this.permission !== "admin") {
      this.$router.push("home");
    }
    this.busType = BusTypeFormStore.getters.busType;

    if (this.busType.region) {
      this.uf = this.busType.region.uf;
      this.regions = await RegionService.getRegionByUf(this.uf);
    }
    this.originalBusType = Object.assign({}, this.busType);
    this.busTypeDefault = {
      name: this.busType.name,
      coverage: this.busType.coverage,
      region: this.busType.region,
      fixedCost: this.busType.fixedCost,
      variableCost: this.busType.variableCost,
      capacity: this.busType.capacity,
      capacityForPrioritySeats: this.busType.capacityForPrioritySeats,
      carbonDioxideEmission: this.busType.carbonDioxideEmission,
      accessibilityElevator: this.busType.accessibilityElevator,
    };
    this.ufDefault = this.uf;

    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";
.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;
}

.cancelButton {
  border-color: $greyVale60;
}
</style>
