import api from "./api";

export class UserExperience {
    
    static async saveUserExperience(request) {
        let endpoint = `/userExperience`
        let response = await api.post(endpoint, request);

        return response.data;
    }
}