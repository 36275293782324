import api from "./api";

export class RegionService {

    static async getAllRegions() {
        const endpoint = `/regions/all`;
        let response = await api.get(endpoint);
        return response.data;
    }

    static async getAllRegionsFiltered(search) {
        const endpoint = `/regions/?search=${search}`;
        let response = await api.get(endpoint);
        return response.data;
    }

    static async deleteRegion(params) {
        const endpoint = `/region/${params}`;
        let response = await api.delete(endpoint);
        return response;
    }

    
    static async editRegion(params) {
        const endpoint = `/region/`;                    
        let response = await api.put(endpoint, params);
        return response;
    }

    static async saveRegion(params) {
        const endpoint = `/region/`;
        let response = await api.post(endpoint, params);
        return response;
    }

    static async getRegionById(id) {
        const endpoint = `/region/` + id;
        let response = await api.get(endpoint);
        return response.data;
    }
    static async getRegionByUf(uf) {
      const endpoint = `/regionByUf/` + uf;
      let response = await api.get(endpoint);
      return response.data;
  }

    static async getRegionIdByBusLineId(id) {
        const endpoint = `/regionByBusLine/` + id;
        let response = await api.get(endpoint);
        return response.data;
    }

    static async getSitesByRegionId(id) {      
      const endpoint = `/siteByRegion/` + id;
      let response = await api.get(endpoint);
      return response.data; 
    }

    static async verifyIfOkToDeleteSite(id) {
      const endpoint = `/verifySiteToDelete/` + id;
      let response = await api.get(endpoint);
      return response.data; 
  }
}