<template>
  <v-dialog
    v-model="show"
    :retain-focus="false"
    class="voucher-modal"
    :width="width"
  >
    <template v-slot:activator="{ on, attrs }">
      <a v-bind="attrs" v-on="on">
        <slot name="openLabel"></slot>
      </a>
    </template>

    <v-card :width="width" class="px-4 pb-4 pt-4">
      <div class="title-conainer">
        <v-card-title class="pl-4 pt-2"
          ><slot name="title"></slot
        ></v-card-title>
        <v-btn class="mb-2" icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-card-text class="pl-4 pr-4 pb-4"
        ><slot name="body"></slot
      ></v-card-text>
      <v-divider class="mx-4 mb-4 mt-0" />
      <v-card-actions class="d-flex actions m-0">
        <slot name="actions"></slot>
        <GenericButton
          icon="mdi-arrow-left"
          title="Voltar"
          secondary
          v-on:click="show = false"
        >
        </GenericButton>
        <slot name="action"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GenericButton from "./GenericButton.vue";

export default {
  name: "DialogModal",
  components: {
    GenericButton,
  },
  props: {
    width: {
      type: String,
      default: "auto",
    },
  },
  data: () => ({
    show: false,
  }),
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

::v-deep .v-application--is-ltr .v-card__actions > .v-btn {
    margin-left: 0px;
    margin: 0px;
}

.voucher-modal {
  width: auto;
}

.voucher-card {
  width: fit-content;
  padding: 32px;
}

.title-conainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions {
  justify-content: flex-end;
}
</style>
