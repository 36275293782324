<template>
  <v-dialog v-if="show" :width="1024" persistent>
    <v-card class="pb-6">
      <v-card-title class="py-6 d-flex justify-space-between">
        <h4>Orientações de importação</h4>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle style="font-size: 16px;">
        Aqui estão algumas orientações para ajudá-lo a importar corretamente
        seus arquivos de cadastro. Certifique-se de seguir estas instruções para
        garantir uma importação precisa:
      </v-card-subtitle>
      <v-card-text class="pb-6 main_text">
        <ol>
          <li>
            <b>Formato do arquivo: </b> O arquivo de importação deve estar no
            formato CSV (Comma Separated Values). Baixe o arquivo de exemplo,
            inclua os dados e salve no formato correto.
          </li>
          <li v-if="importEmployeeType">
            <b>Campos obrigatórios: </b> Certifique-se de incluir os seguintes
            campos obrigatórios no seu arquivo de importação:
            <ul>
              <li>
                <b>registrationNumber:</b> número da matrícula do passageiro que
                não pode ser repetido com outro cadastro de passageiro;
              </li>
              <li><b>name:</b> nome completo do passageiro;</li>
              <li><b>category:</b> adicionar por padrão “ADM”;</li>
              <li>
                <b>shift:</b> regime de trabalho do passageiro, sendo “REMOTE”
                para remotos, e “ON_SITE” para Presencial;
              </li>
              <li>
                <b>isThirdParty:</b> campo para informar se passageiro é
                funcionário terceiro ou não, sendo “true” para funcionários
                terceiros, e “false” para funcionários Vale;
              </li>
              <li>
                <b>region:</b> campo para informar complexo que o passageiro está
                vinculado(a), lembre-se de adicionar o texto exatamente igual ao
                cadastro do complexo no sistema, caso contrário o sistema não
                realizará a importação;
              </li>
              <li>
                <b>address:</b> endereço completo do passageiro, informar o
                endereço no seguinte formado “Logradouro completo, número,
                bairro, cidade, estado, cep”. Algumas orientações sobre o campo
                de endereço:
                <ul>
                  <li>
                    o logradouro do endereço tem que ser extenso (Rua, Avenida)
                  </li>
                  <li>
                    o estado deve ser preenchido por extenso (Paraná, São Paulo,
                    Minas Gerais)
                  </li>
                  <li>separar as informações por vírgula</li>
                  <li>
                    o campo CEP não é obrigatório, mas se for incluso adicionar
                    no final e não utilizar traço (“-“);
                  </li>
                  <li>
                    exemplo de endereço corretamente informado: Avenida Afonso
                    Pena, 1212, Centro, Belo Horizonte, Minas Gerais, 30130003.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li v-else>
            <b>Campos obrigatórios: </b> Certifique-se de incluir os seguintes
            campos obrigatórios no seu arquivo de importação:
            <ul>
              <li>
                <b>usuario id:</b> número de registro do passageiro, que pode
                ser matrícula (deve possuir 8 dígitos) e CPF (deve possuir 11
                dígitos). Se atentar com o Excel pois ele remove dígitos 0 ao
                salvar quando é o primeiro dígito do número, para resolver esse
                problema altera o tipo da célula para Texto;
              </li>
              <li>
                <b>regiao:</b> campo para informar complexo que o passageiro está
                vinculado(a), lembre-se de adicionar o texto exatamente igual ao
                cadastro da complexo no sistema, caso contrário o sistema não
                realizará a importação;
              </li>
              <li>
                <b>codigo-da-linha:</b> campo para informar o código da linha
                que será feito a reserva, lembre-se de adicionar o texto
                exatamente igual ao cadastro do complexo no sistema, caso
                contrário o sistema não realizará a importação;
              </li>
              <li>
                <b>data de inicio da reserva: </b> campo para informar o início
                da recorrência da reserva, esse início maior que a data atual
                (D+1);
              </li>
              <li>
                <b>data de fim da reserva: </b> campo para informar o fim da
                recorrência da reserva, essa data deve ser maior que a data de
                início da reserva;
              </li>
              <li>
                <b>Segunda-feira: </b> campo par informar se o dia da semana
                estará na recorrência semanal, se sim adicionar “X”, se não
                deixar em branco;
              </li>
              <li>
                <b>Terça-feira: </b> campo par informar se o dia da semana
                estará na recorrência semanal, se sim adicionar “X”, se não
                deixar em branco;
              </li>
              <li>
                <b>Quarta-feira: </b> campo par informar se o dia da semana
                estará na recorrência semanal, se sim adicionar “X”, se não
                deixar em branco;
              </li>
              <li>
                <b>Quinta-feira: </b> campo par informar se o dia da semana
                estará na recorrência semanal, se sim adicionar “X”, se não
                deixar em branco;
              </li>
              <li>
                <b>Sexta-feira: </b> campo par informar se o dia da semana
                estará na recorrência semanal, se sim adicionar “X”, se não
                deixar em branco;
              </li>
              <li>
                <b>Sábado: </b> campo par informar se o dia da semana estará na
                recorrência semanal, se sim adicionar “X”, se não deixar em
                branco;
              </li>
              <li>
                <b>Domingo: </b> campo par informar se o dia da semana estará na
                recorrência semanal, se sim adicionar “X”, se não deixar em
                branco;
              </li>
            </ul>
          </li>
          <li v-if="importEmployeeType">
            <b>Outros campos:</b> existem dois campos que não são obrigatórios o
            preenchimento, mas que a importação da opção de importar, sendo:
            <ul>
              <li>
                <b>cpf:</b> campo para informar o CPF do passageiro, necessário
                para buscar dados de telemetria, sem esse campo preenchido os
                relatórios não consideram o passageiro. O campo não pode ser
                duplicado com outro cadastro ou registro da planilha, deve
                conter apenas números e ter no máximo 11 dígitos, se possuir
                menos de 11 e mais de 1 dígito o sistema irá preencher com 0 a
                esquerda;
              </li>
              <li>
                <b>email:</b> campo para informar o e-mail do passageiro,
                necessário para enviar e-mails de comunicação ao passageiro. O
                campo não pode ser duplicado com outro cadastro ou registro da
                planilha.
              </li>
            </ul>
          </li>
          <li>
            <b>Validação dos dados:</b> Antes de iniciar a importação, verifique
            se os dados do arquivo estão corretos e coerentes. Isso inclui
            verificar se os endereços de e-mail são válidos, as matrículas estão
            no formato correto e os campos obrigatórios estão preenchidos para
            cada registro.
          </li>
        </ol>
        <span>
          Após seguir essas orientações, você estará pronto para importar seu
          arquivo de cadastro de passageiros com sucesso. Lembre-se de revisar
          os dados importados no sistema para garantir que tudo tenha sido
          importado corretamente. <br />
          Se você tiver alguma dúvida ou precisar de assistência adicional
          durante o processo de importação, nossa equipe de suporte estará à
          disposição para ajudá-lo.
        </span>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "CsvImportInstructionsDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    importEmployeeType: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";
.main_text {
  height: 300px;
  overflow-y: scroll;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: $greyVale;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: $white;
}

*::-webkit-scrollbar-thumb {
  background-color: $greyVale;
  border-radius: 10px;
  border: 4px solid $white;
}
</style>
