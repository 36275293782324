import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const TabActive = new Vuex.Store({
  state: {
    activeTab: null,
    showSnackbar: false
  },

  getters: {
    activeTab: (state) => {
      return state.activeTab;
    },
    showSnackbar: (state) => {
      return state.showSnackbar;
    }
  },

  mutations: {
    setActiveTab(state, activeTab) {
      state.activeTab = activeTab;
    },

    setShowSnackbar(state, showSnackbar) {
      state.showSnackbar = showSnackbar;
    }
  },
});
