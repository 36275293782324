import api from "./api";

export class AbsenceService {
    static async postAbsence(request) {
        const url = `/absence`;
        let response = await api.post(url, request);
        return response;
    }

    static async putAbsence(request){
        let url = `/absence/${request.id}`;
        let response = await api.put(url, request);
        return response;
    }

    static async getMyAbsence(iamId) {
        let endpoint = `/absence/${iamId}`;
        let response = await api.get(endpoint);
        return response.data;
    }

    static async deleteAbsence(id) {
        let endpoint = `/absence/${id}`;
        let response = await api.delete(endpoint);
        return response.data;
    }
    static async getAbsencesByPeriod(request){
        let endpoint = `/absence`;
        if (request.startDate && request.endDate) {
            endpoint += `?startDate=${request.startDate}&endDate=${request.endDate}`;
        }
        let response = await api.get(endpoint);
        return response.data;
    }
    
}