import api from "./api";

export class ReservationLongTimeService {
    static async getLog() {
        const endpoint = `/guaranteedBooking/log`;
        let response = await api.get(endpoint);
        return response.data;
    }

    static async getLogDeleted() {
        const endpoint = `/deleteGuaranteedBooking/log`;
        let response = await api.get(endpoint);
        return response.data;
    }

    static async registerLongReservation(file) {
        let data;
        try {
            data = await this.getBase64(file);
            data = data.replace("data:text/csv;base64,", "");
        } catch (e) {
            throw new Error("registerLongReservation: " + e);
        }

        
        const request = {
            csvFile: data,
            fileName: file.name
        }
        return this.importReservation(request);
    }

    static getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    /**
     *
     * @param request
     * @return {Promise<any>}
     */
    static async importReservation(request) {
        const endpoint = `/guaranteedBooking`;
        let response = await api.post(endpoint, request);
        return response.data;
    }

    static async registerExclusionReservation(file) {
        let data;
        try {
            data = await this.getBase64(file);
            data = data.replace("data:text/csv;base64,", "");
        } catch (e) {
            throw new Error("registerLongReservation: " + e);
        }

        const request = {
            data: {
                csvFile: data,
                fileName: file.name
            }
        }
        return this.deleteReservation(request);
    }

    /**
     *
     * @param request
     * @return {Promise<any>}
     */
    static async deleteReservation(request) {
        const endpoint = `/guaranteedBooking`;
        let response = await api.delete(endpoint, request);
        return response.data;
    }
}