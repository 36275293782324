<template>
  <v-dialog
    :value="show"
    @input="$emit('update:show', false)"
    @keydown.esc="show = false"
    :retain-focus="false"
    class="absence-modal"
    :width="width"
    :height="height"
    persistent
  >
    <v-card :width="width" class="px-6 pb-6 pt-6">
      <v-row class="title-conainer">
        <v-col class="px-0 py-0 ml-4 mt-4 mb-3 d-flex">
          <slot name="header"></slot>
        </v-col>
        <v-col cols="1" align="end" class="px-0 py-0 mt-4 mr-1 mb-3">
          <v-btn
            class="px-0 py-0"
            height="0"
            min-width="0"
            v-if="close"
            icon
            v-on:click="show = false"
          >
            <v-icon class="icon-costume">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-card elevation="0">
        <slot name="subtitle"></slot>
      </v-card>

      <v-card elevation="0">
        <slot name="body"></slot>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "GenericDialog",
  props: {
    width: {
      type: String,
      default: "auto",
    },
    height: {
      type: String,
      default: "auto",
    },
    back: { type: Boolean, default: false },
    close: { type: Boolean, default: false },
    visible: { type: Boolean, default: false },
  },

  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close-dialog");
        }
      },
    },
  },
};
</script>

<style scoped>
.absence-modal {
  width: auto;
}

.icon-costume {
  font-size: 24px;
  color: #6f6f6f;
}

.absence-card {
  width: fit-content;
  padding: 32px;
}

.title-conainer {
  display: flex;
  align-items: center;
}

.actions {
  justify-content: flex-end;
}

.v-dialog .v-card > .v-card__text {
  padding: 0;
}
.v-card__title {
  padding-bottom: 0px;
  padding-top: 0px;
}

.card__title {
  padding: 0px;
}

.v-dialog > .v-card > .v-card__actions {
  padding: 0px;
}

.v-card__actions > .v-btn.v-btn {
  padding: 0px;
}
</style>
