<template>
  <v-card class="main-content pa-4 fill-height">
    <v-dialog v-model="showFormSite" persistent>
      <v-card>
        <v-card-title v-if="newSiteMode"> Inclusão de Site </v-card-title>
        <v-card-title v-else> Alteração de Site </v-card-title>
        <v-card-text>
          <v-text-field label="Nome" v-model="editedSiteName"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <GenericButton
            class="mr-4"
            title="Cancelar"
            icon="mdi-close"
            secondary
            v-on:click="closeFormSite"
          ></GenericButton>
          <GenericButton
            title="Salvar"
            icon="mdi-content-save-outline"
            primary
            v-on:click="saveSite"
          ></GenericButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      :custom-message="customMessage"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>
    <v-row>
      <v-col>
        <v-card-title class="mb-2">
          <h4 v-if="operation == 'Creation'">Incluir Complexo</h4>
          <h4 v-else>Alterar Complexo</h4>
        </v-card-title>
        <v-card-subtitle class="mb-7" v-if="operation == 'Creation'">
          Aqui você pode incluir o complexo.
        </v-card-subtitle>
        <v-card-subtitle class="mb-7" v-else>
          Aqui você pode alterar o complexo.
        </v-card-subtitle>
      </v-col>
      <v-col align="right" class="mr-4 mt-3">
        <GenericButton
          title="Cancelar"
          icon="mdi-close"
          secondary
          class="mr-4"
          v-on:click="backToAdminRegions"
          :disabled="loading"
        ></GenericButton>
        <GenericButton
          title="Salvar"
          icon="mdi-content-save-outline"
          primary
          v-on:click="saveRegion"
          :disabled="loading"
        ></GenericButton>
      </v-col>
    </v-row>
    <v-card-text class="pb-2">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row no-gutters>
          <v-col cols="5">
            <v-select
              label="Selecione o estado"
              v-model="region.uf"
              :items="listaEstados"
              item-text="nome"
              item-value="sigla"
              outlined
            />
          </v-col>
          <v-col cols="5" class="ml-sm-13 pl-sm-13">
            <v-text-field
              v-model="region.name"
              @change="edited = true"
              label="Nome"
              :rules="[isNotEmptyRule]"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="5">
            <v-select
              label="Aceita Reservas de Terceiros"
              @change="edited = true"
              :rules="[isNotEmptyRule]"
              v-model="region.acceptThirdPartyBook"
              :items="acceptThirdPartyBookOptions"
              item-text="title"
              item-value="value"
              outlined
            />
          </v-col>
          <v-col cols="5" class="ml-sm-13 pl-sm-13">
            <v-select
              label="Aceita Reservas de Colaboradores de Outros Complexos"
              @change="edited = true"
              :rules="[isNotEmptyRule]"
              v-model="region.acceptOtherRegionsBook"
              :items="acceptOtherRegionsOptions"
              item-text="title"
              item-value="value"
              outlined
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="5">
            <v-text-field
              v-model="limitDaysBookOnSite"
              @change="edited = true"
              label="Período máximo de reserva (On Site)"
              placeholder="Preencher limite de reserva em dias"
              type="number"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="5" class="ml-sm-13 pl-sm-13">
            <v-text-field
              v-model="limitDaysBookRemote"
              @change="edited = true"
              label="Período máximo de reserva (Remoto)"
              placeholder="Preencher limite de reserva em dias"
              type="number"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-text class="pt-0">
      <v-data-table
        :headers="headers"
        class="elevation-1 col-12"
        :items="region.site"
        :loading="loading"
      >
        <template v-slot:[`header.name`]="{}">
          <v-row>
            <v-col style="align-items: center; display: flex"> Site </v-col>
            <v-col align="right">
              <GenericButton
                title="Inserir site"
                icon="mdi-plus"
                btnTable
                @click="addNewSite"
              ></GenericButton>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-1" color="primary" @click="editSite(item)"
            >mdi-pencil-outline</v-icon
          >
          <DeleteConfirmation
            :dialog="showDialogDelete"
            :value="item"
            v-on:send-delete="deleteSite(item)"
          ></DeleteConfirmation>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomAlert from "@/components/CustomAlert";
import { RegionFormStore } from "@/store/regionForm";
import { isNotEmptyRule } from "@/services/validation";
import { RegionService } from "@/services/region";
import { estados } from "@/store/estados";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import GenericButton from "../components/GenericButton.vue";
export default {
  name: "regionForm",
  components: {
    CustomAlert,
    DeleteConfirmation,
    GenericButton,
  },
  store: RegionFormStore,
  data: () => ({
    listaEstados: estados,
    showAlert: false,
    showDialog: false,
    showFormSite: false,
    newSiteMode: false,
    overlay: false,
    customMessage: "",
    overlayMessage: "",
    editedSiteIndex: -1,
    editedSiteName: "",
    alertMessageType: "default",
    alertType: "success",
    valid: true,
    limitDaysBookOnSite: "",
    limitDaysBookRemote: "",
    loading: false,
    showDialogDelete: false,
    file: {},
    edited: false,
    headers: [
      { text: "", value: "actions", sortable: false, width: "100px" },
      { text: "Site", value: "name", sortable: false },
    ],
    region: {
      id: "",
      name: "",
      acceptThirdPartyBook: "",
      acceptOtherRegionsBook: "",
      limitDaysBookOnSite: null,
      limitDaysBookRemote: null,
      site: [],
    },
    acceptThirdPartyBookOptions: [
      { title: "Sim", value: true },
      { title: "Não", value: false },
    ],
    acceptOtherRegionsOptions: [
      { title: "Sim", value: true },
      { title: "Não", value: false },
    ],
    operation: "",
  }),
  methods: {
    isNotEmptyRule,
    saveRegion: async function () {
      this.customMessage = "";
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.region.site || this.region.site.length == 0) {
        this.setCustomAlertPropos(
          false,
          "É obrigatória a inclusão de um site."
        );
        return;
      }

      if (this.limitDaysBookOnSite === "") {
        this.region.limitDaysBookOnSite = 0;
      } else {
        this.region.limitDaysBookOnSite = this.limitDaysBookOnSite;
      }

      if (this.limitDaysBookRemote === "") {
        this.region.limitDaysBookRemote = 0;
      } else {
        this.region.limitDaysBookRemote = this.limitDaysBookRemote;
      }

      try {
        this.loading = true;
        if (this.operation == "Creation")
          await RegionService.saveRegion(this.region);
        else await RegionService.editRegion(this.region);
        await this.uploadSuccess();
        this.$router.push({
          path: "admin-regions",
          query: { showAlert: true },
        });
      } catch (error) {
        this.uploadError(error);
      }
      this.loading = false;
    },
    async deleteSite(item) {
      let msgError = "";
      if (item.id)
        msgError = await RegionService.verifyIfOkToDeleteSite(item.id);
      if (msgError) {
        this.setCustomAlertPropos(false, msgError);
      } else {
        this.region.site.splice(this.region.site.indexOf(item), 1);
      }
    },
    saveSite() {
      if (this.editedSiteName) {
        if (this.newSiteMode) {
          if (!this.region.site) {
            this.region.site = new Array();
          }
          this.region.site.push({ id: null, name: this.editedSiteName });
        } else {
          this.region.site[this.editedSiteIndex].name = this.editedSiteName;
        }
      }
      this.showFormSite = false;
    },
    closeFormSite() {
      this.showFormSite = false;
    },
    addNewSite() {
      this.editedSiteName = "";
      this.newSiteMode = true;
      this.showFormSite = true;
      this.editedSiteIndex = -1;
    },
    editSite(item) {
      this.editedSiteIndex = this.region.site.indexOf(item);
      this.editedSiteName = item.name;
      this.newSiteMode = false;
      this.showFormSite = true;
    },
    async uploadSuccess() {
      this.setAlertProps(true, "regionSaveSucess");
    },
    uploadError(e) {
      if (e.data.includes("400 BAD_REQUEST")) {
        this.setCustomAlertPropos(false, e.data.message);
      } else {
        this.setAlertProps(false, "regionSaveError");
      }
    },
    setCustomAlertPropos(isSuccess, message) {
      if (isSuccess) {
        this.alertType = "success";
      } else {
        this.alertType = "error";
      }
      this.showAlert = true;
      this.customMessage = message;
    },
    setAlertProps(isSuccess, type) {
      if (isSuccess) {
        this.alertType = "success";
      } else {
        this.alertType = "error";
      }
      this.showAlert = true;
      this.alertMessageType = type;
    },
    backToAdminRegions() {
      this.$router.push("/admin-regions");
    },
    closeAlert() {
      this.showAlert = false;
    },
  },
  async beforeMount() {
    try {
      this.loading = true;
      if (RegionFormStore.getters.region.id)
        this.region = RegionFormStore.getters.region;
      this.operation = RegionFormStore.getters.operation;

      if (this.operation === "Edit") {
        if (this.region.limitDaysBookOnSite === 0) {
          this.region.limitDaysBookOnSite = "";
        } else {
          this.limitDaysBookOnSite = this.region.limitDaysBookOnSite;
        }

        if (this.region.limitDaysBookRemote === 0) {
          this.region.limitDaysBookRemote = "";
        } else {
          this.limitDaysBookRemote = this.region.limitDaysBookRemote;
        }
      }
      this.loading = false;
      this.edited = false;
    } catch {
      this.loading = false;
    }
  },
};
</script>
<style scoped lang="scss">
@import "../style/variables.scss";

.addSiteButton {
  background-color: white !important;
  font-size: small !important;
  color: #6f6f6f !important;
  border-style: none;
  border-color: none;
  box-shadow: 0px 0px;
}
</style>
