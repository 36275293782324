<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateValue"
        :label="label"
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="rules"
        :disabled="disabled"
        :outlined = "outlined"
        :dense = "dense"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      locale="pt-BR"
      no-title
      scrollable
      @input="closePicker"
      @change="changeInput"
      :min="min"
      :disabled="disabled"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "datePicker",
  props: {
    label: String,
    formDate: String,
    disabled: Boolean,
    rules: Array,
    min: String,
    outlined: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
  },
  data: function() {
    return {
      menu: false,
      date: this.$props.formDate,
      reservation: {},
      auxRecurrence: "",
      formDateLocal: "",
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      if (date.indexOf("-") == -1) return date;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    closePicker() {
      this.menu = false;
      this.$emit("date-chosen", this.formatDate(this.date));
      this.$emit("date", this.date);
      this.$emit("picked");      
    },
    changeInput() {
      this.$emit("change-input")
    },
    reset() {
      this.formDateLocal = null;
      this.date = null;
    },
  },
  computed: {
    getDate() {
      if (!this.formDateLocal) {
        return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10);
      }
      return this.formDateLocal;
    },
    dateValue: {
      get() {
        if (!this.formDateLocal) {
          return this.formatDate(this.date);
        }
        return this.formatDate(this.formDateLocal);
      }, 
      set(newVal) {
        this.formDateLocal = newVal;
      }
    }
  },
  watch: {
    formDate(newVal) {
      this.formDateLocal = newVal;
    },
    formDateLocal(newVal) {
      this.$emit('update:formDate', newVal);
    }
  }
};
</script>
