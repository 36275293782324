<template class="fill-height">
  <v-card class="main-content pa-4 fill-height">
    <v-card-text v-if="booking">
      <v-row class="mb-2">
        <v-row
          class="mb-2"
          v-if="
            Object.keys(booking).length == 0 || Object.keys(booking) == null
          "
        >
          <v-col class="col-12 mt-4">
            <h5>Você ainda não possui reservas.</h5>
          </v-col>
        </v-row>
      </v-row>
      <v-row class="mb-2" v-if="!Object.keys(booking).length == 0">
        <v-col class="col-12 pt-1">
          <h5>{{ booking.busLine ? booking.busLine.code : "" }}</h5>
        </v-col>
        <v-col class="col-12 py-1 info-text">
          <span>Nome: </span>
          <b>{{ booking.employee ? booking.employee.name : "" }}</b>
        </v-col>
        <v-col class="col-12 py-1 info-text">
          <span>Data Inicial: </span>
          <b> {{ booking ? booking.startDate : "" }}</b>
        </v-col>
        <v-col class="col-12 py-1 info-text">
          <span>Data Final: </span>
          <b class="body1 font-weight-bold">{{
            booking ? booking.endDate : ""
          }}</b>
        </v-col>
        <v-col
          class="col-12 py-1 info-text"
          v-if="booking.daysRecurrence != ''"
        >
          <span>Dias: </span>
          <b>{{ translate(booking.daysRecurrence) }}</b>
        </v-col>
        <v-col class="col-12 pt-1 info-text">
          <span>Regime:</span>
          <b>
            {{
              booking.employee
                ? booking.employee.shift == "REMOTE"
                  ? "Remoto"
                  : "Presencial"
                : ""
            }}</b
          >
        </v-col>
        <v-row>
          <v-col class="col-12 py-0" align="end">
            <v-btn
              outlined
              class="px-9"
              @click="setReservationModel(booking, 'edit')"
            >
              <v-icon class="mr-2" small>mdi-pencil</v-icon>
              Editar
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { BookReservationStore } from "@/store/bookReservationForm";

export default {
  name: "VoucherComponent",
  props: {
    booking: Object,
  },
  store: BookReservationStore,
  methods: {
    setReservationModel(reservation, operation) {
      const daysRecurrence = this.translate(reservation.daysRecurrence)
      reservation.daysRecurrence = daysRecurrence.toLowerCase().replace(/,/g, ";");
      BookReservationStore.commit("setReservationModel", reservation);
      BookReservationStore.commit("setOperation", operation);
      this.$router.push("/book-reservation");
    },
    translate(daysRecurrenceTranslated) {
      if (
        daysRecurrenceTranslated != null &&
        daysRecurrenceTranslated != undefined &&
        daysRecurrenceTranslated != ""
      ) {
        daysRecurrenceTranslated = daysRecurrenceTranslated.replace(/;/g, ", ");
        daysRecurrenceTranslated = daysRecurrenceTranslated.replace(
          /monday/g,
          "Segunda"
        );
        daysRecurrenceTranslated = daysRecurrenceTranslated.replace(
          /tuesday/g,
          "Terça"
        );
        daysRecurrenceTranslated = daysRecurrenceTranslated.replace(
          /wednesday/g,
          "Quarta"
        );
        daysRecurrenceTranslated = daysRecurrenceTranslated.replace(
          /thursday/g,
          "Quinta"
        );
        daysRecurrenceTranslated = daysRecurrenceTranslated.replace(
          /friday/g,
          "Sexta"
        );
        daysRecurrenceTranslated = daysRecurrenceTranslated.replace(
          /saturday/g,
          "Sábado"
        );
        daysRecurrenceTranslated = daysRecurrenceTranslated.replace(
          /sunday/g,
          "Domingo"
        );
      }
      return daysRecurrenceTranslated;
    },
  },
};
</script>

<style scoped lang="scss">
h5 {
  font-size: 22px;
}
.info-text {
  font-size: 20px;
}
</style>
