<template>
  <div ref="printMe">
    <slot></slot>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";

export default {
  methods: {
    generatePDF() {
      const scale = 4;
      const element = this.$refs.printMe;

      this.$html2canvas(element, {
        logging: true,
        letterRendering: true,
        allowTaint: false,
        useCORS: true,
        scale,
        backgroundColor: "#fff",
      })
        .then((canvas) => {
          const pdf = new jsPDF();
          pdf.addImage(canvas, "PNG", 16, 16, Math.round(element.clientWidth/scale), Math.round(element.clientHeight/scale));
          pdf.save(this.fileName);
        })
        .catch((error) => {
          console.log("ERROR SAVING IMAGE", error);
        });
    },
  },

  props: {
    fileName: String,
  },
};
</script>