import msalInstance from "../msalPlugin";

const permissionMap = {
  admin: 3,
  supervisor: 2,
  employee: 1,
};

export class PermissionService {
  static getPermission() {
    let response = msalInstance.getAccount().idToken;
    
    if (response.roles == null || response.roles == undefined || response.roles == "") {
      return "employee";
    }

    let highestPermission = 0;

    for (const permission of response.roles) {
      highestPermission = Math.max(
        highestPermission,
        permissionMap[permission] || 0
      );
    }

    switch (highestPermission) {
      case 3:
        return "admin";
      case 2:
        return "supervisor";
      default:
        return "employee";
    }
  }
}
