import Papa from "papaparse";
import { SearchAddressService } from "@/services/searchAddress";
import api from "./api";

export class EmployeeService {
  /**
   *
   * @param file {File}
   * @return {Promise<*>}
   */
  static async registerEmployees(file) {
    let employees;
    try {
      employees = await this.convertCSVToJson(file);
      employees = await Promise.all(
        employees.map(async function (e) {
          e.registrationNumber = e.matricula_vale;
          delete e.matricula_vale;

          e.name = e.nome;
          delete e.nome;

          e.category = e.categoria;
          delete e.categoria;

          e.shift = e.regime_de_trabalho;
          delete e.regime_de_trabalho;

          e.isThirdParty =
            e.funcionario_vale.toLowerCase() === "sim" ? true : false;
          delete e.funcionario_vale;

          e.region = e.complexo;
          delete e.complexo;

          e.preferential =
            e.assento_preferencial.toLowerCase() === "sim" ? true : false;
          delete e.assento_preferencial;

          e.address = e.endereco;
          delete e.endereco;

          e.latitude = "";
          e.longitude = "";
          e.postalCode = "";
          if (e.address) {
            let response = await SearchAddressService.getMapAddress(e.address);

            if (response.length > 0) {
              e.latitude = response[0].position.lat;
              e.longitude = response[0].position.lon;
              if (response[0].address.extendedPostalCode?.length > 9) {
                e.postalCode = response[0].address.extendedPostalCode.substring(
                  0,
                  9
                );
              } else {
                e.postalCode =
                  response[0].address?.extendedPostalCode == undefined
                    ? ""
                    : response[0].address?.extendedPostalCode;
              }
            }
          }
          return e;
        })
      );
      employees = employees.filter((e) => e.registrationNumber !== "");
    } catch (e) {
      throw new Error("registerEmployees: " + e);
    }
    const request = { listEmployees: employees };
    return this.importEmployees(request);
  }

  /**
   * @param input {string | File}
   * @returns {Promise<Array<any>>}
   */
  static async convertCSVToJson(input) {
    return new Promise((resolve, reject) => {
      Papa.parse(input, {
        header: true,
        delimiter: ";",
        transformHeader: function (h) {
          return h.replaceAll(" ", "_").toLowerCase();
        },
        skipEmptyLines: true,
        complete: function (results) {
          resolve(results.data);
        },
        error: function (e) {
          reject(e);
        },
      });
    });
  }

  /**
   *
   * @param request
   * @return {Promise<any>}
   */
  static async importEmployees(request) {
    const endpoint = `/employees/import`;
    let response = await api.post(endpoint, request);
    return response.data;
  }

  /**
   *
   * @returns {Promise<any>}
   */
  static async listEmployees(
    page = "",
    size = "",
    searchFilter = "",
    search = "",
    region = ""
  ) {
    const endpoint = `/employees/paginated?page=${page}&size=${size}&searchFilter=${searchFilter}&search=${search}&region=${region}`;
    let response = await api.get(endpoint);
    return response.data;
  }

  static async listEmployeesNoPagination(
    searchFilter = "",
    search = "",
    region = ""
  ) {
    const endpoint = `/employees?searchFilter=${searchFilter}&search=${search}&region=${region}`;
    let response = await api.get(endpoint);
    return response.data;
  }

  static async getEmployeeSearch(value) {
    let response = await api.get(`/employees/search/${value}`);
    return response.data;
  }

  static async getEmployee(employeeId) {
    const endpoint = `/employees/${employeeId}`;
    let response = await api.get(endpoint);
    return response.data;
  }

  static async postEmployee(request) {
    const endpoint = `/employees`;
    let response = await api.post(endpoint, request);
    return response.data;
  }

  static async updateEmployee(employeeId, request) {
    const endpoint = `/employees/${employeeId}`;
    let response = await api.patch(endpoint, request);
    return response.data;
  }

  static async deleteEmployee(params) {
    const endpoint = `/employees/${params}`;
    let response = await api.delete(endpoint);
    return response;
  }

  static async getEmployeesByCpfOrRegistrationNumber(param) {
    const endpoint = `employees/cpfOrRegistrationNumber/${param}/`;
    let response = await api.get(endpoint);
    return response.data;
  }

  static async anonymizeEmployee(request) {
    const endpoint = `/employees/anonymize`;
    let response = await api.put(endpoint, request);
    return response;
  }
}
