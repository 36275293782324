<template>
  <v-app>
    <v-app-bar class="px-2" app color="primary" dark>
      <v-img
        class="mr-4"
        src="../assets/rotas-header-white-logo.svg"
        width="auto"
        max-height="24"
        max-width="140"
      ></v-img>
    </v-app-bar>
    <v-main>
      <v-container fluid class="fill-height">
        <CustomAlert
          :dialog="showAlert"
          :message-type="alertMessageType"
          :type="alertType"
          :customMessage="customMessage"
          @close-alert="closeAlert"
          @update="showAlert = $event"
        ></CustomAlert>
        <v-row class="fill-height justify-center align-center">
        
          <v-col class="col-6 align-center">
            <v-row class="justify-center">
              <v-card class="col-6 pa-12 color-card">
              <v-row class="justify-center ">
                  <ValeSuaVagaLogo></ValeSuaVagaLogo>
              </v-row>
                <v-row class="justify-center welcome-message mt-10">
                  Bem-vindo ao sistema de reserva de assentos.
                </v-row>
                <v-row class="justify-center mt-10 " >
                  <v-btn
                    color="primary"
                    block
                    @click="logar"
                    :disabled="loading"
                  >
                    <v-progress-circular indeterminate v-if="loading">
                    </v-progress-circular>
                    <v-icon class="mr-2" small>mdi-login</v-icon>
                    Entrar
                  </v-btn>
                </v-row>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ValeSuaVagaLogo from "@/components/ValeSuaVagaLogo";
import { LoginService } from "@/services/login";
import { EmployeeService } from "@/services/employee";
import CustomAlert from "@/components/CustomAlert";

export default {
  name: "LoginPage",
  components: {
    ValeSuaVagaLogo,
    CustomAlert,
  },
  data: () => ({
    loading: false,
    showAlert: false,
    alertMessageType: "default",
    alertType: "success",
    customMessage: "",
  }),
  methods: {

    logar() { 
        LoginService.login();
    },
    formatShift(employee) {
      let employeeShiftRaw = employee.shift;
      let employeeShift;
      if (employeeShiftRaw == "ON_SITE") {
        employeeShift = "ON SITE";
      } else {
        employeeShift = "REMOTE";
      }
      return employeeShift;
    },
    closeAlert () {
      this.showAlert = false;
    }
  },
  async created() {
    const tokenIam = location.href.split("?")[1];
    if (tokenIam) {
      const iamData = tokenIam.split("&");
      this.code = iamData[0];
      if (this.code) {
        this.loading = true;
        this.code = this.code.replace("code=", "");
        let auth = {};
        try {
          auth = await LoginService.auth(this.code);
          localStorage.setItem("auth", JSON.stringify(auth));
          let userInfo = await LoginService.userInfo(auth.access_token);
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          let employee = {};
          try {            
            employee = await EmployeeService.getEmployee(userInfo.user_id);            
            localStorage.setItem('registrationNumber', employee.registrationNumber.toUpperCase());
            localStorage.setItem('name', employee.name);
            localStorage.setItem('employeeShift', this.formatShift(employee));
          } catch (e) {
            this.customMessage = e;
          }
          window.location.replace("/")
        } catch (e) {
          this.loading = false;
          this.alertType = "error";
          this.showAlert = true;          
          this.alertMessageType = "employeeOperationError";
          this.customMessage = e.data.message;
        }
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.v-main {
  background-image: url("../assets/backgroud-login-new.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .v-card {
    border-radius: 1rem;
  }

  .v-btn {
    text-transform: none;
  }
}
.color-card {
  background: rgba(255, 255, 255, 0.9);
}
.welcome-message {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: center;
}

</style>
