<template>
  <v-card color="main-content pa-0 fill-height">
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      customMessage=""
      @close-alert="closeAlert"
      @update="showAlert = $event"
    >
    </CustomAlert>
    <v-card-title class="pl-0 title">
      <h4 v-if="permission == 'admin' || permission == 'supervisor'">
        Reservas
      </h4>
      <h4 v-else>Minhas Reservas</h4>
    </v-card-title>
    <v-card-subtitle class="pl-0 pt-1 pb-6">
      Aqui você pode verificar as reservas, excluí-las ou editá-las.
    </v-card-subtitle>
    <v-card-text class="pl-0">
      <v-row v-if="permission == 'admin' || permission == 'supervisor'">
        <v-col class="col-3 mb-0 pb-0 pl-3">
          <v-select
            class=""
            :rules="[isNotEmptyRule]"
            v-model="isPersonal"
            :items="isPersonalBookingOptions"
            @change="setRegistrationNumber"
            v-if="permission !== 'employee'"
            outlined
            dense
          >
          </v-select>
        </v-col>
        <v-col class="col-9 mb-0 pb-0">
          <v-autocomplete
            class=""
            v-model="registrationNumber"
            :items="employees"
            :item-text="getEmployeeText"
            :item-value="getItemValue"
            :rules="[isNotEmptyRule]"
            label="Matrícula/CPF a quem a reserva é destinada"
            @input="getBookings"
            v-if="!isPersonal"
            no-data-text="Digite pelo menos 6 digitos para buscar."
            v-on:keyup="getEmployees"
            :search-input.sync="employeeSearch"
            outlined
            dense
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-progress-circular
        style="width: 100%"
        :size="50"
        :width="5"
        color="primary"
        indeterminate
        class="fill-height mt-5"
        v-if="loading"
      >
      </v-progress-circular>
      <v-row v-if="!loading">
        <v-col class="table-space">
          <v-data-table
            :headers="headers"
            :items="reservationList"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            :items-per-page="10"
            item-key="id"
            class="elevation-1"
            no-data-text="Nenhuma reserva encontrada"
            show-expand
          >
            <template v-slot:[`item.actions`]="{ item }">
              <a
                @click="selectReservationToBeEdited(item)"
                v-if="isChangeValid(item)"
              >
                <v-icon small class="mr-1" color="primary">mdi-pencil</v-icon>
              </a>
              <v-tooltip top max-width="30%">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    color="disabled"
                    v-if="!isChangeValid(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Não é possível editar reservas passadas</span>
              </v-tooltip>

              <v-tooltip top max-width="30%">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="disabled"
                    class="mr-2"
                    v-if="!isChangeValid(item)"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Não é possível excluir reservas passadas</span>
              </v-tooltip>

              <DeleteConfirmation
                :dialog="showDialog"
                v-on:send-delete="deleteReservation(item)"
                v-if="isChangeValid(item)"
              >
              </DeleteConfirmation>
              <DialogModal :showDialog="showVoucher" width="375px">
                <template v-slot:title> Selo Digital </template>
                <template v-slot:body>
                  <DownloadPDF
                    :fileName="item.employee.name"
                    ref="PDFDowloader"
                  >
                    <VoucherBadge
                      :name="item.employee.name"
                      :isThirdParty="item.employee.thirdParty"
                      :busLine="item.busLine.code"
                      :recurrenceDays="item.daysRecurrence"
                      :direction="item.direction"
                      :startDate="item.startDate"
                      :expirationDate="item.endDate"
                      :shift="item.employee.shift"
                      :isPriority="item.prioritySeat"
                    />
                  </DownloadPDF>
                </template>
                <template v-slot:openLabel>
                  <v-tooltip top nudge-right="45" max-width="30%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="showVoucher = true"
                        small
                        class="mr-2"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        v-if="isChangeValid(item)"
                      >
                        mdi-open-in-new</v-icon
                      >
                    </template>
                    <span>Selo digital</span>
                  </v-tooltip>
                  <v-tooltip top max-width="30%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        color="primary"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        v-if="item.prioritySeat && isChangeValid(item)"
                      >
                        mdi-human-wheelchair</v-icon
                      >
                    </template>
                    <span>Vaga com assento preferêncial</span>
                  </v-tooltip>
                  <v-tooltip top max-width="30%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        class="mr-2"
                        color="disabled"
                        v-bind="attrs"
                        v-on="on"
                        v-if="item.prioritySeat && !isChangeValid(item)"
                      >
                        mdi-human-wheelchair</v-icon
                      >
                    </template>
                    <span>Vaga com assento preferêncial</span>
                  </v-tooltip>
                </template>

                <template v-slot:action>
                  <GenericButton
                    class="ml-4"
                    icon="mdi-printer-outline"
                    title="Imprimir"
                    primary
                    v-on:click="downloadPDF"
                  >
                  </GenericButton>
                </template>
              </DialogModal>
              <CanceledBookingEmail
                :currentUser="currentUser"
                ref="canceledBookingEmail"
              ></CanceledBookingEmail>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td class="pt-4 pb-0 px-8" :colspan="5">
                <p class="body1">
                  <v-row>
                    <v-col>
                      Site de destino: {{ item.busLine.site.name }}
                    </v-col>
                    <v-col>
                      Baldeio:
                      {{ item.busLine.transshipment ? "Sim" : "Não" }}</v-col
                    >
                  </v-row>
                  <v-row>
                    <v-col>
                      Ponto inicial da rota: {{ item.departureBusStop.name }}
                    </v-col>
                    <v-col>
                      Ponto final da rota: {{ item.arrivalBusStop.name }}
                    </v-col>
                  </v-row>
                </p>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomAlert from "@/components/CustomAlert";
import { BookingService } from "@/services/booking";
import { BookReservationStore } from "@/store/bookReservationForm";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import VoucherBadge from "@/components/VoucherBadge";
import DialogModal from "@/components/DialogModal";
import DownloadPDF from "@/components/DownloadPDF";
import { EmployeeService } from "@/services/employee";
import CanceledBookingEmail from "@/components/CanceledBookingEmail";
import { isNotEmptyRule } from "@/services/validation";
import { BookUtils } from "@/utils/bookUtils";
import { DateUtils } from "@/utils/dateUtils";
import { TabActive } from "../store/tabActive";
import GenericButton from "../components/GenericButton.vue";
import { PermissionService } from "../services/permission";

export default {
  name: "MyReservations",

  components: {
    CustomAlert,
    DeleteConfirmation,
    VoucherBadge,
    DialogModal,
    DownloadPDF,
    CanceledBookingEmail,
    GenericButton,
  },

  store: TabActive,
  BookReservationStore,

  data: () => ({
    headers: [
      { text: "", value: "actions" },
      { text: "Linha de ônibus", value: "busLine.code" },
      { text: "Direção", value: "direction" },
      { text: "Data Inicial", value: "startDate" },
      { text: "Data Final", value: "endDate" },
      { text: "Dias de recorrência", value: "daysRecurrence" },
      { text: "", value: "data-table-expand" },
    ],
    showAlert: false,
    showDialog: false,
    alertMessageType: "default",
    alertType: "success",
    singleExpand: true,
    expanded: [],
    loading: false,
    isPersonalBookingOptions: [
      { text: "Minhas reservas", value: true },
      { text: "Outras reservas", value: false },
    ],
    showVoucher: false,
    voucher: {},
    isPersonal: true,
    employees: [],
    employeeSearch: "",
    permission: "",
    currentUser: {},
    registrationNumber: null,
    valueItem: "",
  }),

  methods: {
    deleteError() {
      this.setAlertProps(false, "deleteError");
    },

    async deleteSuccess() {
      this.setAlertProps(true, "emailSendedWhenDeleteSuccess");
    },

    isChangeValid(item) {
      return DateUtils.isValidDateRange(item);
    },

    setAlertProps(isSuccess, type) {
      if (isSuccess) {
        this.alertType = "success";
      } else {
        this.alertType = "error";
      }
      this.showAlert = true;
      this.alertMessageType = type;
    },
    closeAlert() {
      this.closeAlert;
    },
    async deleteReservation(booking) {
      try {
        await BookingService.deleteBooking(booking.id);
        this.$refs.canceledBookingEmail.sendEmail(booking);
        this.deleteSuccess();
      } catch {
        this.deleteError();
      }
      const registrationNumber = this.isPersonal
        ? localStorage.getItem("registrationNumber")
        : this.registrationNumber;
      await this.getBookings(registrationNumber);
    },

    selectReservationToBeEdited(reservation) {
      BookReservationStore.commit("setReservationModel", reservation);
      BookReservationStore.commit("setOperation", "edit");
      TabActive.commit("setActiveTab", 0);
      this.$emit("tab-changed", 0);
    },

    downloadPDF() {
      this.$refs.PDFDowloader.generatePDF();
    },

    async getEmployees() {
      this.employees = [];
      if (this.employeeSearch != null && this.employeeSearch.length >= 5) {
        this.employees =
          await EmployeeService.getEmployeesByCpfOrRegistrationNumber(
            this.employeeSearch
          );
      }
    },

    async getBookings(registrationNumber) {
      this.loading = true;
      this.reservationList = await BookingService.getMyBooking(
        registrationNumber
      );
      BookUtils.updateDaysRecurrenceList(this.reservationList);
      this.loading = false;
    },

    async setRegistrationNumber(isPersonal) {
      if (isPersonal) {
        this.registrationNumber = localStorage.getItem("registrationNumber");
        this.getBookings(this.registrationNumber);
      } else {
        this.registrationNumber = "";
        this.reservationList = [];
      }
    },

    getEmployeeText(item) {
      let searchTerm = this.employeeSearch;
      if (this.employeeSearch && this.employeeSearch.includes(" - ")) {
        searchTerm = this.employeeSearch.split(" - ")[0];
      }
      if (item.cpf != null && item.cpf.includes(searchTerm)) {
        this.valueItem = "cpf";
        return `${item.cpf} - ${item.name}`;
      } else if (
        item.registrationNumber != null &&
        item.registrationNumber.includes(searchTerm)
      ) {
        this.valueItem = "registrationNumber";
        return `${item.registrationNumber} - ${item.name}`;
      } else {
        this.valueItem = "registrationNumber";
        return `${item.registrationNumber} - ${item.name}`;
      }
    },
    getItemValue(item) {
      if (this.valueItem == "cpf") {
        return item.cpf;
      } else if (item.registrationNumber) {
        return item.registrationNumber;
      } else {
        return item.cpf;
      }
    },

    successBookingAlert() {
      this.alertType = "success";
      this.customMessage = "Reserva feita com sucesso.";
      this.showAlert = true;
      TabActive.commit("setShowSnackbar", false);
    },

    isNotEmptyRule,
  },
  async beforeMount() {
    this.loading = true;

    this.permission = PermissionService.getPermission();

    this.currentUser = await EmployeeService.getEmployee(
      localStorage.getItem("registrationNumber")
    );

    this.registrationNumber =
      BookReservationStore.getters.lastRegistrationNumberBooking;

    if (!this.registrationNumber) {
      this.registrationNumber = this.currentUser.registrationNumber;
    }

    this.reservationList = await BookingService.getMyBooking(
      this.registrationNumber
    );

    BookReservationStore.commit("setLastRegistrationNumberBooking", null);

    this.loading = false;
  },

  async updated() {
    const showSnackbar = await TabActive.getters.showSnackbar;

    if (
      this.registrationNumber &&
      this.currentUser.registrationNumber != this.registrationNumber
    ) {
      this.isPersonal = false;
      this.registrationNumber;
    }

    BookUtils.updateDaysRecurrenceList(this.reservationList);

    if (showSnackbar) {
      this.successBookingAlert();
    }
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;

  .invalid-input {
    color: $redVale;
  }

  .v-btn {
    text-transform: none;
  }
}

::v-deep .v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn {
    margin-left: 0;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 24px;
}

::v-deep .v-data-table-header {
  background-color: #f4f4f4;
  text-align: center;
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.04);
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(0, 0, 0, 0.04);
}

::v-deep .theme--light.v-data-table .v-data-footer {
  border-top: thin solid rgba(0, 0, 0, 0.04);
}

.title {
  padding-top: 32px;
}

.table-space {
  padding-left: 12.5px;
}
</style>
