<template>
  <v-dialog :retain-focus="false" v-model="showDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <GenericButton
        title="Inserir ponto de ônibus"
        icon="mdi-plus"
        btnTable
        @click="openBookingStops"
        v-bind="attrs"
        v-on="on"
      ></GenericButton>
    </template>

    <v-card>
      <v-card-title> Pontos de ônibus </v-card-title>
      <v-card-subtitle>
        <v-text-field
          v-model="search"
          label="Pesquisa"
          single-line
          hide-details
        ></v-text-field>
      </v-card-subtitle>
      <v-data-table
        :headers="headersbuslineStops"
        class="elevation-0 col-12"
        :items="buslineStops"
        :loading="loadingBusStops"
        :search="search"
        item-key="id"
        v-model="selected"
        show-select
      >
      </v-data-table>

      <v-card-actions>
        <v-spacer></v-spacer>
        <GenericButton
        class="mr-4"
        title="Cancelar"
        icon="mdi-close"
        secondary
        @click="showDialog = false"
        ></GenericButton>
        <GenericButton
          title="Incluir"
          icon="mdi-plus"
          primary
          @click="insertBusStop"
        ></GenericButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { BusStopsService } from "@/services/busStops";
import GenericButton from "./GenericButton.vue";
export default {
  name: "BusStopsSelect",
  components: {
    GenericButton,
  },
  props: {
    regionId: String,
  },

  data: function () {
    return {
      search: "",
      buslineStops: [],
      loadingBusStops: false,
      showDialog: false,
      selected: [],
      headersbuslineStops: [
        { text: "Nome", value: "name", sortable: false },
        { text: "Referência", value: "reference", sortable: false },
      ],
    };
  },

  methods: {
    insertBusStop() {
      this.$emit("send-busstop", this.selected);
      this.showDialog = false;
    },
    async openBookingStops() {
      this.selected = [];
      this.loadingBusStops = true;
      this.buslineStops = [];
      this.buslineStops = await BusStopsService.getBusStopsByRegion(
        this.regionId
      );

      this.loadingBusStops = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.label {
  font-size: 0.9rem;
  font-weight: bold;
  color: $greyVale !important;
}
.btnStyle {
  background-color: white !important;
  font-size: small !important;
  color: #6f6f6f !important;
  border-style: none;
  border-color: none;
  box-shadow: 0px 0px;
}
</style>
