import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const BookReservationStore = new Vuex.Store({
  state: {
    reservation: {},
    listReservation: {},
    lastRegistrationNumberBooking: null,
    operation: "create",
  },
  getters: {
    reservation: (state) => {
      return state.reservation;
    },
    listReservation: (state) => {
      return state.listReservation;
    },
    lastRegistrationNumberBooking: (state) => {
      return state.lastRegistrationNumberBooking;
    },
    operation: (state) => {
      return state.operation;
    },
  },
  mutations: {
    setReservationModel(state, reservation) {
      if (!reservation) {
        state.reservation = {
          employeeRegistrationNumber: "",
          addressId: "",
          busLine: "",
          startDate: "",
          date: "",
          endDate: "",
          recurrenceDaysOfWeek: [],
          busStop: "",
          busStopReturn: "",
          siteId: "",
          region: "",
          direction: "",
          prioritySeat: Boolean,
        };
      } else {
        state.reservation = {
          id: reservation.id,
          employeeRegistrationNumber:
          reservation.employee.cpf != null ||
          reservation.employee.cpf != "" ||
          reservation.employee.cpf != undefined
            ? reservation.employee.cpf
            : reservation.employee.registrationNumber,
          addressId: reservation.address.id,
          busLine: reservation.busLine,
          startDate: reservation.startDate,
          date: reservation.date ? reservation.startDate : "",
          endDate: reservation.endDate,
          recurrenceDaysOfWeek:
            reservation.daysRecurrence.length > 0
              ? reservation.daysRecurrence.trim().split(";")
              : [],
          busStop: reservation.arrivalBusStop,
          busStopReturn: reservation.departureBusStop,
          site: reservation.busLine.site,
          region: reservation.busLine.region,
          direction: reservation.direction,
          prioritySeat: reservation.prioritySeat,
        };
      }
    },

    setLastRegistrationNumberBooking(state, registrationNumber) {
      state.lastRegistrationNumberBooking = registrationNumber;
    },

    setOperation(state, operation) {
      state.operation = operation;
    },
  },
});
