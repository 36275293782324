import api from "./api";

export class SettingsService {
    static async putSettings(request) {
        const endpoint = `/settings`;
        let response = await api.put(endpoint, request);
        return response;
    }

    static async getSettings() {
        const endpoint = `/settings`;
        const response = await api.get(endpoint);
        return response.data;
    }

    static async postSettings(request){
        const endpoint = `/settings`;
        const response = await api.post(endpoint, request);
        return response.data;
    }
}