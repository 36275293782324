import api from "./api";

export class BookingService {
  static async getBooking(request) {
    let endpoint = `/reports/busLineReport`;
    
    if (request.startDate && request.endDate) {
      endpoint += `?startDate=${request.startDate}&endDate=${request.endDate}`;
    }

    if(request.state) {
      endpoint += `&uf=${request.state}`
    }

    if(request.regionId) {
      endpoint += `&regionId=${request.regionId}`
    }

    if(request.site) {
      endpoint += `&site=${request.site}`
    }

    let busLinesIds = null;
    if (request.busLinesIds.length > 0)
      busLinesIds = request.busLinesIds.join(',');

    if(busLinesIds != null) {
      endpoint +=`&busLinesIds=${encodeURIComponent(busLinesIds)}`;
    }

    let response = await api.get(endpoint);
    return response.data;
  }

  static async getMyBooking(iamId) {
    let endpoint = `/booking/employee/${iamId}`;
    let response = await api.get(endpoint);
    return response.data;
  }

  static async getBookingById(id) {
    let endpoint = `/booking/${id}`;
    let response = await api.get(endpoint);
    return response.data;
  }

  static async deleteBooking(id) {
    let endpoint = `/booking/${id}`;
    let response = await api.delete(endpoint);
    return response.data;
  }

  static async postBooking(request) {
    const endpoint = `/booking`;
    let response = await api.post(endpoint, request);
    return response.data;
  }

  static async putBooking(id, request) {
    const url = `/booking/${id}`;
    let response = await api.put(url, request);
    return response.data;
  }

  static async getLatestBooking(registrationNumber) {
    const endpoint = `/mostRecentBooking/employee/${registrationNumber}`;
    let response = await api.get(endpoint);
    return response.data;
  }

  static async postBookingBatch(request) {
    const endpoint = `/bookingBatch`;
    let response = await api.post(endpoint, request);
    return response.data;
  }

  static async getBookingBusline() {
    const endpoint = `/booking/busLines`;
    let response = await api.get(endpoint);
    return response.data;
  }

  static async getBookingBuslineByRegion(regionId, filterByActive) {
    const endpoint = `/booking/busLinesByRegion?region=${regionId}&filterByActive=${filterByActive}`;
    let response = await api.get(endpoint);
    return response.data;
  }

  static async getBookingBusLineBySite(siteId, direction) {
    const endpoint = `/booking/busLinesBySite?siteId=${siteId}&direction=${direction}`;
    let response = await api.get(endpoint);
    return response.data;
  }
}
