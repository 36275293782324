<template>
  <v-card class="main-content pa-0 fill-height">
    <v-overlay v-model="overlay" class="align-center justify-center">
      <div style="font-size: 1.8rem; font-weight: bold">
        Aguarde, as informações necessárias estão sendo carregadas...
      </div>
      <v-progress-linear
        indeterminate
        :height="6"
        color="white"
      ></v-progress-linear>
    </v-overlay>
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      :custom-message="customMessage"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>
    <v-card-title class="pl-0 title">
      <h4 v-if="operation === 'edit'">Alterar reserva</h4>
      <h4 v-else>Reservar assento</h4>
    </v-card-title>
    <v-card-subtitle class="pl-0 pt-1" v-if="operation === 'edit'"
      >Realize a alteração da reserva do seu assento.</v-card-subtitle
    >
    <v-card-subtitle class="pl-0 pt-1 subtitle" v-else
      >Efetue o agendamento da reserva do seu assento.</v-card-subtitle
    >
    <v-dialog v-model="showOverlapConfirm" width="511">
      <v-card width="511" class="px-6 pb-6 pt-6">
        <div class="title-conainer pl-0">
          <v-card-title
            class="pl-0 pt-0 pb-0"
            style="color: #393939; font-size: 24px"
            >Sobrepor a reserva</v-card-title
          >
          <v-btn icon @click="showOverlapConfirm = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text class="pl-0 pt-6 pb-6">
          Foi identificada uma reserva já existente para o mesmo dia. Deseja
          cancelar a reserva existente e fazer uma nova para este dia?
        </v-card-text>
        <v-card-actions class="d-flex actions pr-0 pb-0 pt-0 pl-0">
          <GenericButton
            icon="mdi-autorenew"
            title="Sobrepor"
            primary
            v-on:click="save(true)"
            v-if="!loading"
          >
          </GenericButton>
          <template v-if="loading">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ViewLineMapDialog
      v-on:closeDialog="showMap = false"
      :mapProps="mapProps"
      :showDialog.sync="showMap"
      ref="viewLineMapDialog"
    ></ViewLineMapDialog>
    <v-card-text class="card-response">
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="form-response teste2">
          <div class="input-response">
            <v-select
              :rules="[isNotEmptyRule]"
              label="Reserva Pessoal"
              v-model="isPersonal"
              :items="isPersonalBookingOptions"
              @input="setEmployee"
              v-if="permission !== 'employee'"
              outlined
              dense
            ></v-select>
          </div>
          <div class="input-response">
            <v-select
              v-if="permission !== 'employee' && permission !== '' && isPersonal"
              :rules="[isNotEmptyRule]"
              label="Matricula Vale ou CPF a quem a ausência é destinada"
              v-model="employeeName"
              :items="employeesNameOptions"
              outlined
              dense
            ></v-select>
            <v-autocomplete
              v-model="booking.employeeRegistrationNumber"
              :items="employees"
              :item-text="getEmployeeText"
              :item-value="getItemValue"
              :rules="[isNotEmptyRule]"
              label="Matrícula Vale ou CPF a quem a reserva é destinada"
              @input="setSelectedEmployeeValues"
              v-if="permission !== 'employee' && permission !== '' && !isPersonal"
              no-data-text="Digite pelo menos 6 digitos para buscar."
              v-on:keyup="getEmployees"
              :search-input.sync="employeeSearch"
              outlined
              dense
            ></v-autocomplete>
          </div>
        </div>
        <div class="form-response">
          <div
            class="mt-1 recurrenceContainer input-response"
            align="right"
            cols="5"
          >
            <v-select
              label="Reserva Recorrente"
              v-model="isRecurrent"
              :items="isRecurrentOptions"
              :rules="[isNotEmptyRule]"
              v-on:change="changeIsRecurrent"
              :disabled="!booking.employeeRegistrationNumber"
              outlined
              dense
              @change="clearDates"
            >
            </v-select>
          </div>
          <div class="mt-1 input-response" v-if="!isRecurrent">
            <DatePicker
              :label="'Data'"
              ref="isolatedBookingDate"
              :data.sync="booking.startDate"
              :rules="[isNotEmptyRule]"
              :formDate="booking.startDate"
              :date="booking.date"
              :disabled="!booking.employeeRegistrationNumber"
              :min="today"
              outlined
              dense
              v-on:date="booking.date = $event"
              v-on:date-chosen="
                booking.startDate = $event;
                getNumberOfOccupiedSeats();
              "
            ></DatePicker>
          </div>
          <div class="input-response" v-if="isRecurrent">
            <DatePicker
              v-if="isRecurrent"
              :label="'Início da recorrência'"
              ref="recurrenceStart"
              :data.sync="booking.startDate"
              :rules="[isNotEmptyRule]"
              :formDate="formatDate(booking.startDate)"
              :date="booking.startDate"
              :min="today"
              :disabled="!booking.employeeRegistrationNumber"
              @date="booking.date = $event"
              @date-chosen="booking.startDate = $event"
              @picked="clearEndDate"
              outlined
              dense
            ></DatePicker>
          </div>
        </div>
        <div class="form-response" v-if="isRecurrent">
          <div class="input-response">
            <DatePicker
              v-if="isRecurrent"
              :label="'Término da recorrência'"
              ref="recurrenceEnd"
              :data.sync="booking.endDate"
              :formDate="formatDate(booking.endDate)"
              :date="booking.endDate"
              :disabled="disableEndDate"
              :min="getMinEndDate"
              :rules="isRecurrent ? [isNotEmptyRule] : []"
              @date-chosen="booking.endDate = $event"
              @date="booking.date = $event"
              outlined
              dense
            ></DatePicker>
          </div>
          <div class="input-response">
            <v-select
              label="Recorrência desejada pela"
              v-model="recurrenceWeekDays"
              v-if="isRecurrent"
              :items="weekOptions"
              :rules="[isNotEmptyRule]"
              :disabled="!booking.employeeRegistrationNumber"
              item-text="text"
              item-value="id"
              outlined
              dense
              multiple
              persistent-hint
            >
            </v-select>
          </div>
        </div>
        <div class="form-response">
          <div class="input-response">
            <v-select
              label="Direção"
              v-model="booking.direction"
              :items="lineDirectionOptions"
              :rules="[isNotEmptyRule]"
              :disabled="!booking.employeeRegistrationNumber"
              item-text="text"
              item-value="value"
              @change="changeDirection"
              outlined
              dense
            />
          </div>
          <div class="input-response">
            <v-select
              label="Estado"
              v-model="state"
              :items="statesOptions"
              :rules="[isNotEmptyRule]"
              :disabled="!booking.employeeRegistrationNumber"
              item-text="nome"
              item-value="sigla"
              @change="getRegionByUF"
              outlined
              dense
            />
          </div>
        </div>
        <div class="form-response">
          <div class="input-response">
            <v-select
              label="Complexo"
              v-model="regionId"
              @change="getSitesByRegionId"
              :items="regions"
              :disabled="!booking.employeeRegistrationNumber"
              item-text="name"
              item-value="id"
              :rules="[isNotEmptyRule]"
              outlined
              dense
            />
          </div>
          <div class="input-response pb-0">
            <v-select
              label="Site de destino"
              v-model="selectedSite"
              :items="sitesOptions"
              :rules="[isNotEmptyRule]"
              :disabled="!booking.employeeRegistrationNumber"
              item-text="name"
              item-value="id"
              @change="getNearBusStop"
              outlined
              dense
            />
          </div>
        </div>
        <div class="form-response" v-if="showPriorityModel">
          <div class="input-response" v-if="showPriorityModel">
            <v-select
              label="Assento preferencial"
              v-model="isPriority"
              :items="isPriorityOptions"
              :rules="[isNotEmptyRule]"
              :disabled="!booking.employeeRegistrationNumber"
              @change="getNumberOfOccupiedSeats"
              outlined
              dense
            />
          </div>
        </div>

        <v-card class="load-card" v-if="loadingNearBusStop">
          <div class="text-center progress-response">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </div>
        </v-card>

        <div
          v-if="nearestBusStops.length > 0 && showListBusStop"
          class="table-response"
        >
          <v-card
            v-if="nearestBusStops.length > 0 && showListBusStop"
            elevation="0"
            outlined
          >
            <v-radio-group
              v-model="booking.busStop"
              @change="setBusLineSelected()"
              mandatory
            >
              <v-data-table
                :headers="headers"
                :items="nearestBusStops"
                :items-per-page="5"
                no-data-text="Não foram localizados pontos próximos!"
              >
                <template v-slot:item="{ item }">
                  <div class="d-flex justify-space-between">
                    <div>
                      <v-radio
                        class="mb-2 mr-4 ml-8 mt-4"
                        :value="item"
                        :key="item.busStop.id"
                        return-object
                      >
                        <template v-slot:label>
                          <span v-html="busLineCodeList(item)"></span>
                        </template>
                      </v-radio>
                    </div>
                    <div class="icons">
                      <div
                        v-if="item.hasAccessibilityElevator"
                        class="icon-width"
                      >
                        <v-tooltip left max-width="30%">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="wheelchair-icon"
                              v-bind="attrs"
                              v-on="on"
                              small
                            >
                              mdi-wheelchair
                            </v-icon>
                          </template>
                          <span>Esse ônibus possui elevador!</span>
                        </v-tooltip>
                      </div>
                      <div v-if="item.hasTransshipment" class="icon-width ml-3">
                        <v-tooltip left max-width="30%">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small>
                              mdi-bus-alert
                            </v-icon>
                          </template>
                          <span>Esta linha possui baldeio</span>
                        </v-tooltip>
                      </div>
                      <div>
                        <v-btn icon @click.prevent="openMapDialog(item)">
                          <v-icon small>mdi-map-outline</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </template>
              </v-data-table>
            </v-radio-group>
          </v-card>
          <v-card
            elevation="0"
            outlined
            v-if="
              nearestBusStops.length > 0 &&
              showListBusStop &&
              isRecurrent != true
            "
            class="card-line"
          >
            <v-col v-if="showListBusStop">
              <v-icon color="#393939" class="ml-5 mr-3">
                mdi-account-multiple-outline
              </v-icon>
              <div
                class="info-seats"
                v-if="booking.direction.toUpperCase() === 'AMBOS'"
              >
                <span
                  >Assentos disponíveis na ida:
                  <strong
                    >{{ totalSeats - occupancyGoing }} de
                    {{ totalSeats }}</strong
                  ></span
                >
                <span
                  >Assentos disponíveis na volta:
                  <strong
                    >{{ totalSeats - occupancyReturn }} de
                    {{ totalSeats }}</strong
                  ></span
                >
              </div>
              <div class="info-seats" v-else>
                <span
                  >Assentos disponíveis na
                  {{ booking.direction.toLowerCase() }}:
                  <strong
                    >{{
                      totalSeats > occupiedSeats
                        ? totalSeats - occupiedSeats
                        : 0
                    }}
                    de {{ totalSeats }}</strong
                  >
                </span>
              </div>
            </v-col>
          </v-card>
        </div>
        <div class="table-response">
          <v-card
            v-if="nearestBusStops.length <= 0 && showListBusStop"
            class="card-line elevation-0 mt-0"
          >
            <v-icon color="#393939" class="ml-8 mr-3 my-4 icon-account-off"
              >mdi-account-off-outline</v-icon
            >
            <div class="pt-1">Não há assentos disponíveis para essa linha.</div>
          </v-card>
        </div>

        <v-row v-if="loading" class="text-center progress-response">
          <v-col class="col-11">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-col>
        </v-row>

        <div class="div-button">
          <div>
            <GenericButton
              icon="mdi-close"
              title="Cancelar"
              class="button-response cancel-button"
              secondary
              v-on:click="cancel"
              v-if="!loading"
            >
            </GenericButton>
          </div>
          <div>
            <GenericButton
              icon="mdi-check"
              title="Fazer reserva"
              class="button-response"
              primary
              :disabled="
                bookButtonStatusDisabled ||
                loading ||
                loadingNearBusStop ||
                nearestBusStops.length == 0
              "
              v-on:click="save(false)"
              v-if="!loading"
            >
            </GenericButton>
          </div>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomAlert from "@/components/CustomAlert";
import DatePicker from "@/components/DatePicker";
import ViewLineMapDialog from "@/components/ViewLineMapDialog";
import { isNotEmptyRule } from "@/services/validation";
import { BookingService } from "@/services/booking";
import { RouteService } from "@/services/route";
import { RegionService } from "@/services/region";
import { EmployeeService } from "@/services/employee";
import { BusStopsService } from "@/services/busStops";
import { BookReservationStore } from "@/store/bookReservationForm";
import { estados } from "@/store/estados";
import { TabActive } from "../store/tabActive";
import { DateUtils } from "@/utils/dateUtils";
import GenericButton from "../components/GenericButton.vue";
import { PermissionService } from "../services/permission";

export default {
  name: "BookReservation",
  components: {
    CustomAlert,
    DatePicker,
    ViewLineMapDialog,
    GenericButton,
  },
  store: BookReservationStore,
  TabActive,
  data: () => ({
    headers: [
      {
        text: "Linhas disponíveis",
        align: "start",
        value: "action",
      },
    ],
    permission: "",
    isPersonal: "",
    selectedEmployeeIsThirdParty: false,
    selectedEmployeeRegion: "",
    booking: {
      employeeRegistrationNumber: "",
      addressId: "",
      busLineId: "",
      startDate: "",
      date: new Date(),
      endDate: "",
      recurrenceDaysOfWeek: [],
      busStop: "",
      busStopReturn: null,
      siteId: "",
      prioritySeat: false,
    },
    state: "",
    region: "",
    isRecurrent: "",
    recurrenceWeekDays: [],
    isPersonalBookingOptions: [
      { text: "Sim", value: true },
      { text: "Não", value: false },
    ],
    isRecurrentOptions: [
      { text: "Sim", value: true },
      { text: "Não", value: false },
    ],
    isPriorityOptions: [
      { text: "Sim", value: true },
      { text: "Não", value: false },
    ],
    busLines: [],
    regionId: "",
    busLineDirection: "",
    valid: true,
    isPriority: false,
    showPriorityModel: false,
    showAlert: false,
    showDialog: false,
    alertMessageType: "default",
    alertType: "success",
    customMessage: "",
    bookButtonStatusDisabled: false,
    selectBusLineStatusDisabled: false,
    weekOptions: [
      { text: "Segunda", id: "monday" },
      { text: "Terça", id: "tuesday" },
      { text: "Quarta", id: "wednesday" },
      { text: "Quinta", id: "thursday" },
      { text: "Sexta", id: "friday" },
      { text: "Sabado", id: "saturday" },
      { text: "Domingo", id: "sunday" },
    ],
    lineDirectionOptions: [
      { text: "Ida", value: "IDA" },
      { text: "Volta", value: "VOLTA" },
      { text: "Ambos", value: "AMBOS" },
    ],
    employees: [],
    edit: false,
    address: {},
    regions: [],
    overlay: false,
    operation: "",
    employeeSearch: "",
    transshipment: false,
    fullOcupation: 0,
    totalPrioritySeats: 0,
    destinationSite: "",
    showOverlapConfirm: false,
    today: "",
    loading: false,
    statesOptions: estados,
    showMap: false,
    mapProps: {
      latitude: 0.0,
      longitude: 0.0,
      address: null,
      addressTitle: null,
    },
    sitesOptions: [],
    selectedSite: "",
    nearestBusStops: [],
    nearestBusStop: {},
    loadingNearBusStop: false,
    disableEndDate: true,
    minEndDate: "",
    showListBusStop: false,
    occupiedSeats: 0,
    occupancyGoing: -1,
    occupancyReturn: -1,
    totalSeats: 0,
    valueItem: "",
    employeeName: "",
    employeesNameOptions: [],
  }),
  computed: {
    getMinEndDate() {
      let newDate;
      let dateParts;
      if (this.booking.startDate.length > 0) {
        dateParts = this.booking.startDate.split("/");
        newDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      } else {
        dateParts = this.today.split("-");
        newDate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);
      }
      newDate.setDate(newDate.getDate() + 1);
      return newDate.toISOString().substring(0, 10);
    },
  },
  methods: {
    isNotEmptyRule,
    busLineCodeList(item) {
      if (this.booking.direction == "AMBOS") {
        return (
          item.busLineCode +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Ida: </strong> " +
          item.busStop.name +
          " " +
          this.getDistance(item.distance) +
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Retorno: </strong> " +
          item.busStopReturn.name +
          " " +
          this.getDistance(item.distanceReturn)
        );
      } else {
        return (
          item.busLineCode +
          " - " +
          item.busStop.name +
          " " +
          this.getDistance(item.distance)
        );
      }
    },
    orderWeekDays() {
      let orderedWeekDays = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ];
      this.recurrenceWeekDays.sort(
        (a, b) => orderedWeekDays.indexOf(a) - orderedWeekDays.indexOf(b)
      );
    },
    getDistance(distance) {
      if (distance < 1) return Math.round(distance * 1000, 0) + " m";
      else return Math.round(distance, -2) + " km";
    },
    getRouteName(item) {
      return `${item.code}`;
    },
    getEmployeeText(item) {
      if (this.edit) {
        if (item.cpf != null) {
          this.valueItem = "cpf";
          return `${item.cpf} - ${item.name}`;
        } else if (item.registrationNumber != null) {
          this.valueItem = "registrationNumber";
          return `${item.registrationNumber} - ${item.name}`;
        } else {
          this.valueItem = "registrationNumber";
          return `${item.registrationNumber} - ${item.name}`;
        }
      } else {
        let searchTerm = this.employeeSearch;
        if (this.employeeSearch && this.employeeSearch.includes(" - ")) {
          searchTerm = this.employeeSearch.split(" - ")[0];
        }
        if (item.cpf != null && item.cpf.includes(searchTerm)) {
          this.valueItem = "cpf";
          return `${item.cpf} - ${item.name}`;
        } else if (
          item.registrationNumber != null &&
          item.registrationNumber.includes(searchTerm)
        ) {
          this.valueItem = "registrationNumber";
          return `${item.registrationNumber} - ${item.name}`;
        } else {
          this.valueItem = "registrationNumber";
          return `${item.registrationNumber} - ${item.name}`;
        }
      }
    },
    getItemValue(item) {
      if (this.valueItem == "cpf") {
        return item.cpf;
      } else if (item.registrationNumber) {
        return item.registrationNumber;
      } else {
        return item.cpf;
      }
    },
    getAddressText(item) {
      return item?.givenAddress ? item.givenAddress : item.formattedAddress;
    },
    closeAlert() {
      this.showAlert = false;
      this.customMessage = "";
    },
    setIsThirdParty(selectedEmployee) {
      this.selectedEmployeeIsThirdParty = selectedEmployee.isThirdParty;
    },
    setAddresses(selectedEmployee) {
      if (selectedEmployee != null) {
        this.address = selectedEmployee.address;
      }
    },
    setPriority(selectedEmployee) {
      if (selectedEmployee != null) {
        this.showPriorityModel = selectedEmployee.preferential;
        if (!this.edit) {
          this.isPriority = selectedEmployee.preferential;
        }
      }
    },
    setSelectedEmployeeRegion(selectedEmployee) {
      this.selectedEmployeeRegion = selectedEmployee.region;
    },
    validateBooking() {
      if (!this.regionId) return false;
      let bookRegion = this.regions.filter((x) => x.id == this.regionId)[0];

      if (!bookRegion) return false;
      let isThirdPartyValidationSuccessful =
        !this.selectedEmployeeIsThirdParty ||
        (bookRegion.acceptThirdPartyBook && this.selectedEmployeeIsThirdParty);

      let isOtherRegionsValidationSuccessful =
        bookRegion.acceptOtherRegionsBook ||
        this.selectedEmployeeRegion.name === bookRegion.name;

      let isValidationSuccessful =
        isThirdPartyValidationSuccessful && isOtherRegionsValidationSuccessful;

      if (isValidationSuccessful) {
        this.showAlert = false;
        this.bookButtonStatusDisabled = false;
        this.selectBusLineStatusDisabled = false;
      } else {
        this.selectBusLineStatusDisabled = true;
        this.showAlert = true;
        this.bookButtonStatusDisabled = true;

        if (!isThirdPartyValidationSuccessful) {
          this.thirdPartyNotAllowedAlert();
        } else if (!isOtherRegionsValidationSuccessful) {
          this.otherRegionsBookNotAllowedAlert();
        }
      }

      return isValidationSuccessful;
    },
    setSelectedEmployeeValues(employeeRegistrationNumber) {
      if (employeeRegistrationNumber?.length > 0) {
        const selectedEmployee = this.employees.filter(
          (employee) =>
            employee.registrationNumber.toUpperCase() ===
              employeeRegistrationNumber.toUpperCase() ||
            employee.cpf ===
              employeeRegistrationNumber.toUpperCase()
        );
        this.setActualEmployeeValues(selectedEmployee[0]);
        this.validateBooking();
      }
    },
    setActualEmployeeValues(selectedEmployee) {
      this.setSelectedEmployeeRegion(selectedEmployee);
      this.setIsThirdParty(selectedEmployee);
      this.setAddresses(selectedEmployee);
      this.setPriority(selectedEmployee);
    },
    async setEmployee() {
      if (this.isPersonal) {
        this.booking.employeeRegistrationNumber =
          localStorage.getItem("registrationNumber");
        await this.getEmployees();
      } else {
        this.booking.employeeRegistrationNumber = "";
      }

      if (this.booking.employeeRegistrationNumber) {
        this.setSelectedEmployeeValues(this.booking.employeeRegistrationNumber);
      }
    },
    async setBusLine(busLineId) {
      try {
        const busSelected = await RouteService.getBuslineId(busLineId);
        this.fullOcupation = busSelected.numberOfSeats;
        this.totalPrioritySeats = busSelected.busType.capacityForPrioritySeats;
        this.transshipment = busSelected.transshipment;
        this.destinationSite = busSelected.destinationSite;
        this.busLineDirection = busSelected.direction;
        this.busLineDirection = this.busLineDirection.toLowerCase();
      } catch (e) {
        console.log(e);
      }
    },
    changeIsRecurrent() {
      this.booking.recurrenceDaysOfWeek = [];
      if (!this.isRecurrent) {
        this.booking.endDate = "";
        this.recurrenceWeekDays = [];
      }
    },
    async getNumberOfOccupiedSeats() {
      const { busLineId, startDate, direction } = this.booking;

      if (!busLineId || !startDate || !direction) {
        return;
      }

      const { occupancyGoing, occupancyReturn } =
        await RouteService.occupiedSeats(
          busLineId,
          startDate,
          direction.substr(0, 1),
          this.isPriority
        );

      this.totalSeats = this.isPriority
        ? this.totalPrioritySeats
        : this.fullOcupation;

      if (direction.toUpperCase() === "AMBOS") {
        this.occupancyGoing = occupancyGoing;
        this.occupancyReturn = occupancyReturn;
      } else {
        this.occupiedSeats =
          occupancyGoing === -1 ? occupancyReturn : occupancyGoing;
      }
    },
    otherRegionsBookNotAllowedAlert() {
      this.alertType = "error";
      this.customMessage =
        "A complexo selecionado não aceita reservas serem vinculadas a colaboradores de outros complexos.";
      this.alertMessageType = "createBookingError";
      this.showAlert = true;
    },
    thirdPartyNotAllowedAlert() {
      this.alertType = "error";
      this.customMessage =
        "A complexo selecionado não aceita reservas serem vinculadas a terceiros.";
      this.alertMessageType = "createBookingError";
      this.showAlert = true;
    },
    async getBusLines() {
      this.validateBooking();
      this.booking.busLineId = "";
      this.busLines = await BookingService.getBookingBuslineByRegion(
        this.regionId,
        true
      );
    },
    formatDate(date) {
      if (!date) return null;
      if (date.indexOf("/") == -1) return date;
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    async save(overlapBooking) {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.booking.overlapBooking = overlapBooking;

      if (overlapBooking) {
        this.showOverlapConfirm = false;
        overlapBooking = false;
      }

      this.booking.siteId = this.selectedSite;
      this.booking.prioritySeat = this.isPriority;
      if (!this.booking.date)
        this.booking.date = this.formatDate(this.booking.startDate);
      if (!this.isRecurrent) {
        this.booking.endDate = this.booking.startDate;
        this.booking.recurrenceDaysOfWeek = [];
      } else {
        this.orderWeekDays();
        this.booking.recurrenceDaysOfWeek = this.recurrenceWeekDays;
      }
      if (this.formatDate(this.booking.date) >= this.today) {
        try {
          this.loading = true;
          this.booking.addressId = this.address.id;
          if (this.booking.direction == "AMBOS") {
            this.booking.busStopReturn = this.booking.busStop.busStopReturn;
          } else {
            this.booking.busStopReturn = null;
          }
          if (this.operation === "create") {
            await BookingService.postBooking(this.booking);
            BookReservationStore.commit(
              "setLastRegistrationNumberBooking",
              this.booking.employeeRegistrationNumber
            );
          } else if (this.edit) {
            await BookingService.putBooking(this.booking.id, this.booking);
            
            BookReservationStore.commit(
              "setLastRegistrationNumberBooking",
              this.booking.employeeRegistrationNumber
            );
          }
          await TabActive.commit("setActiveTab", 1);
          this.$emit("tab-changed", 1);
          this.reloadBooking();
          this.resetForm();
          TabActive.commit("setShowSnackbar", true);
          this.loading = false;
        } catch (e) {
          console.log(e);
          this.createBookingError(e);
        }
      } else {
        this.invalidDate();
      }

      this.resetBookingModel();
    },
    async cancel() {
      this.resetBookingModel();
      await this.$router.push(`/home`);
    },

    createBookingError(e) {
      if (e.status == 409) {
        this.loading = false;
        this.showOverlapConfirm = true;
      } else {
        this.customMessage = e.data.message;
        this.alertType = "error";
        this.alertMessageType = "createBookingError";
        this.showAlert = true;
        this.loading = false;
      }
    },
    invalidDate() {
      this.alertType = "error";
      this.alertMessageType = "invalidDate";
      this.showAlert = true;
    },
    async resetBookingModel() {
      await BookReservationStore.commit("setReservationModel", null);
      await BookReservationStore.commit("setOperation", "create");
    },
    async getEmployees() {
      this.employees = [];
      if (this.isPersonal) {
        this.employees = await EmployeeService.getEmployeeSearch(
          localStorage.registrationNumber
        );
        this.employeeName = `${this.booking.employeeRegistrationNumber} - ${this.employees[0].name} `;
        this.employeesNameOptions = [this.employeeName];
      } else if (
        this.employeeSearch != null &&
        this.employeeSearch.length >= 5
      ) {
        this.employees = await EmployeeService.getEmployeeSearch(
          this.employeeSearch
        );
      }
    },
    async getRegionByUF() {
      this.regions = [];
      this.regionId = "";
      this.selectedSite = "";
      this.sitesOptions = [];
      this.nearestBusStops = [];
      this.nearestBusStop = {};
      this.regions = await RegionService.getRegionByUf(this.state);
    },

    async getSitesByRegionId() {
      this.selectedSite = null;
      this.sitesOptions = [];
      this.nearestBusStops = [];
      this.nearestBusStop = {};
      this.sitesOptions = await RegionService.getSitesByRegionId(this.regionId);
    },
    changeDirection() {
      if (this.selectedSite) this.getNearBusStop();
    },
    async getNearBusStop() {
      const request = {
        registrationNumber: this.booking.employeeRegistrationNumber.trim(),
        siteId: this.selectedSite,
        busStopsQuantity: 20,
        direction: this.booking.direction.charAt(0),
      };
      this.nearestBusStops = [];
      this.nearestBusStop = {};
      this.loadingNearBusStop = true;
      this.nearestBusStops = await BusStopsService.getNearBusStops(request);
      this.showListBusStop = true;

      this.loadingNearBusStop = false;
    },
    openMapDialog(busStop) {
      this.mapProps.latitude = Number(busStop.busStop.latitude);
      (this.mapProps.longitude = Number(busStop.busStop.longitude)),
        (this.mapProps.address = busStop.busStop.address),
        (this.mapProps.addressTitle = busStop.busLineCode);
      this.$refs.viewLineMapDialog.setAzureMapsOptions();
      this.showMap = true;
    },
    async setBusLineSelected() {
      if (
        this.booking &&
        this.booking.busStop &&
        this.booking.busStop.busLineId
      ) {
        this.booking.busLineId = this.booking.busStop.busLineId;
        await this.setBusLine(this.booking.busLineId);
        this.getNumberOfOccupiedSeats();
      }
    },
    setToday() {
      const today = DateUtils.getTodayDateToISOString();
      this.today = today;
      this.minEndDate = today;
    },
    setMinEndDate() {
      const dateParts = this.booking.startDate.split("/");
      const newDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      newDate.setDate(newDate.getDate() + 1);
      this.minEndDate = newDate.toISOString().substring(0, 10);
    },
    clearDates() {
      this.booking.endDate = "";
      this.booking.startDate = "";

      if (this.$refs.isolatedBookingDate) {
        this.$refs.isolatedBookingDate.reset();
      } else if (this.$refs.recurrenceStart && this.$refs.recurrenceEnd) {
        this.$refs.recurrenceEnd.reset();
        this.$refs.recurrenceStart.reset();
      }
    },

    clearEndDate() {
      this.setMinEndDate();
      this.disableEndDate = false;
      this.booking.endDate = "";
      this.$refs.recurrenceEnd.reset();
    },
    resetForm() {
      this.booking = {
        employeeRegistrationNumber: "",
        addressId: "",
        busLineId: "",
        startDate: "",
        date: new Date(),
        endDate: "",
        recurrenceDaysOfWeek: [],
        busStop: "",
        busStopReturn: null,
        siteId: "",
        prioritySeat: false,
      };
      this.isPersonal = false;
      this.loadingNearBusStop = false;
      this.showListBusStop = false;
      this.$refs.form.reset();
    },
    async reloadBooking() {
      this.$emit("reload-booking");
    },
  },
  async beforeMount() {
    try {
      this.overlay = true;
      this.employees = await EmployeeService.getEmployeeSearch(
        localStorage.registrationNumber
      );

      this.permission = PermissionService.getPermission();
      if (this.permission === "employee") {
        this.isPersonal = true;
        this.setEmployee();
      }

      this.operation = BookReservationStore.getters.operation;

      this.booking = {
        employeeRegistrationNumber:
          BookReservationStore.getters.reservation.employeeRegistrationNumber,
        addressId: BookReservationStore.getters.reservation.addressId,
        busLine: BookReservationStore.getters.reservation.busLine,
        startDate: BookReservationStore.getters.reservation.startDate,
        date: BookReservationStore.getters.reservation.date,
        endDate: BookReservationStore.getters.reservation.endDate,
        recurrenceDaysOfWeek:
          BookReservationStore.getters.reservation.recurrenceDaysOfWeek,
        busStop: BookReservationStore.getters.reservation.busStop,
        busStopReturn: BookReservationStore.getters.reservation.busStopReturn,
        site: BookReservationStore.getters.reservation.site,
        region: BookReservationStore.getters.reservation.region,
        prioritySeat: BookReservationStore.getters.reservation.prioritySeat,
      };

      if (this.operation === "edit") this.edit = true;

      if (this.edit) {
        this.employees = await EmployeeService.getEmployeeSearch(
          this.booking.employeeRegistrationNumber
        );

        if (
          this.booking.employeeRegistrationNumber ==
            localStorage.getItem("registrationNumber") ||
          this.employees[0].cpf == localStorage.getItem("registrationNumber")
        ) {
          this.isPersonal = true;
          this.setEmployee();
        } else {
          this.isPersonal = false;
          this.setActualEmployeeValues(this.employees[0]);
        }

        (this.lineDirectionOptions = [
          { text: "Ida", value: "IDA" },
          { text: "Volta", value: "VOLTA" },
        ]),
          (this.isRecurrent = this.booking.startDate != this.booking.endDate);
        if (this.isRecurrent) {
          this.minEndDate = this.setMinEndDate();
          this.disableEndDate = false;
        }

        this.booking.employeeRegistrationNumber =
          BookReservationStore.getters.reservation.employeeRegistrationNumber;
        this.booking.id = BookReservationStore.getters.reservation.id;
        this.state = BookReservationStore.getters.reservation.region.uf;
        await this.getRegionByUF();
        this.regionId = BookReservationStore.getters.reservation.region.id;
        await this.getSitesByRegionId();
        this.selectedSite = BookReservationStore.getters.reservation.site.id;
        this.booking.direction =
          BookReservationStore.getters.reservation.direction;
        this.isPriority = BookReservationStore.getters.reservation.prioritySeat;

        await this.getNearBusStop();
        this.booking.busStop =
          BookReservationStore.getters.reservation.departureBusStop;
        this.booking.busLineId =
          BookReservationStore.getters.reservation.busLine.id;

        if (this.booking.recurrenceDaysOfWeek) {
          this.recurrenceWeekDays = this.booking.recurrenceDaysOfWeek.map(
            (element) => {
              if (element.toLowerCase().trim() == "segunda") return "monday";
              if (element.toLowerCase().trim() == "terça") return "tuesday";
              if (element.toLowerCase().trim() == "quarta") return "wednesday";
              if (element.toLowerCase().trim() == "quinta") return "thursday";
              if (element.toLowerCase().trim() == "sexta") return "friday";
              if (element.toLowerCase().trim() == "sábado") return "saturday";
              if (element.toLowerCase().trim() == "domingo") return "sunday";
            }
          );
        }

        this.isPersonal =
          this.booking.employeeRegistrationNumber ==
          localStorage.getItem("registrationNumber");
        await this.getNumberOfOccupiedSeats();

        this.resetBookingModel();
      }

      if (this.isPersonal != "") {
        this.setEmployee();
      }

      this.setToday();
      this.overlay = false;
    } catch (e) {
      console.error(e);
      this.overlay = false;
    }
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
}

.title {
  padding-top: 32px;
}

.title-conainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.actions {
  justify-content: flex-end;
}

.v-btn {
  text-transform: none;
}

.titleOption {
  font-weight: 500;
  font-size: 1.2em;
}

.subtitle {
  padding-bottom: 32px;
}
.col-1 {
  align-self: center;
  margin: 0;
  padding: 0;
}
.cancelButton {
  border-color: $greyVale60;
}

.recurrenceContainer {
  margin-top: -26.5px;
}

.recurrence {
  flex-grow: 1;
}

.pointer {
  cursor: pointer;
}

.cursorDefault {
  cursor: default;
}

.noBusStop {
  display: none;
}

.radioBusStopContainer {
  border: 1px solid $greyVale60;
  border-radius: 4px;
}
.v-label {
  font-size: desired size;
}

.icons {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon-width {
    width: 16px;
    height: 36px;
    display: flex;
    align-items: center;
  }
}

.wheelchair-icon {
  color: #008f83;
}

.icon-account-off {
  color: #393939;
  font-size: 14px;
}

.card-line {
  height: 44px;
  display: flex;
  color: #393939;
  flex-direction: row;
  align-items: center;
  border-width: thin;
  border-style: solid;
  border-color: #e0e0e0;
  text-transform: none;
  border-radius: 4px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.radioButton {
  font-size: 14px !important;
}

.info-seats {
  display: inline-flex;
}

.info-seats > span:first-child {
  margin-right: 40px;
}

::v-deep .v-radio label {
  font-size: 12px;
  color: #6f6f6f;
  margin-left: 4px;
}

::v-deep .v-input--radio-group {
  margin-top: 0;
}

::v-deep .v-icon.v-icon {
  font-size: 20px;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0 24px;
}

::v-deep .v-messages {
  min-height: 0px;
  min-width: 0px;
}

::v-deep .v-input__slot {
  margin-bottom: 0px;
}

::v-deep .theme--light.v-data-table .v-data-footer {
  margin-top: 8px;
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.04);
}

::v-deep .theme--light.v-data-table .v-data-footer {
  border-top: thin solid rgba(0, 0, 0, 0.04);
}

.form-response {
  width: 91.5%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  .input-response {
    width: 44.4%;
  }
}

.table-response {
  width: 91.5%;
  margin-bottom: 24px;
}

.div-button {
  width: 91.5%;
  display: flex;
  justify-content: end;

  .button-response {
    margin-left: 12px;
  }

  .cancel-button {
    margin-right: 16px !important;
  }
}
.card-response {
  padding-top: 24px;
  padding-left: 24px;
  padding-bottom: 24px;
  padding-right: 24px;
  border-width: thin;
  border-style: solid;
  border-color: #e0e0e0;
  text-transform: none;
  border-radius: 4px;
}

.progress-response {
  margin-bottom: 16px;
}

@media screen and (max-width: 700px) {
  .form-response {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    .input-response {
      width: 100%;
      margin-bottom: 4px;
    }
  }

  .div-button {
    width: 100%;
    display: flex;
    flex-direction: column;

    .button-response {
      width: 100%;
      margin-bottom: 16px !important;
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
    }

    .cancel-button {
      margin-right: 0;
    }
  }

  .card-response {
    width: 100%;
    padding-left: 16px;
    padding-top: 12px;
  }

  .table-response {
    width: 100%;
    margin-bottom: 8px;

    .v-input--selection-controls {
      margin-top: 0px;
      padding-top: 0px;
    }
  }
}

.font-no-vagas {
  color: #393939;
}

.icon-no-vagas {
  color: #393939;
}

.load-card {
  width: 91.5%;
}
</style>
