<template>
  <v-card class="main-content pa-4 fill-height">
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      :customMessage="customMessage"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>
    <v-row>
      <v-col>
        <v-card-title>
          <h4>Administrar passageiros esperados</h4>
        </v-card-title>
        <v-card-subtitle class="mb-1">
          Aqui você poderá visualizar os passageiros esperados por ponto de
          ônibus.
        </v-card-subtitle>
      </v-col>
    </v-row>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              class="elevation-1 col-12"
              :search="search"
              :custom-filter="getAllRoutes"
              :items="busLines"
              item-key="id"
              v-model="selectRoute"
              :loading="loading"
              :options.sync="options"
              :server-items-length="totalRoutes"
              :footer-props="{
                'items-per-page-options': [10],
              }"
            >
              <template v-slot:top>
                <v-row class="mb-2">
                  <v-col class="col-2">
                    <v-text-field
                      v-model="search"
                      label="Buscar"
                      outlined
                      dense
                      class="mt-1"
                      @keyup.enter="getAllRoutes"
                      :clearable="true"
                    ></v-text-field>
                  </v-col>
                  <v-col class="col-2">
                    <v-select
                      class="mt-1"
                      label="Estado"
                      v-model="state"
                      :items="statesOptions"
                      item-text="nome"
                      item-value="sigla"
                      @change="getRegionByUF"
                      @keyup.enter="getAllRoutes"
                      :clearable="true"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col class="col-2">
                    <v-select
                      outlined
                      dense
                      label="Complexo"
                      v-model="regionId"
                      :items="regions"
                      :clearable="true"
                      item-text="name"
                      item-value="id"
                      class="mt-1"
                      :disabled="!state"
                      @change="loadSites"
                      @keyup.enter="getAllRoutes"
                    />
                  </v-col>
                  <v-col class="col-2">
                    <v-combobox
                      id="comboboxSite"
                      v-model="selectedSite"
                      label="Site de destino"
                      :items="sites"
                      item-text="name"
                      class="mt-1"
                      outlined
                      dense
                      :disabled="!regionId"
                      @change="setSiteList"
                    />
                  </v-col>
                  <v-col class="mt-1">
                    <GenericButton
                      class="mr-4"
                      title="Limpar"
                      icon="mdi-filter-off-outline"
                      secondary
                      @click="clearFilter"
                    ></GenericButton>
                    <GenericButton
                      title="Buscar"
                      icon="mdi-magnify"
                      primary
                      @click="getAllRoutes"
                    ></GenericButton>
                  </v-col>
                </v-row>
                <v-row class="mt-0 mb-2">
                  <v-col v-if="selectedSiteList.length > 0" class="pt-0 pb-0">
                    <span v-for="data in selectedSiteList" v-bind:key="data.id">
                      <v-chip
                        :input-value="data.id"
                        close
                        @click:close="remove(data.id)"
                        class="mr-2 mb-2"
                      >
                        {{ data.name }}
                      </v-chip>
                    </span>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  color="primary"
                  small
                  class="mr-2"
                  @click="editRoute(item)"
                  >mdi-pencil-outline</v-icon
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomAlert from "@/components/CustomAlert";
import { RouteService } from "@/services/route";
import { RegionService } from "@/services/region";
import { estados } from "@/store/estados";
import GenericButton from "../components/GenericButton.vue";
import { PermissionService } from "../services/permission";

export default {
  name: "adminExpectedEmployees",
  components: {
    CustomAlert,
    GenericButton,
  },
  data: () => ({
    search: "",
    regionId: "",
    showAlert: false,
    showDialog: false,
    alertMessageType: "default",
    alertType: "success",
    customMessage: "",
    regionSearch: "",
    regionSearchAux: "",
    valid: true,
    headers: [
      { text: "", value: "actions" },
      { text: "Linha de ônibus", value: "code" },
      { text: "Itinerário", value: "direction" },
      //Adequação tecnica para colocar os valores do detalhes em baixo do headers
      { text: "Complexo", value: "region.name" },
      { text: "Site", value: "site.name" },
      { text: "", value: "" },
      { text: "", value: "" },
      { text: "", value: "data-table-expand" },
    ],
    busLines: [],
    selectRoute: [],
    loading: true,
    options: {},
    filterAux: "",
    totalRoutes: 0,
    regions: [],
    state: "",
    statesOptions: estados,
    sites: [],
    selectedSiteList: [],
    selectedSiteIds: [],
    selectedSite: "",
  }),
  watch: {
    options: {
      handler() {
        this.getAllRoutes();
      },
      deep: true,
    },
  },
  methods: {
    editRoute(route) {
      this.$router.push("/expected-employees-form/" + route.id);
    },
    async filter() {
      if (this.regionSearch == null) this.regionSearch = "";
      if (
        this.filterAux != this.search ||
        this.regionSearch != this.regionSearchAux
      ) {
        this.filterAux = this.search;
        this.regionSearchAux = this.regionSearch;
        if (this.search.length >= 3 || this.regionSearch.length > 0) {
          try {
            this.loading = true;
            let response = await RouteService.getAllRoutes(
              0,
              10,
              this.search,
              this.regionSearch
            );
            this.busLines = response.content;
            this.totalRoutes = response.totalElements;
            this.loading = false;
          } catch (e) {
            window.alert(e);
          }
        } else if (this.search == null || this.search.length <= 1) {
          this.getAllRoutes();
        }
      }
    },
    async getAllRoutes() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;

      if (this.regionId == null) {
        this.regionId = "";
      }
      if (this.search == null) {
        this.search = "";
      }
      if (this.state == null) {
        this.state = "";
      }

      let response = await RouteService.getAllRoutes(
        page - 1,
        itemsPerPage,
        this.search,
        this.regionId,
        this.state,
        this.selectedSiteIds
      );

      this.busLines = response.content;
      this.totalRoutes = response.totalElements;
      this.loading = false;
    },
    clearFilter() {
      this.search = "";
      this.regionId = "";
      this.state = "";
      this.selectedSite = "";

      (this.selectedSiteIds = []),
        (this.selectedSiteList = []),
        this.getAllRoutes();
    },
    async getRegionByUF() {
      this.clearInputsWhenChangeState();
      this.regions = await RegionService.getRegionByUf(this.state);
    },
    async loadSites() {
      try {
        this.selectedSite = "";
        this.selectedSiteList = [];
        this.selectedSiteIds = [];
        this.sites = await RegionService.getSitesByRegionId(this.regionId);
      } catch {
        this.selectedSite = "";
        this.selectedSiteList = [];
        this.selectedSiteIds = [];
        this.sites = [];
      }
    },
    setSiteList() {
      if (this.selectedSiteList.includes(this.selectedSite)) {
        this.invalidBusLineInput = true;
        this.generateReportError("Esse site já foi selecionada");
      } else {
        this.invalidBusLineInput = false;
        if (
          this.selectedSite.id !== "" &&
          this.selectedSite.id !== null &&
          this.selectedSite.name !== ""
        ) {
          this.selectedSiteList.push(this.selectedSite);
          this.selectedSiteIds.push(this.selectedSite.id);
          this.$nextTick(() => {
            this.selectedSite = "";
          });
        }
      }
    },
    remove(item) {
      const index = this.selectedSiteList.findIndex((object) => {
        return object.id === item;
      });
      if (index >= 0) {
        this.selectedSiteList.splice(index, 1);
        this.selectedSiteIds.splice(index, 1);
      }
    },
    generateReportError(errorMessage) {
      this.alertType = "error";
      this.showAlert = true;
      if (errorMessage != null) {
        this.customMessage = errorMessage;
      } else {
        this.customMessage = "Ocorreu um erro ao submeter a solicitação";
      }
    },
    setAlertProps(isSuccess, type) {
      if (isSuccess) {
        this.alertType = "success";
      } else {
        this.alertType = "error";
      }
      this.showAlert = true;
      this.alertMessageType = type;
    },
    closeAlert() {
      this.showAlert = false;
    },
    clearInputsWhenChangeState() {
      this.regionId = "";
      this.regions = [];
      this.selectedSite = "";
      this.selectedSiteList = [];
    },
  },
  async beforeMount() {
    let getRegions = await RegionService.getAllRegions();
    this.regions = getRegions;
    this.permission = PermissionService.getPermission();
    if (this.permission !== "admin") {
      this.$router.push("home");
    }

    if (this.$route.query.showAlert) {
      this.setAlertProps(true, "defaultSuccess");
    }
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";
.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;

  .v-card-subtitle {
    color: $greyVale40;
  }

  .v-card-text {
    text-align: center;
  }
}

.v-btn {
  text-transform: none;
}

.v-data-table {
  tbody {
    color: $greyVale;
    td {
      background-color: white;
    }
    .v-icon {
      color: $greyVale;
    }
  }
}

::v-deep .v-chip.v-size--default {
  font-size: 12px;
  color: #6f6f6f;
  background: #f4f4f4;
}

::v-deep .v-icon.v-icon.v-icon--link {
  color: #6f6f6f;
}

::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
}
</style>
