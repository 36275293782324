// NOTE: Do not couple this file with any component specific validation.

export function isNotEmptyRule(value, msg) {
    if (!msg) {
        msg = "Campo Obrigatório";
    }
    return isNotEmpty(value) ? true : msg;
}

export function isNotEmpty(value) {
    if (value === undefined || value === null || value === "") {
        // NOTE: Do not use `!value` because we want to consider
        //  the boolean `false` and number `0` as not empty.
        return false;
    }
    return value.toString().trim() !== "";
}

export function isPositiveNumber(value, msg){
    if(!msg){
        msg = "O valor deve ser positivo";
    }

    return Number(value) >= 0 ? true : msg; 
}

export function isGreaterThanZero(value, msg){
    if(!msg){
        msg = "O valor deve ser maior que zero";
    }

    return Number(value) > 0 ? true : msg; 
}

export function minLength(value, size) {
    const msg = `Mínimo ${size} caracteres`;
    if (value)
      return value.length >= size ? true : msg;
    else return msg;
}

export function validateEmail(value){
    if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) return true ;

    return "Insira um email válido";
}

export function validateCPF(cpf){
    if (!cpf || cpf == "") return true;
    if (!checkAll(prepare(cpf)))
        return "Informe um CPF válido";
    
    return true;
}

const notDig = i => !['.', '-', ' '].includes(i)
const prepare = cpf => cpf.trim().split('').filter(notDig).map(Number)
const is11Len = cpf => cpf.length === 11
const notAllEquals = cpf => !cpf.every(i => cpf[0] === i)
const onlyNum = cpf => cpf.every(i => !isNaN(i))

const calcDig = limit => (a, i, idx) => a + i * ((limit + 1) - idx)
const somaDig = (cpf, limit) => cpf.slice(0, limit).reduce(calcDig(limit), 0)
const resto11 = somaDig => 11 - (somaDig % 11)
const zero1011 = resto11 => [10, 11].includes(resto11) ? 0 : resto11

const getDV = (cpf, limit) => zero1011(resto11(somaDig(cpf, limit)))
const verDig = pos => cpf => getDV(cpf, pos) === cpf[pos]

const checks = [is11Len, notAllEquals, onlyNum, verDig(9), verDig(10)]
const checkAll = cpf => checks.map(f => f(cpf)).every(r => !!r)