<template>
  <v-card class="main-content pa-6 fill-height">
    <v-card-title class="pl-0 pt-0 pb-0 mb-6">
      <h4>Termo de Privacidade de Dados</h4>
    </v-card-title>

    <v-card-text class="mb-6 pl-0 pb-0">
      O Termo de Privacidade visa apresentar como tratamos os seus dados
      pessoais coletados para que Você realize o agendamento e reserva de
      poltrona para seu deslocamento através do transporte coletivo que a VALE
      disponibiliza para seus empregados, inclusive das demais empresas do
      grupo, bem como para terceiros cujos contratos de prestação de serviço das
      contratadas contenham cláusulas que estabeleçam que o transporte é uma
      responsabilidade da Vale. Para isso, a Vale realiza a coleta e trata
      alguns dos seus dados pessoais quando Você os submete ou nos fornece para
      atingirmos o objetivo apresentado neste Termo. Clique em “Baixar texto
      completo” para acessar as informações completas sobre o seu tratamento de
      dados pessoais.
    </v-card-text>

    <v-btn
      color="primary"
      text
      href="Vale_TermodePrivacidadeEspecífico_Sistema de agendamento de vagas do transporte (rev 23jun22).pdf"
      target="_blank"
      class="mb-6"
    >
      <v-icon class="mr-2" color="primary">mdi-download-outline</v-icon>
      Baixar texto completo
    </v-btn>

    <v-card-actions class="col-2 pl-0 pt-0">
      <v-btn color="primary" @click="accept">
        <v-icon class="mr-2"> mdi-check-all </v-icon> Estou ciente
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "PrivacyTerm",
  props: {
    parentComponent: String,
  },
  data() {
    return {
      checkbox: false,
    };
  },
  methods: {
    accept() {
      this.checkbox = true;
      localStorage.setItem("privacyTerm", this.checkbox);
      localStorage.setItem("showNotification", "true");
      if (this.parentComponent) {
        this.$emit("close-alert");
      } else {
        this.$router.push("home").catch(() => {});
      }
    },
  },
  mounted() {
    this.checkbox = localStorage.getItem("privacyTerm");
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;

  .invalid-input {
    color: $redVale;
  }
}
</style>
