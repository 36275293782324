import api from "./api";

export class EmailService{

    static async postEmailDeleteBooking(params) {
        const headers = {'Content-Type': 'application/json'}
        let response = await api.post(`/email/delete-booking`,params,{headers: headers});
        return response.data;
    }

    static async postEmailDeleteBatchBooking(params) {
        const headers = {'Content-Type': 'application/json'}
        let response = await api.post(`/email/deleteBookingBatch`,params,{headers: headers});
        return response.data;
    }

    static async postEmailDeleteUser(params) {
        const headers = {'Content-Type': 'application/json'}
        let response = await api.post(`/email/deleteUserEmail`,params,{headers: headers});
        return response.data;
    }

}