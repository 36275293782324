<template>
  <div id="emailContainer" ref="emailContainer">
    <slot> </slot>
  </div>
</template>

<script>
import { EmailService } from "@/services/email";
export default {
  name: "EmailSender",
  data: () => ({}),
  methods: {
    postEmailDeleteBooking(params) {
      try {
        let payload = {
          ...params,
        };
        EmailService.postEmailDeleteBooking(payload);
      } catch {
        console.log("Erro ao enviar e-mail !");
      }
    },

    postEmailDeleteBatchBooking(params) {
      try {
        let payload = {
          ...params,
        };
        EmailService.postEmailDeleteBatchBooking(payload);
      } catch {
        console.log("Erro ao enviar e-mail !");
      }
    },

    postEmailDeleteUser(params) {
      try {
        let payload = {
          ...params,
        };
        EmailService.postEmailDeleteUser(payload);
      } catch {
        console.log("Erro ao enviar e-mail !");
      }
    },
  },
};
</script>

<style></style>
