import axios from "axios"
import msalInstance from "../msalPlugin"


let api = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
});

api.interceptors.request.use(
    async config => {
      if (msalInstance.isTokenExpired()) {
        await msalInstance.renewToken()
      }

      const token = window.localStorage.getItem("idToken");
      if(token) {
          config.headers.Authorization = "Bearer " + token;
          config.headers.RegistrationNumber = window.localStorage.getItem("userId");
      }else {
        config.headers.Authorization = "Bearer access_token_bypass";
        config.headers.RegistrationNumber = window.localStorage.getItem("registrationNumber");
        
          config.headers.post['Content-Type'] = 'text/plain';
      }
      return config;
    },
);

api.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.response.status === 401 && error.response.statusText === "Unauthorized") {
            window.localStorage.removeItem("auth");
            window.localStorage.removeItem("userInfo");
            window.localStorage.removeItem("name");
            window.localStorage.removeItem("registrationNumber");
            window.location.href = "/";
            return Promise.reject('Credenciais Inválidas');
        }else if (error.status === 400) {
            if (typeof error.error === 'object') {
                if (error.error && error.error.message === 'Invalid credentials') {
                    return Promise.reject('Credenciais Inválidas');
                } else {
                    let msg = '';
                    if (error.error && error.error.messages) {
                        msg = error.error.messages[0];
                    } else if (error.error && error.error.message) {
                        msg = error.error.message;
                    } else {
                        msg = error.message;
                    }
                    return Promise.reject(msg);
                }
            } else if (typeof error.error === 'string') {
                return Promise.reject(error.error);
            }
        }else if (error.status === 500) {
            return Promise.reject('Erro interno, contate o administrador do sistema');
        }else if (error.status === 0) {
            return Promise.reject('Problemas com a conexão, tente novamente.');
        }
        return Promise.reject(error.response);
    }
);

export default api;