<template>
  <v-card class="main-content fill-height pa-4">

    <v-card-title class="mb-2 pl-0 ml-1">
      <b>Área de início</b>
    </v-card-title>

    <div class="div-botons">

      <v-col class="pa-0">
        <v-btn large @click="goToAddReservation" class="btn-home">
          <div class="div-icon-btn"><v-icon class="mr-1">mdi-calendar-outline</v-icon></div>
          RESERVAR ASSENTO
        </v-btn>
      </v-col>
      <v-col class="pa-0">
        <v-btn large @click="goToMyReservations" class="btn-home">
          <div class="div-icon-btn"><v-icon class="mr-1">mdi-file-document-outline</v-icon></div>
          MINHAS RESERVAS
        </v-btn>
      </v-col>
      <v-col class="pa-0">
        <v-btn large @click="goToMyProfile" class="btn-home">
          <div class="div-icon-btn"><v-icon class="mr-1">mdi-account-circle-outline</v-icon></div>
          MEU PERFIL
        </v-btn>
      </v-col>
      <v-col class="pa-0">
        <v-btn large @click="goToMyAbstences" class="btn-home">
          <div class="div-icon-btn"><v-icon class="mr-1">mdi-account-minus-outline</v-icon></div>
          MINHAS AUSÊNCIAS
        </v-btn>
      </v-col>           

    </div>

    <v-card-title class="mb-2 pl-0 ml-1">
        <b>Próxima reserva</b>
    </v-card-title>

    <LatestBooking class="pa-0"></LatestBooking>

  </v-card>
</template>

<script>
import LatestBooking from "@/components/LatestBooking";
import { TabActive } from "../store/tabActive";

export default {
  name: 'StartPage',
  components: {
    LatestBooking,
  },
  store: TabActive,
  methods: {
    async goToAddReservation(){
      await this.$store.commit('setActiveTab', 0);
      this.$router.push("/booking");
    },
    async goToMyReservations(){
      await this.$store.commit('setActiveTab', 1);
      this.$router.push("/booking");
    },
    goToMyProfile(){
      this.$router.push("/my-profile");
    },
    goToMyAbstences(){
      this.$router.push("/my-absences");
    }
  },
};
</script>


<style scoped lang="scss">
@import "../style/variables.scss";

.btn-home {
  width: 262px !important;
  height: 104px !important;
  background-color: #D6EBEA !important;
  font-weight: 700;

  @media (max-width: '1365px') {
    width: 100% !important;
    height: 72px !important;
    margin-bottom: 0px;
    margin-right: 12px;
  }
}
.div-botons {
  height: auto;
  display: grid;
  gap: 0.1rem;
  grid-template-columns: repeat(4, 18rem);
  grid-template-rows: 7.5rem;

  @media (max-width: 1365px) {
    height: auto;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}
.div-icon-btn {
  margin-right: 4px;
}
</style>

