import api from "./api";

export class ReleaseService {
  static async getRelease() {
    let endpoint = `/release`;
    let response = await api.get(endpoint);

    return response.data;
  }

  static async getAllRelease() {
    let endpoint = `/releases`;
    let response = await api.get(endpoint);

    return response.data;
  }
}
