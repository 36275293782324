import searchAddressApi from "./searchAddressApi";
import searchReverseAddressApi from "./searchReverseAddressApi";

export class SearchAddressService {
  static async getMapAddress(query) {
    let searchQuery = `&query=${query}`;
    let response = await searchAddressApi.get(`${searchQuery}`);
    return response.data.results;
  }

  static async getAddressByCoordinates(lat, lon) {
    let query = `&query=${lat},${lon}`;
    let response = await searchReverseAddressApi.get(`${query}`);
    return response.data.addresses;
  }
}
