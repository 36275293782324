export class DateUtils {
  static toController(date) {
    if (date) {
      let splittedDate = date.split("/");
      date = [splittedDate[2], splittedDate[1], splittedDate[0]].join("/");
      let dateReplace = date.replaceAll("/", "");
      return Number.parseInt(dateReplace);
    }
    return "";
  }

  static getTomorrowDateToISOString() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().substring(0, 10);
  }

  static getTodayDateToISOString() {
    const today = new Date();
    return today.toISOString().substring(0, 10);
  }

  static isValidDateRange(item) {
    let startDate = DateUtils.toController(item.startDate);
    let endDate = DateUtils.toController(item.endDate);

    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;
    const currentDay = today.getDate();
    const currentDate = parseInt(
      `${currentYear}${currentMonth.toString().padStart(2, "0")}${currentDay
        .toString()
        .padStart(2, "0")}`
    );

    if (item.endDate && item.startDate) return endDate >= currentDate;
    return startDate >= currentDate;
  }

}

export const WEEK_DAYS = [
  { name: "segunda", value: 0 },
  { name: "terça", value: 1 },
  { name: "quarta", value: 2 },
  { name: "quinta", value: 3 },
  { name: "sexta", value: 4 },
  { name: "sábado", value: 5 },
  { name: "domingo", value: 6 },
];