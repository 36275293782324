<template>
  <v-card class="main-content pa-4 fill-height">
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      :customMessage="customMessage"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>
    <v-card-title class="justify-space-between">
      <b>Administrar Complexos</b>
      <GenericButton
        class="d-inline-flex justify-end"
        title="Adicionar complexo"
        icon="mdi-plus"
        primary
        v-on:click="createRegion()"
      ></GenericButton>
    </v-card-title>
    <v-card-subtitle class="mb-4">
      Aqui você poderá administrar os complexos existentes.
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            class="elevation-1 mt-12 col-12"
            :custom-filter="filter"
            :items="regions"
            v-model="selectRegion"
            item-key="id"
            :loading="loading"
            :options.sync="options"
            :server-items-length="totalRegions"
            :footer-props="{
              'items-per-page-options': [10],
            }"
          >
            <template v-slot:top>
              <v-row class="align-center justify-space-between px-4">
                <v-col class="col-10">
                  <v-text-field
                    v-model="search"
                    @keyup.enter="filter"
                    label="Buscar"
                  ></v-text-field>
                </v-col>
                <v-col class="col-2 pr-2">
                  <GenericButton
                    title="Buscar"
                    icon="mdi-magnify"
                    secondary
                    v-on:click="filter"
                  ></GenericButton>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <tr>
                <v-icon
                  small
                  color="primary"
                  class="mr-1"
                  @click="editRegion(item)"
                  >mdi-pencil-outline</v-icon
                >
                <DeleteConfirmation
                  :dialog="showDialog"
                  :value="item.id"
                  v-on:send-delete="deleteRegion(item.id)"
                ></DeleteConfirmation>
              </tr>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <td>{{ item.name }}</td>
            </template>
            <template v-slot:[`item.acceptThirdPartyBook`]="{ item }">
              <td>{{ item.acceptThirdPartyBook ? "Sim" : "Não" }}</td>
            </template>
            <template v-slot:[`item.acceptOtherRegionsBook`]="{ item }">
              <td>{{ item.acceptOtherRegionsBook ? "Sim" : "Não" }}</td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomAlert from "@/components/CustomAlert";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import { RegionService } from "@/services/region";
import { isNotEmptyRule } from "@/services/validation";
import { RegionFormStore } from "@/store/regionForm";
import GenericButton from "../components/GenericButton.vue";
import { PermissionService } from "../services/permission";

export default {
  name: "adminRegions",
  components: {
    CustomAlert,
    DeleteConfirmation,
    GenericButton,
  },
  store: RegionFormStore,
  data: () => ({
    search: "",
    showAlert: false,
    showDialog: false,
    loading: true,
    alertMessageType: "default",
    alertType: "success",
    selectRegion: [],
    valid: true,
    file: {},
    fileWasChosen: false,
    headers: [
      { text: "", value: "actions", sorteable: false, width: "90px" },
      { text: "Nome", value: "name" },
      { text: "Aceita Reserva de Terceiros", value: "acceptThirdPartyBook" },
      {
        text: "Aceita Reserva de Colaboradores de Outros Complexos",
        value: "acceptOtherRegionsBook",
      },
    ],
    regions: [],
    options: {},
    filterAux: "",
    totalRegions: 0,
    customMessage: "",
  }),
  methods: {
    isNotEmptyRule,

    async deleteSuccess() {
      this.setAlertProps(true, "deleteSuccess");
    },
    deleteError(err) {
      if (err == 409)
        this.setAlertProps(false, "deleteRegionIfExistsLineError");
      else this.setAlertProps(false, "deleteError");
    },
    setAlertProps(isSuccess, type) {
      if (isSuccess) {
        this.alertType = "success";
      } else {
        this.alertType = "error";
      }
      this.showAlert = true;
      this.alertMessageType = type;
    },
    async deleteRegion(id) {
      try {
        let sites;
        try {
          sites = await RegionService.getSitesByRegionId(id);
        } catch (e) {
          console.log(e);
        }
        if (sites && sites.length > 0) {
          this.customMessage =
            "Não é possível excluir complexo com site relacionado !";
          this.setAlertProps(false, "");
          return;
        }
        await RegionService.deleteRegion(id);
        this.deleteSuccess();
        this.getAllRegions();
      } catch (err) {
        this.deleteError(err.status);
      }
    },
    editRegion(region) {
      RegionFormStore.commit("setRegionModel", region);
      RegionFormStore.commit("setOperation", "Edit");
      this.$router.push("/region-form");
    },
    createRegion() {
      RegionFormStore.commit("setRegionModel", {});
      RegionFormStore.commit("setOperation", "Creation");
      this.$router.push("/region-form");
    },
    async filter() {
      try {
        this.loading = true;
        let response = await RegionService.getAllRegionsFiltered(this.search);
        this.regions = response;
        this.totalRegions = response.length;
        this.loading = false;
      } catch (e) {
        this.setAlertProps(false, e);
      }
    },
    async getAllRegions() {
      try {
        this.loading = true;
        let response = await RegionService.getAllRegions();
        this.regions = response;
        this.totalRegions = response.totalElements;
        this.loading = false;
      } catch (e) {
        window.alert(e);
      }
    },
    closeAlert() {
      this.showAlert = false;
    },
  },
  async beforeMount() {
    await this.getAllRegions();

    if (this.$route.query.showAlert) {
      this.setAlertProps(true, "defaultSuccess");
    }

    this.permission = PermissionService.getPermission();
    if (this.permission !== "admin") {
      this.$router.push("home");
    }
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;

  .v-card-subtitle {
    color: $greyVale40;
  }

  .v-card {
    width: 100vw;
  }
}

.v-btn {
  text-transform: none;
  padding: 12px 16px 12px 12px !important;
}

.secondaryBtn {
  background-color: #ffffff !important;
  color: #393939;
  border-width: thin;
  border-style: solid;
  border-color: #a8a8a8;
  box-shadow: 0px 0px;
}
</style>
