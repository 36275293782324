<template>
  <GenericDialog
    class="px-5 py-5"
    :visible="showNotification && notification !== null && notification.active === true"
    v-on:close-dialog="closeNotification"
    width="600px"
  >
    <template v-slot:header>
      <v-card-title
        class="px-0 py-0 mx-0 pb-2"
        style="color: #006a61; font-size: 24px"
      >
      <span class="mdi mdi-alert mr-3"></span>
        <strong>Atualização do sistema SUAvaga</strong>
      </v-card-title>
    </template>
    <template v-slot:body>
      <v-card-text class="pl-1 pr-0">
        <div>
          {{ notification.message }}
        </div>
        <div class="mt-5">Atenciosamente, equipe SUAvaga.</div>
      </v-card-text>
      <v-card-actions class="d-flex flex-row-reverse px-0 pb-0">
        <v-btn color="primary" @click="closeNotification">
          <v-icon class="mr-2"> mdi-check-all </v-icon> Estou ciente
        </v-btn>
      </v-card-actions>
    </template>
  </GenericDialog>
</template>

<script>
import GenericDialog from "../components/GenericDialog.vue";
import { NotificationService } from "@/services/notification.js";

export default {
  name: "Notifications",
  components: {GenericDialog},
  props: ["showNotification"],

  data: () => ({
    notification: {}
  }),

  methods: {
    async getNotifications () {
      try {
        const response = await NotificationService.getNotifications();
        this.notification = response
      }
      catch(error){
        console.error('Error getting notifications:', error);
        this.notification = {};
      }
    },

    closeNotification() {
      this.$emit("close-dialog");
    },
  },

  async beforeMount() {
    this.getNotifications();
  },
};
</script>
