<template>
    <v-card class="main-content pa-4 fill-height">  
      <v-card-text class="mb-2 justify-center" v-if="!showProgressBar">
        <template>
          <v-tabs v-model="tab" @change="tabHeadChanged($event)">
            <v-tab>Importar Reservas</v-tab>
            <v-tab>Exclusão de Reservas</v-tab>
          </v-tabs>
        </template>

        <v-tabs-items v-model="tab">
            <v-tab-item class="mt-8">
                <ReservationManagmentInsertTab style="width: 100%;">
                </ReservationManagmentInsertTab>
            </v-tab-item>
  
            <v-tab-item class="mt-8">
              <ReservationManagmentExclusionTab style="width: 100%;" />
            </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </template>
  
<script>
import ReservationManagmentInsertTab from "@/views/ReservationManagmentInsertTab"
import ReservationManagmentExclusionTab from "@/views/ReservationManagmentExclusionTab.vue";
import { EmployeeFormStore } from "@/store/employeeForm";
import { PermissionService } from "../services/permission";
  
  export default {
    name: "ReservationManagment",
  
    components: {
      ReservationManagmentInsertTab,
      ReservationManagmentExclusionTab
    },
  
    store: EmployeeFormStore,
  
    data: () => ({
      permission: "",
      showProgressBar: true,
      indeterminateQuery: true,
      tab: null,
    }),
  
    methods: {
          
      tabHeadChanged(item) {
        this.shouldShowImportButtons = item === 1 ? true : false;
      },
    },
  
    async beforeMount() {
    this.permission = PermissionService.getPermission();
    },
  
    async mounted() {
      this.showProgressBar = false;
      this.indeterminateQuery = true;
    },
  };
  </script>
  
<style scoped lang="scss">
@import "../style/variables.scss";
  
  .main-content {
    width: 66.6%;
    box-shadow: -2px 2px 8px 0px #353b463d;
    color: $greyVale;
  
    .v-card-subtitle {
      color: $greyVale40;
    }
  
    .v-card-text {
      text-align: center;
    }
  }
  
  .v-card__subtitle, .v-card__text, .v-card__title {
    padding: 8px;
    padding-top: 0px;
  }
  
  .v-data-table {
    tbody {
      color: $greyVale;
  
      .v-icon {
        color: $greyVale;
      }
    }
  }
  .v-tab {
    text-transform: capitalize;
  }

.v-card__subtitle, .v-card__text, .v-card__title {
  padding: 8px;
}
  
  </style>