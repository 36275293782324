import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

export const BusTypeFormStore = new Vuex.Store({
    state: {
        busType: {},
    },
    getters: {
        busType: (state) => {
            return state.busType;
        },
    },
    mutations: {
        setBusTypeModel(state, busType) {
            if (!busType) {
                state.busType = {
                    name:"",
                    capacity:null,
                    uf: null,
                    region: null,
                    fixedCost:null,
                    variableCost:null,
                    carbonDioxideEmission:null,
                    coverage:null
                }
            } else {
                state.busType = busType;
            }
        },     
    }
});
