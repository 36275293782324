<template>
  <v-card color="main-content fill-height py-4 px-6">
    <CustomAlert
      :dialog="showAlert"
      :type="alertType"
      :customMessage="customMessage"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>
    <v-card-title class="pl-0">
      <h4>Relatório de ausências</h4>
    </v-card-title>
    <v-card-subtitle class="mb-2 pl-0">
      Use o filtro abaixo para visualizar o histórico de ausências no período de
      tempo selecionado.
    </v-card-subtitle>
    <div class="form-search">
      <v-card-text class="pa-0">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row justify="space-between" no-gutters>
            <v-col class="col-12" v-if="invalidInput">
              <span class="invalid-input">{{ errorMsg }}</span>
            </v-col>
            <v-col class="col-5 pt-0">
              <DatePicker
                :label="'Início do histórico'"
                ref="startDate"
                :data.sync="request.startDate"
                :rules="[isNotEmptyRule]"
                @picked="clearEndDate"
                @date-chosen="request.startDate = $event"
                outlined
              ></DatePicker>
            </v-col>
            <v-col class="col-5 pt-0">
              <DatePicker
                :label="'Término do histórico'"
                :data.sync="request.endDate"
                ref="endDate"
                :min="minEndDate"
                @date-chosen="request.endDate = $event"
                @change-input="searchHistory"
                :rules="[isNotEmptyRule]"
                outlined
              ></DatePicker>
            </v-col>
          </v-row>
          <v-row justify="space-between" no-gutters>
            <v-col class="col-5 py-0">
              <v-select
                label="Estado"
                v-model="state"
                :items="statesOptions"
                item-text="nome"
                item-value="sigla"
                @change="getRegionByUF"
                :clearable="true"
                @input="searchHistory"
                outlined
              />
            </v-col>
            <v-col class="col-5 py-0">
              <v-select
                label="Complexo"
                v-model="regionId"
                :items="regions"
                item-text="name"
                item-value="id"
                :clearable="true"
                :disabled="!state"
                @input="searchHistory"
                outlined
              ></v-select>
            </v-col>
          </v-row>

          <InfoSearch
            :qtdeListInfo="absenceList.length"
            :showResult="showSearchResult"
          ></InfoSearch>

          <v-row no-gutters>
            <v-col class="d-flex justify-end align-center" cols="12">
              <GenericButton
                class="mr-4"
                title="Limpar filtros"
                icon="mdi-filter-off-outline"
                secondary
                @click="clearSearch"
              ></GenericButton>
              <GenericButton
                title="Gerar relatório"
                icon="mdi-cached"
                primary
                @click="downloadCSVData"
                :disabled="!showSearchResult || absenceList.length === 0"
                v-if="!loading"
              ></GenericButton>
              <template v-if="loading">
                <div>
                  <v-progress-circular
                    style="width: 170px;"
                    :size="25" 
                    :width="2"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import CustomAlert from "@/components/CustomAlert";
import InfoSearch from "../components/InfoSearch.vue";
import GenericButton from "@/components/GenericButton";

import { isNotEmptyRule } from "@/services/validation";
import { ReportsService } from "@/services/reports";
import { RegionService } from "@/services/region";
import { estados } from "@/store/estados";

export default {
  name: "AbsenceReport",
  components: {
    DatePicker,
    CustomAlert,
    InfoSearch,
    GenericButton,
  },
  data: function () {
    return {
      menu1: false,
      menu2: false,
      request: {
        startDate: null,
        endDate: null,
      },
      valid: false,
      errorMsg: "",
      invalidInput: false,
      invalidBusLineInput: false,
      loading: false,
      absenceList: [],
      customMessage: "",
      showAlert: false,
      alertType: "error",
      regions: [],
      regionId: null,
      state: "",
      statesOptions: estados,
      minEndDate: "",
      showSearchResult: false,
    };
  },
  methods: {
    isNotEmptyRule,
    updateAbsenceListDates() {
      this.absenceList.forEach((absence) => {
        var initialDate = new Date(absence.startDate);
        var endDate = new Date(absence.endDate);
        initialDate = initialDate
          ?.toLocaleString("pt-BR", { timeZone: "UTC" })
          .split(" ");
        endDate = endDate
          ?.toLocaleString("pt-BR", { timeZone: "UTC" })
          .split(" ");
        absence.startDate = initialDate[0].replace(",", "");
        absence.endDate = endDate[0].replace(",", "");
      });
    },

    async searchHistory() {
      if (this.request.endDate == null || this.request.endDate == "") {
        this.showSearchResult = false;
        return;
      }
      try {
        this.loading = true;
        if (this.regionId == null) this.regionId = "";

        let list = await ReportsService.getAbsenceReport(
          this.request.startDate,
          this.request.endDate,
          this.regionId,
          this.state
        );
        this.absenceList = list;
        this.invalidInput = false;
        this.showSearchResult = true;

        this.updateAbsenceListDates();
      } catch (e) {
        console.log(e);
        this.showSearchResult = false;
      } finally {
        this.loading = false;
      }
    },
    downloadCSVData() {
      let csv =
        "Matricula Vale;Nome do colaborador;CPF;Complexo;Estado;Data Inicial;Data Final;Motivo\n";
      this.absenceList.forEach((absence) => {
        csv +=
          absence.registrationNumber +
          ";" +
          absence.name +
          ";" +
          absence.cpf +
          ";" +
          absence.region +
          ";" +
          absence.uf +
          ";" +
          absence.startDate +
          ";" +
          absence.endDate +
          ";" +
          absence.reason;
        csv += "\n";
      });
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download =
        "consultaRelatoriodeAusenciasDe" +
        this.request.startDate.replace("/", "-") +
        "a" +
        this.request.endDate.replace("/", "-") +
        ".csv";
      anchor.click();
      // anchor.delet;e()
    },
    setMinEndDate() {
      let newDate;
      let dateParts;

      dateParts = this.request.startDate.split("/");
      newDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      newDate.setDate(newDate.getDate());
      this.minEndDate = newDate.toISOString().substring(0, 10);
    },
    clearEndDate() {
      this.request.endDate = null;
      this.setMinEndDate();
      this.showSearchResult = false;
      this.absenceList = [];
      this.$refs.endDate.reset();
    },
    clearSearch() {
      this.request = {
        busLinesIds: [],
        startDate: null,
        endDate: null,
      };

      this.regionId = null;
      this.state = null;
      this.showSearchResult = false;

      this.$refs.startDate.reset();
      this.$refs.endDate.reset();
      this.$refs.form.reset();
    },
    closeAlert() {
      this.showAlert = false;
      this.customMessage = "";
    },
    generateReportError(errorMessage) {
      this.alertType = "error";
      this.showAlert = true;
      if (errorMessage != null) {
        this.customMessage = errorMessage;
      } else {
        this.customMessage = "Ocorreu um erro ao submeter a solicitação";
      }
    },
    async getRegionByUF() {
      this.regionId = "";
      this.regions = [];
      this.regions = await RegionService.getRegionByUf(this.state);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;

  .invalid-input {
    color: $redVale;
  }
}

::v-deep .v-btn {
  text-transform: none !important;
}

.form-search {
  padding: 24px 134px 24px 24px;
  border-width: thin;
  border-style: solid;
  border-color: #e0e0e0;
  border-radius: 4px;
}
</style>
