<template>
  <v-card class="elevation-0 pt-6" style="width: 100%;">
      <TabsView
        :componentsTab="componentsTab"
        :grow="true"
        @tab-changed="tabChanged"
      >
        <template v-slot:contentTab>
          <v-tabs-items v-model="releasesTab">
            <v-tab-item>
              <Release
                class="pt-6"
                @tab-changed="tabChanged"
                :key="releaseKey"
              ></Release>
            </v-tab-item>

            <v-tab-item>
              <ReleaseHistory
                @tab-changed="tabChanged"
                :key="historicKey"
              ></ReleaseHistory>
            </v-tab-item>
          </v-tabs-items>
        </template>
      </TabsView>
  </v-card>
</template>

<script>
import Release from "../components/Release.vue";
import ReleaseHistory from "../components/ReleaseHistory.vue";
import TabsView from "../components/TabsView";

export default {
  name: "ReleaseTabs",
  components: {
    TabsView,
    Release,
    ReleaseHistory,
  },

  data: () => ({
    releasesTab: null,
    componentsTab: [
      { title: "Novas atualizações" },
      { title: "Histórico de atualizações" },
    ],
    releaseKey: 0,
    historicKey: 0,
  }),

  methods: {
    tabChanged(releasesTab) {
      this.releasesTab = releasesTab;
      if (releasesTab === 0) {
        this.releaseKey++;
      } else if (releasesTab === 1) {
        this.historicKey++;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

</style>
