<template>
  <v-dialog v-model="showDialog" width="700" @input="setAddressModel">
    <template v-slot:activator="{ on, attrs }">
      <v-row class="card-address" v-if="isAddressListFilled()" no-gutters>
        <v-col class="address" cols="12">
          <strong>Endereço residencial</strong>
          <div class="home-address">
            {{ userAddress.formattedAddress }}
            <v-btn icon @click="editAddress">
              <v-icon color="#6F6F6F" class="align-end" small
                >mdi-pencil-outline</v-icon
              >
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="!isAddressListFilled()" no-gutters>
        <v-col cols="12">
          <a v-bind="attrs" v-on="on" class="new-address">
            <v-icon color="primary">mdi-plus</v-icon>
            Adicionar novo endereço
          </a>
        </v-col>
      </v-row>
    </template>
    <v-card class="pb-6">
      <v-card-title class="pb-0"> Endereço </v-card-title>
      <v-card-subtitle class="mt-1">
        Verifique no mapa se o endereço inserido está correto.
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" xs="6" sm="6" md="9" lg="9" class="py-0">
              <gmap-autocomplete
                v-bind:class="{ autoCompleteIsEmpty: autoCompleteIsEmpty }"
                class="introInput mb-4"
                @place_changed="updateAddress"
                ref="gmapAutocomplete"
              >
                <template v-slot:input="slotProps">
                  <v-text-field
                    outlined
                    label="Endereço"
                    placeholder="Pesquisar..."
                    color="secondary"
                    v-on:listeners="slotProps.listeners"
                    v-on:attrs="slotProps.attrs"
                  ></v-text-field>
                </template>
              </gmap-autocomplete>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="3" lg="3" class="py-0 pb-1">
              <v-text-field
                outlined
                dense
                label="Complemento"
                v-model="chosenAddress.complement"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <GmapMap
            ref="mapRef"
            :center="currentLocation"
            :zoom="zoom"
            map-type-id="roadmap"
            style="width: 100%; height: 46vh"
            @click="handleMapClick"
          >
            <GmapMarker
              :position="marker.position"
              :clickable="true"
              :draggable="true"
              :visible="showMarker"
              @click="center = marker.position"
            />
          </GmapMap>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <GenericButton
          title="Cancelar"
          icon="mdi-close"
          secondary
          v-on:click="closeDialog('cancel')"
        >
        </GenericButton>

        <GenericButton
          title="Salvar"
          icon="mdi-content-save-outline"
          primary
          v-on:click="getUserAddress"
        >
        </GenericButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import { isNotEmptyRule } from "@/services/validation";
import GenericButton from "./GenericButton.vue";

export default {
  name: "addressDialog",

  components: {
    GenericButton
  },
  props: {
    address: Object,
    registrationNumber: String,
  },

  computed: {
    google: gmapApi,
    userAddress: {
      get: function () {
        return this.address == null ? {} : this.address;
      },
    },
  },

  data: function () {
    return {
      valid: true,
      showDialog: false,
      marker: {
        position: {
          lat: -15.7801,
          lng: -47.9292,
        },
      },
      complement: "",
      currentLocation: {
        lat: -15.7801,
        lng: -47.9292,
      },
      selectedTableRow: {},
      chosenAddress: {
        id: undefined,
        name: "",
        formattedAddress: "",
        givenAddress: "",
        postalCode: "",
        complement: "",
        latitude: 0,
        longitude: 0,
      },
      autoCompleteIsEmpty: false,
      showAlert: false,
      alertMessageType: "default",
      alertType: "success",
      singleExpand: true,
      expanded: [],
      zoom: 16,
      showMarker: true,
    };
  },
  methods: {
    isNotEmptyRule,

    getMapRef() {
      const mapRef = this.$refs["mapRef"];
      if (!mapRef) {
        throw new Error("vue2-google-maps mapRef not found on Vue $refs");
      }
      return mapRef;
    },

    async getMap() {
      return await this.getMapRef().$mapPromise;
    },

    geolocate() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.currentLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.marker.position.lat = this.currentLocation.lat;
            this.marker.position.lng = this.currentLocation.lng;
            resolve(this.currentLocation);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    panToMarker() {
      this.getMapRef().panTo(this.marker.position);
    },

    async handleMapClick(e) {
      const position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.marker.position = position;
      await this.setChosenAddress(position);
      this.setAddressInput();
      this.autoCompleteIsEmpty = false;
    },

    async updateAddress(place) {
      const position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.marker.position = position;

      await this.setChosenAddress(position);

      this.autoCompleteIsEmpty = false;
      this.panToMarker();
    },

    async setChosenAddress(position) {
      this.chosenAddress.latitude = position.lat;
      this.chosenAddress.longitude = position.lng;
      const geocode = await this.getGeocode(position);
      this.chosenAddress.formattedAddress = geocode[0].formatted_address;
      this.chosenAddress.postalCode = this.findPostalCodeInGeocode(geocode);
      this.chosenAddress.givenAddress =
        this.$refs.gmapAutocomplete.$refs.input.value;
    },

    getGeocode(position) {
      const geocoder = new this.google.maps.Geocoder();
      return new Promise((resolve, reject) => {
        geocoder.geocode(
          {
            location: {
              lat: parseFloat(position.lat),
              lng: parseFloat(position.lng),
            },
          },
          (results_, status_) => {
            if (status_ !== this.google.maps.GeocoderStatus.OK) {
              const reason = `geocode: status: ${status_}`;
              return reject(reason);
            } else {
              this.showMarker = true;
              this.zoom = 16;
            }
            return resolve(results_);
          }
        );
      });
    },
    /**
     * Finds the postal code for a location.
     * @param geocode - The geocode result object returned from the Google Maps APIs.
     * @return {null|string} - The postal code of the location corresponding to the
     *  geocode result object, if it exists, and null otherwise.
     */
    findPostalCodeInGeocode(geocode) {
      if (!geocode || geocode.length < 1) {
        return null;
      }
      let postalCode = null;
      for (let addr of geocode) {
        postalCode = addr.address_components.find(
          (addrComponentItem) => addrComponentItem.types[0] === "postal_code"
        );
        if (postalCode && postalCode.short_name) {
          break;
        }
      }
      return postalCode.short_name;
    },

    setAddressInput() {
      this.$refs.gmapAutocomplete.$refs.input.value =
        this.chosenAddress.formattedAddress;
    },

    getUserAddress() {
      const formattedAddress = this.chosenAddress.formattedAddress;
      const lastAddressSearched = this.$refs.gmapAutocomplete.$refs.input.value;
      if (!formattedAddress || !lastAddressSearched) {
        this.autoCompleteIsEmpty = true;
      }
      if (!this.$refs.form.validate() || this.autoCompleteIsEmpty) {
        return;
      }

      this.userAddress.id =
        this.chosenAddress.id != null ? this.chosenAddress.id : null;
      this.userAddress.formattedAddress = this.chosenAddress.formattedAddress;
      this.userAddress.givenAddress = this.chosenAddress.givenAddress;
      this.userAddress.postalCode = this.chosenAddress.postalCode;
      this.userAddress.complement = this.chosenAddress.complement;
      this.userAddress.latitude = this.chosenAddress.latitude;
      this.userAddress.longitude = this.chosenAddress.longitude;
      this.$emit("address-updated");
      this.closeDialog();
    },

    editAddress() {
      this.chosenAddress = JSON.parse(JSON.stringify(this.userAddress));
      this.chosenAddress.latitude = Number(this.userAddress.latitude);
      this.chosenAddress.longitude = Number(this.userAddress.longitude);
      this.showDialog = true;
      this.zoom = 16;
      this.showMarker = true;
      this.setAddressModel();
    },

    async closeDialog() {
      try {
        this.resetData();
        await this.geolocate();
      } catch (error) {
        this.callbackErrorGeolocate(error);
      } finally {
        this.showDialog = false;
      }
    },

    async resetData() {
      try {
        this.$refs.form.reset();
        this.chosenAddress = {
          name: "",
          formattedAddress: "",
          postalCode: "",
          complement: "",
          latitude: 0,
          longitude: 0,
        };
        this.$refs.gmapAutocomplete.$refs.input.value = "";
        this.autoCompleteIsEmpty = false;
        await this.geolocate();
      } catch (error) {
        this.callbackErrorGeolocate(error);
      } finally {
        this.showDialog = false;
      }
    },

    setAddressModel() {
      if (!this.chosenAddress.formattedAddress) {
        return;
      }
      this.marker.position.lat = this.chosenAddress.latitude;
      this.marker.position.lng = this.chosenAddress.longitude;
      /**
       * This workaround was made to wait the gmapAutocomplete is ready
       * in the this.$refs when is editing a address
       */
      let vm = this;
      let interval = setInterval(function () {
        if (vm.$refs != undefined) {
          vm.setAddressInput();
          vm.panToMarker();
          clearInterval(interval);
        }
      }, 500);
    },

    isAddressListFilled() {
      if (
        this.userAddress == null ||
        Object.keys(this.userAddress).length === 0
      )
        return false;
      return true;
    },

    callbackErrorGeolocate(error) {
      if (error.code === 1) {
        this.showMarker = false;
        this.zoom = 4;
      } else {
        console.log(error);
      }
    },
  },

  async beforeMount() {
    try {
      await this.geolocate();
      this.zoom = 16;
      this.showMarker = true;
    } catch (error) {
      this.callbackErrorGeolocate(error);
    }
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.table-component {
  padding-bottom: 4rem;
}

.v-data-table {
  td {
    color: $greyVale;
    max-width: 40vw;
    padding: 0 !important;
  }
}

.v-card {
  .v-card__title {
    color: $greyVale;
  }

  .introInput {
    width: 100%;
    color: rgba(0, 0, 0, 0.87);
    border: $greyVale solid 0.5px;
    border-radius: 0.3rem;
    padding: 12px;
    height: 2.4rem;
    font-size: 1rem;
  }

  .autoCompleteIsEmpty {
    border: $redVale solid 2px;
    color: $redVale;
  }

  .new-address,
  .delete-address {
    font-size: 0.9rem;
    font-weight: bold;
  }
}

.v-list-item__content {
  display: inline;
  width: 50%;

  .v-list-item__title {
    display: inline;
  }
}

.address {
  display: flex;
  line-height: 2.4;
  font-size: 14px;
  flex-direction: column;
}

.address > strong {
  color: #6f6f6f;
}

.home-address {
  display: flex;
  justify-content: space-between;
}

.v-dialog > .v-card > .v-card__actions {
  padding-left: 24px;
  padding-right: 24px;
}

.v-dialog > .v-card > .v-card__title {
  padding-top: 24px;
}

@media screen and (max-width: 700px) {
  .new-address {
    display: flex;
    justify-content: center;
    align-items: self-end;
  }
}

::v-deep .v-card__actions > .v-btn.v-btn {
  padding-left: 12px;
  padding-right: 16px;
}

.v-dialog > .v-card > .v-card__actions {
  padding: 0px 16px;
}

::v-deep .v-dialog > .v-card > .v-card__actions {
  padding: 0px 24px;
}

@media screen and (max-width: 399px) {
  .home-address {
    width: 68vw;
  }
}

@media screen and (min-width: 400px) and (max-width: 599px) {
  .home-address {
    width: 72vw;
  }
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .home-address {
    width: 74vw;
  }
}

@media screen and (min-width: 960px) and (max-width: 1263px) {
  .home-address {
    width: 76vw;
  }
}

@media screen and (min-width: 1264px) {
  .home-address {
    width: 78vw;
  }
}
</style>
