import api from "./api";

export class BusTypeService {
  static async getAllBusTypes() {
    let response = await api.get("busType");
    return response.data;
  }

  static async addBusType(request) {
    let response = await api.post("busType", request);
    return response.data;
  }

  static async updateBusType(request) {
    let response = await api.put("busType", request);
    return response.data;
  }

  static async getFilteredBusTypes(request) {
    let endpoint = `/busType/filtered?search=${request.search}&regionId=${request.region}`;
    let response = await api.get(endpoint);
    return response.data;
  }

  static async deleteBusType(id) {
    let response = await api.delete(`/busType/${id}`);
    return response.data;
  }

  static async getBusTypeByRegion(id){
    const endpoint = `/busType/` + id;
    let response = await api.get(endpoint)
    return response.data;
  }
}
