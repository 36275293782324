<template>
  <v-card class="elevation-0 px-0 pb-0 pt-2">
    <v-expansion-panels multiple>
      <v-expansion-panel
        v-for="release in releases"
        :key="release.releaseNumber"
        class="mt-4 expand-panels"
      >
        <v-expansion-panel-header class="expand-panels-title px-4 py-3">
          <b> Release {{ release.releaseNumber }} </b>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="expand-panels-content m-4">
          <ul class="lists">
            <li
              class="mb-2"
              v-for="releaseNew in release.news"
              :key="releaseNew"
            >
              {{ releaseNew }}
            </li>
            <li
              class="mb-2"
              v-for="releaseBug in release.bugs"
              :key="releaseBug"
            >
              {{ releaseBug }}
            </li>
            <li
              class="mb-2"
              v-for="releaseImportant in release.important"
              :key="releaseImportant"
            >
              {{ releaseImportant }}
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { ReleaseService } from "@/services/release.js";

export default {
  name: "ReleaseHistory",

  props: {},

  data: () => ({
    releases: [],
  }),
  methods: {
    async getAllRelease() {
      try {
        this.releases = await ReleaseService.getAllRelease();
      } catch (err) {
        this.releases = [{}];
      }
    },
  },

  async beforeMount() {
    this.getAllRelease();
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.expand-panels {
  border-width: thin;
  border-style: solid;
  border-color: #e0e0e0;
  text-transform: none;
  border-radius: 4px;
}

.expand-panels-title {
  color: #6f6f6f;
  background-color: #f4f4f4;
  font-size: 12px;
}

.expand-panels-content {
  color: #393939;
  font-size: 12px;
  border-top: thin solid rgba(0, 0, 0, 0.04);
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 16px 0 8px 0;
}

::v-deep .v-expansion-panels {
  width: 100%;
  height: auto;

  @media screen and (max-width: "768px") {
    width: 100% !important;
  }
}

::v-deep .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 48px;
}

::v-deep .v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px;
}

::v-deep .v-text-field--outlined.v-input--is-focused {
  border-color: #007e7a;

  fieldset {
    border: 0px;
  }
}

::v-deep .v-text-field--outlined fieldset {
  height: 32px !important;
  border: 0px;
  top: 0px;
}

::v-deep .v-text-field--outlined .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  font-size: 14px;
}

::v-deep .v-text-field--outlined .v-input__slot {
  padding: 0 16px !important;
}

::v-deep .v-text-field--outlined .v-input__append-inner {
  margin: auto 0px;
  height: fit-content;
  display: flex;
  align-items: center;

  .v-input__icon--append {
    margin: auto 0px;

    .v-icon {
      font-size: 18px;
    }
  }
}

::v-deep .v-text-field--outlined .v-text-field__slot {
  height: 32px;

  input {
    padding: 0px;
    margin: auto 0px;
  }
}
</style>
