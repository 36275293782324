<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-if="hideIcon"
        v-model="timeValue"
        :label="label"        
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="rules"
        :outlined="outlined"
      ></v-text-field>
      <v-text-field v-else
        v-model="timeValue"
        :label="label"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="rules"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu && !readonly"
      format="24hr"
      v-model="time"
      full-width
      @click:minute="closeTimePicker"
      
    ></v-time-picker>
  </v-menu>
</template>

<script>
import { isNotEmptyRule } from "@/services/validation";

export default {
  name: "TimePicker",
  props: {
    label: String,
    formTime: String,
    rules: Array,
    readonly: Boolean,
    hideIcon: Boolean,
    outlined: {type: Boolean, default: false}
  },
  data: () => ({
    menu: false,
    time: null,
    timeEdit: ''
  }),
  methods: {
    isNotEmptyRule,
    closeTimePicker() {
      this.$refs.menu.save(this.time);
      this.$emit("chosen-time", this.time);
      this.$emit("edited-time");
    },
    clearTime() {
      this.time = null;
      this.timeEdit = '';
    },
  },
  computed: {
    timeValue() {
      if (!this.formTime) {
       return this.time;
      }
      return this.formTime;
    }
  }
};
</script>

<style scoped>
.v-card {
  width: 100%;
}
.v-text-field.v-text-field--enclosed ::v-deep .v-text-field__details {
    padding-top: 0px;
    margin-bottom: 0px;
}

::v-deep .v-input__slot{
margin-bottom: 0px;
}
</style>
