<template>
  <v-card class="main-content pa-4 fill-height">
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      :customMessage="customMessage"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>
    <v-row>
      <v-col>
        <v-card-title>
          <h4>Administrar Linha de ônibus</h4>
        </v-card-title>
        <v-card-subtitle class="mb-4">
          Aqui você pode administrar os pontos de ônibus
        </v-card-subtitle>
      </v-col>
      <v-col class="pt-8 mr-5" align="right">
        <GenericButton
          class="mr-4"
          title="Cancelar"
          icon="mdi-close"
          :secondary="!this.editMode"
          :tertiary="this.editMode"
          @click="cancelEdit"
        ></GenericButton>
        <GenericButton
          class="mr-4"
          :title="activeStatusButtonText"
          :icon="dialogTextValues.buttonIcon"
          secondary
          @click="showDisableRouteDialog = true"
          v-if="this.editMode"
        ></GenericButton>
        <v-progress-circular
          style="width: 100px"
          :size="25"
          :width="2"
          color="primary"
          indeterminate
          v-show="overlay"
        >
        </v-progress-circular>
        <GenericButton
          title="Salvar"
          icon="mdi-content-save-outline"
          primary
          @click="save"
          :disabled="!isEdited"
          v-show="!overlay"
        ></GenericButton>
      </v-col>
    </v-row>

    <v-card-text>
      <v-form class="formRoute" ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col class="pb-0">
            <v-text-field
              v-model="route.code"
              label="Nome da rota"
              outlined
              :rules="[isNotEmptyRule]"
              @input="fieldValueHasChanged"
            ></v-text-field>
          </v-col>
          <v-col class="col-4 pb-0">
            <v-select
              v-model="uf"
              label="Estado"
              item-text="nome"
              item-value="sigla"
              outlined
              @change="UFChanged()"
              :items="listaEstados"
              :rules="[isNotEmptyRule]"
              @input="fieldValueHasChanged"
            />
          </v-col>
          <v-col class="col-4 pb-0">
            <v-select
              v-model="route.region"
              label="Complexo"
              return-object
              item-text="name"
              outlined
              @change="regionChanged()"
              :items="regions"
              :disabled="!uf"
              :rules="[isNotEmptyRule]"
              @input="fieldValueHasChanged"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="col-4 py-0 mb-0">
            <v-select
              v-model="route.site"
              label="Site de destino"
              return-object
              item-text="name"
              item-value="id"
              outlined
              :items="sites"
              :disabled="!route.region"
              :rules="[isNotEmptyRule]"
              @input="fieldValueHasChanged"
            />
          </v-col>
          <v-col class="col-4 py-0 mb-0">
            <v-select
              v-model="route.busType"
              label="Tipo do ônibus"
              return-object
              item-text="name"
              item-value="id"
              outlined
              :items="busTypeOptions"
              :disabled="!route.region"
              :rules="[isNotEmptyRule]"
              @input="fieldValueHasChanged"
            ></v-select>
          </v-col>
          <v-col class="col-4 py-0 mb-0">
            <v-select
              v-model="route.transshipment"
              label="Baldeio"
              item-text="text"
              item-value="value"
              outlined
              :items="isTransshipment"
              :rules="[isNotEmptyRule]"
              @input="fieldValueHasChanged"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="col-3 py-0">
            <TimePicker
              ref="departureTime"
              hideIcon
              outlined
              v-on:chosen-time="route.departureTime = $event"
              @edited-time="fieldValueHasChanged"
              :label="'Horário de Partida - Ida'"
              :data.sync="route.departureTime"
              :formTime="route.departureTime"
              :rules="busStops.length > 0 ? [isNotEmptyRule] : []"
              :key="componentKey"
            ></TimePicker>
          </v-col>
          <v-col class="col-3 py-0">
            <TimePicker
              ref="arrivalTime"
              hideIcon
              outlined
              v-on:chosen-time="route.arrivalTime = $event"
              @edited-time="fieldValueHasChanged"
              :label="'Horário de chegada - Ida'"
              :data.sync="route.arrivalTime"
              :formTime="route.arrivalTime"
              :rules="busStops.length > 0 ? [isNotEmptyRule] : []"
              :key="componentKey"
            ></TimePicker>
          </v-col>
          <v-col class="col-3 py-0">
            <TimePicker
              ref="departureTimeReturn"
              hideIcon
              outlined
              v-on:chosen-time="route.departureTimeReturn = $event"
              @edited-time="fieldValueHasChanged"
              :label="'Horário de Partida - Volta'"
              :data.sync="route.departureTimeReturn"
              :formTime="route.departureTimeReturn"
              :rules="busStopsReturn.length > 0 ? [isNotEmptyRule] : []"
              :key="componentKeyReturn"
            ></TimePicker>
          </v-col>
          <v-col class="col-3 py-0">
            <TimePicker
              ref="arrivalTimeReturn"
              hideIcon
              outlined
              @chosen-time="route.arrivalTimeReturn = $event"
              @edited-time="fieldValueHasChanged"
              :label="'Horário de chegada - Volta'"
              :data.sync="route.arrivalTimeReturn"
              :formTime="route.arrivalTimeReturn"
              :rules="busStopsReturn.length > 0 ? [isNotEmptyRule] : []"
              :key="componentKeyReturn"
            ></TimePicker>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-text>
      <v-row>
        <v-col>
          <v-data-table
            class="elevation-1 col-12"
            disable-pagination
            hide-default-footer
            v-sortable-data-table
            @sorted="saveOrder"
            :headers="headers"
            :items="busStops"
            :loading="loadingBusStops"
            @update:items="compareList(busStopDefault, $event)"
          >
            <template v-slot:[`header.orderColumn`]="{}">
              <BusStopsSelect
                v-on:send-busstop="insertBusStops($event)"
                :regionId="regionId"
              ></BusStopsSelect>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <DeleteConfirmation
                v-on:send-delete="deleteBusStop(item)"
                :dialog="showDialog"
                :value="item"
              ></DeleteConfirmation>
            </template>
            <template v-slot:[`item.orderColumn`]>
              <v-icon small class="mr-2">mdi-align-vertical-distribute</v-icon>
            </template>
          </v-data-table>
        </v-col>

        <v-col>
          <v-data-table
            class="elevation-1 col-12"
            disable-pagination
            hide-default-footer
            v-sortable-data-table
            @sorted="saveOrderReturn"
            :headers="headersReturn"
            :items="busStopsReturn"
            :loading="loadingBusStopsReturn"
            @update:items="fieldValueHasChanged"
          >
            <template v-slot:[`header.orderColumn`]="{}">
              <BusStopsSelect
                v-on:send-busstop="insertBusStopsReturn($event)"
                :regionId="regionId"
              ></BusStopsSelect>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <DeleteConfirmation
                v-on:send-delete="deleteBusStopReturn(item)"
                :dialog="showDialog"
                :value="item"
              ></DeleteConfirmation>
            </template>
            <template v-slot:[`item.orderColumn`]>
              <v-icon small class="mr-2">mdi-align-vertical-distribute</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="showCancelConfirm" width="500">
      <v-card>
        <v-card-title> Cancelar </v-card-title>

        <v-card-text> Tem certeza de que gostaria de cancelar ? </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <GenericButton
            class="mr-4"
            title="Não"
            secondary
            @click="showCancelConfirm = false"
          ></GenericButton>
          <GenericButton
            title="Sim"
            primary
            @click="cancelConfirm"
          ></GenericButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDisableRouteDialog" width="500">
      <v-card>
        <v-card-title>
          {{ dialogTextValues.title }}
        </v-card-title>

        <v-card-text>
          {{ dialogTextValues.body }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <GenericButton
            class="mr-4"
            title="Cancelar"
            icon="mdi-close"
            secondary
            @click="showDisableRouteDialog = false"
          ></GenericButton>
          <GenericButton
            :title="dialogTextValues.button"
            :icon="dialogTextValues.buttonIcon"
            primary
            @click="changeBusLineActiveStatus"
          ></GenericButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { RouteFormStore } from "@/store/routeForm";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import { RouteService } from "@/services/route";
import { RegionService } from "@/services/region";
import { BusStopsService } from "@/services/busStops";
import { BusTypeService } from "@/services/busType";
import { isNotEmptyRule } from "@/services/validation";
import CustomAlert from "@/components/CustomAlert";
import TimePicker from "@/components/TimePicker";
import Sortable from "sortablejs";
import BusStopsSelect from "@/components/BusStopsSelect";
import { estados } from "@/store/estados";
import GenericButton from "../components/GenericButton.vue";

export default {
  name: "routeDetail",
  props: {},
  components: {
    CustomAlert,
    TimePicker,
    DeleteConfirmation,
    BusStopsSelect,
    GenericButton,
  },
  data: () => ({
    valid: false,
    listaEstados: estados,
    alertType: "success",
    showAlert: false,
    alertMessageType: "default",
    route: {},
    routeDefault: {},
    ufDefault: {},
    uf: "",
    isEdited: false,
    sites: [],
    backupRoute: {},
    busStops: [],
    busStopsReturn: [],
    backupBusStops: [],
    backupBusStopsReturn: [],
    busStopDefault: [],
    busStopReturnDefault: [],
    regions: [],
    loadingBusStops: false,
    loadingBusStopsReturn: false,
    permission: "",
    edited: false,
    editMode: false,
    showDialog: false,
    regionId: "",
    overlay: false,
    editing: false,
    showDisableRouteDialog: false,
    activeStatusButtonText: "",
    showCancelConfirm: false,
    dialogTextValues: {
      title: "",
      body: "",
      button: "",
      buttonIcon: "",
    },
    lineDirectionOptions: [
      { text: "Ida", value: "IDA" },
      { text: "Volta", value: "VOLTA" },
      { text: "Ambos", value: "AMBOS" },
    ],
    isTransshipment: [
      { text: "Sim", value: true },
      { text: "Não", value: false },
    ],
    busTypeOptions: [],
    headers: [
      { text: "", value: "actions", sortable: false, width: "5px" },
      { text: "Pontos de ônibus - Ida", value: "name", sortable: false },
      { text: "", value: "orderColumn", align: "right", sortable: false },
    ],
    headersReadOnly: [
      { text: "Pontos de ônibus - Ida", value: "name", sortable: false },
    ],

    headersReturn: [
      { text: "", value: "actions", sortable: false, width: "5px" },
      { text: "Pontos de ônibus - Volta", value: "name", sortable: false },
      { text: "", value: "orderColumn", align: "right", sortable: false },
    ],
    headersReadOnlyReturn: [
      { text: "Pontos de ônibus - Volta", value: "name", sortable: false },
    ],
    customMessage: "",
    componentKey: 0,
    componentKeyReturn: 0,
  }),
  store: RouteFormStore,
  watch: {
    options: {
      handler() {
        this.getAllRoutes();
      },
      deep: true,
    },
    busStops(val) {
      if (val.length === 0) {
        this.route.arrivalTime = "";
        this.route.departureTime = "";
        this.componentKey++;
      }
    },
    busStopsReturn(val) {
      if (val.length === 0) {
        this.route.arrivalTimeReturn = "";
        this.route.departureTimeReturn = "";
        this.componentKeyReturn++;
      }
    },
  },
  methods: {
    isNotEmptyRule,
    isEmptyField(value) {
      if (value === undefined || value === null || value === "") {
        return true;
      }
      return false;
    },
    saveOrder(event) {
      const movedItem = this.busStops.splice(event.oldIndex, 1)[0];
      this.busStops.splice(event.newIndex, 0, movedItem);
      this.isEdited = true;
    },
    saveOrderReturn(event) {
      const movedItem = this.busStopsReturn.splice(event.oldIndex, 1)[0];
      this.busStopsReturn.splice(event.newIndex, 0, movedItem);
      this.isEdited = true;
    },
    async deleteBusStop(item) {
      let countBookings = await BusStopsService.countBookingsBusStop(
        item.id,
        item.idRoute
      );

      if (countBookings > 0)
        this.setAlertProps(false, "deleteBusStopErrorWithBookings");
      else this.busStops.splice(this.busStops.indexOf(item), 1);

      this.listBusStopsEdit(this.busStopDefault, this.busStops);
    },
    async deleteBusStopReturn(item) {
      let countBookings = await BusStopsService.countBookingsBusStop(
        item.id,
        item.idRoute
      );

      if (countBookings > 0)
        this.setAlertProps(false, "deleteBusStopErrorWithBookings");
      else this.busStopsReturn.splice(this.busStopsReturn.indexOf(item), 1);
      this.listBusStopsEdit(this.busStopReturnDefault, this.busStopsReturn);
    },
    insertBusStops(busStops) {
      let busStopsIds = this.busStops.map((busStop) => busStop.id);
      busStops.forEach((element) => {
        if (busStopsIds.indexOf(element.id) == -1) {
          element.idRoute = null;
          element.busStopOrder = this.busStops.length + 1;
          this.busStops.push(element);
        }
      });
      this.listBusStopsEdit(this.busStopDefault, this.busStops);
    },
    insertBusStopsReturn(busStops) {
      let busStopsIds = this.busStopsReturn.map((busStop) => busStop.id);
      busStops.forEach((element) => {
        if (busStopsIds.indexOf(element.id) == -1) {
          element.idRoute = null;
          element.busStopOrder = this.busStopsReturn.length + 1;
          this.busStopsReturn.push(element);
        }
      });
      this.listBusStopsEdit(this.busStopReturnDefault, this.busStopsReturn);
    },
    listBusStopsEdit(busStopDefault, busStop) {
      if (this.editMode && this.isValid()) {
        this.compareList(busStopDefault, busStop);
      } else {
        this.fieldValueHasChanged();
      }
    },
    edit() {
      this.backupBusStops = [];
      this.backupBusStops = JSON.parse(JSON.stringify(this.busStops));
      this.backupRoute = Object.assign({}, this.route);
    },
    editReturn() {
      this.backupBusStopsReturn = [];
      this.backupBusStopsReturn = JSON.parse(
        JSON.stringify(this.busStopsReturn)
      );
      this.backupRoute = Object.assign({}, this.route);
    },
    async UFChanged() {
      this.isEdited = false;
      this.regions = await RegionService.getRegionByUf(this.uf);
      this.clearWhenRegionChange();
      this.clearSite();
    },
    regionChanged() {
      this.isEdited = false;
      this.route.site = null;
      this.clearWhenRegionChange();

      if (this.route.region) {
        this.regionId = this.route.region.id;
        this.loadSites(this.regionId);
        this.loadBusType(this.regionId);
      } else {
        this.regionId = "";
        this.sites = [];
      }
    },
    cancelEdit() {
      this.showCancelConfirm = true;
    },
    cancelConfirm() {
      this.showCancelConfirm = false;
      this.$router.push("/admin-routes");
    },
    setDialogValues() {
      if (this.route.active) {
        this.dialogTextValues.title = "Desabilitar Rota";
        this.dialogTextValues.body =
          "Todas as reservas serão canceladas no momento que a rota for desabilitada.";
        this.dialogTextValues.button = "Desabilitar Rota";
        this.dialogTextValues.buttonIcon = "mdi-map-marker-off-outline";
      } else {
        this.dialogTextValues.title = "Habilitar Rota";
        this.dialogTextValues.body =
          "Após ativar a rota será possível cadastrar reservas vinculadas a ela.";
        this.dialogTextValues.button = "Habilitar Rota";
        this.dialogTextValues.buttonIcon = "mdi-map-marker-plus-outline";
      }
    },
    async changeBusLineActiveStatus() {
      let busLineActiveStatus = {
        id: this.route.id,
        activate: !this.route.active,
      };

      try {
        await RouteService.changeBusLineActiveStatus(busLineActiveStatus);
        this.showDisableRouteDialog = false;
        if (this.route.active) this.setAlertProps(true, "desableRouteSuccess");
        else this.setAlertProps(true, "enableRouteSuccess");

        this.route.active = !this.route.active;
        this.activeStatusButtonText = this.getActiveButtonText();
        this.setDialogValues();
      } catch (error) {
        console.log(error);
      }
    },
    async save() {
      try {
        if (!this.$refs.form.validate()) {
          return;
        }
        this.overlay = true;

        if (this.busStops.length > 0 && this.busStopsReturn.length > 0)
          this.route.direction = "AMBOS";
        else if (this.busStops.length > 0) this.route.direction = "IDA";
        else if (this.busStopsReturn.length > 0) this.route.direction = "VOLTA";

        for (let i = 0; i < this.busStops.length; i++) {
          this.busStops[i].busStopOrder = i + 1;
        }
        for (let i = 0; i < this.busStopsReturn.length; i++) {
          this.busStopsReturn[i].busStopOrder = i + 1;
        }
        let requestBody = {
          BusLine: this.route,
          BusStops: this.busStops,
          BusStopsReturn: this.busStopsReturn,
        };
        let response = await RouteService.editBusLine(requestBody);
        this.route = response.data;
        let responseBusStops = await RouteService.getBuslineStops(
          this.route.id
        );
        this.busStops = responseBusStops.filter((e) => e.direction == "I");
        this.busStopsReturn = responseBusStops.filter(
          (e) => e.direction == "V"
        );
        this.backupBusStops = [];
        this.backupBusStopsReturn = [];
        this.backupRoute = {};
        this.overlay = false;
        this.$router.push({ path: "admin-routes", query: { showAlert: true } });
      } catch (e) {
        console.log(e);
        this.route.arrivalTime = "";
        this.route.departureTime = "";
        this.route.arrivalTimeReturn = "";
        this.route.departureTimeReturn = "";
        this.componentKey++;
        this.componentKeyReturn++;
        this.overlay = false;
        this.customMessage = e.data.message;
        this.setAlertProps(false, "saveRouteError");
      }
    },
    compareList(listOriginal, newList) {
      var edit = 0;

      listOriginal.forEach((originalObj, index) => {
        const newObj = newList[index];

        if (!newObj || JSON.stringify(originalObj) !== JSON.stringify(newObj)) {
          edit++;
        }
      });

      for (let i = listOriginal.length; i < newList.length; i++) {
        if (
          !listOriginal.find(
            (originalObj) =>
              JSON.stringify(originalObj) === JSON.stringify(newList[i])
          )
        ) {
          edit++;
        }
      }
      if (edit == 0) {
        this.isEdited = false;
      } else {
        this.isEdited = true;
      }
    },
    validateDirection() {
      var i = false;
      var v = false;

      if (
        this.route.departureTime !== "" &&
        this.route.departureTime !== undefined &&
        this.route.arrivalTime !== "" &&
        this.route.arrivalTime != undefined
      ) {
        i = true;
      }
      if (
        this.route.departureTimeReturn !== "" &&
        this.route.departureTimeReturn !== undefined &&
        this.route.arrivalTimeReturn !== "" &&
        this.route.arrivalTimeReturn !== undefined
      ) {
        v = true;
      }

      if (i && v) {
        if (
          this.busStops !== null &&
          this.busStops !== undefined &&
          this.busStops.length > 0 &&
          this.busStopsReturn !== null &&
          this.busStopsReturn !== undefined &&
          this.busStopsReturn.length > 0
        ) {
          return true;
        }
      } else if (i) {
        if (
          this.busStops !== null &&
          this.busStops !== undefined &&
          this.busStops.length > 0 &&
          !(
            this.busStopsReturn !== null &&
            this.busStopsReturn !== undefined &&
            this.busStopsReturn.length > 0
          )
        ) {
          return true;
        }
      } else if (v) {
        if (
          this.busStopsReturn !== null &&
          this.busStopsReturn !== undefined &&
          this.busStopsReturn.length > 0 &&
          !(
            this.busStops !== null &&
            this.busStops !== undefined &&
            this.busStops.length > 0
          )
        ) {
          return true;
        }
      }

      return false;
    },
    hasChange() {
      if (
        (!Object.is(this.routeDefault.code, this.route.code) ||
          !Object.is(this.ufDefault, this.uf) ||
          !Object.is(this.routeDefault.region, this.route.region) ||
          !Object.is(this.routeDefault.site.id, this.route.site.id) ||
          !Object.is(this.routeDefault.busType.id, this.route.busType.id) ||
          !Object.is(
            this.routeDefault.transshipment,
            this.route.transshipment
          ) ||
          !Object.is(
            this.routeDefault.departureTime,
            this.route.departureTime
          ) ||
          !Object.is(this.routeDefault.arrivalTime, this.route.arrivalTime) ||
          !Object.is(
            this.routeDefault.departureTimeReturn,
            this.route.departureTimeReturn
          ) ||
          !Object.is(
            this.routeDefault.arrivalTimeReturn,
            this.route.arrivalTimeReturn
          )) &&
        this.isValid()
      ) {
        return true;
      }
      return false;
    },
    isValid() {
      if (this.route.site !== null || this.route.busType !== null) {
        if (
          this.route.code !== "" &&
          this.route.code !== undefined &&
          this.uf !== "" &&
          this.uf !== undefined &&
          this.route.region !== "" &&
          this.route.region !== undefined &&
          this.route.site.id !== "" &&
          this.route.site.id !== undefined &&
          this.route.busType.id !== "" &&
          this.route.busType.id !== undefined &&
          this.route.transshipment !== "" &&
          this.route.transshipment !== undefined &&
          this.validateDirection()
        ) {
          return true;
        }
      }
      return false;
    },
    fieldValueHasChanged() {
      if (this.editMode) {
        if (this.hasChange()) {
          this.isEdited = true;
          return;
        }
      } else if (this.isValid()) {
        this.isEdited = true;
        return;
      }
      this.isEdited = false;
    },
    isAllMandatoryFieldsFilledIn() {},
    setAlertProps(isSuccess, type) {
      if (isSuccess) {
        this.alertType = "success";
      } else {
        this.alertType = "error";
      }
      this.alertMessageType = type;
      this.showAlert = true;
    },
    closeAlert() {
      this.showAlert = false;
    },
    returnPage() {
      this.$router.push("/admin-routes");
    },
    getActiveButtonText() {
      if (this.route.active) {
        return "Desabilitar Rota";
      }
      return "Habilitar Rota";
    },
    async loadSites(id) {
      try {
        this.sites = await RegionService.getSitesByRegionId(id);
      } catch {
        this.sites = [];
      }
    },
    async loadBusType(id) {
      try {
        this.busTypeOptions = await BusTypeService.getBusTypeByRegion(id);
      } catch {
        this.busTypeOptions = [];
      }
    },
    clearUf() {
      this.uf = "";
      this.clearWhenRegionChange();
    },
    clearWhenRegionChange() {
      this.clearBusType();
      this.clearListBusStops();
      this.clearTimes();
      this.clearTransshipment();
    },
    clearSite() {
      this.route.site = null;
      this.sites = [];
    },
    clearListBusStops() {
      this.busStops = [];
      this.busStopsReturn = [];
    },
    clearBusType() {
      this.route.busType = "";
    },
    clearTransshipment() {
      this.route.transshipment = "";
    },
    clearTimes() {
      this.route.departureTime = "";
      this.route.arrivalTime = "";

      this.route.departureTimeReturn = "";
      this.route.arrivalTimeReturn = "";
    },
  },
  async beforeMount() {
    try {
      this.overlay = true;
      this.route = RouteFormStore.getters.route;

      if (this.route.region) {
        this.uf = this.route.region.uf;
        this.regions = await RegionService.getRegionByUf(this.uf);
        this.loadSites(this.route.region.id);
        this.busTypeOptions = await BusTypeService.getBusTypeByRegion(
          this.route.region.id
        );
      }

      this.editMode = RouteFormStore.getters.editMode;

      if (this.route.region) this.regionId = this.route.region.id;
      else this.regionId = "";
      this.activeStatusButtonText = this.getActiveButtonText();
      if (this.route.id) {
        this.loadingBusStops = true;
        let responseBusStops = await RouteService.getBuslineStops(
          this.route.id
        );
        this.busStops = responseBusStops.filter((e) => e.direction == "I");
        this.busStopsReturn = responseBusStops.filter(
          (e) => e.direction == "V"
        );
        this.loadingBusStops = false;
      }
      this.setDialogValues();
      this.busStopDefault = this.busStops.slice();
      this.busStopReturnDefault = this.busStopsReturn.slice();
      this.routeDefault = {
        code: this.route.code,
        region: this.route.region,
        site: this.route.site,
        busType: this.route.busType,
        transshipment: this.route.transshipment,
        departureTime: this.route.departureTime,
        arrivalTime: this.route.arrivalTime,
        departureTimeReturn: this.route.departureTimeReturn,
        arrivalTimeReturn: this.route.arrivalTimeReturn,
      };
      this.ufDefault = this.uf;

      this.overlay = false;
    } catch (e) {
      this.overlay = false;
      this.setAlertProps(false, "loadRouteError");
    }
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.formRoute {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  justify-content: space-around;
  align-items: inherit;

  border: 1px solid #f4f4f4;
}

.v-text-field.v-text-field--enclosed ::v-deep .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 2px;
}
</style>
