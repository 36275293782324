import { render, staticRenderFns } from "./AbsenceReport.vue?vue&type=template&id=450d6562&scoped=true"
import script from "./AbsenceReport.vue?vue&type=script&lang=js"
export * from "./AbsenceReport.vue?vue&type=script&lang=js"
import style0 from "./AbsenceReport.vue?vue&type=style&index=0&id=450d6562&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "450d6562",
  null
  
)

export default component.exports