import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

export const EmployeeFormStore = new Vuex.Store({
    state: {
        employee: {},
        operation: 'create',
        mandatory: false
    },
    getters: {
        employee: (state) => {
            return state.employee;
        },
        operation: (state) => {
            return state.operation;
        },
        mandatory: (state) => {
            return state.mandatory;
        }
    },
    mutations: {
        setEmployeeModel(state, employee) {
            if (!employee) {
                state.employee = {
                    registrationNumber: "",
                    name: "",
                    category: "",
                    shift: "",
                    addresses: [],
                    isThirdParty: "",
                    email: "",
                    region: "",
                    cpf: ""
                }
            } else {
                state.employee = employee;
            }
        },

        setOperation(state, operation) {
            state.operation = operation;
        },

        setMandatory(state, mandatory) {
            state.mandatory = mandatory;
        }
    }
});
