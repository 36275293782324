<template>
  <v-snackbar
    v-model="snackbar"
    :color="snackbarColor"
    :messageType="messageType"
    class="mx-xl-9 my-xl-7 mx-lg-6 my-lg-6 mx-md-2 my-md-6 mx-sm-8 my-sm-6 mx-xs-1 my-xs-2"
    timeout=10000
    right
    light
    text
  >
  <div class="d-flex">
    <v-icon class="mr-4" :color="snackbarColor" text small
      >mdi-{{ icon }}</v-icon
    >
    <span>
      {{
        customMessage != "" && customMessage != null
          ? customMessage
          : this.shownMessage
      }}
    </span>
  </div>
    <template v-slot:action="{ attrs }">
      <v-btn
        :color="snackbarColor"
        class="mr-2"
        x-small
        icon
        plain
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "CustomAlert",
  props: {
    dialog: { type: Boolean, default: false },
    messageType: { type: String },
    type: { type: String },
    customMessage: { type: String },
  },
  data: function () {
    return {
      messages: new Map(),
      shownMessage: "",
      snackbarColor: "",
      icon: "",
      playTimeout: false,
    };
  },
  computed: {
    snackbar: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update", value);
      },
    },
    typeMsg: {
      get() {
        return this.type
      },
      set(value) {
        this.$emit("update", value);
      }
    }
  },
  created() {
    this.messages.set("", "");
    this.messages.set("defaultSuccess", "Operação realizada com sucesso");
    this.messages.set(
      "defaultError",
      "Não foi possível realizar esta operação no momento."
    );
    this.messages.set("uploadSuccess", "Arquivo carregado com sucesso!");
    this.messages.set("deleteSuccess", "Registro(s) removido(s) com sucesso.");
    this.messages.set(
      "uploadError",
      "Certifique-se de que o arquivo .csv contém as colunas registrationNumber, name, category, shift, isThirdParty, email e region.\n" +
        "    Além disso, os itens devem estar separados por ponto e vírgula e sem espaço."
    );
    this.messages.set(
      "uploadError",
      "Certifique-se de que o arquivo .csv contém as colunas usuario id"
    );
    this.messages.set(
      "noFile",
      "Certifique-se de que o arquivo foi selecionado."
    );
    this.messages.set(
      "uploadKmzError",
      "Erro ao fazer upload do arquivo .kmz."
    );
    this.messages.set(
      "deleteError",
      "Não foi possível remover esse(s) registro(s) no momento."
    );
    this.messages.set(
      "deleteRegionIfExistsLineError",
      "Não é possível remover complexo que possui rota relacionada."
    );
    this.messages.set(
      "createEmployeeSuccess",
      "Colaborador criado com sucesso."
    );
    this.messages.set(
      "editEmployeeSuccess",
      "Colaborador editado com sucesso."
    );
    this.messages.set(
      "employeeOperationError",
      "Não foi possível finalizar essa operação no momento."
    );
    this.messages.set("createBookingError", "Falha na criação da reserva.");
    this.messages.set("SaveAbsenceSuccess", "Ausência cadastrada com sucesso.");
    this.messages.set(
      "SaveAbsenceError",
      "Não foi possível finalizar o cadastro da ausência no momento."
    );
    this.messages.set(
      "SaveAddressSuccess",
      "Endereço(s) salvo(s) com successo!"
    );
    this.messages.set(
      "SaveAddressError",
      "Não foi possível salvar os endereços no momento."
    );
    this.messages.set("downloadSuccess", "O download está sendo realizado.");
    this.messages.set(
      "AdressMissing",
      "Este colaborador não possui endereços cadastrados."
    );
    this.messages.set(
      "AdressRegisterMissing",
      "É necessario cadastrar pelo menos um endereço"
    );
    this.messages.set(
      "invalidDate",
      "Não é possivel realizar uma reserva retroativa."
    );
    this.messages.set(
      "wrongRegistrationNumber",
      "O campo Matricula/CPF precisa ter 8 ou 11 caracteres."
    );
    this.messages.set("regionSaveSucess", "Complexo salvo com sucesso.");
    this.messages.set("regionSaveError", "Falha ao salvar o complexo.");
    this.messages.set(
      "emailSendedWhenDeleteSuccess",
      "Registro(s) removido(s) com sucesso e email de remoção enviado."
    );
    this.messages.set("loadRouteError", "Erro ao carregar a linha de ônibus");
    this.messages.set("saveRouteError", "Erro ao salvar a linha de ônibus");
    this.messages.set(
      "deleteBusStopErrorWithBookings",
      "Não é possível excluir ponto de ônibus com reserva associada"
    );
    this.messages.set("saveRouteSuccess", "Linha de ônibus salva com sucesso");
    this.messages.set(
      "desableRouteSuccess",
      "Linha de ônibus desabilitada com sucesso"
    );
    this.messages.set(
      "enableRouteSuccess",
      "Linha de ônibus habilitada com sucesso"
    );
    this.messages.set(
      "saveExpectedEmployeesSucess",
      "Quantidade de colaboradores esperados salvo com sucesso !"
    );
  },
  updated() {
    if (this.typeMsg.includes("success")) {
      this.snackbarColor = "success";
      this.icon = "check-circle-outline";
    } else if (this.typeMsg.includes("warning")) {
      this.snackbarColor = "warning";
      this.icon = "alert-outline";
    } else {
      this.snackbarColor = "error";
      this.icon = "close-circle-outline";
    }

    if (!this.dialog) {
      this.$emit("close-alert");
      return;
    }

    if (this.type === "success") {
      this.shownMessage = (this.messageType == undefined) ? this.messages.get("defaultSuccess") : this.messages.get(this.messageType);
    } else if (this.type === "error") {
      this.shownMessage = (this.messageType == undefined) ? this.messages.get("defaultError") : this.messages.get(this.messageType);
    } else this.shownMessage = "";
  },
};
</script>