<template>
  <v-list class="pt-0 pb-0 div-buttons">
    <v-row no-gutters>
      <v-col class="ml-4 mr-2 mb-2 pa-0">
          <v-btn large @click="openNewTab" class="btn-home">
            <div class="btn-content">
              <div color class="div-icon-btn">
                <v-icon class="mb-2">mdi-face-agent</v-icon>
              </div>
              <div>Acesso ao VSC</div>
            </div>
          </v-btn>
      </v-col>
      <v-col class="mr-4 pa-0">
          <v-btn large @click="openFaqs" class="btn-home">
            <div class="btn-content">
              <div class="div-icon-btn">
                <v-icon class="mb-2">mdi-help-box-outline</v-icon>
              </div>
              <div>Perguntas frequentes</div>
            </div>
          </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="ml-4 mr-2 mb-4 pa-0">
          <v-btn large @click="openUserExperience" class="btn-home">
            <div class="btn-content">
              <div class="div-icon-btn">
                <v-icon class="mb-2">mdi-help-box-multiple-outline</v-icon>
              </div>
              <div>Avaliação do sistema</div>
            </div>
          </v-btn>
      </v-col>
      <v-col class="mr-4 pa-0">
          <v-btn large @click="openRelease" class="btn-home">
            <div class="btn-content">
              <div class="div-icon-btn">
                <v-icon class="mb-2">mdi-note-text-outline</v-icon>
              </div>
              <div>Release notes</div>
            </div>
          </v-btn>
      </v-col>
    </v-row>
  </v-list>
</template>

<script>
export default {
  name: "MenuHelp",
  comments: {},

  props: {},

  data: () => ({}),
  methods: {
    openNewTab() {
      window.open(
        "https://vale.service-now.com/sp?id=sc_cat_item&sys_id=eaf3df931ba120103a8185dde54bcb42&vc_par=1",
        "_blank",
        "noreferrer"
      );
    },
    openFaqs() {
      this.$emit("openFaqs");
    },
    openRelease() {
      this.$emit("openRelease");
    },
    openUserExperience() {
      this.$emit("openUserExperience");
    }
  },

  async beforeMount() {},
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.btn-content {
  display: flex;
  flex-direction: column;
  color: #008f83;
  font-size: 12px;
}

.btn-home {
  width: 154px !important;
  height: 126px !important;
  
  background-color: #d6ebea !important;
  text-transform: none;
  font-weight: 400;
  flex-grow: 1;


  @media (max-width: "1365px") {
    width: 100% !important;
    height: 126px !important;
    margin-bottom: 0px;
    margin-right: 12px;
  }
}

.div-buttons {
  height: auto;
  display: grid;
  gap: 0.1rem;

  @media (max-width: 1365px) {
    height: auto;
    width: 100%;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
  }
}

.icon-costume {
  font-size: 20px;
  color: #6f6f6f;
}
</style>
