<template>
  <v-card color="main-content fill-height pa-4">
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    >
    </CustomAlert>
    <v-card-title>
      <h4>Dias de Inatividade</h4>
    </v-card-title>
    <v-card-subtitle class="mb-4">
      <p>
        Configure a quantidade de dias a serem considerados inatividade para o
        relatório de inatividade.
      </p>
    </v-card-subtitle>
    <v-card-text>
      <v-form ref="form" lazy-validation>
        <v-row v-if="!loading">
          <v-col class="col-6 py-0">
            <v-text-field
              label="Dias a serem considerados inatividade:"
              v-model="settings.inactivityDays"
              :rules="[isNotEmptyRule, isPositiveNumber]"
              @keyup="inputChanged"
              @click="inputChanged"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-6 space-button pt-0" align="right">
            <template v-if="loading">
              <div class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
            </template>
            <GenericButton
              title="Salvar"
              icon="mdi-content-save-outline"
              primary
              :disabled="!hasChanged"
              @click="updateSettings"
              v-if="!loading"
            ></GenericButton>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomAlert from "@/components/CustomAlert";
import { SettingsService } from "@/services/settings";
import { isNotEmptyRule, isPositiveNumber } from "@/services/validation";
import GenericButton from "../components/GenericButton.vue";

export default {
  name: "InactivityReport",
  components: {
    CustomAlert,
    GenericButton
  },
  data: function () {
    return {
      settings: {
        inactivityDays: 0,
        id: null,
      },
      existSettings: false,
      hasChanged: false,
      loading: true,
      showAlert: false,
      alertType: "success",
      alertMessageType: "defaultSuccess",
      initialValue: null,
    };
  },
  methods: {
    async loadSettings() {
      const settings = await SettingsService.getSettings();
      this.existSettings = settings.length != 0;
      if (this.existSettings) {
        this.settings = settings[0];
        this.initialValue = settings[0].inactivityDays;
      }
    },
    async updateSettings() {
      try {
        if (!this.$refs.form.validate()) {
          return;
        }

        this.loading = true;
        if (!this.existSettings) {
          await SettingsService.postSettings(this.settings);
        } else {
          await SettingsService.putSettings(this.settings);
        }
        this.alertType = "success";
        this.alertMessageType = "defaultSuccess";
        await this.loadSettings();
        this.showAlert = true;
        this.hasChanged = false;
        this.loading = false;
      } catch (e) {
        this.alertType = "error";
        this.alertMessageType = "defaultError";
      } finally {
        setTimeout(() => {
          this.closeAlert();
        }, 10000);
      }
    },
    closeAlert() {
      this.showAlert = false;
    },
    inputChanged() {
      if (this.initialValue != parseInt(this.settings.inactivityDays)) {
        this.hasChanged = true;
      } else {
        this.hasChanged = false;
      }
    },
    isNotEmptyRule,
    isPositiveNumber,
  },
  async beforeMount() {
    await this.loadSettings();
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;
}

.space-button {
  margin-top: 2px;
}
</style>
