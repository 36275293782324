import api from "./api";

export class BusStopsService {
  static async getBusStops() {
    let response = await api.get(`/busStops`);
    return response.data;
  }
  static async getBusStopsByRegion(regionId) {
    let response = await api.get(`/busStopsByRegion/${regionId}`);
    return response.data;
  }

  static async getFinalBusStops() {
    let response = await api.get(`/busStops?order=final`);
    return response.data;
  }

  static async countBookingsBusStop(busStopId, RouteId) {
    let response = await api.get(
      `/countBookingByBusStopRoute/${busStopId}?routeId=${RouteId}`
    );
    return response.data;
  }

  static async addBusStop(request) {
    let response = await api.post("/busStops", request);
    return response.data;
  }

  static async getFilteredBusStops(request) {
    let response = await api.get(
      `/busStops/filtered?regionId=${request.regionId}&name=${request.name}&reference=${request.reference}`
    );
    return response.data;
  }

  static async updateBusStop(request) {
    let response = await api.put("/busStops", request);
    return response.data;
  }

  static async deleteBusStop(id) {
    let response = await api.delete(`/busStops/${id}`);
    return response.data;
  }

  static async getNearBusStops(request) {
    let response = await api.get(
      `/busStops/nearBusStops?registrationNumber=${request.registrationNumber}
      &siteId=${request.siteId}
      &busStopsQuantity=${request.busStopsQuantity}
      &direction=${request.direction}`
    );
    return response.data;
  }
}
