<template>
  <v-card class="main-content pa-2 fill-height">
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      customMessage=""
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>

    <GenericDialog
      close
      :visible="showAddBusTypeDialog"
      v-on:close-dialog="closeDialog"
      width="600px"
    >
      <template v-slot:header>
        <v-card-title
          class="px-0 py-0 ml-1"
          style="color: #393939; font-size: 24px"
        >
          Adicionar Tipos de Ônibus
        </v-card-title>
      </template>

      <template v-slot:subtitle>
        <v-card-subtitle class="pl-0 ml-1 pt-0 pb-6">
          Aqui você poderá adicionar um novo tipo de ônibus.
        </v-card-subtitle>
      </template>

      <template v-slot:body>
        <v-card-text class="px-0 py-0">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-1">
              <v-col class="col-6 py-0">
                <v-text-field
                  label="Nome"
                  outlined
                  dense
                  v-model="busType.name"
                  :rules="[isNotEmptyRule]"
                ></v-text-field>
              </v-col>
              <v-col class="col-6 py-0">
                <v-select
                  label="Abrangência"
                  v-model="busType.coverage"
                  :items="coverageTypes"
                  item-text="name"
                  item-value="value"
                  :rules="[isNotEmptyRule]"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col class="col-6 py-0">
                <v-select
                  label="Selecione o estado"
                  v-model="uf"
                  :items="listaEstados"
                  @change="UFChanged()"
                  item-text="nome"
                  item-value="sigla"
                  :rules="[isNotEmptyRule]"
                  outlined
                  dense
                />
              </v-col>
              <v-col class="col-6 py-0">
                <v-select
                  label="Complexo"
                  v-model="busType.region"
                  :items="regions"
                  item-text="name"
                  return-object
                  :rules="[isNotEmptyRule]"
                  :disabled="uf == null"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col class="col-6 py-0">
                <v-currency-field
                  label="Custo fixo"
                  v-model="busType.fixedCost"
                  prefix="R$"
                  outlined
                  dense
                ></v-currency-field>
              </v-col>
              <v-col class="col-6 py-0">
                <v-currency-field
                  label="Custo variável"
                  v-model="busType.variableCost"
                  prefix="R$"
                  outlined
                  dense
                ></v-currency-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col align="right" class="col-6 py-0">
                <v-tooltip left max-width="30%">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="secondaryText"
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span
                    >Refere-se à quantidade de assentos no ônibus, sem
                    considerar os lugares designados como preferenciais.</span
                  >
                </v-tooltip>
                <v-text-field
                  label="Capacidade"
                  outlined
                  dense
                  v-model="busType.capacity"
                  :rules="[isNotEmptyRule, isGreaterThanZero]"
                  type="number"
                  min="0"
                ></v-text-field>
              </v-col>
              <v-col align="right" class="col-6 py-0">
                <v-tooltip left max-width="30%">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="secondaryText"
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span
                    >Refere se a lugares para pessoas com necessidades de
                    acessibilidade</span
                  >
                </v-tooltip>
                <v-text-field
                  label="Capacidade preferencial"
                  outlined
                  dense
                  v-model="busType.capacityForPrioritySeats"
                  :rules="[isNotEmptyRule, isPositiveNumber]"
                  type="number"
                  min="0"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0 align-end">
              <v-col align="right" class="col-6 py-0">
                <v-tooltip left max-width="30%">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="secondaryText"
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span
                    >Refere-se a ônibus equipado com um elevador para
                    passageiros com necessidades especiais.</span
                  >
                </v-tooltip>
                <v-select
                  label="Elevador para acessibilidade"
                  v-model="busType.accessibilityElevator"
                  :items="accessibilityElevatorOptions"
                  item-text="name"
                  item-value="value"
                  :rules="[isNotEmptyRule]"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col class="col-6 py-0">
                <v-text-field
                  label="Emissão de CO2"
                  outlined
                  dense
                  v-model="busType.carbonDioxideEmission"
                  type="number"
                  min="0"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-col class="mt-3 pt-0 pb-0 pr-0" align="right">
              <v-progress-circular
                style="width: 70px"
                :size="30"
                :width="2"
                color="primary"
                indeterminate
                v-show="loadingAdd"
              >
              </v-progress-circular>
              <GenericButton
                title="Salvar"
                icon="mdi-content-save-outline"
                target="_blank"
                primary
                @click="addBusType()"
                :disabled="loadingAdd"
                v-show="!loadingAdd"
              ></GenericButton>
            </v-col>
          </v-form>
        </v-card-text>
      </template>
    </GenericDialog>

    <v-row align="center">
      <v-col align-self="center" cols="8" class="pb-0">
        <v-card-title>
          <h4>Administrar Tipos de Ônibus</h4>
        </v-card-title>
        <v-card-subtitle class="mb-5">
          Aqui você pode visualizar, adicionar, editar ou excluir os tipos de
          ônibus.
        </v-card-subtitle>
      </v-col>
      <v-col class="mr-7">
        <v-row justify="end" class="mb-4">
          <GenericButton
            title="Adicionar tipo de ônibus"
            icon="mdi-plus-circle"
            primary
            @click="toggleAddDialog"
          ></GenericButton>
        </v-row>
      </v-col>
    </v-row>
    <v-card-text>
      <v-row>
        <v-col>
          <v-data-table
            class="elevation-0"
            item-key="id"
            v-model="selectBusType"
            :search="search"
            :headers="headers"
            :custom-filter="filter"
            :items="busTypes"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': [10],
            }"
          >
            <template v-slot:[`item.accessibilityElevator`]="{ item }">
              {{ item.accessibilityElevator ? "Sim" : "Não" }}
            </template>
            <template v-slot:top>
              <v-row class="mb-1">
                <v-col class="col-3">
                  <v-text-field
                    outlined
                    dense
                    v-model="filters.search"
                    :clearable="true"
                    @keyup.enter="getFilteredBusTypes"
                    label="Buscar"
                    class="mt-1"
                  ></v-text-field>
                </v-col>
                <v-col class="col-3">
                  <v-select
                    outlined
                    dense
                    label="Filtrar por complexo"
                    v-model="filters.region"
                    :items="regions"
                    :clearable="true"
                    item-text="name"
                    item-value="id"
                    class="mt-1"
                    @keyup.enter="getFilteredBusTypes"
                  />
                </v-col>
                <v-col class="col-3 mt-1">
                  <GenericButton
                    class="mr-4"
                    title="Limpar"
                    icon="mdi-filter-off-outline"
                    tertiary
                    @click="clearFilter"
                  ></GenericButton>
                  <GenericButton
                    title="Buscar"
                    icon="mdi-magnify"
                    secondary
                    @click="getFilteredBusTypes"
                  ></GenericButton>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="icon-table" @click="editBusType(item)"
                >mdi-pencil</v-icon
              >
              <DeleteConfirmation
                :dialog="showDialog"
                :value="item.id"
                v-on:send-delete="deleteBusType(item.id)"
              ></DeleteConfirmation>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-progress-circular indeterminate v-if="uploading" />
    </v-card-text>
  </v-card>
</template>

<script>
import CustomAlert from "@/components/CustomAlert";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import GenericDialog from "@/components/GenericDialog";
import { isNotEmptyRule } from "@/services/validation";
import { isGreaterThanZero } from "@/services/validation";
import { isPositiveNumber } from "@/services/validation";
import { BusTypeService } from "@/services/busType";
import { BusTypeFormStore } from "@/store/busTypeForm";
import { RegionService } from "@/services/region";
import { estados } from "@/store/estados";
import GenericButton from "../components/GenericButton.vue";
import { PermissionService } from "../services/permission";

export default {
  name: "BusTypeManagement",
  components: {
    CustomAlert,
    DeleteConfirmation,
    GenericDialog,
    GenericButton,
  },
  store: BusTypeFormStore,
  data: () => ({
    filters: {
      search: "",
      region: "",
    },
    options: {},
    listaEstados: estados,
    uf: null,
    regions: [],
    showAlert: false,
    showDialog: false,
    alertMessageType: "default",
    alertType: "success",
    headers: [
      { text: "", value: "actions", sortable: false },
      { text: "Nome", value: "name" },
      { text: "Capacidade de assentos", value: "capacity" },
      { text: "Capacidade preferencial", value: "capacityForPrioritySeats" },
      { text: "Elevador para acessibilidade", value: "accessibilityElevator" },
      { text: "Complexo", value: "region.name" },
    ],
    uploading: false,
    loading: true,
    busTypes: [],
    showAddBusTypeDialog: false,
    busType: {
      name: "",
      capacity: null,
      capacityForPrioritySeats: null,
      region: null,
      uf: null,
      fixedCost: null,
      variableCost: null,
      carbonDioxideEmission: null,
      accessibilityElevator: null,
      coverage: null,
    },
    coverageTypes: [
      { name: "Intermunicipal", value: "INTERCITY" },
      { name: "Intramunicipal", value: "INTRACITY" },
    ],
    accessibilityElevatorOptions: [
      { name: "Sim", value: true },
      { name: "Não", value: false },
    ],
    selectBusType: [],
    search: "",
    filterAux: "",
    regionSearch: "",
    regionSearchAux: "",
    totalBusTypes: 0,
    loadingAdd: false,
    valid: false,
  }),
  methods: {
    isNotEmptyRule,
    isGreaterThanZero,
    isPositiveNumber,
    async filter() {
      if (this.regionSearch == null) this.regionSearch = "";
      if (
        this.filterAux != this.search ||
        this.regionSearch != this.regionSearchAux
      ) {
        this.filterAux = this.search;
        this.regionSearchAux = this.regionSearch;
        if (this.search.length >= 3 || this.regionSearch.length > 0) {
          try {
            this.loading = true;
            this.filters.region = this.regionSearch;
            this.filters.search = this.search;
            let response = await BusTypeService.getFilteredBusTypes(
              this.filters
            );
            this.busTypes = response;
            this.loading = false;
          } catch (e) {
            window.alert(e);
          }
        } else if (this.search == null || this.search.length <= 1) {
          this.getAllBusTypes();
        }
      }
    },
    clearFilter() {
      this.filters = {
        search: "",
        region: "",
      };
      this.getFilteredBusTypes();
    },
    editBusType(busType) {
      BusTypeFormStore.commit("setBusTypeModel", busType);
      this.$router.push("/bus-type-edit");
    },
    toggleAddDialog() {
      this.busType = {
        name: "",
        capacity: null,
        fixedCost: null,
        variableCost: null,
        carbonDioxideEmission: null,
        coverage: null,
      };
      this.showAddBusTypeDialog = !this.showAddBusTypeDialog;
    },
    clearBusType() {
      this.$refs.form.reset();
      this.busType = {
        name: "",
        capacity: null,
        capacityForPrioritySeats: null,
        region: null,
        uf: null,
        fixedCost: null,
        variableCost: null,
        carbonDioxideEmission: null,
        accessibilityElevator: null,
        coverage: null,
      };
    },
    async getAllBusTypes() {
      try {
        this.loading = true;
        this.busTypes = await BusTypeService.getAllBusTypes();
        this.totalBusTypes = this.busTypes.length;
        this.loading = false;
      } catch (e) {
        window.alert(e);
      }
    },
    async getFilteredBusTypes() {
      this.loading = true;
      if (this.filters.search == null) {
        this.filters = "";
      }
      if (this.filters.region == null) {
        this.filters.region = "";
      }
      this.busTypes = await BusTypeService.getFilteredBusTypes(this.filters);
      this.loading = false;
    },
    async addBusType() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        let busType = {
          ...this.busType,
          carbonDioxideEmission: Number(this.busType.carbonDioxideEmission),
        };
        this.loadingAdd = true;
        await BusTypeService.addBusType(busType);
        this.loadingAdd = false;
        this.toggleAddDialog();
        this.alertType = "success";
        this.showAlert = true;
        this.alertMessageType = "defaultSuccess";
        this.clearBusType();
        this.getAllBusTypes();
      } catch (e) {
        this.alertType = "error";
        this.alertMessageType = "defaultError";
        this.showAlert = true;
        this.loadingAdd = false;
        this.toggleAddDialog();
      }
    },
    async deleteBusType(id) {
      try {
        await BusTypeService.deleteBusType(id);
        this.alertType = "success";
        this.showAlert = true;
        this.alertMessageType = "defaultSuccess";
        if (this.search != null || this.regionSearch.length > 0) {
          this.getFilteredBusTypes();
        } else {
          this.getAllBusTypes();
        }
      } catch (e) {
        this.alertType = "error";
        this.alertMessageType = "defaultError";
        this.showAlert = true;
      }
    },
    async UFChanged() {
      this.regions = await RegionService.getRegionByUf(this.uf);
      this.busType.region = null;
      this.busType.site = null;
      this.sites = [];
    },
    closeAlert() {
      this.showAlert = false;
    },
    closeDialog() {
      this.showAddBusTypeDialog = false;
    },
  },
  async beforeMount() {
    let getRegions = await RegionService.getAllRegions();
    this.regions = getRegions;

    this.permission = PermissionService.getPermission();
    if (this.permission !== "admin") {
      this.$router.push("home");
    }

    if (this.$route.query.showAlert) {
      this.alertType = "success";
      this.showAlert = true;
      this.alertMessageType = "defaultSuccess";
    }

    this.getAllBusTypes();
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";
.header-container {
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.04);
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(0, 0, 0, 0.04);
}

.icon-table {
  margin-right: 4px;
  margin-left: 24px;
}

::v-deep .theme--light.v-data-table .v-data-footer {
  border-top: thin solid rgba(0, 0, 0, 0.04);
}

::v-deep
  .v-dialog
  > .v-dialog--active
  > .v-dialog__content
  > .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(0, 0, 0, 0.04);
}

::v-deep
  .v-dialog
  > .v-dialog__content
  > .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child {
  border-bottom: thin solid rgba(0, 0, 0, 0.04);
}

::v-deep
  .v-dialog__content
  > .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.04);
}

::v-deep .v-dialog > .theme--light.v-data-table .v-data-footer {
  border-top: thin solid rgba(0, 0, 0, 0.04);
}

::v-deep .v-data-table .v-data-table-header {
  background-color: #f4f4f4 !important;
}
</style>
