<template>
  <v-card class="main-content pa-6 fill-height">
    <v-overlay v-model="overlay" class="align-center justify-center">
      <div class="load-style">
        Aguarde, os passageiros estão sendo importados...
      </div>
      <v-progress-linear
        indeterminate
        :height="6"
        color="white"
      ></v-progress-linear>
    </v-overlay>
    <v-overlay v-model="overlayDownload" class="align-center justify-center">
      <div class="load-style">Aguarde, o download está em andamento...</div>
      <v-progress-linear
        indeterminate
        :height="6"
        color="white"
      ></v-progress-linear>
    </v-overlay>
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      :customMessage="customMessage"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    >
    </CustomAlert>
    <CsvImportInstructionsDialog
      :show="shouldShowInstructionsModal"
      :importEmployeeType="true"
      @close="shouldShowInstructionsModal = false"
    />

    <v-card-text class="my-2 py-0 px-0 justify-center" v-if="!showProgressBar">
      <template>
        <v-tabs v-model="tab" @change="tabHeadChanged($event)">
          <v-tab>Cadastro de Passageiros</v-tab>
          <v-tab>Importar passageiros</v-tab>
        </v-tabs>
      </template>

      <v-card-title class="title-container">
        <b>Cadastro de passageiro</b>
        <span
          v-if="!shouldShowImportButtons"
          class="d-none d-md-inline-flex justify-end"
        >
          <GenericButton
            class="mr-4"
            title="Baixar relatório"
            icon="mdi-tray-arrow-down"
            secondary
            @click="downloadCSVData"
            v-if="selectedEmployees.length == 0"
          ></GenericButton>
          <GenericButton
            title="Adicionar passageiros"
            icon="mdi-account-multiple-plus-outline"
            primary
            @click="setEmployeeModel(null, 'create')"
          ></GenericButton>
        </span>

        <span
          v-if="shouldShowImportButtons"
          class="d-none d-md-inline-flex justify-end"
        >
          <GenericButton
            title="Download do template"
            icon="mdi-tray-arrow-down"
            primary
            href="empregados-exemplo.csv"
            target="_blank"
          ></GenericButton>
        </span>
      </v-card-title>

      <v-card-subtitle class="pl-0 pb-0 mt-1" v-if="!showProgressBar">
        <span v-if="employees.length === 0">
          Não existem passageiros cadastrados. Selecione um arquivo (.csv) para
          cadastrar vários simultaneamente.
        </span>
        <span v-else>Aqui você poderá cadastrar os passageiros a seguir.</span>
      </v-card-subtitle>

      <v-progress-linear
        :indeterminate="indeterminateQuery"
        :active="showProgressBar"
      >
      </v-progress-linear>

      <v-tabs-items v-model="tab">
        <v-tab-item class="mt-6">
          <v-card-text class="card-space">
            <v-row>
              <v-col class="col-12 mb-0 px-0 py-0">
                <v-form ref="form">
                  <v-row
                    class="d-flex align-self-center justify-start mb-0"
                    no-gutters
                  >
                    <v-col cols="2" class="mr-1">
                      <v-select
                        label="Filtrar por"
                        v-model="searchFilter"
                        :items="filterOptions"
                        :rules="regionSearch === '' ? [isNotEmptyRule] : []"
                        item-text="text"
                        item-value="value"
                        @change="changeFilter"
                        outlined
                        dense
                      />
                    </v-col>
                    <v-col cols="2" class="mx-1" v-if="showSearch">
                      <v-text-field
                        v-model="search"
                        label="Identificação"
                        @keyup.enter="getEmployees()"
                        :rules="showSearch ? [isNotEmptyRule] : []"
                        outlined
                        dense
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2" class="mr-0 ml-1">
                      <v-select
                        label="Complexo"
                        v-model="regionSearch"
                        :items="regions"
                        :clearable="true"
                        item-text="name"
                        item-value="id"
                        outlined
                        dense
                      />
                    </v-col>
                    <GenericButton
                      class="mx-4"
                      title="Limpar filtros"
                      icon="mdi-filter-off-outline"
                      tertiary
                      @click="clearSearch"
                    ></GenericButton>
                    <GenericButton
                      title="Buscar"
                      icon="mdi-magnify"
                      secondary
                      @click="shouldGetEmployee"
                    ></GenericButton>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
            <v-row class="pt-3">
              <v-col class="px-0 py-0">
                <v-data-table
                  v-model="selectedEmployees"
                  item-key="registrationNumber"
                  class="elevation-1 col-12 px-0 py-0"
                  :headers="headers"
                  :items="employees"
                  :options.sync="options"
                  :server-items-length="totalEmployees"
                  :loading="loading"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 20, 30, 40, 50],
                  }"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                      small
                      class="mr-1"
                      color="primary"
                      @click="setEmployeeModel(item, 'edit')"
                      >mdi-pencil-outline</v-icon
                    >
                    <DeleteConfirmation
                      :dialog="showDialog"
                      v-on:send-delete="bodyDelete(item, false)"
                    >
                    </DeleteConfirmation>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <EmailSender ref="emailSender" />
        </v-tab-item>

        <v-tab-item class="mt-8">
          <v-row
            class="align-center justify-space-between elevation-1"
            v-if="permission === 'admin' || permission === 'supervisor'"
          >
            <v-col class="col-8">
              <v-file-input
                v-model="file"
                label="Selecione um arquivo (.csv) com novos passageiros."
                type="file"
                @change="fileWasChosen = true"
                @click="showAlert = false"
                accept=".csv"
              ></v-file-input>
            </v-col>
            <v-col class="col-3 d-flex justify-end">
              <v-btn
                color="primary"
                v-on:click="sendFile()"
                v-if="fileWasChosen"
                :disabled="!fileWasChosen"
              >
                <v-icon class="mr-2">mdi-tray-arrow-up</v-icon>
                Importar passageiros</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-card>
              <v-card-title class="text-h5 lighten-2">
                Modelo de arquivo .csv
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="[
                    { text: 'Matricula Vale', value: 'registrationNumber' },
                    { text: 'Nome', value: 'name' },
                    { text: 'CPF', value: 'cpf' },
                    { text: 'Categoria', value: 'category' },
                    { text: 'Regime de trabalho', value: 'shift' },
                    { text: 'Funcionario Vale', value: 'isThirdParty' },
                    { text: 'E-mail', value: 'email' },
                    { text: 'Complexo', value: 'region' },
                    { text: 'Assento Preferencial', value: 'preferential' },
                    { text: 'Endereço', value: 'address' },
                  ]"
                  :items="[
                    {
                      registrationNumber: 'c123',
                      name: 'Teste 1',
                      cpf: '73084135002',
                      category: 'ADM',
                      shift: 'REMOTE',
                      isThirdParty: 'Sim',
                      email: 'teste1@email.com',
                      region: 'Regiao Exemplo',
                      preferential: 'Não',
                      address: 'Logradouro, número, cidade, estado',
                    },
                    {
                      registrationNumber: 'c124',
                      name: 'Teste 2',
                      cpf: '16395777009',
                      category: 'ADM',
                      shift: 'ON_SITE',
                      isThirdParty: 'Não',
                      email: 'teste2@email.com',
                      region: 'Regiao Exemplo',
                      preferential: 'Sim',
                      address: 'Logradouro, número, cidade, estado',
                    },
                  ]"
                  class="elevation-1"
                >
                </v-data-table>
              </v-card-text>
              <v-divider></v-divider>
            </v-card>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { EmployeeService } from "@/services/employee";
import { RegionService } from "@/services/region";
import { isNotEmptyRule } from "@/services/validation";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import CustomAlert from "@/components/CustomAlert";
import { EmployeeFormStore } from "@/store/employeeForm";
import EmailSender from "@/components/EmailSender";
import CsvImportInstructionsDialog from "@/components/CsvImportInstructionsDialog";
import GenericButton from "../components/GenericButton.vue";
import { PermissionService } from "../services/permission";

export default {
  name: "RegisterEmployees",

  components: {
    CustomAlert,
    DeleteConfirmation,
    EmailSender,
    CsvImportInstructionsDialog,
    GenericButton,
  },

  store: EmployeeFormStore,

  data: () => ({
    permission: "",
    overlay: false,
    overlayDownload: false,
    search: "",
    file: {},
    showProgressBar: true,
    indeterminateQuery: true,
    fileWasChosen: false,
    employees: [],
    regionSearch: "",
    regions: [],
    selectedEmployees: [],
    filterOptions: [
      { text: "Nome", value: "nome" },
      { text: "CPF", value: "cpf" },
      { text: "Matricula", value: "matricula" },
    ],
    searchFilter: "",
    headers: [
      { text: "", value: "actions", width: "80px" },
      { text: "Nome", value: "name" },
      {
        text: "Funcionário Vale?",
        value: "formattedIsThirdParty",
        width: "80px",
      },
      { text: "Matrícula", value: "registrationNumber" },
      { text: "CPF", value: "formattedCpf" },
      { text: "Regime", value: "formattedShift" },
      {
        text: "Assento Preferencial",
        value: "preferentialFormatted",
        width: "130px",
      },
      { text: "Email", value: "email" },
      { text: "Complexo", value: "region.name", width: "118px" },
    ],
    showAlert: false,
    showDialog: false,
    customMessage: "",
    alertMessageType: "default",
    alertType: "success",
    deleteLabel: "Remover passageiro",
    options: {},
    totalEmployees: 0,
    loading: true,
    tab: null,
    currentUser: {},
    shouldShowInstructionsModal: false,
    shouldShowImportButtons: false,
    showSearch: false,
  }),

  watch: {
    options: {
      handler() {
        this.getEmployees();
      },
      deep: true,
    },
  },

  methods: {
    isNotEmptyRule,
    sendFile: async function () {
      try {
        this.overlay = true;
        await EmployeeService.registerEmployees(this.file);
        await this.uploadSuccess();
        setTimeout(() => {
          this.resetFileInput();
        }, 1000);
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.uploadError(error);
        setTimeout(() => {
          this.resetFileInput();
        }, 1000);
      }
    },

    resetFileInput() {
      this.file = null;
      this.fileWasChosen = false;
    },

    async uploadSuccess() {
      this.customMessage = "";
      this.setAlertProps(true, "uploadSuccess");
      await this.getEmployees();
    },

    uploadError(error) {
      if (error.status == 500) {
        this.customMessage =
          "Ocorreu um erro durante a importação. Tente novamente e caso o problema se mantenha, entre em contato com o apoio técnico.";
      } else if (error.data) {
        this.customMessage = error.data;
      } else {
        this.customMessage =
          "Não foi possível importar a planilha, verifique a estrutura e os dados por favor !";
      }
      this.setAlertProps(false, "uploadError");
    },

    async deleteSuccess() {
      this.setAlertProps(true, "emailSendedWhenDeleteSuccess");
      this.selectedEmployees = [];
      await this.getEmployees();
    },

    deleteError() {
      this.selectedEmployees = [];
      this.setAlertProps(false, "deleteError");
    },

    setAlertProps(isSuccess, type) {
      if (isSuccess) {
        this.alertType = "success";
      } else {
        this.alertType = "error";
      }
      this.showAlert = true;
      this.alertMessageType = type;
    },
    closeAlert() {
      this.showAlert = false;
    },
    clearSearch() {
      this.regionSearch = "";
      this.search = "";
      this.searchFilter = "";
      this.showSearch = false;
      this.$refs.form.reset();
      this.getEmployees();
    },
    changeFilter() {
      this.showSearch = true;
      this.search = "";
    },
    setEmployeeModel(employee, operation) {
      EmployeeFormStore.commit("setEmployeeModel", employee);
      EmployeeFormStore.commit("setOperation", operation);
      EmployeeFormStore.commit("setMandatory", false);
      this.$router.push("/employee-form");
    },

    async bodyDelete(employee, multipleDelete) {
      try {
        if (multipleDelete) {
          this.requestDelete(employee);
        } else {
          this.requestDelete([employee]);
        }
      } catch {
        this.setAlertProps(false, "Erro na estrutura das multiplas exclusÃµes");
      }
    },

    async requestDelete(employeeArray) {
      try {
        let usersIds = [];
        employeeArray.map((employee) => {
          usersIds.push(employee.id);
        });
        await EmployeeService.anonymizeEmployee(usersIds);
      } catch {
        this.deleteError();
      } finally {
        employeeArray.forEach((employee) => this.sendEmail(employee));
        this.deleteSuccess();
        await this.getEmployees();
      }
    },

    async getEmployees() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;

      if (this.regionSearch === null) {
        this.regionSearch = "";
      }

      let response = await EmployeeService.listEmployees(
        page - 1,
        itemsPerPage,
        this.searchFilter,
        this.clearMask(this.search),
        this.regionSearch
      );

      this.totalEmployees = response.totalElements;
      this.employees = response.content;
      this.employees.forEach((employee) => {
        employee.registrationNumber = this.emptyString(
          employee.registrationNumber
        );
        employee.email = this.emptyString(employee.email);
        employee.cpf = this.emptyString(employee.cpf);
        employee.formattedShift =
          employee.shift == "ON_SITE" ? "Presencial" : "Remoto";
        employee.formattedCpf = this.formatCPF(employee.cpf);
        employee.formattedIsThirdParty = employee.isThirdParty ? "Não" : "Sim";
        employee.preferentialFormatted = employee.preferential ? "Sim" : "Não";
      });
      this.loading = false;
      this.showProgressBar = false;
      this.indeterminateQuery = true;
    },
    shouldGetEmployee() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.getEmployees();
    },
    emptyString(value) {
      if (value == null || value == "" || value == undefined) {
        return "-";
      } else {
        return value;
      }
    },
    clearMask(mask) {
      if (mask) {
        return mask.replace(/\.|-/g, "");
      }
      return mask;
    },
    async downloadCSVData() {
      try {
        this.overlayDownload = true;
        let response = await EmployeeService.listEmployeesNoPagination(
          this.searchFilter,
          this.search,
          this.regionSearch
        );
        this.employeesAll = response;
        let csv =
          "Matrícula Vale,Funcionário Vale?,Nome,Cpf,Regime,Email,Complexo,Assento preferencial\n";
        this.employeesAll.forEach((employees) => {
          csv +=
            employees.registrationNumber +
            "," +
            employees.isThirdParty +
            "," +
            employees.name +
            "," +
            employees.cpf +
            "," +
            employees.shift +
            "," +
            employees.email +
            "," +
            employees.region?.name +
            "," +
            employees.preferential;
          csv += "\n";
        });

        const anchor = document.createElement("a");
        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
        anchor.target = "_blank";
        const time = Date.now();
        const today = new Date(time);

        anchor.download =
          "ListaTodosPassagerios em " +
          today.toLocaleDateString().replace("/", "-") +
          ".csv";
        anchor.click();
        this.setAlertProps(true, "downloadSuccess");
        this.overlayDownload = false;
      } catch (e) {
        this.overlayDownload = false;
        console.log(e);
      }
    },

    sendEmail(employee) {
      let params = {
        to: employee.email,
        subject: "Usuário removido do sistema SUAvaga",
        emailBody: ``,
      };

      this.$refs.emailSender.postEmailDeleteUser(params);
    },

    formatCPF(cpf) {
      if (!cpf) return "";
      if (cpf.length != 11) return cpf;
      return (
        cpf.substring(0, 3) +
        "." +
        cpf.substring(3, 6) +
        "." +
        cpf.substring(6, 9) +
        "-" +
        cpf.substring(9, 11)
      );
    },

    treatNullString(string) {
      if (string != null && string != "null") return string;
      return "";
    },

    toggleInstructionsModal() {
      this.shouldShowInstructionsModal = !this.shouldShowInstructionsModal;
    },

    tabHeadChanged(item) {
      this.shouldShowImportButtons = item === 1 ? true : false;
    },
  },

  async beforeMount() {
    this.permission = PermissionService.getPermission();
    let getRegions = await RegionService.getAllRegions();
    this.regions = getRegions;
    this.currentUser = await EmployeeService.getEmployee(
      localStorage.getItem("registrationNumber")
    );
    if (this.$route.query.showAlert) {
      this.setAlertProps(true, "defaultSuccess");
    }
  },

  async mounted() {
    this.showProgressBar = false;
    this.indeterminateQuery = true;
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;

  .v-card-subtitle {
    color: $greyVale40;
  }

  .v-card-text {
    text-align: center;
  }
}

.v-btn {
  text-transform: none;
}

.v-data-table {
  tbody {
    color: $greyVale;

    .v-icon {
      color: $greyVale;
    }
  }
}
.v-tab {
  text-transform: capitalize;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0 0 0;
}

.icon-edit {
  color: 008F83;
}

.load-style {
  font-size: 1.8rem;
  font-weight: bold;
}

::v-deep .v-data-table-header {
  background-color: #f4f4f4;
  text-align: center;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0 0 0 24px;
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.04);
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(0, 0, 0, 0.04);
}

::v-deep .theme--light.v-data-table .v-data-footer {
  border-top: thin solid rgba(0, 0, 0, 0.04);
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 8px 0 8px 24px;
}

.card-space {
  padding-left: 12.5px;
}
</style>
