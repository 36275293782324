<template>
  <VoucherComponent :booking="booking"></VoucherComponent>
</template>

<script>
import VoucherComponent from "@/components/VoucherComponent";
import { BookingService } from "@/services/booking";
import { BookUtils } from "@/utils/bookUtils";

export default {
  name: "BookingVoucher",
  components: {
    VoucherComponent,
  },
  data: () => ({
    booking: {},
  }),
  async beforeCreate() {
    this.booking = await BookingService.getBookingById(this.$route.params.id);
    BookUtils.updateDaysRecurrence(this.booking);
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;

  .v-card-subtitle {
    color: $greyVale40;
  }
}

.v-btn {
  text-transform: none;
}
</style>
