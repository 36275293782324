import api from "./api";

export class RouteService {
  static async getAllRoutes(
    page = "",
    size = "",
    search = "",
    region = "",
    uf = "",
    sitesId = ""
  ) {
    let endpoint = `/busLines?page=${page}&size=${size}&search=${search}&region=${region}&uf=${uf}`;

    let sitesIds = null;
    if (sitesId.length > 0) {
      sitesIds = sitesId.join(",");
      endpoint += `&sitesId=${sitesIds}`;
    }

    let response = await api.get(endpoint);
    return response.data;
  }

  static async deleteRoute(params) {
    const endpoint = `/manageBusLine/${params}`;
    let response = await api.delete(endpoint);
    return response;
  }

  static async editRoute(params) {
    const endpoint = `/manageBusLine/`;
    let response = await api.put(endpoint, params);
    return response;
  }

  static async editBusLine(params) {
    const endpoint = `/busLineEdit/`;
    let response = await api.put(endpoint, params);
    return response;
  }

  static async manageBusLine(params) {
    const endpoint = `/manageBusLine/`;
    let response = await api.post(endpoint, params);
    return response;
  }

  static async occupiedSeats(busLineId, date, direction, prioritySeat) {
    const endpoint = `/occupiedSeats/?busLineId=${busLineId}&date=${date}&direction=${direction}&prioritySeat=${prioritySeat}`;
    let response = await api.get(endpoint);
    return response.data;
  }

  static async getBuslineId(id) {
    const endpoint = `/busLines/` + id;
    let response = await api.get(endpoint);
    return response.data;
  }

  static async changeBusLineActiveStatus(params) {
    const endpoint = "/busLine/activation";
    let response = await api.patch(endpoint, params);
    return response;
  }

  static async getBuslineStops(id) {
    const endpoint = `/bookingstops/` + id;
    let response = await api.get(endpoint);
    return response.data;
  }

  static async saveBusStopRouteExpectedEmployee(busStopRoutes) {
    const endpoint = `/pathExpectedEmployees`;
    let response = await api.patch(endpoint, busStopRoutes);
    return response.data;
  }
}
