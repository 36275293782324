<template>
  <v-card
    :elevation="elevation"
    :class="[isDialog ? 'dialog-style' : 'no-dialog']"
    class="main-content fill-height"
  >
    <CustomAlert
      :dialog="showAlertAbsence"
      :message-type="alertMessageType"
      :type="alertType"
      :customMessage="customMessage"
      @close-alert="closeAlert"
      @update="showAlertAbsence = $event"
    ></CustomAlert>
    <v-card-subtitle class="mb-4 ml-1 pt-1 pl-0">
      Indique as férias ou outro motivo que leve você ou o colaborador a não
      conseguir comparecer ao trabalho.
    </v-card-subtitle>
    <v-card-text class="mx-0 my-0">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row v-if="permission !== 'employee'">
          <v-col class="col-12 py-0 px-0">
            <v-select
              v-if="isEdit"
              label="Matricula Vale ou CPF a quem a ausência é destinada"
              :items="employeeNameOption"
              v-model="nameEmployee"
              :disabled="isEdit"
              outlined
            />
            <v-autocomplete
              v-if="!isEdit"
              v-model="absence.busBookingOwnerRegistrationNumber"
              :items="employees"
              :item-text="getEmployeeRegistrationNumberAndName"
              item-value="registrationNumber"
              :rules="[isNotEmptyRule]"
              label="Matricula Vale ou CPF a quem a ausência é destinada"
              outlined
              no-data-text="Digite pelo menos 6 digitos para buscar."
              :disabled="disableInputs"
              v-on:keyup="getEmployees"
              :search-input.sync="employeeSearch"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col py-0 pl-0 pr-1">
            <DatePicker
              ref="startDate"
              :label="'Início'"
              outlined
              v-on:date-chosen="absence.startDate = $event"
              :rules="[isNotEmptyRule]"
              @picked="clearEndDate"
              :min="minStartDate"
            ></DatePicker>
          </v-col>
          <v-col class="col py-0 pr-0">
            <DatePicker
              ref="endDate"
              :label="'Término'"
              outlined
              v-on:date-chosen="absence.endDate = $event"
              :rules="[isNotEmptyRule]"
              :min="minEndDate"
              :disabled="disableEndDate"
            ></DatePicker>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 py-0 px-0">
            <v-textarea
              label="Motivo"
              outlined
              v-model="absence.reason"
              :rules="[isNotEmptyRule]"
              :disabled="disableInputs"
              rows="1"
            ></v-textarea>
          </v-col>
        </v-row>
        <div class="container-information">
          <div class="icon-information">
            <v-tooltip top max-width="30%">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small style="color: #a8a8a8">
                  mdi-information-outline
                </v-icon>
              </template>
              <span
                >A sinalização de ausência no período que não haverá uso
                disponibiliza a vaga para ser reservada por outros usuários,
                otimizando assim, a utilização dos coletivos.</span
              >
            </v-tooltip>
          </div>
          <div style="font-size: 14px; color: #a8a8a8">
            Caso possua reservas, a ausência deve ser informada até 12h antes da
            data de utilização da vaga reservada
          </div>
        </div>
        <v-row justify="end">
          <v-col class="col-12 mt-3 px-0 py-0" align="right">
            <GenericButton
              title="Salvar"
              icon="mdi-content-save-outline"
              primary
              v-on:click="save"
            >
            </GenericButton>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { isNotEmptyRule } from "@/services/validation";
import { EmployeeService } from "@/services/employee";
import { AbsenceService } from "@/services/absence";
import CustomAlert from "@/components/CustomAlert";
import DatePicker from "@/components/DatePicker";
import GenericButton from "./GenericButton.vue";
import { PermissionService } from "../services/permission";

export default {
  name: "AbsenceReport",
  props: {
    elevation: { type: Number, default: 2 },
    isDialog: { type: Boolean, default: false },
    isEdit: { type: Boolean, default: false },
    employeeText: { type: String, default: "" },
    idAbsence: { type: String, default: ""},
    absenceEdit: { type: Object }
  },
  components: {
    CustomAlert,
    DatePicker,
    GenericButton,
  },
  data: () => ({
    valid: false,
    employees: [],
    permission: "",
    absence: {
      id: "",
      busBookingOwnerRegistrationNumber: "",
      startDate: "",
      endDate: "",
      reason: "",
      additionalInformation: "",
      busBookingCreatorRegistrationNumber: "",
    },
    showAlertAbsence: false,
    alertMessageType: "default",
    alertType: "success",
    customMessage: "",
    disableInputs: false,
    employeeSearch: "",
    disableEndDate: true,
    minEndDate: "",
    minStartDate: "",
    employeeNameOption: [],
    nameEmployee: ""
  }),
  methods: {
    isNotEmptyRule,
    getEmployeeRegistrationNumberAndName(item) {
      let searchTerm = this.employeeSearch;
      if (this.employeeSearch && this.employeeSearch.includes(" - ")) { 
        searchTerm = this.employeeSearch.split(" - ")[0];
      }
      if(item.cpf != null && item.cpf.includes(searchTerm)){
        return `${item.cpf} - ${item.name} `;
      } else 
      if(item.registrationNumber != null && item.registrationNumber.includes(searchTerm.toUpperCase())){
        return `${item.registrationNumber} - ${item.name} `;
      }
      return ""
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        let retorno;
        if (this.isEdit) {
          retorno = await AbsenceService.putAbsence(this.absence);
        } else {
          retorno = await AbsenceService.postAbsence(this.absence);
        }
          
        if (retorno) {
          this.saveSuccess();
        }

        this.$refs.form.reset();
      } catch (e) {
        if(e.status == 409){
          this.alertType = "warning";
          this.customMessage = e.data.message;
          this.alertMessageType = "defaultError";
          this.showAlertAbsence = true;
          this.loading = false;
        } else {
          this.saveError();
        }
        this.$refs.form.reset();
      }
      if (this.permission === "employee") {
        this.$refs.form.reset();
      }
    },
    saveError() {
      this.showAlertAbsence = true;
      this.alertType = "error";
      this.alertMessageType = "SaveAbsenceError";
    },
    saveSuccess() {
      this.$emit('sucess-add-absence');
    },
    closeAlert() {
      this.disableInputs = false;
      this.absence.additionalInformation = "";
      this.showAlertAbsence = false;
    },
    async getEmployees() {
      this.employees = [];
      if (this.employeeSearch != null && this.employeeSearch.length > 5) {
        this.employees = await EmployeeService.getEmployeeSearch(
          this.employeeSearch
        );
      }
    },
    setMinStartDate() {
      const newDate = new Date();
      this.minStartDate = newDate.toISOString().substring(0, 10);
    },
    setMinEndDate() {
      const dateParts = this.absence.startDate.split("/");
      const newDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      this.minEndDate = newDate.toISOString().substring(0, 10);
    },
    clearEndDate() {
      this.setMinEndDate();
      this.disableEndDate = false;
      this.absence.endDate = "";
      this.$refs.endDate.reset();
    },
  },
  watch: {
  absenceEdit(newVal){
    this.absence.id = newVal.id
    this.absence.busBookingOwnerRegistrationNumber = newVal.employee.cpf
    this.absence.startDate = newVal.startDate
    this.absence.endDate = newVal.endDate
    this.absence.reason = newVal.reason
    this.absence.busBookingCreatorRegistrationNumber = newVal.busBookingCreatorRegistrationNumber
    this.nameEmployee = newVal.employee.name
    this.employeeNameOption = [ this.nameEmployee ]
  }
  },
  async beforeMount() {
    this.permission = PermissionService.getPermission();
    this.absence.busBookingCreatorRegistrationNumber =
      localStorage.getItem("registrationNumber");
    if (this.permission === "employee") {
      this.absence.busBookingOwnerRegistrationNumber =
        localStorage.getItem("registrationNumber");
    }
    if(this.isEdit){
      this.nameEmployee = this.employeeText
      this.employeeNameOption = [ this.employeeText ]
      this.absence.id = this.idAbsence
      this.absence.busBookingOwnerRegistrationNumber = this.absenceEdit.employee.cpf
    }
    this.setMinStartDate();
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.main-content {
  width: 30%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;

  .v-card-subtitle {
    color: $greyVale40;
  }

  .v-card__actions {
    position: absolute;
    bottom: 4vh;
    width: calc(100% - 2vw);
  }

  .v-card__title {
    font-size: 22px;
  }
}

.container {
  display: flex;
  justify-content: end;
}

.v-btn {
  text-transform: none;
}

.dialog-style {
  padding: 0px;
}

.no-dialog {
  padding: 16px;
}

.container-information {
  display: flex;
  flex-direction: row;
  margin-left: -13px;
}

.icon-information {
  display: flex;
  align-self: flex-start;
  margin-top: 3px;
  margin-right: 8px;
}
</style>
