import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const RouteFormStore = new Vuex.Store({
  state: {
    route: {},
    editMode: false,
  },
  getters: {
    route: (state) => {
      return state.route;
    },
    editMode: (state) => {
      return state.editMode;
    },
  },
  mutations: {
    setRouteModel(state, route) {
      if (!route) {
        state.route = {
          arrivalTime: "",
          busType: "",
          code: "",
          departureTime: "",
          destinationSite: "",
          direction: "",
          finalBusStop: {},
          id: "",
          initialBusStop: {},
          numberOfSeats: 0,
          transshipment: false,
          region: "",
          site: {},
          active: true,
        };
      } else {
        state.route = route;
      }
    },
    setEditMode(state, editMode) {
      if (!editMode) {
        state.editMode = false;
      } else {
        state.editMode = editMode;
      }
    },
  },
});
