import api from "./api";

export class AddressService {
    /**
     *
     * @returns {Promise<any>}
     */
    static async postAddress(employeeId, request) {
        const endpoint = `/address/${employeeId}`;
        let response = await api.post(endpoint, request);
        return response.data;
    }
    static async deleteAddress(addressId){
        const endpoint = `/address/${addressId}`;
        let response = await api.delete(endpoint);
        return response.data;
    }
}