import api from "./api";

export class ReportsService {
    static async getUsageReport(startDate, endDate, regionId) {
        const endpoint = `/reports/usage?startDate=${startDate}&endDate=${endDate}&regionId=${regionId}`;
        let response = await api.get(endpoint);
        return response.data;
    }

    static async getUnreservedUsageReport(startDate, endDate, regionId){
        const endpoint = `/reports/unreservedUsage?startDate=${startDate}&endDate=${endDate}&regionId=${regionId}`;
        let response = await api.get(endpoint);
        return response.data;
    }

    static async getUnusedBookingReport(startDate, endDate, regionId){
        const endpoint = `/reports/unusedBooking?startDate=${startDate}&endDate=${endDate}&regionId=${regionId}`;
        let response = await api.get(endpoint);
        return response.data;
    }

    static async getInactivityReport(regionId) {
        const endpoint = `/reports/inactivityReport?regionId=${regionId}`;
        let response = await api.get(endpoint);
        return response.data;
    }

    static async getEdlenessReport(regionId) {
        const endpoint = `/reports/edlenessReport?regionId=${regionId}`;
        let response = await api.get(endpoint);
        return response.data;
    }

    static async getAbsenceReport(startDate, endDate, regionId, uf) {
        const endpoint = `/reports/absenceReport?startDate=${startDate}&endDate=${endDate}&regionId=${regionId}&uf=${uf}`;
        let response = await api.get(endpoint);
        return response.data;
    }

    static async getBusOccupancy(startDate, endDate, region, busLine) {
        let endpoint = `/reports/busOccupancyReport?startDate=${startDate}&endDate=${endDate}&regionId=${region}&busLine=${busLine}`
        let response = await api.get(endpoint);
        return response.data; 
    }

    static async getExcludedReservation(request) {
        let endpoint = `/reports/excludedReservation`;
        
        if (request.startDate && request.endDate) {
          endpoint += `?startDate=${request.startDate}&endDate=${request.endDate}`;
        }
    
        if(request.state) {
          endpoint += `&uf=${request.state}`
        }
    
        if(request.regionId) {
          endpoint += `&regionId=${request.regionId}`
        }
    
        if(request.site) {
          endpoint += `&site=${request.site}`
        }
    
        let busLinesIds = null;
        if (request.busLinesIds.length > 0)
          busLinesIds = request.busLinesIds.join(',');
    
        if(busLinesIds != null) {
          endpoint +=`&busLinesIds=${encodeURIComponent(busLinesIds)}`;
        }
    
        let response = await api.get(endpoint);
        return response.data;
      }
}