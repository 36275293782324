<template>
  <v-card class="main-content pa-4 fill-height">
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      :customMessage="customMessage"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>
    <v-card-title>
      <b>Nova reserva em lote</b>
    </v-card-title>
    <v-card-subtitle
      >Faça as reservas de assentos para múltiplos passageiros.</v-card-subtitle
    >
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="11">
            <v-combobox
              id="comboboxEmployee"
              v-model="selectedEmployee"
              :items="listEmployee"
              :item-text="getEmployeeRegistrationNumberAndName"
              hide-selected
              hint="Somente passageiros com endereço vinculado irão aparecer como opção para reservar assento."
              persistent-hint
              label="Matrícula Vale a quem a reserva é destinada"
              v-on:keyup="getEmployees"
              :search-input.sync="employeeSearch"
              :rules="[isEmployeeListFilled]"
              v-on:change="setEmployeeSelectedList"
              outlined
              dense
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row id="selected-employees">
          <v-col cols="11">
            <span
              v-for="data in selectedEmployeeList"
              v-bind:key="data.registrationNumber"
            >
              <v-chip
                v-if="data.selectedValue === 'cpf'"
                :input-value="data.cpf"
                close
                @click:close="remove(data.cpf)"
                class="ma-1"
              >
                {{ data.cpf + " - " + data.name }}
              </v-chip>
              <v-chip
                v-if="data.selectedValue === 'registrationNumber'"
                :input-value="data.registrationNumber"
                close
                @click:close="remove(data.registrationNumber)"
                class="ma-1"
              >
                {{ data.registrationNumber + " - " + data.name }}
              </v-chip>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="recurrenceContainer mt-1" align="right" cols="5">
            <v-select
              label="Reserva Recorrente"
              v-model="isRecurrent"
              :items="isRecurrentOptions"
              :rules="[isNotEmptyRule]"
              v-on:change="changeIsRecurrent"
              outlined
              dense
            >
            </v-select>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col class="mt-1" cols="5">
            <DatePicker
              v-if="!isRecurrent"
              :label="'Data'"
              ref="isolatedBookingDate"
              :data.sync="bookingBatch.startDate"
              :formDate="bookingBatch.startDate"
              :rules="[isNotEmptyRule]"
              :min="tomorrow"
              @date-chosen="bookingBatch.startDate = $event"
              outlined
              dense
            ></DatePicker>
            <DatePicker
              v-else
              :label="'Início da recorrência'"
              ref="recurrenceStart"
              :data.sync="bookingBatch.startDate"
              :formDate="bookingBatch.startDate"
              :rules="[isNotEmptyRule]"
              :min="tomorrow"
              @date-chosen="bookingBatch.startDate = $event"
              @picked="clearEndDate"
              outlined
              dense
            ></DatePicker>
          </v-col>
        </v-row>

        <v-row v-if="isRecurrent">
          <v-col class="col-5">
            <DatePicker
              :label="'Término da recorrência'"
              ref="recurrenceEnd"
              :data.sync="bookingBatch.endDate"
              :formDate="bookingBatch.endDate"
              :rules="[isNotEmptyRule]"
              :disabled="disableEndDate"
              :min="getMinEndDate"
              @date-chosen="bookingBatch.endDate = $event"
              outlined
              dense
            ></DatePicker>
          </v-col>
          <v-col cols="1" />
          <v-col class="col-5">
            <v-select
              label="Recorrência desejada pela"
              v-model="recurrenceWeekDays"
              :items="weekOptions"
              :rules="[isNotEmptyRule]"
              item-text="text"
              item-value="id"
              outlined
              dense
              multiple
              persistent-hint
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-5">
            <v-select
              label="Estado"
              v-model="state"
              :items="statesOptions"
              :rules="[isNotEmptyRule]"
              item-text="nome"
              item-value="sigla"
              @change="getRegionByUF()"
              outlined
              dense
            />
          </v-col>
          <v-col cols="1" />
          <v-col class="col-5">
            <v-select
              label="Direção"
              v-model="bookingBatch.direction"
              :items="lineDirectionOptions"
              :rules="[isNotEmptyRule]"
              @change="getBusLines"
              item-text="text"
              item-value="value"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-5">
            <v-select
              label="Complexo"
              v-model="regionId"
              @change="getSitesByRegionId"
              :items="regions"
              :clearable="true"
              item-text="name"
              item-value="id"
              :rules="[isNotEmptyRule]"
              outlined
              dense
            />
          </v-col>
          <v-col cols="1" />
          <v-col class="col-5">
            <v-select
              label="Site de destino"
              v-model="selectedSite"
              :items="sitesOptions"
              :rules="[isNotEmptyRule]"
              item-text="name"
              item-value="id"
              @change="getBusLines"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <v-select
              label="Linhas Disponíveis"
              :disabled="selectBusLineStatusDisabled"
              v-model="bookingBatch.busLineId"
              :items="busLines"
              :item-text="getRouteName"
              item-value="id"
              :rules="[isNotEmptyRule]"
              @input="setBusLine"
              outlined
              dense
            >
            </v-select>
          </v-col>
          <v-col cols="1" />
          <v-col cols="5">
            <v-text-field
              :label="
                busLineDirection == 'ida' || busLineDirection == 'ambos'
                  ? 'Chegada'
                  : 'Partida'
              "
              v-model="busStop"
              :disabled="true"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="end" align-content="end">
          <v-col align="end">
            <GenericButton
              title="Cancelar"
              icon="mdi-close"
              class="mr-4"
              secondary
              v-on:click="cancel"
              v-if="!loading"
            >
            </GenericButton>
            <GenericButton
              title="Fazer reserva"
              icon="mdi-check"
              primary
              v-on:click="save"
              :disabled="bookButtonStatusDisabled"
              v-if="!loading"
            >
            </GenericButton>
            <template v-if="loading">
              <div class="text-center">
                <p>por favor aguarde, a reserva está sendo realizada!</p>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
            </template>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomAlert from "@/components/CustomAlert";
import { EmployeeService } from "@/services/employee";
import { RouteService } from "@/services/route";
import { BookingService } from "@/services/booking";
import { RegionService } from "@/services/region";
import { isNotEmptyRule } from "@/services/validation";
import DatePicker from "@/components/DatePicker";
import { estados } from "@/store/estados";
import { DateUtils } from "@/utils/dateUtils";
import GenericButton from "../components/GenericButton.vue";

export default {
  name: "BookingBatch",
  components: {
    CustomAlert,
    DatePicker,
    GenericButton,
  },
  data: () => ({
    bookingBatch: {
      addressesIds: [],
      busLineId: "",
      startDate: "",
      endDate: "",
      recurrenceDaysOfWeek: [],
      identifiers: [],
      siteId: "",
      direction: "",
    },
    loading: false,
    showAlert: false,
    showDialog: false,
    bookButtonStatusDisabled: false,
    selectBusLineStatusDisabled: false,
    alertMessageType: "default",
    alertType: "success",
    customMessage: "",
    valid: true,
    listEmployee: [],
    disableEndDate: true,
    busLines: [],
    regions: [],
    regionId: "",
    tomorrow: DateUtils.getTodayDateToISOString(),
    busLineDirection: "",
    busStop: "",
    lineDirectionOptions: [
      { text: "Ida", value: "IDA" },
      { text: "Volta", value: "VOLTA" },
      { text: "Ambos", value: "AMBOS" },
    ],
    recurrenceWeekDays: [],
    weekOptions: [
      { text: "Segunda", id: "monday" },
      { text: "Terça", id: "tuesday" },
      { text: "Quarta", id: "wednesday" },
      { text: "Quinta", id: "thursday" },
      { text: "Sexta", id: "friday" },
      { text: "Sabado", id: "saturday" },
      { text: "Domingo", id: "sunday" },
    ],
    selectedEmployee: "",
    employeeSearch: "",
    selectedEmployeeList: [],
    isRecurrent: "",
    isRecurrentOptions: [
      { text: "Sim", value: true },
      { text: "Não", value: false },
    ],
    state: "",
    statesOptions: estados,
    sitesOptions: [],
    selectedSite: "",
    valueDataCpf: false,
  }),
  computed: {
    getMinEndDate() {
      let newDate;
      let dateParts;
      if (this.bookingBatch.startDate.length > 0) {
        dateParts = this.bookingBatch.startDate.split("/");
        newDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      } else {
        dateParts = this.tomorrow.split("-");
        newDate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);
      }
      newDate.setDate(newDate.getDate() + 1);
      return newDate.toISOString().substring(0, 10);
    },
    getTomorrow() {
      const tomorrow = DateUtils.getTodayDateToISOString();
      return tomorrow;
    },
  },
  methods: {
    isNotEmptyRule,
    isEmployeeListFilled() {
      if (this.selectedEmployeeList.length == 0)
        return "Selecione ao menos um colaborador para fazer o agendamento em lote.";

      return true;
    },
    getRouteName(item) {
      return `${item.code}`;
    },
    getEmployeeRegistrationNumberAndName(item) {
      let searchTerm = this.employeeSearch;
      if (this.employeeSearch && this.employeeSearch.includes(" - ")) {
        searchTerm = this.employeeSearch.split(" - ")[0];
      }
      if (item.cpf != null && item.cpf.includes(searchTerm)) {
        this.valueDataCpf = true;
        return `${item.cpf} - ${item.name}`;
      } else if (
        item.registrationNumber != null &&
        item.registrationNumber.includes(searchTerm)
      ) {
        this.valueDataCpf = false;
        return `${item.registrationNumber} - ${item.name}`;
      } else {
        this.valueDataCpf = false;
        return `${item.registrationNumber} - ${item.name}`;
      }
    },
    thirdPartyNotAllowedAlert() {
      this.alertType = "error";
      this.customMessage =
        "A complexo selecionado não aceita reservas serem vinculadas a terceiros. Remova os colabores terceiros que foram adicionados a lista.";
      this.alertMessageType = "createBookingError";
      this.showAlert = true;
      this.showAlert = true;
      this.showAlert = true;
    },
    otherRegionsNotAllowedAllert() {
      this.alertType = "error";
      this.customMessage =
        "A complexo selecionado não aceita reservas serem vinculadas a colaboradores de outros complexos. Remova os colabores que não pertecem a complexo selecionado.";
      this.alertMessageType = "createBookingError";
      this.showAlert = true;
    },
    validateBooking() {
      let bookRegion = this.regions.filter((x) => x.id == this.regionId)[0];
      let isThirdPartyValidationSuccessful;
      let isOtherRegionsValidationSuccessful;
      let bookingValidationSuccessful;

      if (this.regionId != "") {
        isThirdPartyValidationSuccessful =
          bookRegion.acceptThirdPartyBook ||
          !this.selectedEmployeeList.some((e) => e.isThirdParty === true);

        isOtherRegionsValidationSuccessful =
          bookRegion.acceptOtherRegionsBook ||
          !this.selectedEmployeeList.some((e) => e.region.id != this.regionId);

        bookingValidationSuccessful =
          isThirdPartyValidationSuccessful &&
          isOtherRegionsValidationSuccessful;

        if (bookingValidationSuccessful) {
          this.showAlert = false;
          this.bookButtonStatusDisabled = false;
          this.selectBusLineStatusDisabled = false;
        } else {
          this.selectBusLineStatusDisabled = true;
          this.bookButtonStatusDisabled = true;
          if (!isThirdPartyValidationSuccessful) {
            this.thirdPartyNotAllowedAlert();
          } else if (!isOtherRegionsValidationSuccessful) {
            this.otherRegionsNotAllowedAllert();
          }
        }
      }
    },
    remove(item) {
      const index = this.selectedEmployeeList.findIndex((object) => {
        return object.registrationNumber === item || object.cpf === item;
      });

      this.listEmployee.push(this.selectedEmployee[index]);

      if (index >= 0) {
        this.selectedEmployeeList.splice(index, 1);
      }

      this.validateBooking();
    },
    cleanInputs() {
      this.$refs.form.reset();
      this.regionId = "";
      this.selectedEmployeeList = [];
      this.bookingBatch = {
        addressesIds: [],
        busLineId: "",
        startDate: "",
        endDate: "",
        recurrenceDaysOfWeek: [],
        identifiers: [],
        siteId: "",
        direction: "",
      };
    },
    closeAlert() {
      this.showAlert = false;
    },
    async cancel() {
      this.cleanInputs();
      await this.$router.push(`/home`);
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.bookingBatch.addressesIds = [];
      for (let item of this.selectedEmployeeList) {
        this.bookingBatch.identifiers.push({
          employeeId: item.id,
          addressId: item.address.id,
        });
        this.bookingBatch.addressesIds.push(item.address.id);
      }
      if (!this.isRecurrent) {
        this.bookingBatch.endDate = this.bookingBatch.startDate;
        this.bookingBatch.recurrenceDaysOfWeek = [];
        this.recurrenceWeekDays = [];
      } else {
        this.bookingBatch.recurrenceDaysOfWeek = this.recurrenceWeekDays;
      }
      try {
        this.loading = true;
        await BookingService.postBookingBatch(this.bookingBatch);
        this.alertType = "success";
        this.customMessage = "Operação realizada com sucesso";
        this.showAlert = true;
        this.alertMessageType = "defaultSuccess";
        this.cleanInputs();
        this.loading = false;
      } catch (e) {
        this.customMessage = e.data.message;
        this.alertType = "error";
        this.alertMessageType = "createBookingError";
        this.showAlert = true;
        this.loading = false;
      }
    },
    async setBusLine(item) {
      if (item != null) {
        try {
          const busSelected = await RouteService.getBuslineId(item);
          this.fullOcupation = busSelected.numberOfSeats;
          this.transshipment = busSelected.transshipment;
          this.destinationSite = busSelected.destinationSite;
          this.busLineDirection = busSelected.direction;
          this.busLineDirection = this.busLineDirection.toLowerCase();
          if (
            this.busLineDirection == "ida" ||
            this.busLineDirection == "ambos"
          ) {
            this.busStop = busSelected.finalBusStop.name;
          } else if (this.busLineDirection == "volta") {
            this.busStop = busSelected.initialBusStop.name;
          }
        } catch (e) {
          console.error(e);
        }
      }
    },
    async getEmployees() {
      if (this.employeeSearch != null && this.employeeSearch.length > 3) {
        this.listEmployee = await EmployeeService.getEmployeeSearch(
          this.employeeSearch
        );

        this.listEmployee = this.listEmployee.filter((el) => {
          for (let i = 0; i < this.selectedEmployeeList.length; i++) {
            if (
              this.selectedEmployeeList[i].registrationNumber ===
              el.registrationNumber
            ) {
              return false;
            }
          }
          return true;
        });
      }
    },
    setEmployeeSelectedList() {
      if (
        this.selectedEmployee.registrationNumber != undefined ||
        this.selectedEmployee.cpf != undefined
      ) {
        this.selectedEmployeeList.push({
          ...this.selectedEmployee,
          selectedValue: this.valueDataCpf ? "cpf" : "registrationNumber",
        });
        this.$nextTick(() => {
          this.selectedEmployee = "";
          this.listEmployee = [];
        });
      }
      this.validateBooking();
    },
    changeIsRecurrent() {
      this.bookingBatch.recurrenceDaysOfWeek = [];
      this.bookingBatch.startDate = "";
      if (!this.isRecurrent) {
        this.bookingBatch.endDate = "";
        this.recurrenceWeekDays = [];
      }
    },
    async getBusLines() {
      this.busLines = [];
      this.bookingBatch.busLineId = "";
      this.busStop = "";

      if (this.selectedSite != "") {
        this.busLines = await BookingService.getBookingBusLineBySite(
          this.selectedSite,
          this.bookingBatch.direction
        );
        this.bookingBatch.siteId = this.selectedSite;
        this.validateBooking();
      }
    },
    setTomorrow() {
      this.tomorrow = DateUtils.getTodayDateToISOString();
      this.minEndDate = this.tomorrow;
    },
    setMinEndDate() {
      const dateParts = this.bookingBatch.startDate.split("/");
      const newDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      newDate.setDate(newDate.getDate() + 1);
      this.minEndDate = newDate.toISOString().substring(0, 10);
    },

    clearDates() {
      this.bookingBatch.endDate = "";
      this.$refs.recurrenceEnd.reset();
      this.bookingBatch.startDate = "";
      this.$refs.recurrenceStart.reset();
      this.$refs.isolatedBookingDate.reset();
    },

    clearEndDate() {
      this.setMinEndDate();
      this.disableEndDate = false;
      this.bookingBatch.endDate = "";
      this.$refs.recurrenceEnd.reset();
    },
    async getRegionByUF() {
      this.regions = [];
      this.regionId = "";
      this.bookingBatch.busLineId = "";
      this.bookingBatch.siteId = "";
      this.busLines = [];
      this.busStop = "";
      this.nearestBusStops = [];
      this.nearestBusStop = {};
      this.selectedSite = "";
      this.sitesOptions = [];
      this.regions = await RegionService.getRegionByUf(this.state);
    },
    async getSitesByRegionId() {
      this.busLines = [];
      this.bookingBatch.busLineId = "";
      this.busStop = "";
      this.bookingBatch.siteId = "";
      this.nearestBusStops = [];
      this.nearestBusStop = {};
      this.selectedSite = null;
      this.sitesOptions = [];
      this.sitesOptions = await RegionService.getSitesByRegionId(this.regionId);
    },

    async beforeMount() {
      let getRegions = await RegionService.getAllRegions();
      this.regions = getRegions;
      this.setTomorrow();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";
.col-1 {
  align-self: center;
  margin: 0;
  padding: 0;
}

#selected-employees {
  margin-top: 0;
}

.v-text-field__details {
  font-weight: bold;
}
.recurrenceContainer {
  margin-top: -22px;
}
.v-text-field__details {
  margin-bottom: 0 !important;
}
.cancelButton {
  border-color: $greyVale60;
}
</style>
