<template>
  <v-card color="main-content fill-height py-4 px-6">
    <CustomAlert
      :dialog="showAlert"
      :type="alertType"
      :customMessage="customMessage"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    >
    </CustomAlert>
    <v-card-title class="pl-0">
      <h4>Relatório de ocupação de ônibus</h4>
    </v-card-title>
    <v-card-subtitle class="mb-2 pl-0">
      Use o filtro abaixo para visualizar o histórico das ocupações de ônibus
      selecionadas.
    </v-card-subtitle>
    <div class="form-search">
      <v-card-text class="pa-0">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row justify="space-between" no-gutters>
            <v-col class="col-5 pt-0">
              <DatePicker
                :label="'Início do histórico'"
                ref="startDate"
                :data.sync="request.startDate"
                :rules="[isNotEmptyRule]"
                @picked="clearEndDate"
                @date-chosen="request.startDate = $event"
                outlined
              ></DatePicker>
            </v-col>
            <v-col class="col-5 pt-0">
              <DatePicker
                :label="'Término do histórico'"
                ref="endDate"
                :data.sync="request.endDate"
                :min="minEndDate"
                @change-input="searchHistory"
                @date-chosen="request.endDate = $event"
                :rules="[isNotEmptyRule]"
                outlined
              ></DatePicker>
            </v-col>
          </v-row>
          <v-row justify="space-between" no-gutters>
            <v-col class="col-5 py-0">
              <v-select
                label="Complexo"
                v-model="regionId"
                ref="regionId"
                :items="regions"
                item-text="name"
                item-value="id"
                clearable
                @change="getRouteOptions"
                @input="searchHistory"
                @click:clear="clearInput('regionId')"
                :rules="[isNotEmptyRule]"
                outlined
              ></v-select>
            </v-col>
            <v-col class="col-5 py-0">
              <v-select
                label="Selecione a linha de ônibus desejada"
                v-model="busLineId"
                ref="busLineId"
                :items="routeOptions"
                :item-text="getRouteName"
                :disabled="!regionId"
                item-value="id"
                clearable
                @input="searchHistory"
                @click:clear="clearInput('busLineId')"
                :rules="[isNotEmptyRule]"
                outlined
              >
              </v-select>
            </v-col>
          </v-row>

          <InfoSearch
            :qtdeListInfo="busOccupancyList.length"
            :showResult="showSearchResult"
          ></InfoSearch>

          <v-row no-gutters>
            <v-col class="d-flex justify-end align-center" cols="12">
              <GenericButton
                class="mr-4"
                title="Limpar filtros"
                icon="mdi-filter-off-outline"
                secondary
                @click="clearSearch"
              ></GenericButton>
              <GenericButton
                title="Gerar relatório"
                icon="mdi-cached"
                primary
                @click="downloadCSVData"
                :disabled="!showSearchResult || busOccupancyList.length === 0"
                v-if="!loading"
              ></GenericButton>
              <template v-if="loading">
                <div>
                  <v-progress-circular
                    style="width: 170px"
                    :size="25"
                    :width="2"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import CustomAlert from "@/components/CustomAlert";
import InfoSearch from "../components/InfoSearch.vue";
import GenericButton from "@/components/GenericButton";

import { isNotEmptyRule } from "@/services/validation";
import { BookingService } from "@/services/booking";
import { ReportsService } from "@/services/reports";
import { RegionService } from "@/services/region";

export default {
  name: "BookingReport",
  components: {
    DatePicker,
    CustomAlert,
    InfoSearch,
    GenericButton,
  },
  data: function () {
    return {
      routeOptions: [],
      menu1: false,
      menu2: false,
      request: {
        busLineId: null,
        startDate: null,
        endDate: null,
        regionId: null,
      },
      loading: false,
      valid: false,
      customMessage: "",
      showAlert: false,
      invalidBusLineInput: false,
      showDatable: false,
      busOccupancyList: [],
      selectedBusLinesList: [],
      selectedBusLinesIds: [],
      selectedBusLine: "",
      headers: [
        { text: "Data", value: "date" },
        { text: "Dia da Semana", value: "dateOfWeek" },
        { text: "Complexo", value: "region" },
        { text: "Linha de ônibus", value: "busLineCode" },
        { text: "Itinerário", value: "direction" },
        { text: "Capacidade", value: "capacity" },
        { text: "Reservas ONSITE", value: "reserveOnSite" },
        { text: "Reservas remotas", value: "reserveRemote" },
        { text: "Assentos livres", value: "freeSeats" },
      ],
      regions: [],
      regionId: null,
      busLineId: null,
      alertType: "error",
      disabledBusLine: true,
      minEndDate: "",
      showSearchResult: false,
    };
  },
  methods: {
    isNotEmptyRule,
    getRouteName(item) {
      return `${item.code}`;
    },
    getRouteId(item) {
      this.selectedBusLine = item.id;
      return `${item.id}`;
    },

    async searchHistory() {
      if (this.busLineId == null || this.busLineId == "") {
        this.busOccupancyList = [];
        this.showSearchResult = false;
        return;
      }
      try {
        if (this.regionId == "") {
          this.regionId = null;
        }
        this.request.busLineId = this.selectedBusLinesIds;

        var list = await ReportsService.getBusOccupancy(
          this.request.startDate,
          this.request.endDate,
          this.regionId,
          this.busLineId
        );
        this.busOccupancyList = list;

        list.forEach((booking) => {
          if (booking.direction == "I") booking.direction = "IDA";
          if (booking.direction == "V") booking.direction = "VOLTA";
        });

        this.invalidInput = false;
        this.showSearchResult = true;
      } catch (e) {
        console.log(e);
        this.showSearchResult = false;
      }
    },
    downloadCSVData() {
      this.loading = true;
      let csv =
        "Data;Dia da Semana;Complexo;Linha de onibus;Itinerario;Capacidade;Reservas ONSITE;Reservas remotas;Assentos livres\n";
      this.busOccupancyList.forEach((booking) => {
        csv +=
          this.formatDate(booking.dateReport) +
          ";" +
          booking.dayOfWeek +
          ";" +
          booking.region +
          ";" +
          booking.busLineCode +
          ";" +
          booking.direction +
          ";" +
          booking.capacity +
          ";" +
          booking.reserveOnSite +
          ";" +
          booking.reserveRemote +
          ";" +
          booking.freeSeats;
        csv += "\n";
      });

      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      const time = Date.now();
      const today = new Date(time);

      anchor.download =
        "consultaOcupacoesPorLinhaEm" +
        today.toLocaleDateString().replace("/", "-") +
        ".csv";
      anchor.click();
      this.loading = false;
    },
    formatDate(date) {
      let data = date.split(" ")[0];
      if (!data) return null;
      if (data.indexOf("-") == -1) return date;
      const [year, month, day] = data.split("-");
      return `${day}/${month}/${year}`;
    },

    clearInput(input) {
      this.busLineId = null;
      this.busOccupancyList = [];
      this.showSearchResult = false;

      if (input === "busLineId") {
        this.$refs.busLineId.reset();
      } else {
        this.$refs.regionId.reset();
        this.$refs.busLineId.reset();
      }
    },

    clearSearch() {
      this.request = {
        busLineId: null,
        startDate: null,
        endDate: null,
        regionId: null,
      };

      this.regionId = null;
      this.busLineId = null;
      this.showSearchResult = false;
      this.$refs.form.reset();
    },

    generateReportError(errorMessage) {
      this.alertType = "error";
      this.showAlert = true;
      if (errorMessage != null) {
        this.customMessage = errorMessage;
      } else {
        this.customMessage = "Ocorreu um erro ao submeter a solicitação";
      }
    },

    async getRouteOptions(regionId) {
      this.disabledBusLine = true;
      if (regionId != null) {
        this.routeOptions = await BookingService.getBookingBuslineByRegion(
          regionId,
          false
        );

        if (this.routeOptions.length < 1) {
          this.showAlert = true;
          this.alertType = "warning";
          this.customMessage =
            "Não existem linhas de ônibus cadastradas para essa região";
        } else {
          this.disabledBusLine = false;
        }
      }
    },
    setMinEndDate() {
      let newDate;
      let dateParts;

      dateParts = this.request.startDate.split("/");
      newDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      newDate.setDate(newDate.getDate());
      this.minEndDate = newDate.toISOString().substring(0, 10);
    },
    clearEndDate() {
      this.request.endDate = null;
      this.setMinEndDate();
      this.showSearchResult = false;
      this.busOccupancyList = [];
      this.$refs.endDate.reset();
    },
    closeAlert() {
      this.showAlert = false;
    },
  },
  async beforeMount() {
    let getRegions = await RegionService.getAllRegions();
    this.regions = getRegions;
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;

  .invalid-input {
    color: $redVale;
  }

  .booking-report tr > td,
  .booking-report th {
    border-left: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    align-items: center;
  }
}

::v-deep .v-btn {
  text-transform: none;
}

.form-search {
  padding: 24px 134px 24px 24px;
  border-width: thin;
  border-style: solid;
  border-color: #e0e0e0;
  border-radius: 4px;
}
</style>
