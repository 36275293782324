import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const BusStopFormStore = new Vuex.Store({
  state: {
    busStop: {},
    editMode: false,
  },
  getters: {
    busStop: (state) => {
      return state.busStop;
    },
    editMode: (state) => {
      return state.editMode;
    },
  },
  mutations: {
    setBusStopModel(state, busStop) {
      if (!busStop) {
        state.busStop = {
          id: "",
          name: "",
          address: "",
          reference: "",
          latitude: "",
          longitude: "",
          regionId: "",
          editMode: false,
        };
      } else {
        state.busStop = busStop;
      }
    },
    setEditMode(state, editMode) {
      if (!editMode) {
        state.editMode = false;
      } else {
        state.editMode = editMode;
      }
    },
  },
});
