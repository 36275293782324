// import BookingUsageReport from "../views/BookingUsageReport.vue";
// import InactivityReport from "@/views/InactivityReport";
// import EdlenessReport from "@/views/EdlenessReport";
// import UnreservedUsageReport from "@/views/UnreservedUsageReport";
// import UnusedBookingReport from "@/views/UnusedBookingReport";
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import RegisterEmployees from "../views/RegisterEmployees.vue";
import EmployeeForm from "../views/EmployeeForm.vue";
import BookingVoucher from "../views/BookingVoucher.vue";
import LoginPage from "../views/LoginPage.vue";
import AdminRoutes from "../views/AdminRoutes.vue";
import AdminExpectedEmployees from "../views/AdminExpectedEmployees.vue";
import ExpectedEmployeesForm from "../views/ExpectedEmployeesForm.vue";
import AdminRegions from "../views/AdminRegions.vue";
import RoutesForm from "../views/RoutesForm.vue";
import RegionForm from "../views/RegionForm.vue";
import StartPage from "../views/StartPage.vue";
import BookingReport from "../views/BookingReport.vue";
import ExcludedReservation from "../views/ExcludedReservation.vue";
import MyProfile from "@/views/MyProfile";
import BookingTabs from "@/views/BookingTabs";
import BookingBatch from "@/views/BookingBatch";
import PrivacyTerm from "@/components/PrivacyTerm";
import MyAbsences from "../views/MyAbsences.vue";
import AbsenceReport from "../views/AbsenceReport.vue";
import { EmployeeFormStore } from "@/store/employeeForm";
import InactivityDays from "@/views/InactivityDays";
import RouteDetail from "../views/RouteDetail.vue";
import BusStopsManagement from "@/views/BusStopsManagement";
import BusStopForm from "@/views/BusStopForm";
import BusTypeManagement from "@/views/BusTypeManagement";
import BusTypeEdit from "@/views/BusTypeEdit";
import BusOccupancy from "@/views/BusOccupancy"
import ReservationManagment from "../views/ReservationManagment"
import ReservationManagmentInsertTab from "@/views/ReservationManagmentInsertTab";
import {EmployeeService} from "../services/employee"
import "azure-maps-control/dist/atlas.min.css";
import { LoginService } from "../services/login";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
    meta: { allowedProfiles: ["admin", "supervisor", "employee"] },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { allowedProfiles: ["admin", "supervisor", "employee"] },
    children: [
      {
        path: "/",
        name: "StartPage",
        component: StartPage,
        meta: { allowedProfiles: ["admin", "supervisor", "employee"] },
      },
      {
        path: "/register-employees",
        name: "RegisterEmployees",
        component: RegisterEmployees,
        meta: { allowedProfiles: ["admin", "supervisor"] },
      },
      {
        path: "/employee-form",
        name: "EmployeeForm",
        component: EmployeeForm,
        meta: { allowedProfiles: ["admin", "supervisor", "employee"] },
      },
      {
        path: "/booking-voucher/:id",
        name: "BookingVoucher",
        component: BookingVoucher,
        meta: { allowedProfiles: ["admin", "supervisor", "employee"] },
      },
      {
        path: "/admin-routes",
        name: "adminRoutes",
        component: AdminRoutes,
        meta: { allowedProfiles: ["admin"] },
      },
      {
        path: "/admin-expected-employees/",
        name: "adminExpectedEmployees",
        component: AdminExpectedEmployees,
        meta: { allowedProfiles: ["admin"] },
      },

      {
        path: "/expected-employees-form/:busLineId",
        name: "expectedEmployeesForm",
        component: ExpectedEmployeesForm,
        props: true,
        meta: { allowedProfiles: ["admin"] },
      },
      {
        path: "/route-detail",
        name: "routeDetail",
        component: RouteDetail,
        meta: { allowedProfiles: ["admin"] },
      },
      {
        path: "/admin-regions",
        name: "adminRegions",
        component: AdminRegions,
        meta: { allowedProfiles: ["admin"] },
      },
      {
        path: "/routes-form",
        name: "routesForm",
        component: RoutesForm,
        meta: { allowedProfiles: ["admin"] },
      },
      {
        path: "/region-form",
        name: "regionForm",
        component: RegionForm,
        meta: { allowedProfiles: ["admin"] },
      },
      {
        path: "/booking-report",
        name: "BookingReport",
        component: BookingReport,
        meta: { allowedProfiles: ["admin", "supervisor"] },
      },
      {
        path: "/excluded-reservation",
        name: "ExcludedReservation",
        component: ExcludedReservation,
        meta: { allowedProfiles: ["admin", "supervisor"] },
      },
      // {
      //   path: "/inactivity-report",
      //   name: "InactivityReport",
      //   component: InactivityReport,
      //   meta: { allowedProfiles: ["admin", "supervisor"] },
      // },
      // {
      //   path: "/edleness-report",
      //   name: "EdlenessReport",
      //   component: EdlenessReport,
      //   meta: { allowedProfiles: ["admin", "supervisor"] },
      // },
      {
        path: "/my-profile",
        name: "MyProfile",
        component: MyProfile,
        meta: { allowedProfiles: ["admin", "supervisor", "employee"] },
      },
      {
        path: "/booking",
        name: "BookingTabs",
        component: BookingTabs,
        meta: { allowedProfiles: ["admin", "supervisor", "employee"] },
      },
      {
        path: "/my-absences",
        name: "MyAbsences",
        component: MyAbsences,
        meta: { allowedProfiles: ["admin", "supervisor", "employee"] },
      },
      {
        path: "/absence-report",
        name: "AbsenceReport",
        component: AbsenceReport,
        meta: { allowedProfiles: ["admin", "supervisor"] },
      },
      {
        path: "/booking-batch",
        name: "BookingBatch",
        component: BookingBatch,
        meta: { allowedProfiles: ["admin", "supervisor"] },
      },
      {
        path: "/privacy-term",
        name: "PrivacyTerm",
        component: PrivacyTerm,
        meta: { allowedProfiles: ["admin", "supervisor", "employee"] },
      },
      {
        path: "/reservation-managment-insert-tab",
        name: "ReservationManagmentInsertTab",
        component: ReservationManagmentInsertTab,
        meta: { allowedProfiles: ["admin"] },
      },
      {
        path: "/reservation-management",
        name: "ReservationManagment",
        component: ReservationManagment,
        meta: { allowedProfiles: ["admin"] },
      },
      // {
      //   path: "/booking-usage-report",
      //   name: "BookingUsageReport",
      //   component: BookingUsageReport,
      //   meta: { allowedProfiles: ["admin"] },
      // },
      // {
      //   path: "/unreserved-usage-report",
      //   name: "UnreservedUsageReport",
      //   component: UnreservedUsageReport,
      //   meta: { allowedProfiles: ["admin"] },
      // },
      // {
      //   path: "/unused-booking-report",
      //   name: "UnusedBookingReport",
      //   component: UnusedBookingReport,
      //   meta: { allowedProfiles: ["admin"] },
      // },
      {
        path: "/inactivity-days",
        name: "InactivityDays",
        component: InactivityDays,
        meta: { allowedProfiles: ["admin"] },
      },
      {
        path: "/bus-stops-management",
        name: "BusStopsManagement",
        component: BusStopsManagement,
        meta: { allowedProfiles: ["admin"] },
      },
      {
        path: "/bus-stop-form",
        name: "BusStopForm",
        component: BusStopForm,
        meta: { allowedProfiles: ["admin"] },
      },
      {
        path: "/bus-type-management",
        name: "BusTypeManagement",
        component: BusTypeManagement,
        meta: { allowedProfiles: ["admin"] },
      },
      {
        path: "/bus-type-edit",
        name: "BusTypeEdit",
        component: BusTypeEdit,
        meta: { allowedProfiles: ["admin"] },
      },
      {
        path: "/bus-occupancy",
        name: "BusOccupancy",
        component: BusOccupancy,
        meta: { allowedProfiles: ["admin", "supervisor"] },
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {  
  if (    
    localStorage.getItem("idToken") === null ||
    localStorage.getItem("idToken") === undefined ||
    localStorage.getItem("idToken").length === 0
  ) {
    if (to.name !== "LoginPage") {
      next({ name: "LoginPage" });
    } else {
      next();
    }
  } else {
    if (!localStorage?.getItem("privacyTerm")) {
      if (to.name != "PrivacyTerm") {
        next({ name: "PrivacyTerm" });
      } else {
        next();
      }

    }else {
      if(!to.meta.allowedProfiles) {
        next({ name: 'StartPage' })
      }else if(
        localStorage?.idToken?.toString() === undefined ||
        localStorage?.idToken?.toString().length === 0) 
      {
        next({ name: 'StartPage' })
      }else if(to.name === 'LoginPage') {
        next({ name: 'StartPage' })
      }else {
        if(
            (to.name != "LoginPage" && to.name != "StartPage" && to.name != "employee") && 
            (
              localStorage?.idToken?.toString() === undefined ||
              localStorage?.idToken?.toString().length === 0 
            )
          ) {
          
          console.log("Interceptor:  "+to.name)

          localStorage.removeItem("auth");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("name");
          localStorage.removeItem("registrationNumber");
          localStorage.removeItem("employeeShift")
          next({ name: "LoginPage" });      
        }else if (localStorage?.registrationNumber?.toString() === undefined || 
                  localStorage?.registrationNumber?.toString().length === 0 ) {
          let employee;
          let userInfoId = localStorage.getItem("userId");
          if (userInfoId) {
            try {
              employee = await EmployeeService.getEmployee(userInfoId);   
            }
            catch (e) {
              if (e.status !== 404) 
                console.log(e);
            }
          }    

          if (employee) {
            if(employee.cpf == null || employee.cpf == '' || employee.cpf == undefined) {
              if(to.name !== 'EmployeeForm'){
                next({ name: 'EmployeeForm' })
              }
              await EmployeeFormStore.commit("setEmployeeModel", employee);
              await EmployeeFormStore.commit("setOperation", 'edit');
              await EmployeeFormStore.commit("setMandatory", true);
              next();
            } else {
              localStorage.setItem('registrationNumber', employee.cpf);
              localStorage.setItem('name', employee.name);                   
              
              if (employee.shift == "ON_SITE") {
                localStorage.setItem('employeeShift', "ON SITE");
              } else {
                localStorage.setItem('employeeShift', "REMOTE");               
              }              
              next();
            }
          } 
          else {
            await LoginService.getPermission();
            if(to.name !== 'EmployeeForm') {
              next({ name: 'EmployeeForm' })
            } else {
              await EmployeeFormStore.commit("setEmployeeModel", null);
              await EmployeeFormStore.commit("setOperation", 'create');
              await EmployeeFormStore.commit("setMandatory", true);
              next()
            }
          }
        }
        else {
          next() 
        }
      }
    }
  }
});

export default router;
