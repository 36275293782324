<template>
  <v-card class="main-content pa-4 fill-height">
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      :customMessage="customMessage"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>
    <v-row>
      <v-col>
        <v-card-title>
          <h4>Administrar linhas de ônibus</h4>
        </v-card-title>
        <v-card-subtitle class="mb-1">
          Aqui você pode visualizar as linhas existentes, subir nova linha ou
          excluir uma atual
        </v-card-subtitle>
      </v-col>
      <v-col class="mt-3 mr-3" align="right">
        <GenericButton
          title="Adicionar linha de ônibus"
          icon="mdi-text-box-plus-outline"
          primary
          v-on:click="insertRoute()"
        ></GenericButton>
      </v-col>
    </v-row>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col>
            <v-data-table
              class="elevation-1 col-12"
              item-key="id"
              v-model="selectRoute"
              show-expand
              :search="search"
              :headers="headers"
              :custom-filter="getAllRoutes"
              :items="busLines"
              :loading="loading"
              :options.sync="options"
              :server-items-length="totalRoutes"
              :footer-props="{
                'items-per-page-options': [10],
              }"
            >
              <template v-slot:top>
                <v-row class="mb-2">
                  <v-col class="col-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="search"
                      label="Linha de ônibus"
                      class="mt-1"
                      @keyup.enter="getAllRoutes"
                      :clearable="true"
                    ></v-text-field>
                  </v-col>
                  <v-col class="col-2">
                    <v-select
                      class="mt-1"
                      label="Estado"
                      v-model="state"
                      :items="statesOptions"
                      item-text="nome"
                      item-value="sigla"
                      @change="getRegionByUF"
                      @keyup.enter="getAllRoutes"
                      :clearable="true"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col class="col-2">
                    <v-select
                      outlined
                      dense
                      label="Complexo"
                      v-model="regionId"
                      :items="regions"
                      :clearable="true"
                      item-text="name"
                      item-value="id"
                      class="mt-1"
                      :disabled="!state"
                      @change="loadSites"
                      @keyup.enter="getAllRoutes"
                    />
                  </v-col>
                  <v-col class="col-2">
                    <v-combobox
                      id="comboboxSite"
                      v-model="selectedSite"
                      label="Site de destino"
                      :items="sites"
                      item-text="name"
                      class="mt-1"
                      outlined
                      dense
                      :disabled="!regionId"
                      @change="setSiteList"
                    />
                  </v-col>
                  <v-col class="mt-1">
                    <GenericButton
                      class="mr-4"
                      title="Limpar"
                      icon="mdi-filter-off-outline"
                      tertiary
                      v-on:click="clearFilter"
                    ></GenericButton>
                    <GenericButton
                      title="Buscar"
                      icon="mdi-magnify"
                      secondary
                      v-on:click="getAllRoutes"
                    >
                    </GenericButton>
                  </v-col>
                </v-row>
                <v-row class="mt-0 mb-2">
                  <v-col v-if="selectedSiteList.length > 0" class="pt-0 pb-0">
                    <span v-for="data in selectedSiteList" v-bind:key="data.id">
                      <v-chip
                        :input-value="data.id"
                        close
                        @click:close="remove(data.id)"
                        class="mr-2 mb-2"
                      >
                        {{ data.name }}
                      </v-chip>
                    </span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`expanded-item`]="{ item }">
                <td class="pt-1 pb-1 px-8" :colspan="1"></td>
                <td v-if="item.initialBusStop != null" class="pt-1 pb-1 px-8">
                  <v-row>{{ "Partida: " }}</v-row>
                  <v-row>{{ item.initialBusStop.name }}</v-row>
                </td>

                <td class="pt-1 pb-1 px-8" :colspan="1">
                  <v-row>{{ "Tipo de Ônibus: " }}</v-row>
                  <v-row>{{ item.busType.name }}</v-row>
                </td>
                <td class="pt-1 pb-1 px-8" :colspan="1">
                  <v-row>{{ "Site de Destino: " }}</v-row>
                  <v-row>{{ item.destinationSite }}</v-row>
                </td>
                <td class="pt-1 pb-1 px-8" :colspan="4">
                  <v-row>Possui Baldeio:</v-row>
                  <v-row>{{ item.transshipment ? "Sim" : "Não" }}</v-row>
                </td>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  @click="editRoute(item, true)"
                  >mdi-pencil-outline</v-icon
                >

                <v-icon
                  class="mr-2"
                  small
                  @click="openDialog(item.id)"
                  color="primary"
                  >mdi-list-box
                </v-icon>

                <v-tooltip v-if="!item.active" top max-width="30%">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" v-bind="attrs" v-on="on" small>
                      mdi-information
                    </v-icon>
                  </template>
                  <span>Rota Desabilitada</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <GenericDialog
              close
              :visible="showDialog"
              v-on:close-dialog="closeDialog"
              width="600px"
            >
              <template v-slot:header>
                <v-card-title
                  class="px-0 py-0 ml-1 mr-0"
                  style="color: #393939; font-size: 24px"
                >
                  Pontos de Ônibus
                </v-card-title>
              </template>

              <template v-slot:body>
                <div style="width: 576px">
                  <v-responsive
                    class="overflow-y-auto"
                    color="primary"
                    max-height="calc(90vh - 200px)"
                  >
                    <v-data-table
                      :headers="headersbuslineStops"
                      class="elevation-0 mt-6"
                      :items="buslineStops"
                      :loading="loadingBusStops"
                      :items-per-page="10"
                    >
                    </v-data-table>
                    <div style="width: 22px"></div>
                  </v-responsive>
                </div>
              </template>
            </GenericDialog>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomAlert from "@/components/CustomAlert";
import { RouteService } from "@/services/route";
import { RegionService } from "@/services/region";
import { isNotEmptyRule } from "@/services/validation";
import GenericDialog from "@/components/GenericDialog";
import { RouteFormStore } from "@/store/routeForm";
import { estados } from "@/store/estados";
import GenericButton from "../components/GenericButton.vue";
import { PermissionService } from "../services/permission";

export default {
  name: "adminRoutes",
  components: {
    CustomAlert,
    GenericDialog,
    GenericButton,
  },
  store: RouteFormStore,
  data: () => ({
    request: {
      stringKmz: "",
      departureTime: "",
      arrivalTime: "",
      busType: "",
      direction: "",
      transshipment: false,
      destinationSite: "",
      region: {},
    },
    search: "",
    regionSearch: "",
    showAlert: false,
    showDialog: false,
    showDetail: false,
    alertMessageType: "default",
    regionId: "",
    alertType: "success",
    deleteMultipleEmployeesBtnLabel: "Remover Rota",
    valid: true,
    file: {},
    fileWasChosen: false,
    customMessage: "",
    headersbuslineStops: [
      { text: "Nome", value: "name", sortable: false },
      { text: "Sentido", value: "direction", sortable: false },
      { text: "Referência", value: "reference", sortable: false },
    ],
    headers: [
      { text: "", value: "actions", sortable: false },
      { text: "Linha de ônibus", value: "code" },
      { text: "Itinerário", value: "direction" },
      //Adequação tecnica para colocar os valores do detalhes em baixo do headers
      { text: "Complexo", value: "region.name" },
      { text: "Site", value: "site.name", sortable: false },
      { text: "", value: "", sortable: false },
      { text: "", value: "", sortable: false },
      { text: "", value: "data-table-expand", sortable: false },
    ],
    busLines: [],
    selectRoute: [],
    busTypeOptions: [
      { text: "Micro Ônibus", value: "micro" },
      { text: "Ônibus", value: "bus" },
    ],
    lineDirectionOptions: [
      { text: "IDA", value: "IDA" },
      { text: "VOLTA", value: "VOLTA" },
    ],
    uploading: false,
    loading: true,
    options: {},
    totalRoutes: 0,
    regions: [],
    buslineStops: [],
    state: "",
    statesOptions: estados,
    sites: [],
    selectedSiteList: [],
    selectedSiteIds: [],
    selectedSite: "",
    loadingBusStops: false,
  }),
  watch: {
    options: {
      handler() {
        this.getAllRoutes();
      },
      deep: true,
    },
  },
  methods: {
    isNotEmptyRule,

    clearFilter() {
      this.search = "";
      this.regionId = "";
      this.state = "";
      this.selectedSite = "";

      (this.selectedSiteIds = []),
        (this.selectedSiteList = []),
        this.getAllRoutes();
    },
    async openBookingStops(busLineId) {
      this.loadingBusStops = true;
      this.buslineStops = [];
      this.buslineStops = await RouteService.getBuslineStops(busLineId);
      this.loadingBusStops = false;

      for (const busStop of this.buslineStops) {
        if (busStop.direction == "I") {
          busStop.direction = "Ida";
        } else {
          busStop.direction = "Volta";
        }
      }
      return this.buslineStops;
    },
    openDialog(item) {
      this.showDialog = true;
      this.openBookingStops(item);
    },
    closeDialog() {
      this.showDialog = false;
    },
    sendFile: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.uploading = true;
      try {
        this.request.stringKmz = await this.toBase64(this.file);
        this.request.arrivalTime = this.request.departureTime;
        await RouteService.manageBusLine(this.request);
        await this.uploadSuccess();
        this.getAllRoutes();
        this.resetInputs();
      } catch (error) {
        this.uploadError(error);
      }
      this.uploading = false;
    },
    resetInputs() {
      this.file = null;
      this.fileWasChosen = false;
      this.request = {
        stringKmz: "",
        departureTime: "",
        arrivalTime: "",
        busType: "",
        direction: "",
      };
      this.$refs.time.clearTime();
    },
    async uploadSuccess() {
      this.setAlertProps(true, "uploadSuccess");
    },
    uploadError(text) {
      this.setAlertProps(false, "uploadKmzError");
      window.alert(text.data);
    },
    setAlertProps(isSuccess, type) {
      if (isSuccess) {
        this.alertType = "success";
      } else {
        this.alertType = "error";
      }
      this.showAlert = true;
      this.alertMessageType = type;
    },
    closeAlert() {
      this.showAlert = false;
    },
    async deleteSuccess() {
      this.setAlertProps(true, "deleteSuccess");
    },
    deleteError() {
      this.setAlertProps(false, "deleteError");
    },
    async deleteRoutes(id) {
      try {
        await RouteService.deleteRoute(id);
        this.deleteSuccess();
        this.getAllRoutes();
      } catch {
        this.deleteError();
      }
    },
    editRoute(route, editModel) {
      RouteFormStore.commit("setRouteModel", route);
      RouteFormStore.commit("setEditMode", editModel);
      this.$router.push("/route-detail");
    },
    insertRoute() {
      RouteFormStore.commit("setRouteModel", {});
      RouteFormStore.commit("setEditMode", false);
      this.$router.push("/route-detail");
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
      });
    },
    async getAllRoutes() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;

      if (this.regionId == null) {
        this.regionId = "";
      }
      if (this.search == null) {
        this.search = "";
      }
      if (this.state == null) {
        this.state = "";
      }

      let response = await RouteService.getAllRoutes(
        page - 1,
        itemsPerPage,
        this.search,
        this.regionId,
        this.state,
        this.selectedSiteIds
      );

      this.busLines = response.content;
      this.totalRoutes = response.totalElements;
      this.loading = false;
    },
    async getRegionByUF() {
      this.clearInputsWhenChangeState();
      this.regions = await RegionService.getRegionByUf(this.state);
    },
    async loadSites() {
      try {
        this.selectedSiteIds = [];
        this.selectedSiteList = [];
        this.sites = await RegionService.getSitesByRegionId(this.regionId);
      } catch {
        this.selectedSiteList = [];
        this.selectedSiteIds = [];
        this.sites = [];
      }
    },
    setSiteList() {
      if (this.selectedSiteList.includes(this.selectedSite)) {
        this.invalidBusLineInput = true;
        this.generateReportError("Esse site já foi selecionada");
      } else {
        this.invalidBusLineInput = false;
        if (
          this.selectedSite.id !== "" &&
          this.selectedSite.id !== null &&
          this.selectedSite.name !== ""
        ) {
          this.selectedSiteList.push(this.selectedSite);
          this.selectedSiteIds.push(this.selectedSite.id);
          this.$nextTick(() => {
            this.selectedSite = "";
          });
        }
      }
    },
    remove(item) {
      const index = this.selectedSiteList.findIndex((object) => {
        return object.id === item;
      });
      if (index >= 0) {
        this.selectedSiteList.splice(index, 1);
        this.selectedSiteIds.splice(index, 1);
      }
    },
    generateReportError(errorMessage) {
      this.alertType = "error";
      this.showAlert = true;
      if (errorMessage != null) {
        this.customMessage = errorMessage;
      } else {
        this.customMessage = "Ocorreu um erro ao submeter a solicitação";
      }
    },
    clearInputsWhenChangeState() {
      this.regionId = "";
      this.regions = [];
      this.selectedSite = "";
      this.selectedSiteList = [];
    },
  },
  async beforeMount() {
    let getRegions = await RegionService.getAllRegions();
    this.regions = getRegions;
    this.permission = PermissionService.getPermission();
    if (this.permission !== "admin") {
      this.$router.push("home");
    }

    if (this.$route.query.showAlert) {
      this.setAlertProps(true, "defaultSuccess");
    }
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";
.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;

  .v-card-subtitle {
    color: $greyVale40;
  }

  .v-card-text {
    text-align: center;
  }
}

.v-btn {
  text-transform: none;
}

.v-data-table {
  tbody {
    color: $greyVale;
    td {
      background-color: white;
    }
    .v-icon {
      color: $greyVale;
    }
  }
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.04);
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(0, 0, 0, 0.04);
}

::v-deep .theme--light.v-data-table .v-data-footer {
  border-top: thin solid rgba(0, 0, 0, 0.04);
}

::v-deep
  .v-dialog
  > .v-dialog--active
  > .v-dialog__content
  > .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(0, 0, 0, 0.04);
}

::v-deep
  .v-dialog
  > .v-dialog__content
  > .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child {
  border-bottom: thin solid rgba(0, 0, 0, 0.04);
}

::v-deep
  .v-dialog__content
  > .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.04);
}

::v-deep .v-dialog > .theme--light.v-data-table .v-data-footer {
  border-top: thin solid rgba(0, 0, 0, 0.04);
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6f6f6f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6f6f6f;
}

::v-deep .v-responsive__content {
  display: flex;
  flex-direction: row;
}

::v-deep .v-chip.v-size--default {
  font-size: 12px;
  color: #6f6f6f;
  background: #f4f4f4;
}

::v-deep .v-icon.v-icon.v-icon--link {
  color: #6f6f6f;
}
::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
}
</style>
