<template>
  <v-card class="main-content pa-4 fill-height">
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      :customMessage="customMessage"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>
    <v-card elevation="0">
      <v-card-title class="title-container">
        <b v-if="operation === 'edit'">Editar passageiro</b>
        <b v-else>Novo passageiro</b>

        <span class="d-none d-md-inline-flex justify-end">
          <GenericButton
            class="mr-4"
            title="Cancelar"
            icon="mdi-close"
            secondary
            to="/register-employees"
            v-if="!loading"
          ></GenericButton>
          <GenericButton
            title="Salvar"
            icon="mdi-content-save-outline"
            primary
            :disabled="!isEdited || !isValid"
            @click="editEmployee"
            v-if="!loading && operation === 'edit'"
          ></GenericButton>
          <GenericButton
            title="Salvar"
            icon="mdi-content-save-outline"
            primary
            :disabled="!isEdited || !isValid"
            @click="save"
            v-if="!loading && operation !== 'edit'"
          ></GenericButton>
          <template v-if="loading">
            <div class="text-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </template>
        </span>
      </v-card-title>

      <v-card-subtitle v-if="operation === 'edit'">
        Aqui você poderá gerenciar as informações a seguir.
      </v-card-subtitle>

      <v-card-subtitle v-else>
        Aqui você poderá cadastrar novos passageiros.
      </v-card-subtitle>
    </v-card>

    <v-card outlined elevation="0" class="pa-6 pt-0 ma-2">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row no-gutters class="pt-6">
          <v-col cols="12" sm="5">
            <v-text-field
              label="Nome do passageiro"
              v-model="employee.name"
              :rules="[isNotEmptyRule]"
              placeholder="Insira..."
              outlined
              @input="fieldValueHasChanged"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="5" class="ml-sm-13 pl-sm-13">
            <v-text-field
              label="E-mail do passageiro"
              placeholder="Insira..."
              v-model="employee.email"
              :rules="employee.email != '' && employee.email != '-' ? [validateEmail] : []"
              outlined
              @input="fieldValueHasChanged"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="5">
            <v-select
              label="Funcionario Vale"
              placeholder="Selecione..."
              v-model="employee.isThirdParty"
              :items="isThirdPartyOptions"
              item-text="text"
              item-value="value"
              :rules="[isNotEmptyRule]"
              outlined
              @input="fieldValueHasChanged"
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="5" class="ml-sm-13 pl-sm-13">
            <v-text-field
              label="Matricula Vale"
              placeholder="Insira..."
              v-model="employee.registrationNumber"
              maxlength="11"
              outlined
              @input="fieldValueHasChanged"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="5">
            <v-text-field
              label="CPF"
              placeholder="Insira..."
              v-model="employee.cpf"
              :rules="[validateCPF, isNotEmptyRule]"
              v-mask="'###.###.###-##'"
              outlined
              @input="fieldValueHasChanged"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="5" class="ml-sm-13 pl-sm-13">
            <v-select
              label="Categoria"
              placeholder="Selecione..."
              v-model="employee.category"
              :items="shifts"
              item-text="text"
              item-value="value"
              :rules="[isNotEmptyRule]"
              outlined
              @input="fieldValueHasChanged"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="5">
            <v-select
              label="Regime de trabalho"
              placeholder="Selecione..."
              v-model="employee.shift"
              :items="workTypes"
              item-text="text"
              item-value="value"
              :rules="[isNotEmptyRule]"
              outlined
              @input="fieldValueHasChanged"
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="5" class="ml-sm-13 pl-sm-13">
            <v-select
              label="Complexo"
              placeholder="Selecione..."
              v-model="employee.region"
              :items="regions"
              item-text="name"
              :rules="[isNotEmptyRule]"
              return-object
              outlined
              @input="fieldValueHasChanged"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="5">
            <v-select
              label="Assento preferencial"
              placeholder="Selecione..."
              v-model="employee.preferential"
              :items="preferentialOptions"
              item-text="text"
              item-value="value"
              :rules="[isNotEmptyRule]"
              outlined
              @input="fieldValueHasChanged"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-col class="content-address">
          <AddressDialog
            :address="employee.address"
            :registration-number="employee.registrationNumber"
            @address-updated="fieldValueHasChanged"
          ></AddressDialog>
        </v-col>
      </v-form>
    </v-card>

    <div class="d-xl-none d-lg-none d-md-none mt-6">
      <template v-if="loading">
        <div class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </template>
      <GenericButton
        class="mb-4"
        title="Cancelar"
        icon="mdi-close"
        width="97.5%"
        to="/register-employees"
        secondary
        v-if="!loading"
      ></GenericButton>
      <GenericButton
        title="Salvar"
        icon="mdi-content-save-outline"
        primary
        width="97.5%"
        :disabled="!isEdited || !isValid"
        @click="editEmployee"
        v-if="!loading"
      ></GenericButton>
    </div>
  </v-card>
</template>

<script>
import { EmployeeService } from "@/services/employee";
import { AddressService } from "@/services/address";
import {
  isNotEmptyRule,
  validateEmail,
  validateCPF,
} from "@/services/validation";
import AddressDialog from "@/components/AddressDialog";
import CustomAlert from "@/components/CustomAlert";
import { EmployeeFormStore } from "@/store/employeeForm";
import { RegionService } from "@/services/region";
import GenericButton from "../components/GenericButton.vue";
import { PermissionService } from "../services/permission";

export default {
  name: "EmployeeForm",
  components: {
    AddressDialog,
    CustomAlert,
    GenericButton,
  },
  store: EmployeeFormStore,
  data: () => ({
    permission: "",
    operation: "",
    mandatory: false,
    employee: {
      registrationNumber: "",
      name: "",
      email: "",
      cpf: "",
      region: "",
      category: "",
      shift: "",
      address: {},
    },
    isValid: false,
    isEdited: false,
    valid: false,
    loading: false,
    customMessage: "",
    showAlert: false,
    alertMessageType: "default",
    alertType: "success",
    dialog: false,
    regions: [],
    isThirdPartyOptions: [
      { text: "Sim", value: false },
      { text: "Não", value: true },
    ],
    workTypes: [
      { text: "Remoto", value: "REMOTE" },
      { text: "On Site", value: "ON_SITE" },
    ],
    shifts: [
      { text: "Adm", value: "ADM" },
      { text: "Turno", value: "TURNO" },
    ],
    preferentialOptions: [
      { text: "Sim", value: true },
      { text: "Não", value: false },
    ],
  }),
  methods: {
    isNotEmptyRule,
    validateEmail,
    validateCPF,
    save: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        this.loading = true;
        if (this.operation === "create") {
          await this.createEmployee();
        } else if (this.operation === "edit") {
          await this.editEmployee();
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.status != 500) {
          this.employeeOperationErrorCustom(error.data);
        }
        this.employeeOperationError();
      }
    },
    createEmployee: async function () {
      this.employee.address.id = null;
      if (this.employee.registrationNumber.length <= 11) {
        let employeeRet = await EmployeeService.postEmployee(this.employee);
        if (this.mandatory) {
          localStorage.setItem(
            "registrationNumber",
            employeeRet.registrationNumber.toUpperCase()
          );
          window.location.href = "/";
        }
        this.createEmployeeSuccess();
      } else {
        this.adressMandatory();
      }
    },
    editEmployee: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;

      try {
        if(this.employee.registrationNumber === '-'){
          this.employee.registrationNumber = ""
        }
        await EmployeeService.updateEmployee(this.employee.id, this.employee);
        await this.saveNewAddress(this.employee.address);
        this.editEmployeeSuccess();
      } catch (error) {
        if (error.status != 500) {
          this.employeeOperationErrorCustom(error.data);
        }
        this.employeeOperationError();
      } finally {
        this.loading = false;
        this.isEdited = false;
      }
    },
    async saveNewAddress(address) {
      await AddressService.postAddress(
        this.removeCPFMask(this.employee.cpf),
        address
      );
    },
    fieldValueHasChanged() {
      if (this.operation == "edit") {
        const {
          name,
          email,
          isThirdParty,
          registrationNumber,
          cpf,
          category,
          shift,
          region,
          preferential,
        } = this.employee;
        const {
          name: defaultName,
          email: defaultEmail,
          isThirdParty: defaultIsThirdParty,
          registrationNumber: defaultRegistrationNumber,
          cpf: defaultCpf,
          category: defaultCategory,
          shift: defaultShift,
          region: defaultRegion,
          preferential: defaultPreferential,
          address: defaultAddress,
        } = this.employeeDefault;

        let hasAddressChanged;

        const hasNameChanged =
          defaultName.localeCompare(name, { sensitivity: "accent" }) !== 0;
        const hasEmailChanged =
          defaultEmail?.localeCompare(email.replace("-",""), { sensitivity: "accent" }) !== 0;
        const hasIsThirdPartyChanged = defaultIsThirdParty !== isThirdParty;
        const hasRegistrationNumberChanged =
          defaultRegistrationNumber.localeCompare(registrationNumber, {
            sensitivity: "accent",
          }) !== 0;
        const hasCpfChanged =
          defaultCpf?.localeCompare(
            this.removeCPFMask(cpf),
            { sensitivity: "accent" }
          ) !== 0;
        const hasCategoryChanged =
          defaultCategory.localeCompare(category, { sensitivity: "accent" }) !==
          0;
        const hasShiftChanged =
          defaultShift.localeCompare(shift, { sensitivity: "accent" }) !== 0;
        const hasRegionChanged =
          defaultRegion.id?.localeCompare(region.id, {
            sensitivity: "accent",
          }) !== 0;
        const hasPreferentialChanged = preferential !== defaultPreferential;

        if (this.oldAddress == undefined) {
          if (this.employee.address != null) {
            hasAddressChanged = true;
          } else {
            hasAddressChanged = false;
          }
        } else {
          hasAddressChanged =
            defaultAddress.formattedAddress.localeCompare(
              this.oldAddress.formattedAddress,
              { sensitivity: "accent" }
            ) !== 0;
        }

        this.isEdited =
          hasNameChanged ||
          hasEmailChanged ||
          hasIsThirdPartyChanged ||
          hasRegistrationNumberChanged ||
          hasCpfChanged ||
          hasCategoryChanged ||
          hasShiftChanged ||
          hasRegionChanged ||
          hasPreferentialChanged ||
          hasAddressChanged;
      } else {
        this.isEdited = true;
      }
      this.isAllMandatoryFieldsFilledIn();
    },
    isAllMandatoryFieldsFilledIn() {
      const {
        name,
        email,
        cpf,
        isThirdParty,
        category,
        shift,
        region,
        preferential,
        address,
      } = this.employee;

      const addressesExist = address?.formattedAddress != undefined;
      const isNameValid = name != null && name !== "";
      
      const isEmailValid =
        (typeof validateEmail(email) == "string" ? false : true) ||
        email == "" ||
        email == null;
      const isCpfValid =
        cpf != null &&
        cpf !== "" &&
        (typeof validateCPF(cpf) == "string" ? false : true);
      const isThirdPartyValid = isThirdParty !== null && isThirdParty !== "";
      const isCategoryValid = category != null && category !== "";
      const isShiftValid = shift != null && shift !== "";
      const isRegionValid = region != null && region !== "";
      const isPreferentialValid = preferential != null;

      this.isValid =
        isNameValid &&
        isEmailValid &&
        isCpfValid &&
        isThirdPartyValid &&
        isCategoryValid &&
        isShiftValid &&
        isRegionValid &&
        isPreferentialValid &&
        addressesExist;
    },

    createEmployeeSuccess() {
      this.alertType = "success";
      this.alertMessageType = "createEmployeeSuccess";
      this.showAlert = true;
      this.resetEmployeeModel();
      this.$router.push({
        path: "register-employees",
        query: { showAlert: true },
      });
    },
    editEmployeeSuccess() {
      this.alertType = "success";
      this.alertMessageType = "editEmployeeSuccess";
      this.showAlert = true;
      this.resetEmployeeModel();
      if (this.mandatory) {
        this.$router.push(`home`);
      } else {
        this.$router.push({
          path: "register-employees",
          query: { showAlert: true },
        });
      }
    },
    employeeOperationErrorCustom(errorMessage) {
      this.alertType = "error";
      this.customMessage = errorMessage.message;
      this.showAlert = true;
    },
    employeeOperationError() {
      this.alertType = "error";
      this.alertMessageType = "employeeOperationError";
      this.showAlert = true;
      this.resetEmployeeModel();
    },
    adressMandatory() {
      this.alertType = "warning";
      this.alertMessageType = "AdressRegisterMissing";
      this.showAlert = true;
    },
    wrongRegistrationNumber() {
      this.alertType = "warning";
      this.alertMessageType = "wrongRegistrationNumber";
      this.showAlert = true;
    },
    async resetEmployeeModel() {
      await EmployeeFormStore.commit("setEmployeeModel", null);
      await EmployeeFormStore.commit("setOperation", "create");
      await EmployeeFormStore.commit("setMandatory", false);
    },
    closeAlert() {
      this.showAlert = false;
    },
    resetForm() {
      this.$refs.form.reset();
      this.employee = {
        registrationNumber: "",
        name: "",
        email: "",
        cpf: "",
        region: "",
        category: "",
        shift: "",
        address: {},
      };
    },
    removeCPFMask(cpf) {
      return cpf.replaceAll(".","").replace("-","")
    }
  },
  async beforeMount() {
    this.permission = PermissionService.getPermission();
    if (this.permission !== "admin" && this.permission !== "supervisor") {
      this.$router.push("home");
    }
    let getRegions = await RegionService.getAllRegions();
    this.regions = getRegions;
    this.employee = EmployeeFormStore.getters.employee;
    this.operation = EmployeeFormStore.getters.operation;
    this.mandatory = EmployeeFormStore.getters.mandatory;

    if (!(this.employee.address === null)) {
      this.oldAddress = Object.assign({}, this.employee.address);
    } else if (this.operation === "edit") {
      let address = {
        id: null,
        formattedAddress: "",
        givenAddress: "",
        postalCode: "",
        complement: "",
        latitude: 0,
        longitude: 0,
      };
      this.employee.address = address;
      this.oldAddress = Object.assign({}, this.employee.address);
      this.employee.address = {};
    }
    this.employee.email = this.employee.email == "-" ?  "" : this.employee.email;
    this.employee.registrationNumber = this.employee.registrationNumber == "-" ? "" : this.employee.registrationNumber;
    this.employeeDefault = Object.assign({}, this.employee);

    if (this.operation === "create") {
      this.resetForm();
    }
    let shiftDefault = this.workTypes[0];
    if (this.mandatory) {
      this.employee.name = localStorage.getItem("name");
      this.employee.registrationNumber = localStorage.getItem("userId");
      this.employee.shift = shiftDefault.value;
      let isThirdParty = !this.employee.registrationNumber
        .toLowerCase()
        .includes("c0")
        ? this.isThirdPartyOptions[0]
        : this.isThirdPartyOptions[1];
      this.employee.isThirdParty = isThirdParty.value;
    }
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;

  .v-btn {
    text-transform: none;
  }

  .v-card__actions {
    position: absolute;
    bottom: 4vh;
    width: calc(100% - 2vw);
  }
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-address {
  max-width: 100%;
  padding: 0;
}

.bottom-response {
  width: 97.5%;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  width: 85%;
}

::v-deep
  .v-data-table
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  overflow-x: hidden;
  margin-top: 8px;
}

::v-deep
  .v-data-table
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row__cell {
  text-align: left;
}

::v-deep .v-card__subtitle,
.v-card__text,
.v-card__title {
  padding-left: 10px;
}

::v-deep .v-card__subtitle,
.v-card__text,
.v-card__title {
  padding-left: 10px;
}

::v-deep .v-card__title[data-v-29ae29da] {
  padding-right: 10px;
}
</style>
