import axios from "axios";

let searchAddressApi = axios.create({
  baseURL:
    `https://atlas.microsoft.com/search/address/json` +
    `?subscription-key=${process.env.VUE_APP_AZURE_MAPS_API_SUBSCRIPTION_KEY}` +
    `&api-version=${process.env.VUE_APP_AZURE_MAPS_API_VERSION}&countrySet=BR&language=pt-BR`,
});

export default searchAddressApi;
