<template>
  <v-card elevation="0" class="main-content pa-0 px-0 pb-0 pt-0 fill-height">
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      customMessage=""
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>
    <CsvImportInstructionsDialog
      :show="shouldShowInstructionsModal"
      @close="toggleInstructionsModal"
    />
    <v-row class="mb-1">
      <v-col cols="5">
        <v-card-title class="p-0">
          <h4>Importar reservas</h4>
        </v-card-title>
        <v-card-subtitle
          >Efetue a reserva para longas datas a seguir.</v-card-subtitle
        >
      </v-col>
      <v-col class="mt-3 mr-3 pt-2" align="right">
        <GenericButton
          secondary
          title="Download do template"
          icon="mdi-tray-arrow-down"
          href="Exemplo-reserva-cativa-CSV.csv"
          target="_blank"
        >
        </GenericButton>

        <GenericDialog
          close
          :visible="showDialog"
          v-on:close-dialog="closeDialog"
          width="600px"
        >
          <template v-slot:header>
            <div class="title-container">
              <v-card-title
                class="px-0 py-0 ml-1"
                style="color: #393939; font-size: 24px"
              >
                Importar reservas
              </v-card-title>
            </div>
          </template>

          <template v-slot:subtitle>
            <v-divider class="mt-4"></v-divider>
            <v-card-subtitle class="pl-0 ml-1 pt-6">
              Para importar as reservas, selecione a planilha desejada.
            </v-card-subtitle>
          </template>

          <template v-slot:body>
            <v-file-input
              v-model="file"
              label="Selecione um arquivo do seu computador"
              filled
              height="40"
              type="file"
              accept=".csv"
              prepend-inner-icon="mdi-cloud-upload-outline"
              :prepend-icon="null"
              @change="fileWasChosen = true"
              @click="showAlert = false"
            ></v-file-input>

            <v-col class="mt-3 mr-0 pt-2 pr-0" align="right">
              <GenericButton
                primary
                title="Importar"
                icon="mdi-tray-arrow-up"
                v-on:click="sendFile()"
                :disabled="!fileWasChosen"
              >
              </GenericButton>
            </v-col>
          </template>
        </GenericDialog>

        <GenericButton
          class="ml-4"
          title="Importar reservas"
          icon="mdi-tray-arrow-up"
          primary
          v-on:click="openDialog"
        ></GenericButton>
      </v-col>
    </v-row>

    <v-card-text>
      <v-progress-linear
        :indeterminate="indeterminateQuery"
        :active="showProgressBar"
      ></v-progress-linear>
      <v-data-table
        :headers="headers"
        :items="logTable"
        :single-expand="singleExpand"
        show-expand
        item-key="id"
        class="elevation-1"
        :loading="loading"
      >
        <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
          <td v-if="item.hasErrors" class="text-start">
            <v-btn
              icon
              @click="expand(!isExpanded)"
              class="v-data-table__expand-icon"
              :class="{ 'v-data-table__expand-icon--active': isExpanded }"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </td>
        </template>
        <template v-slot:[`expanded-item`]="{ item }">
          <tr v-for="tableError in item.errorsByTable" :key="tableError.id">
            <td class="pt-3 pb-2 px-8">Error na tabela</td>
            <td class="pt-3 pb-2 px-8">
              {{ tableError.type }}
            </td>
            <td></td>
            <td class="pt-3 pb-2 px-8">
              Erro ao importar a tabela. Tente novamente.
            </td>
          </tr>

          <tr v-for="lineError in item.errorsByLine" :key="lineError.id">
            <td class="pt-3 pb-2 px-8">Erro na linha</td>
            <td class="pt-3 pb-2 px-8">{{ lineError.lineNumber }}</td>
            <td class="pt-3 pb-2 px-8">
              {{ translateErrorByLine(lineError.type) }}
            </td>
            <td class="pt-3 pb-2 px-8">
              {{ lineError.additionalInformation }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomAlert from "@/components/CustomAlert";
import GenericDialog from "../components/GenericDialog.vue";
import CsvImportInstructionsDialog from "@/components/CsvImportInstructionsDialog";
import { ReservationLongTimeService } from "../services/reservationLongTime";
import GenericButton from "../components/GenericButton.vue";
import { PermissionService } from "../services/permission";

export default {
  name: "ReservationManagmentInsertTab",
  components: {
    CustomAlert,
    CsvImportInstructionsDialog,
    GenericDialog,
    GenericButton,
  },
  data: () => ({
    show: false,
    expanded: [],
    permission: "",
    search: "",
    file: null,
    showProgressBar: true,
    indeterminateQuery: true,
    fileWasChosen: false,
    headers: [
      { text: "Nome", value: "logFileName" },
      { text: "Status", value: "translatedStatus" },
      { text: "Data da importação", value: "formattedDate" },
      { text: "", value: "data-table-expand" },
    ],
    showAlert: false,
    showDialog: false,
    alertMessageType: "default",
    alertType: "success",
    options: {},
    loading: true,
    singleExpand: true,
    logTable: [],
    shouldShowInstructionsModal: false,
  }),
  watch: {
    options: {
      handler() {
        this.getLogSendFile();
      },
      deep: true,
    },
  },

  methods: {
    openDialog() {
      this.showDialog = true;
      this.resetFileInput();
    },
    closeDialog() {
      this.showDialog = false;
    },
    closeAlert() {
      this.showAlert = false;
    },
    sendFile: async function () {
      try {
        await ReservationLongTimeService.registerLongReservation(this.file);
        await this.uploadSuccess();
        setTimeout(() => {
          this.resetFileInput();
          this.getLogSendFile();
        }, 1000);
      } catch (error) {
        this.uploadError();
        setTimeout(() => {
          this.resetFileInput();
          this.getLogSendFile();
        }, 1000);
      } finally {
        setTimeout(() => {
          this.closeAlert();
        }, 3000);
      }
    },
    resetFileInput() {
      this.file = null;
      this.fileWasChosen = false;
    },
    async uploadSuccess() {
      this.setAlertProps(true, "uploadSuccess");
      this.closeDialog();
      await this.getLogSendFile();
    },
    uploadError() {
      this.setAlertProps(false, "uploadError");
    },
    async deleteSuccess() {
      this.setAlertProps(true, "deleteSuccess");
      this.selectedEmployees = [];
      await this.getLogSendFile();
    },
    deleteError() {
      this.selectedEmployees = [];
      this.setAlertProps(false, "deleteError");
    },
    setAlertProps(isSuccess, type) {
      if (isSuccess) {
        this.alertType = "success";
      } else {
        this.alertType = "error";
      }
      this.showAlert = true;
      this.alertMessageType = type;
    },
    async getLogSendFile() {
      try {
        this.loading = true;
        this.logTable = await ReservationLongTimeService.getLog();
        this.logTable.forEach((log) => {
          log.translatedStatus = this.translateStatus(log.status);
          log.errorsByLine.sort((errorA, errorB) => {
            return parseInt(errorA.lineNumber) - parseInt(errorB.lineNumber);
          });
          const time = new Date(log.date).toLocaleTimeString().slice(0, 5);
          const date = new Date(log.date).toLocaleDateString();
          log.formattedDate = date + " " + time;
        });
        this.loading = false;
      } catch {
        this.logTable = null;
        this.loading = false;
      }
    },
    translateStatus(status) {
      if (status == null) return "";
      if (status == "SUCCESS") return "Importado com sucesso";
      if (status == "SAVING_BOOKINGS") return "Aguarde, salvando as reservas";
      if (status == "ERROR") return "Falha na importação";
    },
    translateErrorByLine(error) {
      if (error == null) return "";
      if (error == "EMPLOYEE")
        return "Não foi possível localizar o passageiro.";
      if (error == "EMPLOYEE_NOT_PREFERENTIAL")
        return "Passageiro não preferencial";
      if (error == "LINE_CODE") return "Linha de ônibus incorreta";
      if (error == "INITIAL_DATE") return "Data de início incorreta";
      if (error == "END_DATE") return "Data de fim incorreta";
      if (error == "WEEKDAYS") return "Ocorreu um erro na recorrência";
      if (error == "NULLFIELDS") return "Campo obrigatório vazio";
      if (error == "DATES") return "Limite de reserva ultrapassado";
      if (error == "REGION_NAME")
        return "Houve um erro, o complexo está incorreto";
      if (error == "SITE_NAME") return "Site incorreto";
      if (error == "DISABLED_BUS_LINE")
        return "Linhas de ônibus não habilitada";
      if (error == "PAST_BOOKING") return "Data incorreta";
      if (error == "DIRECTION_BUSLINE") return "Direção da linha de ônibus";
      if (error == "ABSENCE") return "Ausência registrada";
      if (error == "CONFLICT") return "Conflito de reserva";
      return "Erro na importação.";
    },
    toggleInstructionsModal() {
      this.shouldShowInstructionsModal = !this.shouldShowInstructionsModal;
    },
  },
  beforeMount() {
    this.permission = PermissionService.getPermission();
    this.getLogSendFile();
  },
  async mounted() {
    this.showProgressBar = false;
    this.indeterminateQuery = true;
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;

  .v-card-subtitle {
    color: $greyVale40;
  }

  .v-card-text {
    text-align: center;
    padding-left: 0px;
  }
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-btn {
  text-transform: none;
}

.v-data-table {
  tbody {
    color: $greyVale;

    .v-icon {
      color: $greyVale;
    }
  }
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding-top: 0px;
  padding-left: 1px;
}

.v-input__slot {
  margin-bottom: 0px;
}

.v-text-field.v-text-field--enclosed ::v-deep .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 2px;
}
.v-tab {
  text-transform: capitalize;
}
</style>
