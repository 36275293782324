import api from "./api";

export class FaqsService {
    static async getAllFaqs() {
        let endpoint = `/faqs`;
        let response = await api.get(endpoint);

        return response.data;
    }

    static async getFilteredFaqs(searchTerm) {
        let endpoint = `/faqs?search=${searchTerm}`;
        let response = await api.get(endpoint);

        return response.data;
    }
}
