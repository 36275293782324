<template >
  <v-list class="v-list mt-16">
    <v-list-item to="/home" class="options-wrapper-title">
      <v-list-item-icon>
        <v-icon>mdi-home</v-icon>
      </v-list-item-icon>
      <v-list-item-title class="body1">Início</v-list-item-title>
    </v-list-item>

    <v-list-item to="/booking" class="options-wrapper-title" v-if="permission !== ''">
      <v-list-item-icon>
        <v-icon>mdi-clipboard-check-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-title class="body1">Reservas</v-list-item-title>
    </v-list-item>

    <v-list-item to="/my-profile" class="options-wrapper-title" v-if="permission !== ''">
      <v-list-item-icon>
        <v-icon>mdi-account-circle-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-title class="body1">Meu perfil</v-list-item-title>
    </v-list-item>

    <v-list-item to="/my-absences" class="options-wrapper-title" v-if="permission !== ''">
      <v-list-item-icon>
        <v-icon>mdi-account-minus-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-title class="body1">
        <div v-if="permission == 'admin' || permission == 'supervisor'" class="body1">
          Ausências
        </div>
        <div v-else class="body1">Minhas Ausências</div>
      </v-list-item-title>
    </v-list-item>

    <v-list-group v-if="permission == 'admin' || permission == 'supervisor'" :value="false" no-action>
      <template v-slot:activator>
        <v-list-item-icon>
          <v-icon>mdi-playlist-check</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="options-wrapper-title">
          Relatórios
        </v-list-item-title>
      </template>
      <v-list-item v-for="[to, name] in reports" :key="to" :to="to" class="sub-option-title">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-title v-bind="attrs" v-on="on" class="body1">
            {{ name }}</v-list-item-title>
        </template>
        <span>{{ name }}</span>
      </v-list-item>
    </v-list-group>

    <template v-if="permission === 'admin'">
      <v-list-item v-for="item in optionsAfterReports" :to="item.route" class="options-wrapper-title" :key="item.to">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="body1">{{
          item.name
        }}</v-list-item-title>
      </v-list-item>
    </template>
    
    <v-list-group v-if="permission == 'admin'" :value="false" no-action>
      <template v-slot:activator>
        <v-list-item-icon>
          <v-icon>mdi-bus</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="options-wrapper-title">
          Administrar rotas
        </v-list-item-title>
      </template>
      <v-list-item v-for="[to, name] in routeManagement" :key="to" :to="to" class="sub-option-title">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-title v-bind="attrs" v-on="on" class="body1">
            {{ name }}
          </v-list-item-title>
        </template>
        <span>{{ name }}</span>
      </v-list-item>
    </v-list-group>

    <v-list-item to="/register-employees" class="options-wrapper-title" v-if="permission === 'admin' || permission === 'supervisor'">
      <v-list-item-icon>
        <v-icon>mdi-view-list-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-title class="body1">
        Administrar passageiros
      </v-list-item-title>
    </v-list-item>

    <v-list-group v-if="permission == 'admin'" :value="false" no-action>
      <template v-slot:activator>
        <v-list-item-icon>
          <v-icon>mdi-cog-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="options-wrapper-title">Configurações</v-list-item-title>
      </template>

      <v-list-item to="/inactivity-days" class="sub-option-title" v-if="permission === 'admin'">
        <v-list-item-content>
          <v-list-item-title class="body1">Dias de Inatividade</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
import { PermissionService } from '../services/permission';

export default {
  name: "SideMenu",
  props: {},
  data: () => ({
    permission: "",
    reports: [
      ["/absence-report", "Relatórios de ausência"],
      // ["/booking-usage-report", "Relatórios de uso de reserva"],
      ["/booking-report", "Relatórios por linha de ônibus"],
      ["/bus-occupancy", "Relatório de ocupação de ônibus"],
      ["/excluded-reservation", "Relatório de reservas canceladas"],
      // ["/unreserved-usage-report", "Relatórios de uso sem reserva"],
      // ["/unused-booking-report", "Relatórios de reserva sem uso"],
      // ["/inactivity-report", "Relatórios de inatividade"],
      // ["/edleness-report", "Relatórios de ociosidade"],
    ],
    optionsAfterReports: [
      {
        route: "/booking-batch",
        name: "Reservar assentos em lote",
        icon: "mdi-text-box-multiple-outline",
      },
      {
        route: "/reservation-management",
        name: "Gestão de reserva",
        icon: "mdi-folder-upload-outline",
      },
      {
        route: "/admin-regions",
        name: "Administrar Complexos",
        icon: "mdi-map-outline",
      },
    ],
    routeManagement: [
      ["/admin-routes", "Administrar linhas de ônibus"],
      ["/bus-stops-management", "Administrar pontos de ônibus"],
      ["/bus-type-management", "Administrar tipos de ônibus"],
      ["/admin-expected-employees", "Administrar colaboradores esperados"],
    ],
  }),
  methods: {
    printItem(item) {
      console.log(item);
    },
  },

  mounted() {
    this.permission = PermissionService.getPermission();
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.v-card {
  height: 100%;
  width: 100%;
  box-shadow: none;

  .v-tab {
    justify-content: start;
    align-items: center;
    margin-bottom: 16px;
    text-transform: none;

    p {
      margin-bottom: 0;
    }
  }
}

.v-list,
.v-list-item__title {
  font-weight: 700;
  font-size: 14px;
  box-shadow: none;
}

.v-list-item__title.options-wrapper-title,
.options-wrapper-title.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: $greyVale80 !important;
  box-shadow: none;
}

.sub-option-title.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: $greyVale !important;
}

.v-list .v-list-item--active {
  color: $greenVale !important;
}

.v-list::-webkit-scrollbar {
  display: none;
  width: 0 !important
}
</style>
