import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import * as VueGoogleMaps from 'vue2-google-maps';
import api from "./services/api";
import VueHtml2Canvas from 'vue-html2canvas';
import { VueMaskDirective } from 'v-mask'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'


Vue.use(VueHtml2Canvas);

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        libraries: 'places',
    },
    installComponents: true
})

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, { 
	locale: 'pt-BR',
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
    valueAsInteger: false,
    allowNegative: true
})

Vue.prototype.$http = api;
Vue.directive('mask', VueMaskDirective);
  
new Vue({
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')
