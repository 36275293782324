<template>
  <v-btn
    outlined
    height="40"
    class="pr-4 pl-3 py-3 mx-0 my-0 btn-text"
    :href="href"
    :target="target"
    :to="to"
    :class="{
      primary: primary,
      secondaryBtn: secondary,
      thirdBtn: tertiary,
      'btn-table': btnTable,
    }"
    :disabled="disabled"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <v-icon v-if="icon != ''" class="mr-2 icon-font"> {{ icon }} </v-icon>
    {{ title }}</v-btn
  >
</template>

<script>
export default {
  name: "GenericButton",
  props: {
    primary: { type: Boolean, default: false },
    secondary: { type: Boolean, default: false },
    tertiary: { type: Boolean, default: false },
    btnTable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    title: { type: String, default: "" },
    icon: { type: String, default: "" },
    href: { type: String, default: "" },
    target: { type: String, default: "" },
    to: { type: String, default: "" },
  },
  data: function () {
    return {};
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.btn-table {
  background-color: rgba(0, 0, 0, 0) !important;
  color: #a8a8a8 !important;
  border-style: none;
  border-color: none;
  box-shadow: 0px 0px;
}

.btn-text {
  text-transform: none;
}

.icon-font {
  font-size: 20px;
}
</style>
