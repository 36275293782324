export class BookUtils {
  static updateDaysRecurrence(booking) {
    booking.daysRecurrence = booking.daysRecurrence
      .replace("monday", "segunda")
      .replace("tuesday", " terça")
      .replace("wednesday", " quarta")
      .replace("thursday", " quinta")
      .replace("friday", " sexta")
      .replace("saturday", " sábado")
      .replace("sunday", " domingo");
    if (booking.direction == "I") booking.direction = "IDA";
    if (booking.direction == "V") booking.direction = "VOLTA";
    if (booking.direction == "A") booking.direction = "AMBOS";
    return booking;
  }

  static updateDaysRecurrenceList(bookings) {
    bookings.forEach((booking) => {
      booking.daysRecurrence = booking.daysRecurrence
        .replace("monday", "segunda")
        .replace("tuesday", " terça")
        .replace("wednesday", " quarta")
        .replace("thursday", " quinta")
        .replace("friday", " sexta")
        .replace("saturday", " sábado")
        .replace("sunday", " domingo");
      if (booking.direction == "I") booking.direction = "IDA";
      if (booking.direction == "V") booking.direction = "VOLTA";
      if (booking.direction == "A") booking.direction = "AMBOS";
    });
    return bookings;
  }
}
