import api from "./api";
import msalInstance from "../msalPlugin";

export class LoginService {
    static login() {     
      const loginRequest = {
        scopes: ['user.read'],
        prompt: 'select_account',
      };
      msalInstance.loginRedirect(loginRequest);
    }


    static async getPermission(response) {
      let idToken ;
      if (response) idToken = response.idToken;

      if (idToken){
        localStorage.setItem("name", idToken.name);
        localStorage.setItem("idToken", idToken.rawIdToken);
        if (idToken.claims) {
          localStorage.setItem("userId", idToken.claims.cn);
        }
      }
      localStorage.setItem("employeeShift", "Remote");
    }
    static async revokeToken(refresh_token) {
        let response = await api.post(`/revokeToken/`, refresh_token);
        window.location.href = process.env.VUE_APP_IAM_LOGOUT_URI;
        return response.data;
    }
}