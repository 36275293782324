<template>
  <div>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      @change="changeTab"
      :grow="grow"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>

      <v-tab v-for="component in componentsTab" :key="component.title">
        {{ component.title }}
      </v-tab>
    </v-tabs>
    <slot name="contentTab"></slot>
  </div>
</template>

<script>
import { TabActive } from "../store/tabActive";

export default {
  name: "TabsView",
  props: {
    componentsTab: Array,
    grow: { type: Boolean, default: false },
  },
  store: TabActive,
  computed: {
    tab: {
      get() {
        return TabActive.getters.activeTab;
      },
      set(value) {
        TabActive.commit("setActiveTab", value);
      },
    },
  },

  data: () => ({}),

  methods: {
    changeTab() {
      this.$emit("tab-changed", this.tab);
    },
  },

  async beforeMount() {
    this.tab = await TabActive.getters.activeTab;
    this.$emit("tab-changed", this.tab);
  },

  async beforeDestroy() {
    await TabActive.commit("setActiveTab", null);
  },
};
</script>

<style scoped>
::v-deep .v-tab {
  text-transform: none;
}
</style>
