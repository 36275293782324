<template>
  <v-card class="elevation-0 mt-6 ml-4 mr-3 px-0 py-0">
    <v-row v-if="newsList.length > 0" class="mb-4 d-flex align-center">
      <v-icon class="mr-2 mb-1 icon icon-news"> mdi-bell-ring-outline</v-icon>
      <b class="fonts icon-news"> Novidades </b>
    </v-row>

    <v-row v-if="newsList.length > 0" class="mb-4 mt-0">
      <ul class="lists">
        <li class="mb-2" v-for="news in newsList" :key="news.releaseNumber">
          {{ news }}
        </li>
      </ul>
    </v-row>

    <v-row v-if="bugsList.length > 0" class="mb-4 mt-0">
      <v-icon class="mr-2 mb-1 icon icon-bugs">mdi-bug-outline</v-icon>
      <b class="fonts icon-bugs"> Bugs corrigidos </b>
    </v-row>

    <v-row v-if="bugsList.length > 0" class="mb-4 mt-0">
      <ul class="lists">
        <li class="mb-2" v-for="bugs in bugsList" :key="bugs.releaseNumber">
          {{ bugs }}
        </li>
      </ul>
    </v-row>

    <v-row v-if="importantList.length > 0" class="mb-4 mt-0">
      <v-icon class="mr-2 mb-1 icon icon-important"> mdi-alert </v-icon>
      <b class="fonts icon-important"> Importante! </b>
    </v-row>

    <v-row v-if="importantList.length > 0" class="mt-0 mb-0">
      <ul class="lists important pl-0">
        <li
          class="mb-2"
          v-for="important in importantList"
          :key="important.releaseNumber"
        >
          {{ important }}
        </li>
      </ul>
    </v-row>
  </v-card>
</template>

<script>
import { ReleaseService } from "@/services/release.js";

export default {
  name: "Release",
  components: {},

  props: {},

  data: () => ({
    release: [],
    newsList: [],
    bugsList: [],
    importantList: [],
  }),

  methods: {
    async getRelease() {
      try {
        this.release = await ReleaseService.getRelease();
        this.newsList = this.release.news;
        this.bugsList = this.release.bugs;
        this.importantList = this.release.important;
      } catch (err) {
        this.release = [{}];
      }
    },
  },

  async beforeMount() {
    this.getRelease();
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.fonts {
  font-size: 16px;
}

.icon {
  font-size: 18px;
}

.icon-news {
  color: #006a61;
}

.icon-bugs {
  color: #7a0428;
  margin-bottom: 2px;
}

.icon-important {
  color: #b57802;
}

.lists {
  font-size: 14px;
  color: #393939;
}

.important {
  list-style: none;
  list-style-position: outside;
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6f6f6f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6f6f6f;
}

::v-deep .v-responsive__content {
  display: flex;
  flex-direction: row;
}
</style>
