<template>
  <div class="pl-4 pr-0 py-3">
    <v-text-field
      v-model="searchTerm"
      class="mr-5 mb-3"
      height="32px"
      placeholder="Pesquisar"
      hide-details="auto"
      outlined
      append-icon="mdi-magnify"
      v-on:keyup.enter="faqSearch"
      @click:append="faqSearch"
      @input="onInputChange"
    ></v-text-field>
    <v-responsive
      class="overflow-y-auto"
      color="primary"
      max-height="calc(90vh - 200px)"
    >
      <v-expansion-panels multiple>
        <v-expansion-panel
          v-for="faqs in faqsList"
          :key="faqs.id"
          class="mb-3 expand-panels"
        >
          <v-expansion-panel-header class="expand-panels-title px-4 py-3">
            <b> {{ faqs.question }} </b>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="expand-panels-content m-4">
            {{ faqs.answer }}</v-expansion-panel-content
          >
        </v-expansion-panel>
      </v-expansion-panels>
      <div style="width: 16px"></div>
    </v-responsive>
  </div>
</template>

<script>
import { FaqsService } from "@/services/faqs";

export default {
  name: "Faqs",

  props: {},

  data: () => ({
    faqsList: [],
    searchTerm: "",
  }),
  methods: {
    async getAllFaqs() {
      try {
        this.faqsList = await FaqsService.getAllFaqs();
      } catch (err) {
        this.faqsList = [{}];
      }
    },
    async getFilteredFaqs() {
      try {
        this.faqsList = await FaqsService.getFilteredFaqs(this.searchTerm);
      } catch (err) {
        this.faqsList = [{}];
      }
    },
    async faqSearch() {
      if (this.searchTerm.length >= 0) {
        this.getFilteredFaqs();
      }
    },
    async onInputChange() {
      if (this.searchTerm.length === 0) {
        this.getAllFaqs();
      }
    },
  },

  async beforeMount() {
    this.getAllFaqs();
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.expand-panels {
  border-width: thin;
  border-style: solid;
  border-color: #e0e0e0;
  text-transform: none;
  border-radius: 4px;
}

.expand-panels-title {
  color: #6f6f6f;
  font-size: 12px;
}

.expand-panels-content {
  color: #6f6f6f;
  font-size: 12px;
  border-top: thin solid rgba(0, 0, 0, 0.04);
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 16px;
}

::v-deep .v-expansion-panels {
  width: 348px;
  height: 328px;

  @media screen and (max-width: "768px") {
    width: 100% !important;
  }
}

::v-deep .v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px;
}

::v-deep .v-text-field--outlined {
  border: 1px solid #F4F4F4;
  height: 32px !important;

  fieldset {
    border: 0px;
  }
}

::v-deep .v-text-field--outlined.v-input--is-focused {
  border-color: #007E7A;

  fieldset {
    border: 0px;
  }
}

::v-deep .v-text-field--outlined fieldset {
  height: 32px !important;
  border: 0px;
  top: 0px;
}

::v-deep .v-text-field--outlined .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  font-size: 14px;
}

::v-deep .v-text-field--outlined .v-input__slot {
  padding: 0 16px !important;
}

::v-deep .v-text-field--outlined .v-input__append-inner {
  margin: auto 0px;
  height: fit-content;
  display: flex;
  align-items: center;

  .v-input__icon--append {
    margin: auto 0px;

    .v-icon {
      font-size: 18px;
    }
  }
}

::v-deep .v-text-field--outlined .v-text-field__slot {
  height: 32px;
  
  input {
    padding: 0px;
    margin: auto 0px;
  }
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6f6f6f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6f6f6f;
}

::v-deep .v-responsive__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
