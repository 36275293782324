<template>
  <div id="myMap"></div>
</template>
<script>
import * as atlas from "azure-maps-control";
export default {
  name: "AzureMaps",
  data() {
    return {
      map: null,
      atlas: atlas,
      coordinates: {
        latitude: 0.0,
        longitude: 0.0,
      },
      markersList: [],
      userCoordinates: {
        latitude: 0.0,
        longitude: 0.0,
      },
    };
  },
  props: {
    customCoordinates: {
      longitude: {
        type: Number,
        default: 0.0,
      },
      latitude: {
        type: Number,
        default: 0.0,
      },
    },
    customMarkers: {
      type: Array,
      default: () => {
        return [];
      },
    },
    addUserMarker: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getMap() {
      return this.map;
    },
    async getUserCoordinates() {
      return new Promise(function(resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    },
    async setUserCoordinates() {
      let positionPromise = await this.getUserCoordinates();
      this.userCoordinates.latitude = positionPromise.coords.latitude;
      this.userCoordinates.longitude = positionPromise.coords.longitude;
    },
    addMarkers() {
      this.map.events.add("ready", () => {
        Object.keys(this.markersList).forEach((index) => {
          let marker = this.markersList[index];
          let latitude = marker["latitude"];
          let longitude = marker["longitude"];
          let markerText = marker["text"];
          let draggable = marker["draggable"];
          let htmlMarker = new atlas.HtmlMarker({
            text: markerText,
            position: [longitude, latitude],
            draggable: draggable,
          });

          this.map.markers.add(htmlMarker);
        });
        if (this.addUserMarker) {
          let userMarker = new atlas.HtmlMarker({
            position: [
              this.userCoordinates.longitude,
              this.userCoordinates.latitude,
            ],
            color: "#000",
            draggable: false,
          });
          this.map.markers.add(userMarker);
        }
      });
    },
    clearMarkers() {
      this.map.markers.clear();
    },
    initMap() {
      try {
        const map = new atlas.Map("myMap", {
          center: [this.coordinates.longitude, this.coordinates.latitude],
          zoom: 10,
          language: "pt-BR",
          showBuildingModels: true,
          showFeedbackLink: false,
          showLogo: false,
          view: "Auto",
          authOptions: {
            authType: "subscriptionKey",
            subscriptionKey: `${process.env.VUE_APP_AZURE_MAPS_API_SUBSCRIPTION_KEY}`,
          },
        });
        // map.controls.add(new atlas.control.StyleControl(), {
        //   position: "top-right",
        // });
        this.map = map;
      } catch (error) {
        console.log(error);
      }
    },
    async setCustomMarkers(customMarkers) {
      if (customMarkers == null) {
        this.markersList = [];
      } else {
        this.markersList = customMarkers;
      }
      await this.addMarkers();
    },
    async setCoordinates(customCoordinates) {
      if (customCoordinates == null) {
        this.map.setCamera({
          center: [
            this.userCoordinates.longitude,
            this.userCoordinates.latitude,
          ],
        });
      } else {
        this.coordinates = customCoordinates;
        this.map.setCamera({
          center: [this.coordinates.longitude, this.coordinates.latitude],
        });
      }
    },
    async setAzureMapsOptions(customMarkers, customCoordinates) {
      this.setCoordinates(customCoordinates);
      await this.setCustomMarkers(customMarkers);
    },
    getMarkersCoordinatesList() {
      let markersList = this.map.markers.getMarkers();
      let coordinatesList = [];
      if (markersList) {
        markersList.forEach((marker) => {
          let markerCoordinate = {
            longitude: marker.options.position[0],
            latitude: marker.options.position[1],
          };
          coordinatesList.push(markerCoordinate);
        });
        return coordinatesList;
      }
    },
  },
  async beforeMount() {
    await this.setUserCoordinates();
  },
  mounted() {
    this.initMap();

    this.$nextTick(() => {
      this.map.map.resize();
    });
  },
};
</script>
