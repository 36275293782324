<template>
  <v-dialog v-model="showDialogLocal" width="600" @click:outside="emitClose()">
    <v-card class="ma-0 pa-0">
      <v-card-title class="pa-4 pb-0 d-flex justify-space-between modal-title">
        Visualização do ponto
        <v-btn icon class="pa-0" @click="emitClose()">
          <v-icon medium>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-4 pb-1">
        Aqui você poderá vizualizar o ponto
      </v-card-text>
      <v-divider class="ml-4 mr-6"></v-divider>
      <v-card-text class="px-4 pb-4">
        <v-row align="start" class="ma-0 mt-6 ml-n2 map-align">
          <v-col cols="1">
            <v-row align-content="start">
              <v-icon class="mb-4 marker-icon"
                >mdi-map-marker-outline</v-icon
              >
            </v-row>
          </v-col>
          <v-col cols="11" align-self="center">
            <v-row>
              <b class="address-title">{{ this.mapProps.addressTitle }}</b>
            </v-row>
            <v-row>
              <span class="address-subtitle">
                {{ this.mapProps.address }}
              </span>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center" class="mb-2 map-align">
          <AzureMaps
            :customCoordinates="customCoordinates"
            :customMarkers="customMarkers"
            ref="azureMaps"
            class="map"
          ></AzureMaps>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import AzureMaps from "./AzureMaps.vue";

export default {
  name: "ViewLineMapDialog",
  components: {
    AzureMaps,
  },
  props: {
    showDialog: Boolean,
    mapProps: {
      latitude: Number,
      longitude: Number,
      address: String,
      addressTitle: String,
    },
  },
  data: () => ({
    customCoordinates: null,
    customMarkers: [],
    showDialogLocal: false,
    mapData: {
      latitude: 0.0,
      longitude: 0.0,
      address: null,
      addressTitle: null,
    },
  }),
  methods: {
    setAzureMapsOptions() {
      this.customCoordinates = {};
      this.customMarkers = [];
      const lat = this.mapProps.latitude;
      const lon = this.mapProps.longitude;
      this.customCoordinates = {
        latitude: lat,
        longitude: lon,
      };

      this.customMarkers.push({
        latitude: lat,
        longitude: lon,
        draggable: false,
      });
    },
    emitClose() {
      this.$emit("closeDialog");
    },
  },
  beforeMount() {
    this.mapData = this.mapProps;
  },

  beforeUpdate() {
    this.setAzureMapsOptions();
    this.$nextTick(() => {
      this.$refs.azureMaps.clearMarkers();
      this.$refs.azureMaps.setAzureMapsOptions(
        this.customMarkers,
        this.customCoordinates
      );
    });
  },
  watch: {
    showDialog(newVal) {
      this.showDialogLocal = newVal;
    },
    showDialogLocal(newVal) {
      this.$emit('update:showDialog', newVal);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

#myMap {
  height: 271px;
  width: 488px;
}

.marker-icon {
  color: $greyVale;
  margin-left: 24px;
}

.modal-title {
  font-size: 20px;
  color: $dark;
}

.address-title {
  text-transform: uppercase;
  font-size: 14px;
  color: $greyVale;
}

.address-subtitle {
  font-size: 12px;
  color: $greyVale;
}

.map {
  border-radius: 4px;
  border: 1px solid $greyVale20;
  
}

@media screen and (max-width: 700px) {
  .marker-icon {
    margin-left: 0px;
  }

  .map-align {
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>
