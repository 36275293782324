<template class="fill-height">
  <div class="voucher-container" ref="printMe" id="element-to-print">
    <div class="info-container" v-bind:class="{ isRemote: !isRemote }">
      <div class="info-content">
        <span class="employee-name"
          >{{ getFormattedName() }}
          <v-icon class="icon-wheelchair" color="white" v-if="isPriority"
            >mdi-human-wheelchair</v-icon
          ></span
        >
        <span>{{ isThirdParty ? "Empresa Terceirizada" : "Vale" }}</span>
        <span>{{ recurrence }}</span>
        <span>Período: {{ getPeriod() }} </span>
        <span>
          Sentido:
          {{ direction.charAt(0) + direction.substring(1).toLowerCase() }}</span
        >
      </div>
      <div class="selo-container mb-2">
        <div class="border-selo">
          <qr-code
            class="qrcode"
            :size="256"
            error-level="L"
            :text="qrCodeData"
          ></qr-code>
        </div>
        <v-img
          class="voucher-logo"
          src="../assets/vale-logo-white-name.svg"
          height="20"
          width="54"
        ></v-img>
      </div>
    </div>
    <div class="divider" />
    <div class="busline-container">
      <p class="busline" id="capture">{{ busLine }}</p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueQRCodeComponent from "vue-qrcode-component";
import { WEEK_DAYS } from '../utils/dateUtils';

Vue.component("qr-code", VueQRCodeComponent);
export default {
  name: "VoucherBadge",

  props: {
    name: String,
    recurrenceDays: String,
    startDate: String,
    expirationDate: String,
    direction: String,
    busLine: String,
    shift: String,
    isThirdParty: Boolean,
    isPriority: Boolean,
  },

  data: () => ({
    recurrence: "",
    isRecurrent: false,
    isRemote: false,
  }),

  methods: {
    sortDays(recurrenceDays) {
      const weekDays = WEEK_DAYS;

      let reccurences = recurrenceDays.split(";");

      let formatedReccurence = [];

      reccurences.forEach((day) => {
        formatedReccurence.push(
          weekDays.filter((weekDay) => weekDay.name == day.trim())
        );
      });

      return formatedReccurence.sort((a, b) => a[0].value - b[0].value);
    },
    getStringDays() {
      let sortedDays = this.sortDays(this.recurrenceDays);
      let stringDays = "";

      if(sortedDays != ""){
          sortedDays.forEach((day, i) => {
          stringDays += i === 0 ? day[0].name[0].toUpperCase() + day[0].name.substr(1) : day[0].name;
          if (sortedDays.length > 1) {
            this.isRecurrent = true;
            if (i === sortedDays.length - 2) { 
              stringDays += " e "; 
            } else if (i < sortedDays.length - 1) {
              stringDays += ", ";
            }
          }
        });
      }
    
      this.recurrence = stringDays;
    },
    getFormattedName() {
      const MAX_NAME_LENGTH = 12;
      const nameWithoutContr = this.name.replace("_CONTR", "");
      const firstNameAndLastName = nameWithoutContr.split(" ").slice(0, 2).join(" ");
      const isNameTooLong = firstNameAndLastName.length >= MAX_NAME_LENGTH;

      if (isNameTooLong) {
        return firstNameAndLastName.slice(0, MAX_NAME_LENGTH) + '...';
      } 

      return firstNameAndLastName;
    },
    getPeriod() {
    const startDateSubstring = this.startDate.substring(0, 6) + this.startDate.substring(8);
    if (this.recurrence != "") {
      const expirationDateSubstring = this.expirationDate.substring(0, 6) + this.expirationDate.substring(8);
      return startDateSubstring + ' - ' + expirationDateSubstring;
    } else {
      return startDateSubstring;
    }
  },
  },
  computed: {
    qrCodeData() {
      return `Nome: ${this.name.split(" ").slice(0, 2).join(" ")}\n\n` + 
          `Data: ${this.startDate}\n\n` +
          `Sentido: ${this.direction}\n\n` +
          `Linha: ${this.busLine}`;
    },
  },
  beforeMount() {
    if (this.shift == "REMOTE") {
      this.isRemote = true;
    }

    if (this.sortDays(this.recurrenceDays).length < 7){
      this.getStringDays();
    }

    this.getFormattedName();
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";
.voucher-container {
  height: 344px;
  width: 221px;
  display: flex;
  flex-direction: column;
  margin: auto;

  background-color: $white;
  border: 1px $greyVale40 solid;
  border-radius: 16px !important;

  font-family: Arial;
}

.info-container {
  height: 274px;
  width: auto;
  padding-top: 40px;
  padding-left: 16px;
  padding-right: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 16px 16px 0 0;
  background-color: $greenVale;
  background-image: url("../assets/background-selo-onsite.png");

  .info-content {
    margin-left: 4px;
    font-size: 14px;
    color: $white;

    span {
      display: block;
      margin-bottom: 4px;
    }

    .employee-name {
      font-size: 16px;
      font-weight: 700;
    }
  }
}
::v-deep .v-icon.v-icon {
  font-size: 20px;
}

.selo-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 4px;

  .border-selo {
    border: solid 0.5px;
    border-radius: 4px;
    margin-right: 40px;
    border-color: #f4f4f440;
  }
}

.icon-wheelchair{
  margin-bottom: 8px;
}

.qrcode {
  margin: 4px;
  align-self: center;
  flex-grow: inherit;
  border: solid 2px;
  border-color: white;
  height: 59px;
  width: 59px;

  ::v-deep img {
    height: 100%;
    width: 100%;
  }
}

.voucher-logo {
  align-self: end;
  flex-grow: inherit;
}

.isRemote {
  background-color: $blueVoucher;
  background-image: url("../assets/background-selo-remote.png");
}

.divider {
  min-height: 8px;
  width: auto;
  background-color: $yellowVale;
}

.busline-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 54px;

  .busline {
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: black;
    margin: 0;
  }
}
</style>
