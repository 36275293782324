<template>
  <v-card class="main-content pa-4 fill-height">
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      customMessage=""
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>
    <v-card-title>
      <h4>Alterar Rotas</h4>
    </v-card-title>
    <v-card-subtitle class="mb-7">
      Aqui você pode alterar a rota, somente carregue um novo arquivo KMZ caso
      deseje substituir os pontos da rota !
    </v-card-subtitle>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="align-center justify-space-between">
          <v-col>
            <v-file-input
              v-model="file"
              label="Faça upload de um novo arquivo (.kmz) de linhas de ônibus"
              type="file"
              @change="fileWasChosen = true"
              @click="showAlert = false"
              accept=".kmz"
              id="kmzFile"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <TimePicker
              :label="'Horário de Partida (ponto inicial)'"
              ref="time"
              @change="edited = true"
              :data.sync="route.departureTime"
              :formTime="route.departureTime"
              v-on:chosen-time="route.departureTime = $event"
              :rules="[isNotEmptyRule]"
            ></TimePicker>
          </v-col>
          <v-col>
            <v-select
              label="Direção da Linhas"
              v-model="route.direction"
              @change="edited = true"
              :items="lineDirectionOptions"
              item-text="text"
              item-value="value"
              :rules="[isNotEmptyRule]"
              required
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              label="Tipo do ônibus"
              v-model="route.busType"
              @change="edited = true"
              :items="busTypeOptions"
              item-text="text"
              item-value="value"
              :rules="[isNotEmptyRule]"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              prepend-icon="mdi-map-marker"
              v-model="route.destinationSite"
              @change="edited = true"
              label="Site de destino"
              :rules="[isNotEmptyRule]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              label="Complexo"
              v-model="route.region"
              @change="edited = true"
              :items="regions"
              :clearable="true"
              item-text="name"
              return-object
              :rules="[isNotEmptyRule]"
            />
          </v-col>
          <v-col>
            <v-checkbox
              v-model="route.transshipment"
              label="Baldeio?"
              @change="edited = true"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex justify-space-between align-baseline">
      <v-btn
        :disabled="uploading"
        id="cancel-button"
        color--text="primary"
        v-on:click="backToAdminRoutes()"
      >
        Cancelar
      </v-btn>
      <v-spacer></v-spacer>
      <DialogModal :showDialog="showDisableRouteDialog" width="427px">
        <template v-slot:title>
          {{ dialogTextValues.title }}
        </template>
        <template v-slot:body>
          {{ dialogTextValues.body }}
        </template>
        <template v-slot:openLabel>
          <v-btn class="ma-2" outlined @click="showDisableRouteDialog = true">
            {{ activeStatusButtonText }}</v-btn
          >
        </template>

        <template v-slot:action>
          <v-btn color="primary" @click="changeBusLineActiveStatus()">
            <v-icon small class="mr-2">
              {{ dialogTextValues.buttonIcon }}</v-icon
            >
            {{ dialogTextValues.button }}
          </v-btn>
        </template>
      </DialogModal>
      <v-btn
        color="primary"
        v-on:click="sendFile()"
        :disabled="uploading || (!fileWasChosen && !edited)"
      >
        <div v-if="uploading">Salvando</div>
        <div v-else>Salvar alterações</div>
        <v-progress-circular indeterminate v-if="uploading">
        </v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CustomAlert from "@/components/CustomAlert";
import { RouteFormStore } from "@/store/routeForm";
import TimePicker from "@/components/TimePicker";
import DialogModal from "@/components/DialogModal";
import { isNotEmptyRule } from "@/services/validation";
import { RouteService } from "@/services/route";
import { RegionService } from "@/services/region";
export default {
  name: "routesForm",
  components: {
    TimePicker,
    CustomAlert,
    DialogModal,
  },
  store: RouteFormStore,
  data: () => ({
    showAlert: false,
    showDialog: false,
    showDisableRouteDialog: false,
    alertMessageType: "default",
    alertType: "success",
    valid: true,
    file: {},
    fileWasChosen: false,
    edited: false,
    regions: [],
    activeStatusButtonText: "",
    dialogTextValues: {
      title: "",
      body: "",
      button: "",
      buttonIcon: "",
    },
    route: {
      id: "",
      stringKmz: "",
      departureTime: "",
      arrivalTime: "",
      busType: "",
      direction: "",
      transshipment: false,
      destinationSite: "",
      route: "",
      active: true,
    },
    busTypeOptions: [
      { text: "Micro Ônibus", value: "MICRO" },
      { text: "Ônibus", value: "BUS" },
    ],
    lineDirectionOptions: [
      { text: "IDA", value: "IDA" },
      { text: "VOLTA", value: "VOLTA" },
    ],
    uploading: false,
  }),
  methods: {
    isNotEmptyRule,
    sendFile: async function() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.uploading = true;
      try {
        if (this.fileWasChosen)
          this.route.stringKmz = await this.toBase64(this.file);
        this.route.arrivalTime = this.route.departureTime;
        await RouteService.editRoute(this.route);
        await this.uploadSuccess();
        this.$router.push("/admin-routes");
      } catch (error) {
        this.uploadError(error);
      }
      this.uploading = false;
    },

    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
      });
    },
    async uploadSuccess() {
      this.setAlertProps(true, "uploadSuccess");
    },
    uploadError(text) {
      this.setAlertProps(false, "uploadKmzError");
      window.alert(text.data);
    },
    setAlertProps(isSuccess, type) {
      if (isSuccess) {
        this.alertType = "success";
      } else {
        this.alertType = "error";
      }
      this.showAlert = true;
      this.alertMessageType = type;
    },
    closeAlert () {
      this.showAlert = false
    },
    backToAdminRoutes() {
      this.$router.push("/admin-routes");
    },
    getActiveButtonText() {
      if (this.route.active) {
        return "Desabilitar Rota";
      }

      return "Habilitar Rota";
    },
    setDialogValues() {
      if (this.route.active) {
        this.dialogTextValues.title = "Desabilitar Rota";
        this.dialogTextValues.body =
          "Todas as reservas serão canceladas no momento que a rota for desabilitada.";
        this.dialogTextValues.button = "Desabilitar Rota";
        this.dialogTextValues.buttonIcon = "mdi-map-marker-off";
      } else {
        this.dialogTextValues.title = "Habilitar Rota";
        this.dialogTextValues.body =
          "Após ativar a rota será possível cadastrar reservas vinculadas a ela.";
        this.dialogTextValues.button = "Habilitar Rota";
        this.dialogTextValues.buttonIcon = "mdi-map-marker-plus";
      }
    },
    async changeBusLineActiveStatus() {
      let busLineActiveStatus = {
        id: this.route.id,
        activate: !this.route.active,
      };

      try {
        await RouteService.changeBusLineActiveStatus(busLineActiveStatus);
        await this.uploadSuccess();
        this.$router.push("/admin-routes");
      } catch (error) {
        this.uploadError(error);
      }
    },
  },
  async beforeMount() {
    let getRegions = await RegionService.getAllRegions();
    this.regions = getRegions;
    this.route = RouteFormStore.getters.route;
    this.edited = false;
    this.activeStatusButtonText = this.getActiveButtonText();
    this.setDialogValues();
  },
};
</script>
<style>
#cancel-button {
  color: #007e7a;
  box-shadow: none;
  background-color: white;
}
</style>
