<template>
  <div class="pl-4 pr-0 py-3">
    <v-responsive
      class="overflow-y-auto"
      color="primary"
      max-height="calc(90vh - 200px)"
      ref="userExperience"
    >
      <div class="userExp">
        <v-sheet>
          <p class="question pr-4 pl-0 mt-0 ml-0">
            <b> Como tem sido a sua experiência no SUAvaga? </b>
          </p>
        </v-sheet>

        <v-sheet class="pl-0 pr-4">
          <v-item-group>
            <v-row no-gutters>
              <v-col v-for="assessment in assessments" :key="assessment.id">
                <v-item>
                  <v-sheet
                    class="d-flex flex-column"
                    @click="toggleAssessement(assessment)"
                  >
                    <v-icon
                      class="icon-assessement hover-icon align-self-center"
                      :color="assessment.active ? assessment.colorActive : ''"
                    >
                      {{ assessment.icon }}</v-icon
                    >
                    <p class="label-assessement" :color="colorDesactivated">
                      {{ assessment.label }}
                    </p>
                  </v-sheet>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-sheet>

        <v-sheet v-if="showComentary">
          <div class="feedback-assessement">
            <p>Agradecemos pelo seu feedback!</p>
            <p>Em que mais poderíamos melhorar?</p>
          </div>
          <v-textarea
            class="pt-4 pl-0 pr-4"
            outlined
            rows="3"
            placeholder="Deixe seu comentario (opcional)"
            v-model="comment"
          >
          </v-textarea>
          <div class="pl-0 pr-4 py-3">
            <template v-if="loading">
              <div class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
            </template>
            <v-btn v-else class="primary" @click="sendUserExperience" block>
              <v-icon class="mr-2">mdi-send-outline</v-icon>
              Enviar
            </v-btn>
          </div>
        </v-sheet>
      </div>
      <div style="width: 2px"></div>
    </v-responsive>
  </div>
</template>
<script>
import { UserExperience } from "@/services/userExperience";
import { EmployeeService } from "@/services/employee";

export default {
  name: "UserExperience",

  props: {},

  data: () => ({
    assessments: [
      {
        id: 1,
        value: "VERY_BAD",
        icon: "mdi-emoticon-angry",
        colorActive: "#D64A63",
        label: "Péssimo",
        active: false,
      },
      {
        id: 2,
        value: "BAD",
        icon: "mdi-emoticon-sad",
        colorActive: "#EC96A6",
        label: "Ruim",
        active: false,
      },
      {
        id: 3,
        value: "REGULAR",
        icon: "mdi-emoticon-neutral",
        colorActive: "#F1CB6E",
        label: "Regular",
        active: false,
      },
      {
        id: 4,
        value: "GOOD",
        icon: "mdi-emoticon-happy",
        colorActive: "#C2E4A8",
        label: "Boa",
        active: false,
      },
      {
        id: 5,
        value: "EXCELLENT",
        icon: "mdi-emoticon-excited",
        colorActive: "#99D56B",
        label: "Ótima",
        active: false,
      },
    ],
    colorDesactivated: "#C6C6C6",
    chosenAssesment: null,
    showComentary: false,
    comment: "",
    loading: false,
  }),
  methods: {
    toggleAssessement(assessment) {
      this.$emit("resize-menu");
      if (this.chosenAssesment.value == undefined) {
        let index = assessment.id - 1;

        this.chosenAssesment = assessment;
        this.assessments[index].active = true;
        this.showComentary = true;
      } else if (this.chosenAssesment.value !== assessment.value) {
        let lastIndex = this.chosenAssesment.id - 1;
        let index = assessment.id - 1;

        this.chosenAssesment = assessment;
        this.assessments[lastIndex].active = false;
        this.assessments[index].active = true;
        this.showComentary = true;
      }
    },

    async sendUserExperience() {
      try {
        this.loading = true;
        let request = {
          employeeId: this.employee.id,
          satisfactionLevel: this.chosenAssesment.value,
          comment: this.comment,
          dateTime: this.getCurrentDateTime(),
        };

        await UserExperience.saveUserExperience(request);
        this.$emit(
          "show-message",
          "Avaliação do sistema enviada com sucesso.",
          "success"
        );
        this.$emit("close-menu");
        this.loading = false;
      } catch (error) {
        this.$emit("show-message", error, "error");
        this.$emit("close-menu");
        this.loading = false;
      }
    },

    getCurrentDateTime() {
      const date = new Date();
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");

      const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}`;

      return formattedDateTime;
    },
  },

  async beforeMount() {
    this.chosenAssesment = {};

    this.employee = await EmployeeService.getEmployee(
      localStorage.getItem("registrationNumber")
    );
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";
.question {
  color: #6f6f6f;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
}

.label-assessement {
  color: #a8a8a8;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-top: 8px;
  text-align: center;
}

.icon-assessement {
  cursor: pointer;
  font-size: 45px;
}
.hover-icon {
  color: #c6c6c6;
}
.hover-icon:hover {
  color: #a8a8a8;
}

.feedback-assessement {
  color: #6f6f6f;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
}

.feedback-assessement > p {
  margin: 0;
  padding: 0;
}

::v-deep .v-text-field__details {
  display: none;
}

::v-deep .v-btn > .icon-assessement {
  font-size: 45px;
}

::v-deep .v-textarea textarea {
  font-size: 12px;
  margin-top: 4px !important;
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6f6f6f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6f6f6f;
}

::v-deep .v-responsive__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.userExp {
  width: 348px;
}

@media screen and (max-width: "768px") {
  .userExp {
    width: 100% !important;
  }
}
</style>
