<template>
  <v-card color="main-content fill-height pa-4">
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      customMessage=""
      @close-alert="closeAlert"
      @update="showAlert = $event"
      :key="componentKey"
    ></CustomAlert>
    <v-row>
      <v-col>
        <v-card-title>
          <h4 v-if="permission == 'admin' || permission == 'supervisor'">
            Ausências
          </h4>
          <h4 v-else>Minhas Ausências</h4>
        </v-card-title>
        <v-card-subtitle class="mb-4">
          Aqui você pode verificar as ausências que você já criou ou excluí-las.
        </v-card-subtitle>
      </v-col>
      <v-col class="mt-3 mr-3" align="right">
        <GenericDialog
          close
          :visible="showAbsenceReport"
          v-on:close-dialog="closeDialog"
          width="600px"
        >
          <template v-slot:header>
            <v-card-title
              class="px-0 py-0 ml-1"
              style="color: #393939; font-size: 24px"
            >
              {{ isEdit ? "Editar ausência": "Relatar ausência"}}
            </v-card-title>
          </template>

          <template v-slot:body>
            <AbsenceReport
              isDialog
              style="width: 100%"
              :isEdit="isEdit"
              :idAbsence="idAbsence"
              :absenceEdit="absenceEdit"
              :employeeText="employeeAbsence"
              :elevation="0"
              :key="componentKey"
              v-on:sucess-add-absence="successAddAbsence"
            >
            </AbsenceReport>
          </template>
        </GenericDialog>

        <template>
          <GenericButton
            title="Adicionar ausência"
            icon="mdi-account-off-outline"
            primary
            v-on:click="modalAddAbsence"
          >
          </GenericButton>
        </template>
      </v-col>
    </v-row>
    <v-card-text>
      <v-row v-if="permission == 'admin' || permission == 'supervisor'">
        <v-col class="col-3 mb-0 pb-0">
          <v-select
            class=""
            :rules="[isNotEmptyRule]"
            v-model="isPersonal"
            :items="isPersonalOptions"
            @change="setRegistrationNumber"
            v-if="permission !== 'employee'"
          >
          </v-select>
        </v-col>
        <v-col class="col-9 mb-0 pb-0">
          <v-autocomplete
            class=""
            v-model="registrationNumber"
            :items="employees"
            :item-text="getEmployeeText"
            item-value="registrationNumber"
            :rules="[isNotEmptyRule]"
            label="Matricula Vale ou CPF a quem a ausência é destinada"
            @input="getAbsences"
            v-if="!isPersonal"
            no-data-text="Digite pelo menos 6 digitos para buscar."
            v-on:keyup="getEmployees"
            :search-input.sync="employeeSearch"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="loading">
          <template>
            <div class="text-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </template>
        </v-col>
        <v-col v-if="!loading">
          <v-data-table
            :headers="headers"
            :items="absenceList"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            :items-per-page="10"
            item-key="id"
            class="elevation-1 mt-8"
            no-data-text="Nenhuma ausência encontrada"
          >
            <template v-slot:[`item.actions`]="{ item }">

              <v-tooltip top max-width="30%">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    color="disabled"
                    v-if="!isValidDateRange(item)"
                    >mdi-pencil-outline</v-icon
                  >
                </template>
                <span>Não é possível editar ausências passadas</span>
              </v-tooltip>

              <v-icon
                small
                class="mr-2"
                color="primary"
                @click="editAbsence(item)"
                v-if="isValidDateRange(item)"
                >mdi-pencil-outline
              </v-icon>

              <v-tooltip top max-width="30%">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    color="disabled"
                    v-if="!isValidDateRange(item)"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Não é possível remover ausências passadas</span>
              </v-tooltip>

              <DeleteConfirmation
                :dialog="showDialog"
                v-on:send-delete="deleteAbsence(item.id)"
                v-if="isValidDateRange(item)"
              ></DeleteConfirmation>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomAlert from "@/components/CustomAlert";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import AbsenceReport from "@/components/AbsenceReport";
import GenericDialog from "@/components/GenericDialog";
import { DateUtils } from "@/utils/dateUtils";
import { AbsenceService } from "@/services/absence";
import { EmployeeService } from "@/services/employee";
import { isNotEmptyRule } from "@/services/validation";
import GenericButton from "../components/GenericButton.vue";
import { PermissionService } from "../services/permission";

export default {
  name: "MyAbsences",
  components: {
    CustomAlert,
    DeleteConfirmation,
    AbsenceReport,
    GenericDialog,
    GenericButton,
  },
  data: () => ({
    absenceList: [],
    headers: [
      { text: "", value: "actions" },
      { text: "Razão", value: "reason" },
      { text: "Data Inicial", value: "startDate" },
      { text: "Data Final", value: "endDate" },
      { text: "", value: "data-table-expand" },
    ],
    showAbsenceReport: false,
    showAlert: false,
    showDialog: false,
    loading: false,
    alertMessageType: "default",
    alertType: "success",
    singleExpand: true,
    expanded: [],
    employees: [],
    employeeSearch: "",
    registrationNumber: "",
    isEdit: false,
    idAbsence: "",
    isPersonal: true,
    isPersonalOptions: [
      { text: "Minhas ausências", value: true },
      { text: "Outras ausências", value: false },
    ],
    componentKey: 0,
    employeeAbsence: "",
    absenceEdit: {},
  }),
  methods: {
    isNotEmptyRule,

    async getAllAbsence() {
      this.loading = true;
      if (this.registrationNumber != "") {
        var list = await AbsenceService.getMyAbsence(this.registrationNumber);
        this.absenceList = list;
        this.updateAbsenceListDates();
      } else {
        this.absenceList = [];
      }
      this.loading = false;
    },

    modalAddAbsence() {
      this.isEdit = false
      this.openDialog()
    },

    openDialog() {
      this.componentKey ++;
      this.showAlert=false;
      this.showAbsenceReport = true;
    },

    closeDialog() {
      this.showAbsenceReport = false;
    },

    successAddAbsence() {
      this.getAllAbsence();
      this.closeDialog();
      this.setAlertProps(true, "SaveAbsenceSuccess");
    },

    isValidDateRange(item) {
      return DateUtils.isValidDateRange(item);
    },

    closeAlert() {
      this.showAlert = false;
    },
    deleteError() {
      this.setAlertProps(false, "deleteError");
    },
    async deleteSuccess() {
      this.setAlertProps(true, "deleteSuccess");
    },
    setAlertProps(isSuccess, type) {
      if (isSuccess) {
        this.alertType = "success";
      } else {
        this.alertType = "error";
      }
      this.showAlert = true;
      this.alertMessageType = type;
    },
    async getEmployees() {
      this.employees = [];
      if (this.employeeSearch != null && this.employeeSearch.length > 5) {
        this.employees = await EmployeeService.getEmployeeSearch(
          this.employeeSearch
        );
      }
    },
    getEmployeeText(item) {
      let searchTerm = this.employeeSearch;
      if (this.employeeSearch && this.employeeSearch.includes(" - ")) { 
        searchTerm = this.employeeSearch.split(" - ")[0];
      }
      if(item.cpf != null && item.cpf.includes(searchTerm)){
        return `${item.cpf} - ${item.name} `;
      } else 
      if(item.registrationNumber != null && item.registrationNumber.includes(searchTerm.toUpperCase())){
        return `${item.registrationNumber} - ${item.name} `;
      }
    },
    async getAbsences(registrationNumber) {
      this.loading = true;
      if (registrationNumber != null) {
        let list = await AbsenceService.getMyAbsence(registrationNumber);
        this.absenceList = list;
        this.updateAbsenceListDates();
      } else {
        this.absenceList = [];
      }
      this.loading = false;
    },
    async setRegistrationNumber(isPersonal) {
      if (isPersonal) {
        this.registrationNumber = localStorage.getItem("registrationNumber");
        this.getAbsences(this.registrationNumber);
      } else {
        this.registrationNumber = "";
        this.absenceList = [];
      }
    },
    updateAbsenceListDates() {
      this.absenceList.forEach((absence) => {
        let initialDate = new Date(absence.startDate);
        let endDate = new Date(absence.endDate);

        initialDate.setTime(initialDate.getTime());
        endDate.setTime(endDate.getTime());

        initialDate = initialDate.toLocaleString("pt-BR", { timeZone: "UTC" });
        endDate = endDate.toLocaleString("pt-BR", { timeZone: "UTC" });

        absence.startDate = initialDate.split(" ")[0].replace(",", "");
        absence.endDate = endDate.split(" ")[0].replace(",", "");
      });
    },
    async deleteAbsence(absenceId) {
      try {
        await AbsenceService.deleteAbsence(absenceId);
      } catch {
        this.deleteError();
      } finally {
        this.deleteSuccess();
        this.absenceList = await AbsenceService.getMyAbsence(
          this.registrationNumber
        );
        this.updateAbsenceListDates();
      }
    },
    editAbsence(absence) {
      this.isEdit = true;
      this.employeeAbsence = absence.employee.name;
      this.idAbsence = absence.id
      this.absenceEdit = absence
      this.openDialog()
    }
  },
  async beforeMount() {
    this.permission = PermissionService.getPermission();
    this.isPersonal = true;
    this.setRegistrationNumber(this.isPersonal);
    this.getAllAbsence();
  },
  forceRerender() {
    this.componentKey += 1;
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;

  .invalid-input {
    color: $redVale;
  }

  .v-btn {
    text-transform: none;
  }
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 24px;
}
</style>
