<template>
  <v-card class="main-content pa-6 fill-height">
    <TabsView :componentsTab="componentsTab" @tab-changed="tabChanged">
      <template v-slot:contentTab>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <BookReservation
              style="width: 100%"
              @tab-changed="tabChanged"
              :key="bookReservationKey"
            ></BookReservation>
          </v-tab-item>

          <v-tab-item>
            <MyReservations
              style="width: 100%"
              @tab-changed="tabChanged"
              :key="myReservationsKey"
            ></MyReservations>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </TabsView>
  </v-card>
</template>

<script>
import TabsView from "../components/TabsView";
import BookReservation from "@/views/BookReservation.vue";
import MyReservations from "@/views/MyReservations.vue";

export default {
  name: "BookingTabs",
  components: {
    TabsView,
    BookReservation,
    MyReservations,
  },

  data: () => ({
    tab: null,
    componentsTab: [{ title: "Reservar assento" }, { title: "Reservas" }],
    listBooking: [],
    registrationNumber: "",
    bookReservationKey: 0,
    myReservationsKey: 0,
  }),

  methods: {
    tabChanged(tab) {
      this.tab = tab;
      if (tab === 0) {
        this.bookReservationKey++;
      } else if (tab === 1) {
        this.myReservationsKey++;
      }
    },
  },
};
</script>

<style lang="sass" scoped></style>