<template>
  <EmailSender ref="emailSender"> </EmailSender>
</template>

<script>
import EmailSender from "@/components/EmailSender";

export default {
  name: "CanceledBookingEmail",

  components: {
    EmailSender,
  },

  data: () => ({
    employeeName: "",
    region: "",
    route: "",
    endDate: "",
    startDate: "",
    to: "",
    removedBy: "",
    removedByEmail: "",
  }),

  props: {
    currentUser: Object,
  },
  computed: {
    userCurrent: {
      get: function () {
        return this.currentUser;
      },
    },
  },
  methods: {
    sendEmail(booking) {
      try {

        if (
          booking.employee.registrationNumber.toUpperCase() !==
          this.currentUser.registrationNumber.toUpperCase()
        ) {

          let paramsToEmployee = {
            to: booking.employee.email,
            subject: "Reserva de ônibus removida com sucesso",
            emailBody: "",
            employeeName: booking.employee.name,
            regionName: booking.busLine.region.name,
            busLineName: booking.busLine.code,
            bookingPeriod: this.getPeriodBooking(
              booking.startDate,
              booking.endDate
            ),
            administrador: false,
          };
          this.$refs.emailSender.postEmailDeleteBooking(paramsToEmployee);
        }

        let paramsToAdmin = {
          to: this.userCurrent.email,
          subject: "Reserva de ônibus removida com sucesso",
          emailBody: "",
          employeeName: booking.employee.name,
          regionName: booking.busLine.region.name,
          busLineName: booking.busLine.code,
          bookingPeriod: this.getPeriodBooking(
            booking.startDate,
            booking.endDate
          ),
          administrador: true,
        };

        this.$refs.emailSender.postEmailDeleteBooking(paramsToAdmin);
      } catch(e) {
        console.log("Erro ao enviar e-mail de exclusão de reservas !");
      }
    },

    getPeriodBooking(startDate, endDate) {
      if (this.compareDates(startDate, endDate)) {
        return `${startDate}`;
      }
      return `${startDate} à ${endDate}`;
    },

    compareDates(dateStart, dateEnd) {
      let startDatePart = dateStart.split("/");
      let endDatePart = dateEnd.split("/");

      let startDate = new Date(
        startDatePart[2],
        startDatePart[1] - 1,
        startDatePart[0]
      );
      let endDate = new Date(
        endDatePart[2],
        endDatePart[1] - 1,
        endDatePart[0]
      );

      return startDate.getTime() === endDate.getTime() ? true : false;
    },
  },
};
</script>

<style></style>
