<template>
  <v-card class="main-content pa-4 fill-height">
    <v-overlay v-model="overlay" class="align-center justify-center">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      customMessage=""
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>
    <v-row>
      <v-col>
        <v-card-title>
          <h4>Alterar colaboradores esperados por ponto de ônibus</h4>
        </v-card-title>
        <v-card-subtitle class="mb-1"> </v-card-subtitle>
      </v-col>
      <v-col class="pt-8 mr-5" align="right">
        <GenericButton
          class="mr-4"
          title="Cancelar"
          icon="mdi-close"
          secondary
          @click="cancelEdit"
        ></GenericButton>
        <GenericButton
          title="Salvar"
          icon="mdi-content-save-outline"
          primary
          @click="save"
        ></GenericButton>
      </v-col>
    </v-row>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :search="search"
              class="elevation-1 col-12"
              :items="busStops"
              :loading="loading"
            >
              <template v-slot:[`item.expectedEmployees`]="props">
                <v-text-field
                  :rules="[isPositiveNumber, isNotEmptyRule]"
                  v-model="props.item.expectedEmployees"
                  type="number"
                ></v-text-field>
              </template>
              <template v-slot:top>
                <v-row class="align-center justify-space-between px-4">
                  <v-col class="col-12">
                    <v-text-field
                      v-model="search"
                      label="Buscar"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomAlert from "@/components/CustomAlert";
import { RouteService } from "@/services/route";
import { isNotEmptyRule, isPositiveNumber } from "@/services/validation";
import GenericButton from "../components/GenericButton.vue";

export default {
  name: "adminExpectedEmployees",
  components: {
    CustomAlert,
    GenericButton,
  },
  props: ["busLineId"],
  data: () => ({
    busStops: [],
    showAlert: false,
    valid: true,
    overlay: false,
    loading: false,
    alertMessageType: "",
    search: "",
    alertType: "",
    headers: [
      { text: "Nome", value: "name", sortable: false },
      { text: "Referência", value: "reference", sortable: false },
      {
        text: "Colaboradores esperados",
        value: "expectedEmployees",
        sortable: false,
      },
    ],
  }),
  watch: {
    options: {
      handler() {
        this.getAllRoutes();
      },
      deep: true,
    },
  },
  methods: {
    isNotEmptyRule,
    isPositiveNumber,
    cancelEdit() {
      this.$router.push("/admin-expected-employees");
    },
    async save() {
      try {
        if (!this.$refs.form.validate()) {
          return;
        }
        this.overlay = true;
        let bodyBusStopRoute = this.busStops.map((element) => {
          return {
            id: element.id,
            idRoute: element.idRoute,
            expectedEmployee: element.expectedEmployees,
          };
        });
        await RouteService.saveBusStopRouteExpectedEmployee(bodyBusStopRoute);
        this.setAlertProps(true, "saveExpectedEmployeesSucess");
        this.$router.push("/admin-expected-employees");
        this.overlay = false;
      } catch (e) {
        this.overlay = false;
      }
    },
    setAlertProps(isSuccess, type) {
      if (isSuccess) {
        this.alertType = "success";
      } else {
        this.alertType = "error";
      }
      this.showAlert = true;
      this.alertMessageType = type;
    },
    closeAlert() {
      this.showAlert = false;
    },
  },
  async beforeMount() {
    try {
      this.overlay = true;
      this.busStops = await RouteService.getBuslineStops(this.busLineId);
      this.busStops.forEach((element) => {
        if (element.expectedEmployees == null) element.expectedEmployees = 0;
      });
      this.overlay = false;
    } catch (e) {
      this.overlay = false;
    }
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";
</style>
