import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "vuetify/lib/locale/pt";
Vue.use(Vuetify);


export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#008F83',
                secondary: '#FFFFFF',
                secondaryText: '#393939',
                accent: '#252727',
                success: '#69BE28',
                error: '#c94265',
                disabled: '#A8A8A8',
            },
            dark: {
                primary: '#006A61',
                secondary: '#FFFFFF',
                secondaryText: '#393939',
                success: '#69BE28',
                accent: '#252727',
                error: '#BB133E',
                disabled: '#A8A8A8',
            }
        },
        options: {
          customProperties: true, // Habilita o uso de variáveis CSS personalizadas
        },
        lang: {
          locales: { pt },
          current: "pt",
        },
    },
});
