<template>
  <v-dialog v-model="showDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <a v-bind="attrs" v-on="on" class="label mr-2">
        {{ label }}
        <v-icon small v-bind="attrs" v-on="on" color="primary"
          >mdi-delete
        </v-icon>
      </a>
    </template>

    <v-card>
      <v-card-title> Excluir </v-card-title>

      <v-card-text>
        Tem certeza de que gostaria de excluir este(s) registro(s)?
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <GenericButton
        class="mr-4"
          icon="mdi-close"
          title="Cancelar"
          secondary
          v-on:click="showDialog = false"
          v-if="!loading"
        >
        </GenericButton>
        <GenericButton
          icon="mdi-delete-outline"
          title="Excluir"
          primary
          v-on:click="sendDelete"
          v-if="!loading"
        >
        </GenericButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GenericButton from "../components/GenericButton.vue";

export default {
  name: "DeleteConfirmation",
  components: {
    GenericButton,
  },
  props: {
    dialog: Boolean,
    label: String,
  },

  data: function () {
    return {
      showDialog: JSON.parse(this.dialog),
      loading: false
    };
  },

  methods: {
    sendDelete() {
      this.$emit("send-delete");
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss">
@import "../style/variables.scss";

.label {
  font-size: 0.9rem;
  font-weight: bold;
  color: $greyVale !important;
}
</style>
