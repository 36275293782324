<template>
  <v-card class="main-content pa-4 fill-height">
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      :customMessage="customMessage"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>

    <v-card elevation="0">
      <v-card-title class="title-container">
        <b>Meu perfil</b>
        <span class="d-none d-md-inline-flex justify-end">
          <GenericButton
            v-if="!loading"
            title="Salvar"
            icon="mdi-content-save-outline"
            primary
            v-on:click="editEmployee"
            :disabled="!isEdited || !isValid"
          >
          </GenericButton>
          <template v-if="loading">
            <div class="text-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </template>
        </span>
      </v-card-title>
      <v-card-subtitle>
        Aqui você pode verificar suas informações e se quiser, poderá editá-las
      </v-card-subtitle>
    </v-card>

    <v-card outlined elevation="0" class="pa-6 pt-0 ma-2">
      <v-form ref="form" lazy-validation>
        <v-row no-gutters class="pt-6">
          <v-col cols="12" sm="5">
            <v-text-field
              label="Nome do passageiro"
              v-model="employee.name"
              :rules="[isNotEmptyRule]"
              placeholder="Insira..."
              outlined
              @input="fieldValueHasChanged"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="5" class="ml-sm-13 pl-sm-13">
            <v-text-field
              label="E-mail do passageiro"
              placeholder="Insira..."
              v-model="employee.email"
              :rules="employee.email != '' ? [validateEmail] : []"
              outlined
              :disabled="disabled"
              @input="fieldValueHasChanged"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="5">
            <v-select
              label="Funcionario Vale"
              placeholder="Selecione..."
              v-model="employee.isThirdParty"
              :items="isThirdPartyOptions"
              item-text="text"
              item-value="value"
              :rules="[isNotEmptyRule]"
              outlined
              :disabled="disabled"
              @input="fieldValueHasChanged"
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="5" class="ml-sm-13 pl-sm-13">
            <v-text-field
              label="Matricula Vale"
              placeholder="Insira..."
              v-model="employee.registrationNumber"
              :rules="[isNotEmptyRule]"
              maxlength="11"
              outlined
              :disabled="disabled"
              @input="fieldValueHasChanged"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="5">
            <v-text-field
              label="CPF"
              placeholder="Insira..."
              v-model="employee.cpf"
              :rules="[validateCPF, isNotEmptyRule]"
              v-mask="'###.###.###-##'"
              outlined
              :disabled="disabled"
              @input="fieldValueHasChanged"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="5" class="ml-sm-13 pl-sm-13">
            <v-select
              label="Categoria"
              placeholder="Selecione..."
              v-model="employee.category"
              :items="shifts"
              item-text="text"
              item-value="value"
              :rules="[isNotEmptyRule]"
              outlined
              :disabled="disabled"
              @input="fieldValueHasChanged"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="5">
            <v-select
              label="Regime de trabalho"
              placeholder="Selecione..."
              v-model="employee.shift"
              :items="workTypes"
              item-text="text"
              item-value="value"
              :rules="[isNotEmptyRule]"
              outlined
              :disabled="disabled"
              @input="fieldValueHasChanged"
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="5" class="ml-sm-13 pl-sm-13">
            <v-select
              label="Complexo"
              placeholder="Selecione..."
              v-model="employee.region"
              :items="regions"
              item-text="name"
              :rules="[isNotEmptyRule]"
              return-object
              outlined
              :disabled="disabled"
              @input="fieldValueHasChanged"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="5">
            <v-select
              label="Assento preferencial"
              placeholder="Selecione..."
              v-model="employee.preferential"
              :items="preferentialOptions"
              item-text="text"
              item-value="value"
              :rules="[isNotEmptyRule]"
              outlined
              :disabled="disabled"
              @input="fieldValueHasChanged"
            >
            </v-select>
          </v-col>
        </v-row>

        <div
          v-if="employee.addresses && employee.addresses.length > 0"
          class="subtitle-2 mb-n4"
        >
          Endereço residencial
        </div>
        <v-col class="content-address">
          <AddressDialog
            :address="employee.address"
            :registration-number="employee.registrationNumber"
            @address-updated="fieldValueHasChanged"
          ></AddressDialog>
        </v-col>
      </v-form>
    </v-card>
    <div class="caption mt-4 pl-2">
      <div v-if="permission != 'admin'" class="bottom-response" color="#A8A8A8">
        <v-icon small>mdi-information-outline</v-icon>
        Caso deseje alterar algum campo bloqueado exibido acima, por favor,
        entre em contato com seu administrador.
      </div>
      <div class="d-xl-none d-lg-none d-md-none mt-6">
        <template v-if="loading">
          <div class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </template>
        <GenericButton
          v-else
          title="Salvar"
          icon="mdi-content-save-outline"
          primary
          v-on:click="editEmployee"
          :disabled="!isEdited || !isValid"
        >
        </GenericButton>
      </div>
    </div>
  </v-card>
</template>

<script>
import AddressDialog from "@/components/AddressDialog";
import CustomAlert from "@/components/CustomAlert";
import { EmployeeFormStore } from "@/store/employeeForm";
import { EmployeeService } from "@/services/employee";
import { RegionService } from "@/services/region";
import { AddressService } from "@/services/address";
import {
  isNotEmptyRule,
  validateEmail,
  validateCPF,
} from "@/services/validation";
import GenericButton from "../components/GenericButton.vue";
import { PermissionService } from "../services/permission";

export default {
  name: "MyProfile",

  components: {
    AddressDialog,
    CustomAlert,
    GenericButton
  },
  store: EmployeeFormStore,
  data: () => ({
    permission: "",
    disabled: true,
    dialog: false,
    showAlert: false,
    isEdited: false,
    isValid: false,
    customMessage: "",
    alertMessageType: "default",
    alertType: "success",
    employee: {
      registrationNumber: "",
      name: "",
      email: "",
      cpf: "",
      region: "",
      category: "",
      shift: "",
      address: {},
    },
    employeeDefault: {},
    isThirdPartyOptions: [
      { text: "Sim", value: false },
      { text: "Não", value: true },
    ],
    workTypes: [
      { text: "Remoto", value: "REMOTE" },
      { text: "On Site", value: "ON_SITE" },
    ],
    shifts: [
      { text: "Adm", value: "ADM" },
      { text: "Turno", value: "TURNO" },
    ],
    preferentialOptions: [
      { text: "Sim", value: true },
      { text: "Não", value: false },
    ],
    regions: [],
    loading: false,
  }),

  async beforeMount() {
    this.employee = await EmployeeService.getEmployee(
      localStorage.getItem("registrationNumber")
    );
    this.employeeDefault = Object.assign({}, this.employee);
    this.oldAddress = Object.assign({}, this.employee.address);
    let getRegions = await RegionService.getAllRegions();
    this.regions = getRegions;

    this.permission = PermissionService.getPermission();
    if (this.permission === "admin") {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  },

  methods: {
    isNotEmptyRule,
    validateEmail,
    validateCPF,
    async editEmployee() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;

      try {
        await EmployeeService.updateEmployee(this.employee.id, this.employee);
        await this.saveNewAddresses(this.employee.address);
        this.editEmployeeSuccess();
      } catch (error) {
        if (error.status != 500) {
          this.saveErrorCustom(error.data);
        }
        this.saveError();
      } finally {
        this.loading = false;
        this.isEdited = false;
      }
    },
    async saveNewAddresses(address) {
      await AddressService.postAddress(
        this.employee.registrationNumber,
        address
      );
    },
    fieldValueHasChanged() {
      const {
        name,
        email,
        isThirdParty,
        registrationNumber,
        cpf,
        category,
        shift,
        region,
        preferential,
      } = this.employee;
      const {
        name: defaultName,
        email: defaultEmail,
        isThirdParty: defaultIsThirdParty,
        registrationNumber: defaultRegistrationNumber,
        cpf: defaultCpf,
        category: defaultCategory,
        shift: defaultShift,
        region: defaultRegion,
        preferential: defaultPreferential,
        address: defaultAddress,
      } = this.employeeDefault;

      const hasNameChanged =
        defaultName.localeCompare(name, { sensitivity: "accent" }) !== 0;
      const hasEmailChanged =
        defaultEmail.localeCompare(email, { sensitivity: "accent" }) !== 0;
      const hasIsThirdPartyChanged = defaultIsThirdParty !== isThirdParty;
      const hasRegistrationNumberChanged =
        defaultRegistrationNumber.localeCompare(registrationNumber, {
          sensitivity: "accent",
        }) !== 0;
      const hasCpfChanged =
        defaultCpf?.localeCompare(cpf.replaceAll(".", "").replaceAll("-", ""), {
          sensitivity: "accent",
        }) !== 0;
      const hasCategoryChanged =
        defaultCategory.localeCompare(category, { sensitivity: "accent" }) !==
        0;
      const hasShiftChanged =
        defaultShift.localeCompare(shift, { sensitivity: "accent" }) !== 0;
      const hasRegionChanged =
        defaultRegion.id?.localeCompare(region.id, {
          sensitivity: "accent",
        }) !== 0;
      const hasPreferentialChanged = defaultPreferential !== preferential;
      const hasAddressChanged =
        defaultAddress.formattedAddress.localeCompare(
          this.oldAddress.formattedAddress,
          { sensitivity: "accent" }
        ) !== 0;

      this.isEdited =
        hasNameChanged ||
        hasEmailChanged ||
        hasIsThirdPartyChanged ||
        hasRegistrationNumberChanged ||
        hasCpfChanged ||
        hasCategoryChanged ||
        hasShiftChanged ||
        hasRegionChanged ||
        hasPreferentialChanged ||
        hasAddressChanged;

      this.isAllMandatoryFieldsFilledIn();
    },
    isAllMandatoryFieldsFilledIn() {
      const { name, registrationNumber, cpf, preferential } = this.employee;

      const isNameValid = name !== "";
      const isRegistrationNumberValid = registrationNumber !== "";
      const isCpfValid = cpf !== "";
      const isPreferentialValid = preferential !== null;

      this.isValid =
        isNameValid &&
        isRegistrationNumberValid &&
        isCpfValid &&
        isPreferentialValid
          ? true
          : false;
    },
    editEmployeeSuccess() {
      this.alertType = "success";
      this.alertMessageType = "editEmployeeSuccess";
      this.customMessage = "Operação realizada com sucesso";
      this.showAlert = true;
    },
    saveErrorCustom(errorMessage) {
      this.alertType = "error";
      this.customMessage = errorMessage.message;
      this.showAlert = true;
    },
    saveError() {
      this.alertType = "error";
      this.alertMessageType = "SaveAddressError";
      this.showAlert = true;
    },
    saveSuccess() {
      this.alertType = "success";
      this.alertMessageType = "SaveAddressSuccess";
      this.showAlert = true;
    },
    closeAlert() {
      this.showAlert = false;
    },
    myEventHandler(e) {
      this.screenWidth = e.currentTarget?.innerWidth;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;

  .v-btn {
    text-transform: none;
  }

  .v-card__actions {
    position: absolute;
    bottom: 4vh;
    width: calc(100% - 2vw);
  }
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-address {
  max-width: 100%;
  padding: 0;
}

.bottom-response {
  width: 97.5%;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  width: 85%;
}

::v-deep
  .v-data-table
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  overflow-x: hidden;
  margin-top: 8px;
}

::v-deep
  .v-data-table
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row__cell {
  text-align: left;
}

::v-deep .v-card__subtitle,
.v-card__text,
.v-card__title {
  padding-left: 10px;
}

::v-deep .v-card__title[data-v-29ae29da] {
  padding-right: 10px;
}
</style>
