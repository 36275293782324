import { UserAgentApplication } from 'msal';
import { LoginService } from "@/services/login";

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_AZURE_AD_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/'+process.env.VUE_APP_AZURE_AD_TENANT_ID,
    redirectUri: process.env.VUE_APP_AZURE_AD_REDIRECT_URI,         
    postLogoutRedirectUri: process.env.VUE_APP_AZURE_AD_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    tokenRenewalOffsetSeconds: 300, 
  },
};


const msalInstance = new UserAgentApplication(msalConfig);


msalInstance.isTokenExpired = function() {    
  const expirationDate = this.getAccount().idToken.exp * 1000;
  const currentDate = new Date().getTime();
  return expirationDate < currentDate;
};

msalInstance.handleRedirectCallback((error, response) => {
  LoginService.getPermission(response);
});

msalInstance.renewToken = async function() {
  try {    
    const user = msalInstance.getAccount();
    if (user) {      
      const response = await msalInstance.acquireTokenSilent({
        forceRefresh: true,
        scopes: ['openid'],
        account: user,
      }); 
      localStorage.setItem("idToken",response.idToken.rawIdToken);           
    }    
  } catch (error) {
    console.error('Erro ao renovar o ID Token:', error);
    localStorage.clear();
  }       
};

msalInstance.revokeToken = async function()  {
  try {
    
    const user = msalInstance.getAccount();
    if (user) {     
      const response = await fetch('https://login.microsoftonline.com/'+process.env.VUE_APP_AZURE_AD_TENANT_ID+'/oauth2/v2.0/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `token=${user.idToken}`,
      });
      
      if (response.ok) {
        console.log('Token revogado com sucesso');
      } else {
        throw new Error('Erro ao revogar o token');
      } 
    }

    
  } catch (error) {
    console.error('Erro ao revogar o token:', error);
  }
}

export default msalInstance;
