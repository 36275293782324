<template>
  <v-card color="main-content fill-height py-4 px-6">
    <CustomAlert
      :dialog="showAlert"
      :type="alertType"
      :customMessage="customMessage"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>
    <v-card-title class="pl-0">
      <h4>Relatório por linha de ônibus</h4>
    </v-card-title>
    <v-card-subtitle class="mb-2 pl-0">
      Use o filtro abaixo para visualizar o histórico de reservas das linhas de
      ônibus selecionadas.
    </v-card-subtitle>
    <div class="form-search">
      <v-card-text class="pa-0">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row justify="space-between" no-gutters>
            <v-col class="col-5 pt-0">
              <DatePicker
                :label="'Início do histórico'"
                ref="startDate"
                :data.sync="request.startDate"
                :rules="[isNotEmptyRule]"
                @date-chosen="request.startDate = $event"
                @picked="clearEndDate"
                outlined
              ></DatePicker>
            </v-col>
            <v-col class="col-5 pt-0">
              <DatePicker
                :label="'Término do histórico'"
                ref="endDate"
                :data.sync="request.endDate"
                :min="minEndDate"
                @date-chosen="request.endDate = $event"
                @change-input="searchHistory"
                :rules="[isNotEmptyRule]"
                outlined
              ></DatePicker>
            </v-col>
          </v-row>

          <v-row justify="space-between" no-gutters>
            <v-col class="col-5 pt-0">
              <v-select
                label="Estado"
                ref="state"
                v-model="state"
                :items="statesOptions"
                item-text="nome"
                item-value="sigla"
                @change="getRegionByUF"
                @click:clear="clearInput('state')"
                clearable
                outlined
              />
            </v-col>
            <v-col class="col-5 pt-0">
              <v-select
                label="Complexo"
                ref="region"
                v-model="regionId"
                :items="regions"
                item-text="name"
                item-value="id"
                clearable
                :disabled="!state"
                @change="loadSites"
                @click:clear="clearInput('region')"
                outlined
              ></v-select>
            </v-col>
          </v-row>

          <v-row justify="space-between" no-gutters>
            <v-col class="col-5 py-0">
              <v-select
                v-model="site"
                ref="site"
                label="Site de destino"
                item-text="name"
                item-value="id"
                outlined
                :items="sites"
                :disabled="!regionId"
                clearable
                @change="getRouteOptions"
                @click:clear="clearInput('site')"
              />
            </v-col>
            <v-col class="col-5 py-0">
              <v-combobox
                id="comboboxBusLine"
                v-model="selectedBusLine"
                :items="routeOptions"
                :item-text="getRouteName"
                label="Selecione as linhas de ônibus desejadas"
                @change="setBusLineList"
                :disabled="disabledBusLine"
                outlined
              ></v-combobox>
            </v-col>
          </v-row>

          <v-row v-if="selectedBusLinesList.length > 0">
            <v-col class="pt-0">
              <span v-for="data in selectedBusLinesList" v-bind:key="data.id">
                <v-chip
                  :input-value="data.id"
                  close
                  @click:close="remove(data.id)"
                  class="mr-2 mb-2"
                >
                  {{ data.code }}
                </v-chip>
              </span>
            </v-col>
          </v-row>

          <InfoSearch
            :qtdeListInfo="bookingList.length"
            :showResult="showSearchResult"
          ></InfoSearch>

          <v-row class="mb-6" no-gutters>
            <v-col class="d-flex justify-end align-center" cols="12">
              <GenericButton
                class="mr-4"
                title="Limpar filtros"
                icon="mdi-filter-off-outline"
                secondary
                @click="clearSearch"
              ></GenericButton>
              <GenericButton
                title="Gerar relatório"
                icon="mdi-cached"
                primary
                @click="downloadCSVData"
                :disabled="!showSearchResult || bookingList.length === 0"
                v-if="!loading"
              ></GenericButton>
              <template v-if="loading">
                <div>
                  <v-progress-circular
                    style="width: 170px"
                    :size="25"
                    :width="2"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-data-table
            v-if="showDatable"
            :headers="headers"
            :items="bookingList"
            :items-per-page="10"
            item-key="id"
            class="booking-report elevation-1 mt-8"
            no-data-text="Nenhuma reserva encontrada"
          >
          </v-data-table>
        </v-row>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import CustomAlert from "@/components/CustomAlert";
import InfoSearch from "../components/InfoSearch.vue";
import GenericButton from "@/components/GenericButton";

import { isNotEmptyRule } from "@/services/validation";
import { BookingService } from "@/services/booking";
import { RegionService } from "@/services/region";
import { estados } from "@/store/estados";

export default {
  name: "BookingReport",
  components: {
    DatePicker,
    CustomAlert,
    InfoSearch,
    GenericButton,
  },
  data: function () {
    return {
      routeOptions: [],
      route: "",
      menu1: false,
      menu2: false,
      request: {
        busLinesIds: [],
        startDate: null,
        endDate: null,
        state: null,
        regionId: null,
        site: null,
      },
      loading: false,
      valid: false,
      customMessage: "",
      showAlert: false,
      invalidBusLineInput: false,
      showDatable: false,
      bookingList: [],
      selectedBusLinesList: [],
      selectedBusLinesIds: [],
      selectedBusLine: "",
      headers: [
        { text: "Matricula Vale", value: "employee.registrationNumber" },
        { text: "Nome", value: "employee.name" },
        { text: "Regime de trabalho", value: "employee.shift" },
        { text: "Terceiro", value: "employee.isThirdParty" },
        { text: "Regiao do passageiro", value: "employee.region" },
        { text: "Linha de onibus", value: "busLineCode" },
        { text: "Região da Linha de onibus", value: "busRegion" },
        { text: "Data de criacao da reserva", value: "creationDate" },
        { text: "Reserva recorrente", value: "daysRecurrence" },
        { text: "Data Inicial", value: "startDate" },
        { text: "Data Final", value: "endDate" },
        { text: "Segunda", value: "monday" },
        { text: "Terça", value: "tuesday" },
        { text: "Quarta", value: "wednesday" },
        { text: "Quinta", value: "thursday" },
        { text: "Sexta", value: "friday" },
        { text: "Sábado", value: "saturday" },
        { text: "Domingo", value: "sunday" },
      ],
      regions: [],
      regionId: null,
      state: "",
      statesOptions: estados,
      site: "",
      sites: [],
      alertType: "error",
      disabledBusLine: true,
      minEndDate: "",
      showSearchResult: false,
    };
  },
  methods: {
    isNotEmptyRule,
    getRouteName(item) {
      return `${item.code}`;
    },

    async searchHistory() {
      if (this.request.endDate == null || this.request.endDate == "") {
        this.showSearchResult = false;
        return;
      }
      try {
        if (this.regionId == "") {
          this.regionId = null;
        }
        this.request.busLinesIds = this.selectedBusLinesIds;
        this.request.state = this.state;
        this.request.site = this.site;
        this.request.regionId = this.regionId;
        this.loading = true;
        var list = await BookingService.getBooking(this.request);

        list.forEach((booking) => {
          booking.monday = booking.daysRecurrence.includes("monday")
            ? "X"
            : "-";
          booking.tuesday = booking.daysRecurrence.includes("tuesday")
            ? "X"
            : "-";
          booking.wednesday = booking.daysRecurrence.includes("wednesday")
            ? "X"
            : "-";
          booking.thursday = booking.daysRecurrence.includes("thursday")
            ? "X"
            : "-";
          booking.friday = booking.daysRecurrence.includes("friday")
            ? "X"
            : "-";
          booking.saturday = booking.daysRecurrence.includes("saturday")
            ? "X"
            : "-";
          booking.sunday = booking.daysRecurrence.includes("sunday")
            ? "X"
            : "-";
          if (booking.direction == "I") booking.direction = "IDA";
          if (booking.direction == "V") booking.direction = "VOLTA";
          if (booking.direction == "A") booking.direction = "AMBOS";
        });

        this.bookingList = list;

        this.invalidInput = false;

        this.showSearchResult = true;
      } catch (e) {
        this.loading = false;
        this.showSearchResult = false;
        this.generateReportError();
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    downloadCSVData() {
      this.loading = true;
      let csv =
        "CPF;Matricula Vale;Nome;Regime de trabalho;Terceiro;Complexo do passageiro;UF;Linha de onibus;Direcao;Complexo da linha de onibus;Site;Data de criacao da reserva;Reserva recorrente;" +
        "Data Inicial;Data Final;Segunda;Terça;Quarta;Quinta;Sexta;Sábado;Domingo\n";
      this.bookingList.forEach((booking) => {
        csv +=
          booking.employee.cpf +
          ";" +
          booking.employee.registrationNumber +
          ";" +
          booking.employee.name +
          ";" +
          this.isRemoteOrOnsite(booking.employee.shift) +
          ";" +
          this.isThird(booking.employee.isThirdParty) +
          ";" +
          booking.employee.region +
          ";" +
          booking.uf +
          ";" +
          booking.busLineCode +
          ";" +
          booking.direction +
          ";" +
          booking.busLineRegion +
          ";" +
          booking.site +
          ";" +
          this.formatDate(booking.creationDate) +
          ";" +
          this.getDayOcurrence(booking.daysRecurrence) +
          ";" +
          this.formatDate(booking.startDate) +
          ";" +
          this.formatDate(booking.endDate) +
          ";" +
          booking.monday +
          ";" +
          booking.tuesday +
          ";" +
          booking.wednesday +
          ";" +
          booking.thursday +
          ";" +
          booking.friday +
          ";" +
          booking.saturday +
          ";" +
          booking.sunday;
        csv += "\n";
      });

      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      const time = Date.now();
      const today = new Date(time);

      anchor.download =
        "consultaReservasPorLinhaEm" +
        today.toLocaleDateString().replace("/", "-") +
        ".csv";
      anchor.click();
      this.loading = false;
    },
    getDayOcurrence(listOcurrence) {
      if (listOcurrence != "") {
        return "Sim";
      } else {
        return "Nao";
      }
    },
    isRemoteOrOnsite(shift) {
      if (shift.toUpperCase() == "REMOTE") {
        return "Remoto";
      } else {
        return "On Site";
      }
    },
    isThird(employee) {
      if (employee) {
        return "Sim";
      } else {
        return "Nao";
      }
    },
    formatDate(date) {
      let data = date.split(" ")[0];
      if (!data) return null;
      if (data.indexOf("-") == -1) return date;
      const [year, month, day] = data.split("-");
      return `${day}/${month}/${year}`;
    },

    setMinEndDate() {
      let newDate;
      let dateParts;

      dateParts = this.request.startDate.split("/");
      newDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      newDate.setDate(newDate.getDate());
      this.minEndDate = newDate.toISOString().substring(0, 10);
    },
    clearEndDate() {
      this.request.endDate = null;
      this.setMinEndDate();
      this.showSearchResult = false;
      this.absenceList = [];
      this.$refs.endDate.reset();
    },
    clearInput(input) {
      if (input === "site") {
        this.$refs.site.reset();
      } else if (input === "region") {
        this.$refs.region.reset();
        this.$refs.site.reset();
      } else {
        this.$refs.state.reset();
        this.$refs.region.reset();
        this.$refs.site.reset();
      }
      this.selectedBusLinesIds = [];
      this.selectedBusLinesList = [];
      this.disabledBusLine = true;
      this.searchHistory();
    },
    clearSite(){
      this.sites = [];
      this.site = "";
    },
    clearListBusLine(){
      this.selectedBusLinesList = [];
      this.selectedBusLinesIds = []
    },
    setBusLineList() {
      if (this.selectedBusLinesList.includes(this.selectedBusLine)) {
        this.invalidBusLineInput = true;
        this.generateReportError("Essa linha de ônibus já foi selecionada");
      } else {
        this.invalidBusLineInput = false;
        if (
          this.selectedBusLine.id != "" &&
          this.selectedBusLine.id != null &&
          this.selectedBusLine.code != ""
        ) {
          this.selectedBusLinesList.push(this.selectedBusLine);
          this.selectedBusLinesIds.push(this.selectedBusLine.id);
          this.$nextTick(() => {
            this.selectedBusLine = "";
          });
          this.searchHistory();
        }
      }
    },

    remove(item) {
      const index = this.selectedBusLinesList.findIndex((object) => {
        return object.id === item;
      });
      if (index >= 0) {
        this.selectedBusLinesList.splice(index, 1);
        this.selectedBusLinesIds.splice(index, 1);
        this.searchHistory();
      }
    },

    clearSearch() {
      this.request = {
        busLinesIds: [],
        startDate: null,
        endDate: null,
        regionId: null,
      };

      this.$refs.form.reset();
      this.regionId = null;
      this.site = null;
      this.state = null;
      this.showSearchResult = false;
      this.disabledBusLine = true;

      (this.selectedBusLinesIds = []),
        (this.selectedBusLinesList = []),
        (this.showDatable = false);
    },

    generateReportError(errorMessage) {
      this.alertType = "error";
      this.showAlert = true;
      if (errorMessage != null) {
        this.customMessage = errorMessage;
      } else {
        this.customMessage = "Ocorreu um erro ao submeter a solicitação";
      }
    },

    async getRouteOptions() {
      this.disabledBusLine = true;

      this.routeOptions = await BookingService.getBookingBuslineByRegion(
        this.regionId,
        false
      );

      if (this.routeOptions.length < 1) {
        this.showAlert = true;
        this.alertType = "warning";
        this.customMessage =
          "Não existem linhas de ônibus cadastradas para esse complexo";
        return;
      }
      this.selectedBusLinesList = [];
      this.disabledBusLine = false;
      this.searchHistory();
    },
    closeAlert() {
      this.showAlert = false;
      this.customMessage = "";
    },
    async getRegionByUF() {
      this.regionId = "";
      this.regions = [];
      this.clearListBusLine();
      this.clearSite();
      this.regions = await RegionService.getRegionByUf(this.state);
      this.searchHistory();
    },
    async loadSites() {
      try {
      this.clearListBusLine();
      this.clearSite();
        this.sites = await RegionService.getSitesByRegionId(this.regionId);
        this.searchHistory();
      } catch {
      this.clearListBusLine();
      this.clearSite();
      }
    },
  },
  async beforeMount() {
    let getRegions = await RegionService.getAllRegions();
    this.regions = getRegions;
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";

.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;

  .invalid-input {
    color: $redVale;
  }
  .booking-report tr > td,
  .booking-report th {
    border-left: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    align-items: center;
  }
}

::v-deep .v-btn {
  text-transform: none;
}

.form-search {
  padding: 24px 134px 24px 24px;
  border-width: thin;
  border-style: solid;
  border-color: #e0e0e0;
  border-radius: 4px;
}
</style>
