<template>
  <v-img src="../assets/suavaga-color-logo.svg" max-width="180px"></v-img>
</template>

<script>
export default {
  name: 'ValeLogo',
  props: {}
}
</script>
