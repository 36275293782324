import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

export const RegionFormStore = new Vuex.Store({
    state: {
        region: {},
        operation: "Creation"
       
    },
    getters: {
        region: (state) => {
            return state.region;
        },
        operation: (state) => {
            return state.operation;
        }
    },
    mutations: {
        setRegionModel(state, region) {
            if (!region) {
                state.region = {
                    id: "",
                    name: "",
                    acceptThirdPartyBook: "" ,
                    acceptOtherRegionsBook: ""
                }
            } else {
                state.region = region;
            }
        },
        setOperation(state, operation) {
            state.operation = operation;
        }    
    }
});
