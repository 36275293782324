<template>
  <span>
    <v-row no-gutters v-if="showResult && qtdeListInfo > 0">
      <v-col cols="12">
        <p class="info-data">
          <v-icon small class="mr-2">mdi-information-outline</v-icon>
          <span v-if="qtdeListInfo === 1"
            >Para o intervalo de data selecionado, o relatório filtrado contém
            um total de 1 linha pronta para análise.</span
          >
          <span v-else
            >Para o intervalo de data selecionado, o relatório filtrado contém
            um total de {{ qtdeListInfo }} linhas prontas para
            análise.</span
          >
        </p>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="showResult && qtdeListInfo === 0">
      <v-col cols="12">
        <p class="info-data">
          <v-icon small class="mr-2">mdi-information-outline</v-icon>
          Para o intervalo de data selecionado, o relatório filtrado não contém
          nenhum resultado para análise.
        </p>
      </v-col>
    </v-row>
  </span>
</template>

<script>
export default {
  name: "InfoSearch",
  props: {
    qtdeListInfo: {
      type: Number,
      default: 0
    },
    showResult: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    result: {
      get() {
        return this.showResult;
      },
      set(value) {
        this.$emit("update", value)
      },
    },
    qtdeInfo: {
      get() {
        return this.qtdeListInfo;
      },
      set(value) {
        this.$emit("update", value)
      }
    }

  },
};

</script>

<style scoped>
.info-data {
  margin-bottom: 24px;
  padding: 0;
  color: #6F6F6F;
}
</style>
