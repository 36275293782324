<template>
  <v-card class="main-content pa-4 fill-height">
    <CustomAlert
      :dialog="showAlert"
      :message-type="alertMessageType"
      :type="alertType"
      :customMessage="customMessage"
      @close-alert="closeAlert"
      @update="showAlert = $event"
    ></CustomAlert>

    <v-row align="center">
      <v-col align-self="center" cols="8">
        <v-card-title>
          <h4>Administrar Pontos de Ônibus</h4>
        </v-card-title>
        <v-card-subtitle>
          Aqui você pode visualizar, adicionar, editar ou excluir os pontos de
          ônibus.
        </v-card-subtitle>
      </v-col>
      <v-col class="mr-6">
        <v-row justify="end" class="mb-4">
          <GenericButton
            title="Adicionar ponto de ônibus"
            icon="mdi-map-marker-plus-outline"
            primary
            @click="insertBusStop"
          ></GenericButton>
        </v-row>
      </v-col>
    </v-row>
    <v-card-text>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            class="elevation-1 col-12"
            :items="busStops"
            item-key="id"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': [10],
            }"
          >
            <template v-slot:top>
              <v-row>
                <v-col class="col-3">
                  <v-select
                    label="Complexo"
                    v-model="filters.regionId"
                    :items="regions"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    class="mt-1"
                    @keyup.enter="getFilteredBusStops"
                  />
                </v-col>
                <v-col class="col-3">
                  <v-text-field
                    label="Nome"
                    v-model="filters.name"
                    outlined
                    dense
                    class="mt-1"
                    @keyup.enter="getFilteredBusStops"
                  ></v-text-field>
                </v-col>
                <v-col class="col-3">
                  <v-text-field
                    label="Referência"
                    v-model="filters.reference"
                    outlined
                    dense
                    class="mt-1"
                    @keyup.enter="getFilteredBusStops"
                  ></v-text-field>
                </v-col>
                <v-col class="col-3 mt-1">
                  <GenericButton
                    class="mr-4"
                    title="Limpar"
                    icon="mdi-filter-off-outline"
                    tertiary
                    @click="clearFilter"
                  ></GenericButton>
                  <GenericButton
                    title="Buscar"
                    icon="mdi-magnify"
                    secondary
                    @click="getFilteredBusStops"
                  ></GenericButton>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-1"
                color="primary"
                @click="editBusStop(item, true)"
                >mdi-pencil-outline</v-icon
              >
              <DeleteConfirmation
                :dialog="showDialog"
                :value="item.id"
                v-on:send-delete="deleteBusStop(item.id)"
              ></DeleteConfirmation>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-progress-circular indeterminate v-if="uploading" />
    </v-card-text>
  </v-card>
</template>

<script>
import CustomAlert from "@/components/CustomAlert";
import DeleteConfirmation from "@/components/DeleteConfirmation";
import { RegionService } from "@/services/region";
import { isNotEmptyRule } from "@/services/validation";
import { BusStopsService } from "@/services/busStops";
import { BusStopFormStore } from "@/store/busStopForm";
import GenericButton from "../components/GenericButton.vue";
import { PermissionService } from "../services/permission";

export default {
  name: "BusStopsManagement",
  components: {
    CustomAlert,
    DeleteConfirmation,
    GenericButton,
  },
  store: BusStopFormStore,
  data: () => ({
    filters: {
      name: "",
      reference: "",
      regionId: "",
    },
    regions: [],
    showAlert: false,
    showDialog: false,
    alertMessageType: "default",
    alertType: "success",
    headers: [
      { text: "", value: "actions", sortable: false },
      { text: "Complexo", value: "region.name", sortable: false },
      { text: "Nome", value: "name", sortable: false },
      { text: "Referência", value: "reference", sortable: false },
    ],
    uploading: false,
    loading: true,
    busStops: [],
    showAddBusStopDialog: false,
    busStop: {
      name: "",
      address: "",
      reference: "",
      latitude: "",
      longitude: "",
      regionId: "",
    },
    loadingAdd: false,
    customMessage: "",
  }),
  methods: {
    isNotEmptyRule,
    clearFilter() {
      this.filters = {
        name: "",
        reference: "",
        regionId: "",
      };
      this.getFilteredBusStops();
    },
    deleteSuccess() {
      this.setAlertProps(true, "deleteSuccess");
    },
    deleteError() {
      this.setAlertProps(false, "deleteError");
    },
    editBusStop(busStop, editModel) {
      if (busStop.region) busStop = { ...busStop, regionId: busStop.region.id };
      else busStop = { ...busStop, regionId: null };
      BusStopFormStore.commit("setBusStopModel", busStop);
      BusStopFormStore.commit("setEditMode", editModel);
      this.$router.push("/bus-stop-form");
    },
    insertBusStop() {
      BusStopFormStore.commit("setBusStopModel", {});
      BusStopFormStore.commit("setEditMode", false);
      this.$router.push("/bus-stop-form");
    },
    async getAllBusStops() {
      try {
        this.loading = true;
        this.busStops = await BusStopsService.getBusStops();
        this.loading = false;
      } catch (e) {
        this.alertType = "error";
        this.customMessage = e.data.message;
        this.showAlert = true;
      }
    },
    async getFilteredBusStops() {
      this.loading = true;
      if (this.filters.regionId == null) {
        this.filters.regionId = "";
      }
      if (
        this.filters.reference == null ||
        this.filters.reference == undefined
      ) {
        this.filters.reference = "";
      }

      if (this.filters.name == null || this.filters.name == undefined) {
        this.filters.name = "";
      }
      this.busStops = await BusStopsService.getFilteredBusStops(this.filters);
      this.loading = false;
    },
    async deleteBusStop(id) {
      try {
        await BusStopsService.deleteBusStop(id);
        this.alertType = "success";
        this.showAlert = true;
        this.alertMessageType = "defaultSuccess";
        this.getFilteredBusStops();
      } catch (e) {
        this.alertType = "error";
        this.customMessage = e.data.message;
        this.showAlert = true;
      }
    },
    closeAlert() {
      this.showAlert = false;
    },
    setAlertProps(isSuccess, type) {
      if (isSuccess) {
        this.alertType = "success";
      } else {
        this.alertType = "error";
      }
      this.showAlert = true;
      this.alertMessageType = type;
    },
  },
  async beforeMount() {
    this.permission = PermissionService.getPermission();
    if (this.permission !== "admin") {
      this.$router.push("home");
    }

    this.regions = await RegionService.getAllRegions();
    this.getAllBusStops();

    if (this.$route.query.showAlert) {
      this.setAlertProps(true, "defaultSuccess");
    }
  },
};
</script>

<style scoped lang="scss">
@import "../style/variables.scss";
.main-content {
  width: 66.6%;
  box-shadow: -2px 2px 8px 0px #353b463d;
  color: $greyVale;

  .v-card-subtitle {
    color: $greyVale40;
  }

  .v-card-text {
    text-align: center;
  }
}

.v-btn {
  text-transform: none;
}
.search {
  color: $greyVale40;
}

::v-deep .v-data-table-header {
  background-color: #f4f4f4;
  text-align: center;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr {
  th:first-child {
    gap: 8px;
    padding: 0 24px 0 24px;
    text-align: center !important;
    width: 84px;
  }

  th:nth-child(2) {
    gap: 8px;
    text-align: center !important;
    width: 240px;
  }

  th:nth-child(3) {
    gap: 8px;
    padding: 0 12px 0 12px;
    text-align: center !important;
    width: 300px;
  }

  th:nth-child(4) {
    gap: 8px;
    padding: 0 12px 0 12px;
    text-align: center !important;
    width: 300px;
  }
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr {
  td {
    text-align: center !important;
  }
}
</style>
